import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import {toast} from "react-hot-toast";
import CustomersSink from "./customers-sink";

export interface CustomersDetailInterface {
	id: string;
	info: {
		name: string;
		phoneNumber: string;
		emailAddress: string;
		residence: {
				streetAndNumber: string;
				cityAndPostalCode: string;
		};
		companyIdNumber: string;
		note: string;
	};
	finance: {};
	statistics: {
		projectsCount: number;
		timerItemsCount: number;
	};
}

@sink("customers-detail", SinkFactory)
export default class DetailSink {

	constructor(factory: SinkContainer) {
		this.customers = factory.getSink(CustomersSink);
	}

	customers: CustomersSink;

	@state opened: boolean = false;

	@state id: string | null = null;

	@state loading: boolean = true;

	@state data: CustomersDetailInterface | null = null;

	@effect
	open(id: string) {
		this.opened = true;
		this.id = id;
	}

	@effect
	async load(id: string) {
		this.loading = true;
		const result = await Api.fetch(id);
		if(result !== undefined) {
			this.data = result;
			this.loading = false;
		}
	}

	@trigger("customers-detail/id", { formatter: (id) => id })
	async idTrigger(id: string | null) {
		if(id !== null) await this.load(id as string);
	}

	@effect
	close() {
		this.opened = false;
		this.clear();
	}

	@effect
	clear() {
		this.opened = false;
		this.loading = true;
		this.id = null;
		this.data = null;
	}

	@effect
	async delete() {
		if (this.id !== null) {
			this.loading = true
			const toastId = toast.loading('Probíhá mazání');
			const result = await Api.delete(this.id)
			toast.dismiss(toastId);
			if(result) {
				this.clear();
				await this.customers.loadTable();
				toast.success('Zákazník smazán');
			}
			else toast.error('Nastala chyba');
			this.loading = false;
		}
	}

}
