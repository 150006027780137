import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import styled, {keyframes} from "styled-components";
import {FormContainer, Space, Title} from "../../components/Form";
import {ReactComponent as DarkLogoSVG} from "../../images/TwoDoLogoDark.svg";
import {ReactComponent as LightLogoSVG} from "../../images/TwoDoLogoLight.svg";
import ThemeControl from "../../components/Controls/ThemeControl";
import FormInput, {FormInputLoader} from "../../components/Controls/FormInput";
import {faEnvelope, faKey} from "@fortawesome/pro-light-svg-icons";
import FormButton, {FormButtonLoader} from "../../components/Controls/FormButton";
import SignInSink from "./storage/sign-in-sink";
import {useSink} from "react-redux-sink";
import NavigationSink from "../../store/navigation-sink";
import Routes from "../../routes";
import IdentitySink from "../../store/identity-sink";
import ThemeSink from "../../store/theme-sink";
import {ReactComponent as RocketDarkSVG} from "../../images/twdo_rocket-dark.svg";
import {ReactComponent as RocketLightSVG} from "../../images/twdo_rocket-light.svg";

//#region styled
export const Centered = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
`;
export const DarkLogo = styled(DarkLogoSVG)`
	width: 138.8px;
	height: 36px;
	position: absolute;
	top: 36px;
	left: 36px;
`;
export const LightLogo = styled(LightLogoSVG)`
	width: 138.8px;
	height: 36px;
	position: absolute;
	top: 36px;
	left: 36px;
`;
export const Copyright = styled.p`
	margin: unset;
	width: 100%;
	text-align: center;
	position: absolute;
	bottom: 29px;
	color: #C1C9CC;
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
`;
const flight = keyframes`
    0% { 
        left: -100%;
        top: 100%;
    }
    100% { 
        left: -10%;
        top: 0;
    }
`;
export const RocketContainer = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
`;
export const RocketIconDark = styled(RocketDarkSVG)<{width: number, height: number }>`
	width: 100%;
	transform: rotateZ(-${props => Math.atan((0.9*props.height) / (0.9*props.width)) * 180 / Math.PI}deg);
	position: relative;
	left: -10%;
	animation: ${flight} 2s;
`;
export const RocketIconLight = styled(RocketLightSVG)<{width: number, height: number }>`
	width: 100%;
	transform: rotateZ(-${props => Math.atan((0.9*props.height) / (0.9*props.width)) * 180 / Math.PI}deg);
	position: relative;
	left: -10%;
	animation: ${flight} 2s;
`;
//#endregion

const SignIn: React.FC = () => {
	const signIn = useSink(SignInSink);
	const navigation = useSink(NavigationSink);
	const identity = useSink(IdentitySink);
	const theme = useSink(ThemeSink);

	useEffect(() => {
		if(identity.data !== null) navigation.history.push(Routes.paths.TIMER_OVERVIEW);
		return () => {
			signIn.clear();
		};
	}, []);


	return (
		<>
			<RocketContainer>
				{theme.theme === "dark" && <RocketIconDark
					width={window.innerWidth}
					height={window.innerHeight}
				/>}
				{theme.theme === "light" && <RocketIconLight
					width={window.innerWidth}
					height={window.innerHeight}
				/>}
			</RocketContainer>
			{theme.theme === "light" && <LightLogo/>}
			{theme.theme === "dark" && <DarkLogo/>}
			<ThemeControl/>
			<Centered>
				<FormContainer onSubmit={(e) => {
					e.preventDefault();
					signIn.submit().then();
					return false;
				}}>
					<Title>Přihlášení</Title>
					{!signIn.loading && <FormInput value={signIn.email} onChange={({currentTarget}) => signIn.changeEmail(currentTarget.value)} icon={faEnvelope} placeholder={'Email'}/> || <FormInputLoader/>}
					<Space/>
					{!signIn.loading && <FormInput value={signIn.password} onChange={({currentTarget}) => signIn.changePassword(currentTarget.value)} icon={faKey} placeholder={'Heslo'} isSecret={true}/> || <FormInputLoader/>}
					{!signIn.loading && <FormButton content={'Přihlásit se'}/> || <FormButtonLoader/>}
				</FormContainer>
			</Centered>
			<Copyright>Informační systém TwoDo. Verze 2.0.0</Copyright>
		</>
	);
};
export default SignIn;
