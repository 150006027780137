import React, {useEffect} from "react";
import ListHeader from "../../components/Headers/ListHeader";
import {faPen, faProjectDiagram, faTable, faUndo, faUserFriends, faUserTie} from "@fortawesome/pro-regular-svg-icons";
import Routes from "../../routes";
import {toast} from "react-hot-toast";
import {
	BorderButtonLoader,
	ColumnGraphLoader,
	PaginationLoader,
	RangeControlLoader,
	TableLoader,
	ToggleLoader
} from "../../components/Loaders";
import {Pad24, Pad36, Space12, Space36} from "../../components/Creator";
import TimelineGraphsSink from "./storage/timeline-graphs-sink";
import {useSink} from "react-redux-sink";
import Switcher from "../../components/Controls/Switcher";
import Filters from "../../components/Filters";
import DateRangeControl from "../../components/Controls/DateRangeControl";
import moment from "moment";
import Permission from "../../components/Permissions";
import Permissions from "../../permissions";
import ToggleButton from "../../components/Controls/ToggleButton";
import BorderButton from "../../components/Controls/BorderButton";
import ThemeSink from "../../store/theme-sink";
import FilterSink from "../../store/filter-sink";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import UsersSink from "../Users/storage/users-sink";
import ProjectsSink from "./storage/projects-sink";
import CustomersSink from "../Customers/storage/customers-sink";

const TimelineGraphs: React.FC = () => {
	const timeline = useSink(TimelineGraphsSink);
	const theme = useSink(ThemeSink);
	const filter = useSink(FilterSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const picker = useSink(DateTimePickerSink);
	const users = useSink(UsersSink);
	const projects = useSink(ProjectsSink);
	const customers = useSink(CustomersSink);

	useEffect(() => {
		toast('Pracuje se na tom', {icon: '😎'})
	}, []);

	return (
		<>
			<ListHeader
				title={'Timeline'}
				icon={faTable}
				menu={[
					{ label: 'Grafy', active: true, link: Routes.paths.PROJECTS_TIMELINE_GRAPHS },
					{ label: 'Tabulky', active: false, link: Routes.paths.PROJECTS_TIMELINE_TABLES }
				]}
			/>
			<Pad24/>
			<Filters
				left={[
					<>{true && <DateRangeControl
						id={'timer-brief-filter-dates'}
						from={filter.from}
						to={filter.to}
						rangeStart={moment().toDate()}
						rangeEnd={moment().toDate()}
						goPrev={(from, to) => {
							toast('Téměř hotovo', {icon: '😎'})
						}}
						goNext={(from, to) => {
							toast('Téměř hotovo', {icon: '😎'})
						}}
						onClick={() => {
							picker.toggle('timer-brief-filter-dates', {from: filter.from, to: filter.to});
						}}
					/> || <RangeControlLoader/>}</>,
					<Space36/>,
					<Permission permissions={[Permissions.TIMER_LIST_ALL]}>
						{true && <ToggleButton
							id={'timer-brief-filter-users'}
							selected={filter.users.length > 0}
							active={multiCheckboxPicker.id === 'timer-brief-filter-users'}
							onClick={async () => {
								multiCheckboxPicker.toggle('timer-brief-filter-users');
								multiCheckboxPicker.setSelected(filter.users);
								await users.loadList();
							}}
							icon={faUserFriends}
							label={'Uživatelé'}
						/> || <ToggleLoader/>}
						<Space12/>
					</Permission>,
					<>{true && <ToggleButton
						id={'timer-brief-filter-customers'}
						selected={filter.customers.length > 0}
						active={multiCheckboxPicker.id === 'timer-brief-filter-customers'}
						onClick={async () => {
							multiCheckboxPicker.toggle('timer-brief-filter-customers');
							multiCheckboxPicker.setSelected(filter.customers);
							await customers.loadList();
						}}
						icon={faUserTie}
						label={'Zákazníci'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{true && <ToggleButton
						id={'timer-brief-filter-projects'}
						selected={filter.projects.length > 0}
						active={multiCheckboxPicker.id === 'timer-brief-filter-projects'}
						onClick={async () => {
							multiCheckboxPicker.toggle('timer-brief-filter-projects');
							multiCheckboxPicker.setSelected(filter.projects);
							await projects.loadList();
						}}
						icon={faProjectDiagram}
						label={'Projekty'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{true && <ToggleButton
						id={'timer-brief-filter-description'}
						selected={filter.search.length > 0}
						active={multiCheckboxPicker.id === 'timer-brief-filter-description'}
						onClick={async () => {
							multiCheckboxPicker.toggle('timer-brief-filter-description');
						}}
						icon={faPen}
						label={'Popis'}
					/> || <ToggleLoader/>}</>
				]}
				right={[
					<>{true &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'}
												color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			<Switcher items={[
				{title: 'Projekty v čase', active: true, onClick: () => true},
				{title: 'Lidé na projektech', active: false, onClick: () => true},
				{title: 'Timeline vs Realita', active: false, onClick: () => true}
			]}/>
			<Pad24/>
			<ColumnGraphLoader count={10}/>
			<Pad24/>
		</>
	);
};

export default TimelineGraphs;
