import React from "react";
import {
	faHistory,
	faPlus, faStopwatch
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import {TextItem} from "../../components/Table/Items";
import {useSink} from "react-redux-sink";
import {Pad24} from "../../components/Creator";

const PropertyRecords: React.FC = () => {
	return (
		<>
			<ListHeader
				title={'Záznamy'}
				icon={faHistory}
			/>
			<Table
				header={[
					{label: "Popis", sortable: true},
					{label: "Uživatel", sortable: true},
					{label: "Datum", sortable: true}
				]}
				items={[
					{
						children: [
							<TextItem content={'5'}/>,
							<TextItem content={'5'}/>,
							<TextItem content={'122'}/>,
						]
					}
				]}
			/>
			<Pagination/>
			<Pad24/>
		</>
	);
};

export default PropertyRecords;
