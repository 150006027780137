import Production from "./backend";
import {UsersListItemInterface, UsersTableItemInterface} from "../storage/users-sink";
import {UsersDetailInterface} from "../storage/detail-sink";
import {StructureTableItemInterface} from "../storage/structure-sink";
import {PositionItemInterface} from "../storage/position-sink";
import {EmploymentItemInterface} from "../storage/employment-sink";

export interface FetchAllApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: UsersTableItemInterface[];
		pageNumber: number;
		pageSize: number;
		totalCount: number;
	};
}

export interface FetchDetailApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: UsersDetailInterface;
}

export interface StatusApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
}

export interface FetchListApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: UsersListItemInterface[];
	};
}

export interface FetchStructureApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		structure: StructureTableItemInterface[];
	};
}
export interface FetchEmploymentsApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: EmploymentItemInterface[];
	};
}
export interface FetchPositionsApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: PositionItemInterface[];
	};
}

export interface ApiCalls {
	fetchAll: (active: boolean, page: number, limit: number, search: string, roles: string[], positions: string[], employments: string[]) => Promise<FetchAllApiResult>;
	fetchStructure: () => Promise<void | StructureTableItemInterface[]>;
	fetchList: (withWage?: boolean, name?: string, onlyActive?: boolean) => Promise<UsersListItemInterface[]>;
	fetch: (id: string) => Promise<UsersDetailInterface>;
	save: (id: string | null, name: string, email: string, phone: string, address: string, birthDate: Date, startDate: Date, hourlyWage: number, positions: number[], roles: string[], employment: number, hourlyLoad: string, availability: string, note: string, identificationNumber: string, bankAccount: string, asanaUrl: string, sharePointUrl: string, seniorUserId?: string) => Promise<boolean>;
	archive: (id: string) => Promise<boolean>;
	resetPassword: (id: string) => Promise<boolean>;
	delete: (id: string) => Promise<boolean>;
	fetchEmployments: () => Promise<FetchEmploymentsApiResult>;
	fetchPositions: () => Promise<FetchPositionsApiResult>;
}

export default process.env.NODE_ENV === "production" ? Production : Production;
