import {effect, sink, SinkContainer, SinkFactory, state} from "react-redux-sink";
import moment from "moment";
import {toast} from "react-hot-toast";
import Api from "../api";
import Routes from "../../../routes";
import IdentitySink from "../../../store/identity-sink";
import TimerSink from "./timer-sink";
import TimerStatisticsSink from "./timer-statistics-sink";
import NavigationSink from "../../../store/navigation-sink";

interface TimerItemEditorValidationProps {
	user: boolean;
	project: boolean;
	time: boolean;
}

interface DateProps {
	from: Date;
	to: Date;
}

@sink("timer-item-editor", SinkFactory)
export default class TimerItemEditorSink {

	constructor(factory: SinkContainer) {
		this.identity = factory.getSink(IdentitySink);
		this.timer = factory.getSink(TimerSink);
		this.statistics = factory.getSink(TimerStatisticsSink);
		this.navigation = factory.getSink(NavigationSink);
		const def = moment().toDate();
		this.dates = { from: def, to: def };
	}

	identity: IdentitySink;

	timer: TimerSink;

	statistics: TimerStatisticsSink;

	navigation: NavigationSink;

	@state id: string | null = null;

	@state description: string = "";

	@state user: string | null = null;

	@state project: string | null = null;

	@state dates: DateProps;

	@state saving: boolean = false;

	@state validation: TimerItemEditorValidationProps = {
		user: true,
		project: true,
		time: true
	};

	@effect
	initialize(id: string, description: string, project: string | null, user: string | null, from: Date, to: Date) {
		this.id = id;
		this.description = description;
		this.project = project;
		this.user = user;
		this.dates = { from, to }
	}

	@effect
	changeDescription(description: string) {
		this.description = description;
	}

	@effect
	changeUser(user: string | null) {
		this.user = user;
	}

	@effect
	changeProject(project: string | null) {
		this.project = project;
	}

	@effect
	changeDates(from: Date, to: Date) {
		this.dates = { from, to }
	}

	@effect
	async submit() {
		const valid = this.createValidation();
		this.validate(valid);
		if(TimerItemEditorSink.isValid(valid) && this.id !== null) {
			this.saving = true;
			const toastId = toast.loading('Probíhá ukládání...');
			const result = await Api.updateRecord(this.id, this.description, this.dates.from as Date, this.dates.to as Date, this.project!, this.user ?? undefined);
			toast.dismiss(toastId);
			if(result) {
				toast.success('Záznam upraven');
				this.clear();
				if(this.navigation.location.pathname.startsWith(Routes.paths.TIMER_REPORT_DETAIL)) await this.timer.loadTable();
				else await this.timer.loadTable(true, this.identity.data?.id);
				await this.statistics.loadTodayYesterday();
			}
			this.saving = false;
		}
		else toast.error('Formulář obsahuje nevalidní data');
	}

	private static isValid (obj: TimerItemEditorValidationProps): boolean {
		return obj.time && obj.project && obj.user;
	}

	private createValidation(): TimerItemEditorValidationProps {
		let v = {
			user: true,
			project: true,
			time: true
		};
		if(this.user === null) v.user = false;
		if(!this.dates.from || !this.dates.to || (this.dates.from && this.dates.to && moment(this.dates.to).isBefore(moment(this.dates.from)))) v.time = false;
		return v;
	}

	@effect
	validate(obj: TimerItemEditorValidationProps) {
		this.validation = obj;
	}

	@effect
	clear() {
		const def = moment().toDate();
		this.description = "";
		this.user = null;
		this.project = null;
		this.dates = { from: def, to: def };
		this.saving = false;
		this.id = null;
		this.validation = {
			user: true,
			project: true,
			time: true
		};
	}

}
