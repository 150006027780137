import React, {ReactElement, useEffect} from "react";
import moment from "moment";
import styled from "styled-components";
import {useSink} from "react-redux-sink";
import FilterSink from "../../../store/filter-sink";
import UsersSink, {UsersListItemInterface} from "../../Users/storage/users-sink";
import TimelineTablesSink from "../storage/timeline-tables-sink";
import ProjectsSink from "../storage/projects-sink";
import {faStopwatch} from "@fortawesome/pro-regular-svg-icons";
import TableEditorSink from "../../../store/table-editor-sink";

const TimelineTablePlanContainer = styled.div`
	width: calc(100% - 72px);
	margin: 0 36px;
	overflow-y: auto;
`;
const Table = styled.div<{ width: number; height: number; }>`
	display: grid;
	grid-template-columns: repeat(${({width}) => width}, 1fr);
	grid-template-rows: repeat(${({height}) => height}, 1fr);
`;
const Cell = styled.div<{ row: number; column: number; width: number; height: number; }>`
	grid-row: ${({row}) => row} / ${({height, row}) => row + height};
	grid-column: ${({column}) => column} / ${({width, column}) => column + width};
	padding: 24px;
	border: 1px solid ${({theme}) => theme.colors.lightGray};
`;

const TimelineTableLayout: React.FC = () => {
	const filter = useSink(FilterSink);
	const users = useSink(UsersSink);
	const projects = useSink(ProjectsSink);
	const timeline = useSink(TimelineTablesSink);
	const tableEditor = useSink(TableEditorSink);

	const getMonthNames = (): string[] => {
		const months = [];
		let date = filter.from;
		while (moment(date).isBefore(moment(filter.to))) {
			months.push(`${moment(date).format("MMMM")} ${moment(date).format("YYYY")}`);
			date = moment(date).add(1, 'month').toDate();
		}
		return months;
	};

	const getRenderedMonths = (): Date[] => {
		const months = [];
		let date = filter.from;
		while (moment(date).isBefore(moment(filter.to))) {
			months.push(date);
			date = moment(date).add(1, 'month').toDate();
		}
		return months;
	};

	const showEditor = (id: string, plan: number) => {
		tableEditor.show('cell-' + id, '', {width: 250});
	};

	const count = moment(filter.to).diff(moment(filter.from), 'months') + 1;
	const months = getRenderedMonths();
	const filteredProjects = filter.projects.length > 0 ? projects.list.filter(o => filter.projects.includes(o.id)) : projects.list;
	const filteredProjectsCount = filteredProjects.length;
	const filteredUsers = filter.users.length > 0 ? users.list.filter(o => filter.users.includes(o.id)) : users.list;
	const filteredUsersCount = filteredUsers.length;

	const renderUsers = (): ReactElement[] => {
		const cells = [];
		for (let i = 0; i < count; i++) {
			for (let j = 0; j < filteredUsersCount; j++) {
				cells.push(<Cell row={2} column={i * filteredUsersCount + j + 1 + 1} width={1}
												 height={1}>{filteredUsers[j].name}</Cell>)
			}
		}
		return cells;
	};

	const generateId = (projectId: string, userId: string, date: Date): string => {
			return `${projectId}-${userId}-${moment(date).get('month')}-${moment(date).get('year')}`;
	};

	const renderPlans = (): ReactElement[] => {
		const cells = [];
		for (let i = 0; i < count; i++) {
			for (let j = 0; j < filteredUsersCount; j++) {
				for (let k = 0; k < filteredProjectsCount; k++) {
					const id = generateId(filteredProjects[k].id, filteredUsers[j].id, moment(filter.from).add(i, 'months').toDate());
					cells.push(<Cell
						onClick={() => showEditor(id, 0)}
						className={'table-cell-' + id}
						row={k + 3}
						column={i * filteredUsersCount + j + 1 + 1}
						width={1}
						height={1}>0</Cell>)
				}
			}
		}
		return cells;
	};

	return (
		<TimelineTablePlanContainer>
			<Table width={filteredUsersCount * count + 1} height={filteredProjectsCount + 2}>
				{filteredProjects.map((o, i) => <Cell column={1} row={i + 3} width={1} height={1}>{o.name}</Cell>)}
				{getMonthNames().map((o, i) => <Cell row={1} column={i * filteredUsersCount + 2} width={filteredUsersCount}
																						 height={1}>{o}</Cell>)}
				{renderUsers()}
				{renderPlans()}
			</Table>
		</TimelineTablePlanContainer>
	);
};

export default TimelineTableLayout;
