import {effect, sink, SinkContainer, SinkFactory, state} from "react-redux-sink";
import {toast} from "react-hot-toast";
import Api from "../api";
import {tryParseFloat} from "../../../../lib/string-lib";
import UsersFinanceSink from "../../Users/storage/user-finance-sink";
import ProjectsFinanceSink from "./projects-finance-sink";

interface ProjectsFinanceAddValidationProps {
	amount: boolean;
	project: boolean;
}

@sink("projects-finance-add", SinkFactory)
export default class ProjectsFinanceAddSink {

	constructor(factory: SinkContainer) {
		this.finance = factory.getSink(ProjectsFinanceSink);
	}

	finance: ProjectsFinanceSink;

	@state description: string = "";

	@state amount: string = "";

	@state project: string | null = null;

	@state repeat: string | null = null;

	@state saving: boolean = false;

	@state validation: ProjectsFinanceAddValidationProps = {
		amount: true,
		project: true
	};

	@effect
	changeDescription(description: string) {
		this.description = description;
	}

	@effect
	changeAmount(amount: string) {
		this.amount = amount;
	}

	@effect
	changeProject(project: string | null) {
		this.project = project;
	}

	@effect
	changeRepeat(repeat: string | null) {
		this.repeat = repeat;
	}

	@effect
	async submit() {
		const valid = this.createValidation();
		this.validate(valid);
		if(ProjectsFinanceAddSink.isValid(valid)) {
			this.saving = true;
			const toastId = toast.loading('Probíhá ukládání...');
			const result = await Api.create(this.description, parseFloat(this.amount), this.project!, this.repeat);
			toast.dismiss(toastId);
			if(result !== undefined && result !== null && result.result !== null) {
				toast.success('Uloženo');
				await this.finance.loadTable();
				this.clear();
			}
			this.saving = false;
		}
		else toast.error('Formulář obsahuje nevalidní data');
	}

	private static isValid (obj: ProjectsFinanceAddValidationProps): boolean {
		return obj.amount && obj.project;
	}

	private createValidation(): ProjectsFinanceAddValidationProps {
		const amount = tryParseFloat(this.amount, null);
		let v = {
			amount: true,
			project: true
		};
		if(amount === null) v.amount = false;
		if(this.project === null) v.project = false;
		return v
	}

	@effect
	validate(obj: ProjectsFinanceAddValidationProps) {
		this.validation = obj;
	}

	@effect
	clear() {
		this.description = "";
		this.amount = "";
		this.project = null;
		this.repeat = null;
		this.validation = {
			amount: true,
			project: true
		};
	}
}
