import React, {useEffect} from "react";
import {
	faCouch,
	faFlag,
	faMapMarkerAlt,
	faPlus, faSearch, faUndo
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import {TextItem} from "../../components/Table/Items";
import Creator from "./components/Creator";
import Detail from "./components/Detail";
import {useSink} from "react-redux-sink";
import CreatorSink from "./storage/creator-sink";
import DetailSink from "./storage/detail-sink";
import Filters from "../../components/Filters";
import SearchInput from "../../components/Controls/SearchInput";
import {Pad24, Space12, Space36} from "../../components/Creator";
import ToggleButton from "../../components/Controls/ToggleButton";
import {faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import BorderButton from "../../components/Controls/BorderButton";
import ThemeSink from "../../store/theme-sink";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import PropertySink from "./storage/property-sink";
import {
	BorderButtonLoader,
	PaginationLoader,
	SearchInputLoader,
	TableLoader,
	ToggleLoader
} from "../../components/Loaders";
import PaginationSink from "../../store/pagination-sink";
import FilterSink from "../../store/filter-sink";
import Permissions from "../../permissions";
import moment from "moment";
import UsersSink from "../Users/storage/users-sink";
import DateTimePickerSink from "../../store/date-time-picker-sink";

const PropertyOverview: React.FC = () => {
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const creator = useSink(CreatorSink);
	const detail = useSink(DetailSink);
	const property = useSink(PropertySink);
	const theme = useSink(ThemeSink);
	const pagination = useSink(PaginationSink);
	const filter = useSink(FilterSink);
	const picker = useSink(DateTimePickerSink);
	const users = useSink(UsersSink);

	useEffect(() => {
		return () => {
			multiCheckboxPicker.clear();
			creator.clear();
			detail.clear();
			property.clear();
			pagination.clear();
			filter.clear();
			picker.clear();
			users.clear();
		};
	}, []);

	useEffect(() => {
		multiCheckboxPicker.items = property.locationsList.map((o) => {
			return {
				label: o,
				value: o
			};
		})
	}, [property.locationsList]);

	useEffect(() => {
		multiCheckboxPicker.items = property.typesList.map((o) => {
			return {
				label: o,
				value: o
			};
		})
	}, [property.typesList]);

	useEffect(() => {
		multiCheckboxPicker.items = property.statesList.map((o) => {
			return {
				label: o.name,
				value: o.id.toString()
			};
		})
	}, [property.statesList]);

	useEffect(() => {
		multiCheckboxPicker.items = users.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [users.list]);

	useEffect(() => {
		if(multiCheckboxPicker.confirmed) {
			if(multiCheckboxPicker.id === 'stock-filter-locations') filter.changeLocations(multiCheckboxPicker.selected);
			else if(multiCheckboxPicker.id === 'stock-filter-types') filter.changeTypes(multiCheckboxPicker.selected);
			else if(multiCheckboxPicker.id === 'stock-filter-states') filter.changeStates(multiCheckboxPicker.selected);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		property.loadTable().then();
	}, [pagination.page, pagination.limit, filter.search, filter.locations, filter.types, filter.states]);

	return (
		<>
			<Detail/>
			<Creator/>
			<ListHeader
				title={'Přehled'}
				icon={faCouch}
				headerButton={{
					label: "Přidat položku",
					icon: faPlus,
					click: () => creator.open(null),
					permissions: [Permissions.PROPERTY_CREATE]
				}}
			/>
			<Filters
				left={[
					<>{!property.tableLoading && <SearchInput icon={faSearch}/> || <SearchInputLoader/>}</>,
					<Space36/>,
					<>{!property.tableLoading && <ToggleButton
						id={'property-filter-locations'}
						selected={filter.locations.length > 0}
						active={multiCheckboxPicker.id === 'property-filter-locations'}
						onClick={async () => {
							multiCheckboxPicker.toggle('property-filter-locations');
							multiCheckboxPicker.setSelected(filter.locations);
							await property.loadLocationsList();
						}}
						icon={faMapMarkerAlt}
						label={'Umístění'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!property.tableLoading && <ToggleButton
						id={'property-filter-types'}
						selected={filter.types.length > 0}
						active={multiCheckboxPicker.id === 'property-filter-types'}
						onClick={async () => {
							multiCheckboxPicker.toggle('property-filter-types');
							multiCheckboxPicker.setSelected(filter.types);
							await property.loadTypesList();
						}}
						icon={faFolderOpen}
						label={'Typ'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!property.tableLoading && <ToggleButton
						id={'property-filter-states'}
						selected={filter.states.length > 0}
						active={multiCheckboxPicker.id === 'property-filter-states'}
						onClick={async () => {
							multiCheckboxPicker.toggle('property-filter-states');
							multiCheckboxPicker.setSelected(filter.states);
							await property.loadStatesList();
						}}
						icon={faFlag}
						label={'Stav'}
					/> || <ToggleLoader/>}</>
				]}
				right={[
					<>{!property.tableLoading &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'} color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			{!property.tableLoading && <Table
				header={[
					{label: "Název", sortable: true},
					{label: "Umístění", sortable: true},
					{label: "Typ", sortable: true},
					{label: "Stav", sortable: true},
					{label: "Stáří", sortable: true}
				]}
				items={property.table.map((o) => {
					return {
						children: [
							<TextItem onClick={() => detail.open(o.id)} content={o.name}/>,
							<TextItem content={o.location}/>,
							<TextItem content={o.type}/>,
							<TextItem color={o.state === 'V pořádku' ? theme.colors.brightGreen : theme.colors.red} content={o.state}/>,
							<TextItem content={`${moment().diff(moment(o.age), 'years', true).toFixed(1)} let (${moment(o.age).format("D.M.YYYY")})`}/>
						]
					};
				})}
			/> || <TableLoader columns={4} rows={5}/>}
			{!property.tableLoading && <Pagination/> || <PaginationLoader/>}
			<Pad24/>
		</>
	);
};

export default PropertyOverview;
