import React from "react";
import styled from "styled-components";

//#region styled
export const FormContainer = styled.form`
	width: 396px;
	height: auto;
	padding: 0px 36px 60px 36px;
	background-color: ${({theme}) => theme.colors.white};
	border: 1px solid ${({theme}) => theme.colors.lightWhite};
	border-radius: 4px;
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}){
		padding: 49px 144px 60px 144px;
	}
`;
export const Title = styled.h1`
	font: ${({theme}) => theme.fonts.Philosopher(60, "bold")};
	color: ${({theme}) => theme.colors.blueGray};;
	margin-bottom: 46px;
	text-align: center;
`;
export const Space = styled.div`
	height: 12px;
`;
//#endregion
