import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import PaginationSink from "../../../store/pagination-sink";
import Api from "../api";
import Routes from "../../../routes";
import NavigationSink from "../../../store/navigation-sink";
import {toast} from "react-hot-toast";
import FilterSink from "../../../store/filter-sink";

export interface RoleTableItemInterface {
	id: string;
	name: string;
	usersCount: number;
	permissionsCount: number;
}

export interface RoleListItemInterface {
	id: string;
	name: string;
}

@sink("roles", SinkFactory)
export default class RolesSink {

	constructor(factory: SinkContainer){
		this.pagination = factory.getSink(PaginationSink);
		this.navigation = factory.getSink(NavigationSink);
		this.filter = factory.getSink(FilterSink);
	}

	pagination: PaginationSink;

	navigation: NavigationSink;

	filter: FilterSink;

	@state tableLoading: boolean = true;

	@state table: RoleTableItemInterface[] = [];

	@state listLoading: boolean = true;

	@state list: RoleListItemInterface[] = [];

	@effect
	async loadTable() {
		this.tableLoading = true;
		const result = await Api.fetchAllRoles(
			this.pagination.page,
			this.pagination.limit,
			this.filter.search,
			this.filter.users,
			this.filter.permissions
		);
		if(result !== undefined && result !== null) {
			this.table = result.result.list;
			this.pagination.changeTotal(result.result.totalCount);
		}
		else toast.error('Nastala chyba!');
		this.tableLoading = false;
	}

	@effect
	async loadList() {
		this.listLoading = true;
		this.list = await Api.fetchRolesList();
		this.listLoading = false;
	}

	@effect
	clearTable() {
		this.table = [];
		this.tableLoading = true;
	}

	@effect
	clear() {
		this.clearTable();
	}

}
