import React, {useLayoutEffect} from "react";
import {Redirect, Route} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSink} from "react-redux-sink";
import moment from "moment";
import {useMediaPredicate} from "react-media-hook";
import {Switch} from "react-router";
import ThemeSink from "./store/theme-sink";
import NoMatch from "./pages/NoMatch";

import "./fonts/fonts.css";
import "moment/locale/cs";
import "moment/locale/en-gb";
import Layout from "./layout";
import Routes from "./routes";

const App: React.FC = () => {
	const {i18n} = useTranslation();
	//#region Interantionalization
	const {language} = i18n;
	// Change MomentJS locale to the current language
	useLayoutEffect(() => {
		moment.locale(language);
	}, [language]);
	//#endregion
	//#region Theme
	const theme = useSink(
		ThemeSink,
		// Should be enough to react only to changes of the `theme` property
		(themeSink) => [themeSink.theme]
	);
	const prefersDarkMode = useMediaPredicate("(prefers-color-scheme: dark)");
	useLayoutEffect(() => {
		//TODO: Warning!!!!
		//theme.changeTheme(prefersDarkMode ? "dark" : "light");
	}, [theme, prefersDarkMode]);
	//#endregion
	return (
		<Layout>
			<Switch>
				{Routes.routes.map((o, i) =>
					<Route
						path={o.path}
						component={o.component}
						exact
					/>
				)}
				<Route
					path={'/'}
					exact
				>
					<Redirect to={Routes.paths.SIGN_IN}/>
				</Route>
				<Route>
					<NoMatch/>
				</Route>
			</Switch>
		</Layout>
	);
};
export default App;
