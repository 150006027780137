import React, {useEffect} from "react";
import {
	DetailClose,
	DetailContainer, DetailContent,
	DetailContentLeft, DetailContentRight,
	DetailHeader,
	DetailHeaderButtons, DetailHeaderLeft,
	DetailHeaderRight, DetailInfo, DetailInfoRowGrid, DetailProjectTitle, DetailTitle
} from "../../../components/Detail";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faTimes} from "@fortawesome/pro-light-svg-icons";
import {useSink} from "react-redux-sink";
import DetailSink from "../storage/detail-sink";
import {BorderButtonLoader, DetailInfoLoader, DetailTitleLoader, HeaderButtonLoader} from "../../../components/Loaders";
import HeaderButton from "../../../components/Controls/HeaderButton";
import {faClone, faEdit, faInfo, faPhotoVideo, faTrash, faUpload} from "@fortawesome/pro-regular-svg-icons";
import {Space12, Space36} from "../../../components/Creator";
import BorderButton from "../../../components/Controls/BorderButton";
import ThemeSink from "../../../store/theme-sink";
import CreatorSink from "../storage/creator-sink";
import DialogSink from "../../../store/dialog-sink";
import {useDropzone} from "react-dropzone";
import {GalleryContainer, GalleryItem} from "../../../components/Gallery";
import {UploadImagesContainer, UploadInfo} from "../../../components/Uploader";
import Permissions from "../../../permissions";
import Permission from "../../../components/Permissions";
import moment from "moment";

const Detail: React.FC = () => {
	const detail = useSink(DetailSink);
	const creator = useSink(CreatorSink);
	const dialog = useSink(DialogSink);
	const theme = useSink(ThemeSink);
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({
		disabled: detail.uploading,
		onDrop: async (acceptedFiles) => {
			if (acceptedFiles.length > 0) detail.upload(acceptedFiles).then();
		}
	});

	useEffect(() => {
		if (dialog.confirm === "confirm") {
			if (dialog.id === 'property-delete') {
				detail.delete().then();
				dialog.clear();
			} else if (dialog.id === 'property-duplicate') {
				dialog.clear();
			}
		}
	}, [dialog.confirm]);
	return (
		<DetailContainer show={detail.opened}>
			<DetailHeader>
				<DetailHeaderLeft>
					<DetailClose onClick={() => detail.close()}>
						<FontAwesomeIcon icon={faChevronLeft}/>
					</DetailClose>
					{!detail.loading && detail.data && <DetailTitle title={detail.data?.name}/> || <DetailTitleLoader/>}
				</DetailHeaderLeft>
				<DetailHeaderRight>
					<Permission permissions={[Permissions.PROPERTY_CREATE]}>
						{!detail.loading && detail.data && <HeaderButton
							icon={faEdit}
							label={'Upravit'}
							click={() => creator.open(detail.id)}
						/> || <HeaderButtonLoader/>}
					</Permission>
					<Permission permissions={[Permissions.PROPERTY_CREATE]}>
						<Space12/>
						{!detail.loading && detail.data && <HeaderButton
							icon={faClone}
							label={'Duplikovat'}
							click={() => dialog.init('property-duplicate', 'Upozornění', 'Potvrzením vytvoříte duplikát a následně budete přesměrování do nové položky. Chcete pokračovat?')}
						/> || <HeaderButtonLoader/>}
					</Permission>
					<Permission permissions={[Permissions.PROPERTY_DELETE]}>
						<Space12/>
						{!detail.loading && detail.data &&
						<BorderButton
							color={theme.colors.darkGray}
							icon={faTrash}
							label={'Smazat'}
							click={() => dialog.init('property-delete', 'Varování', 'Opravdu chcete smazat položku skladu?')}
						/> || <BorderButtonLoader/>}
					</Permission>
					<Space36/>
					<DetailClose onClick={() => detail.close()}>
						<FontAwesomeIcon icon={faTimes}/>
					</DetailClose>
				</DetailHeaderRight>
			</DetailHeader>
			<DetailHeaderButtons>
			</DetailHeaderButtons>
			<DetailContent>
				<DetailContentLeft>
					{!detail.loading && detail.data && <DetailInfo icon={faInfo} label={'Informace'}>
						<DetailInfoRowGrid>
							<div>Umístění</div>
							<div>{detail.data.location}</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Typ</div>
							<div>{detail.data.type}</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Stav</div>
							<div>{detail.data.stateName}</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Vlastník</div>
							<div>{detail.data.ownerId ? `${detail.data.ownerName!}` : 'Bez vlastníka'}</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Stáří</div>
							<div>{`${moment().diff(moment(detail.data.age), 'years', true).toFixed(1)} let (${moment(detail.data.age).format("D.M.YYYY")})`}</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Pořizovací cena</div>
							<div>{detail.data.price} Kč</div>
						</DetailInfoRowGrid>
					</DetailInfo> || <DetailInfoLoader/>}
				</DetailContentLeft>
				<DetailContentRight>
					<Permission permissions={[Permissions.PROPERTY_CREATE]}>
						{!detail.uploading && !detail.loading && detail.data && <DetailInfo icon={faUpload} label={'Nahrát fotky'}>
							<UploadImagesContainer {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
								<UploadInfo>
									Přetáhněte soubory nebo klikněte pro výběr
								</UploadInfo>
								<input {...getInputProps()} />
							</UploadImagesContainer>
						</DetailInfo> || <DetailInfoLoader/>}
					</Permission>
					{!detail.uploading && !detail.loading && detail.data && detail.data.photos.length > 0 && <DetailInfo icon={faPhotoVideo} label={'Fotky'}>
						<GalleryContainer>
							{detail.data.photos.map((o, i) => <GalleryItem
								key={i}
								id={o}
								remove={() => detail.deletePhoto(o)}
							/>)}
						</GalleryContainer>
					</DetailInfo> || <DetailInfoLoader/>}
				</DetailContentRight>
			</DetailContent>
		</DetailContainer>
	);
};

export default Detail;
