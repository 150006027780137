import {effect, sink, SinkContainer, SinkFactory, state} from "react-redux-sink";
import Api from "../api";
import {toast} from "react-hot-toast";
import moment from "moment";
import TimerSink from "./timer-sink";
import IdentitySink from "../../../store/identity-sink";
import TimerStatisticsSink from "./timer-statistics-sink";
import NavigationSink from "../../../store/navigation-sink";

@sink("timer-recorder", SinkFactory)
export default class RecorderSink {

	constructor(factory: SinkContainer) {
		this.timer = factory.getSink(TimerSink);
		this.identity = factory.getSink(IdentitySink);
		this.statistics = factory.getSink(TimerStatisticsSink);
	}
	timer: TimerSink;

	statistics: TimerStatisticsSink;

	identity: IdentitySink;

	@state description: string = "";

	@state project: string | null = null;

	@state from: Date | null = null;

	@state to: Date | null = null;

	@state active: boolean = false;

	@state loading: boolean = true;

	@effect
	changeDescription(description: string) {
		this.description = description;
	}

	@effect
	async confirmDescription() {
		if (this.active) await this.updateRecording(this.from!, this.description, this.project ?? undefined);
	}

	@effect
	async changeProject(project: string | null) {
		this.project = project;
		if (this.active) await this.updateRecording(this.from!, this.description, project ?? undefined);
	}

	@effect
	async changeTimes(from: Date, to: Date | null) {
		this.from = from;
		this.to = to;
		if (this.active) await this.updateRecording(from, this.description, this.project ?? undefined);
	}

	@effect
	async loadActive() {
		this.loading = true;
		const result = await Api.fetchActive();
		if (result !== undefined && result !== null && result.isSuccess && result.result !== null) {
			this.active = true;
			this.description = result.result.item.description ?? "";
			this.project = result.result.item.projectId ?? null;
			this.from = moment.utc(result.result.item.start).local().toDate();
			this.to = null;
		}
		this.loading = false;
	}

	@effect
	async createRecord() {
		if(this.from !== null && this.to !== null) {
			this.loading = true;
			const result = await Api.createRecord(this.description, this.from, this.to, this.project ?? undefined, this.identity.data?.id)
			if (result) {
				this.clear();
				await this.timer.loadTable(true, this.identity.data?.id);
				await this.statistics.refreshStatistics();
				toast.success('Záznam vytvořen');
			} else toast.error('Záznam se nepodařilo vytvořit');
			this.loading = false;
		}
	}

	@effect
	async startRecording(description?: string, project?: string) {
		this.loading = true;
		if(description !== undefined) {
			this.description = description;
			this.project = project ?? null;
		}
		else {
			description = this.description;
			project = this.project ?? undefined;
		}
		const start = moment().toDate();
		const result = await Api.startRecording(start, description, project);
		if (result) {
			this.from = start;
			this.active = true;
			toast.success('Časovač aktivován');
		} else {
			toast.error('Časovač se nepodařilo aktivovat');
			this.clear();
		}
		this.loading = false;
	}

	@effect
	async endRecording() {
		this.loading = true;
		const result = await Api.endRecording(moment().toDate());
		if (result) {
			toast.success('Časovač zastaven');
			this.clear();
			await this.timer.loadTable(true, this.identity.data?.id);
			await this.statistics.refreshStatistics();
		} else {
			toast.error('Časovač se nepodařilo zastavit');
		}
		this.loading = false;
	}

	@effect
	async updateRecording(from: Date, description: string, project?: string) {
		this.loading = true;
		const result = await Api.updateRecording(from, description, project);
		if (result) {
			toast.success('Aktivní záznam upraven');
		} else {
			toast.error('Aktivní záznam se nepodařilo upravit');
			await this.loadActive();
		}
		this.loading = false;
	}

	@effect
	async deleteActive() {
		this.loading = true;
		const result = await Api.deleteActive();
		if (result) {
			toast.success('Aktivní záznam odstraněn');
			this.clear();
		} else {
			toast.error('Aktivní záznam se nepodařilo odstranit');
		}
		this.loading = false;
	}

	@effect
	clear() {
		this.active = false;
		this.description = "";
		this.project = null;
		this.from = null;
		this.to = null;
	}

}
