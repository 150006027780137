import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { SinkFactory } from "react-redux-sink";
import { Router } from "react-router-dom";
import "flagpack/dist/flagpack.css";

import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { createNavigationHistory } from "./store/navigation-sink";
import Loading from "./pages/Loading";
import ThemeSink from "./store/theme-sink";
import { ThemeProvider } from "styled-components";
import { Toaster } from "react-hot-toast";

//#region Redux Sink
// Initialize Redux store/sink with DevTools enabled in development env
const store = SinkFactory.createStore({
	devToolOptions: {
		devToolCompose: composeWithDevTools,
		disabled: process.env.NODE_ENV !== "development",
	},
	useTrigger: true,
});
// Get history bound to NavigationSink
const history = createNavigationHistory();
const theme = SinkFactory.getSink(ThemeSink);
//#endregion

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<Toaster/>
			<Router history={history}>
				<React.Suspense fallback={<Loading />}>
					<App />
				</React.Suspense>
			</Router>
		</ThemeProvider>
	</Provider>,
	document.getElementById("root")
);

registerServiceWorker();
