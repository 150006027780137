import styled from "styled-components";
import React, {useEffect, useState} from "react";
import ListHeader from "../../components/Headers/ListHeader";
import {
	faBell, faBracketsCurly,
	faCog,
	faKey,
	faPowerOff, faSave, faScannerKeyboard, faTrash,
	faUser
} from "@fortawesome/pro-regular-svg-icons";
import Routes from "../../routes";
import IdentitySink from "../../store/identity-sink";
import NavigationSink from "../../store/navigation-sink";
import {useSink} from "react-redux-sink";
import DialogSink from "../../store/dialog-sink";
import {
	DetailContent,
	DetailContentLeft,
	DetailContentRight
} from "../../components/Detail";
import {
	CenteredContainer,
	CreatorData,
	CreatorInput,
	Pad24,
	SingleColumnRow
} from "../../components/Creator";
import {DetailInfoLoader} from "../../components/Loaders";
import SettingsSink from "./storage/settings-sink";
import HeaderButton from "../../components/Controls/HeaderButton";
import {CircleUploadImagesContainer, UploaderImage, UploadInfo} from "../../components/Uploader";
import {useDropzone} from "react-dropzone";
import Config from "../../../client-config.json";
import {toast} from "react-hot-toast";
import copy from 'copy-to-clipboard';

export const Space36 = styled.div`
	width: 36px;
`;

const Settings: React.FC = () => {
	const identity = useSink(IdentitySink);
	const navigation = useSink(NavigationSink);
	const dialog = useSink(DialogSink);
	const settings = useSink(SettingsSink);
	const [click, setClick] = useState<NodeJS.Timeout | null>(null);
	const [counter, setCounter] = useState<number>(0);

	const clickDevTools = () => {
		const id = setTimeout(() => {
			setClick(null);
			setCounter(0);
		}, 2000);
		if(click !== null) clearTimeout(click);
		if(counter >= 5) {
			identity.toggleDevTools();
			setCounter(0);
			setClick(null);
		}
		else {
			setClick(id);
			setCounter(counter + 1);
			toast(`Dalšími ${(5 - counter)} kliky ${identity.hasDevTools ? 'deaktivujete' : 'aktivujete'} DevTools`);
		}
	};

	useEffect(() => {
		return () => {
			settings.clear();
			if(click !== null) clearTimeout(click);
		};
	}, []);

	useEffect(() => {
		if (dialog.confirm === 'confirm') {
			if (dialog.id === 'account-settings-reset') {
				sessionStorage.clear();
				localStorage.clear();
				identity.clear();
				navigation.history.push(Routes.paths.SIGN_IN)
			}
			dialog.clear();
		}
	}, [dialog.confirm]);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({
		disabled: false,
		maxFiles: 1,
		onDrop: async (acceptedFiles) => {
			if (acceptedFiles.length === 1) settings.uploadPhoto(acceptedFiles[0]).then();
		}
	});

	return (
		<>
			<ListHeader
				title={'Nastavení'}
				icon={faCog}
				onIconClick={clickDevTools}
				headerButton={{
					label: "Reset aplikace",
					icon: faPowerOff,
					click: () => dialog.init('account-settings-reset', 'Upozornění', 'Chystáte se odstranit lokální data aplikace. Po dokončení budete odhlášeni. Opravdu chcete pokračovat?')
				}}
			/>
			<DetailContent noTop>
				<DetailContentLeft>
					{!settings.profileLoading && <CreatorData icon={faUser} label={identity.data?.name ?? 'Profil'}>
						<CenteredContainer>
							<div>
								<CircleUploadImagesContainer {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
									<UploadInfo>Nahrát profilový obrázek</UploadInfo>
									<UploaderImage src={`${Config.api}/Account/Image?access_token=${identity.data?.token}`}/>
									<input {...getInputProps()} />
								</CircleUploadImagesContainer>
								<Pad24/>
								<CenteredContainer>
									<HeaderButton icon={faTrash} label={'Odstranit foto'} click={() => settings.deletePhoto()}/>
								</CenteredContainer>
							</div>
						</CenteredContainer>
					</CreatorData> || <DetailInfoLoader/>}
					<CreatorData icon={faBell} label={'Oznámení'}>
						Již brzy na Vašich obrazovkách
					</CreatorData>
				</DetailContentLeft>
				<DetailContentRight>
					{!settings.passwordsLoading && <CreatorData icon={faKey} label={'Změna hesla'}>
						<SingleColumnRow>
							<CreatorInput type={'password'}
														value={settings.passwords.oldPassword}
														onChange={({currentTarget}) => settings.changePasswords('oldPassword', currentTarget.value)}
														trailingIcon={faKey} label={'Staré heslo'}/>
						</SingleColumnRow>
						<SingleColumnRow>
							<CreatorInput type={'password'}
														value={settings.passwords.password}
														onChange={({currentTarget}) => settings.changePasswords('password', currentTarget.value)}
														trailingIcon={faKey} label={'Nové heslo'}/>
						</SingleColumnRow>
						<SingleColumnRow>
							<CreatorInput type={'password'}
														value={settings.passwords.passwordAgain}
														onChange={({currentTarget}) => settings.changePasswords('passwordAgain', currentTarget.value)}
														trailingIcon={faKey} label={'Znovu nové heslo'}/>
						</SingleColumnRow>
						<SingleColumnRow>
							<HeaderButton icon={faSave} label={'Uložit'} click={() => settings.savePasswords()}/>
						</SingleColumnRow>
					</CreatorData> || <DetailInfoLoader/>}
					{identity.hasDevTools && <CreatorData icon={faBracketsCurly} label={'DevTools'}>
						<SingleColumnRow>
							<HeaderButton icon={faScannerKeyboard} label={'Kopírovat OAuth token'} click={() => {
								copy(identity.data?.token ?? '');
								toast.success('OAuth token zkopírován');
							}}/>
						</SingleColumnRow>
					</CreatorData>}
				</DetailContentRight>
			</DetailContent>
		</>
	);
};

export default Settings;
