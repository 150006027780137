import {effect, sink, SinkFactory, state} from "react-redux-sink";

export interface ActivityTableItemInterface {
	id: string;
	image: string;
	userName: string;
	projectColor: string;
	projectName: string;
	customerName: string;
	description: string;
	startDateTime?: Date;
	endDateTime?: Date;
	totalDuration: number;
	totalDate: Date;
}

@sink("timer-activity", SinkFactory)
export default class ActivitySink {

	@state tableLoading: boolean = true;

	@state table: ActivityTableItemInterface[] = [];

	@effect
	setTable(table: ActivityTableItemInterface[]) {
		this.table = table;
		this.tableLoading = false;
	}

	@effect
	clearTable() {
		this.table = [];
		this.tableLoading = true;
	}

}
