import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import FilterSink from "../../../store/filter-sink";
import {toast} from "react-hot-toast";
import moment from "moment";
import {BarDatum} from "@nivo/bar";

@sink("tracked-hours-bar-chart", SinkFactory)
export default class TrackedHoursBarChartSink {

	constructor(factory: SinkContainer){
		this.filter = factory.getSink(FilterSink);
	}

	filter: FilterSink;

	@state dataLoading: boolean = true;

	@state data: BarDatum[] = [];

	@effect
	async loadTable() {
		this.dataLoading = true;
		const result = await Api.fetchTrackedHoursPerDays(
			this.filter.from,
			this.filter.to,
			this.filter.users,
			this.filter.projects,
			this.filter.customers,
			this.filter.search
		);
		if(result !== undefined && result !== null && result.result !== null){
			this.data = Object.keys(result.result.durationsPerDays).map(key => ({
				date: key,
				hours: Math.round(moment.duration(result.result.durationsPerDays[key]).asHours() * 10) / 10
			}));
		}
		else toast.error('Nastala chyba!');
		this.dataLoading = false;
	}

	@effect
	clearTable() {
		this.data = [];
		this.dataLoading = true;
	}
}
