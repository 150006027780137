import React, {useEffect} from "react";
import {
	CreatorClose,
	CreatorContainer,
	CreatorContent,
	CreatorData,
	CreatorHeader,
	CreatorHeaderLeft,
	CreatorHeaderRight,
	CreatorInput,
	CreatorTitleInputContainer,
	OneColumnRow,
	Space12,
	Space36, TwoColumnsRow
} from "../../../components/Creator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-light-svg-icons";
import HeaderButton from "../../../components/Controls/HeaderButton";
import {
	faTimes,
	faSave,
	faBox, faMapMarkerAlt, faFolderOpen, faCoins, faCalendar, faFlag, faUserFriends, faCalendarDay
} from "@fortawesome/pro-regular-svg-icons";
import {useSink} from "react-redux-sink";
import CreatorSink from "../storage/creator-sink";
import {DetailInfoLoader, DetailTitleLoader, HeaderButtonLoader} from "../../../components/Loaders";
import moment from "moment";
import ToggleButton from "../../../components/Controls/ToggleButton";
import MultiCheckboxPickerSink from "../../../store/multi-checkbox-picker-sink";
import DateTimePickerSink from "../../../store/date-time-picker-sink";
import UsersSink from "../../Users/storage/users-sink";
import PropertySink from "../storage/property-sink";
import DetailSink from "../storage/detail-sink";

const Creator: React.FC = () => {
	const creator = useSink(CreatorSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const picker = useSink(DateTimePickerSink);
	const users = useSink(UsersSink);
	const property = useSink(PropertySink);

	useEffect(() => {
		if(multiCheckboxPicker.confirmed) {
			if(multiCheckboxPicker.id === 'property-creator-state') creator.changeState(parseInt(multiCheckboxPicker.selected[0]) ?? null);
			else if(multiCheckboxPicker.id === 'property-creator-owner') creator.changeOwner(multiCheckboxPicker.selected[0] ?? null);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		if(picker.confirmed) {
			if(picker.id === 'property-creator-age') creator.changeAge(picker.dates.from);
			picker.clear();
		}
	}, [picker.confirmed]);

	return (
		<CreatorContainer show={creator.opened}>
			<CreatorHeader>
				<CreatorHeaderLeft>
					<CreatorClose onClick={() => creator.close()}>
						<FontAwesomeIcon icon={faChevronLeft}/>
					</CreatorClose>
					{!creator.loading && <CreatorTitleInputContainer
						value={creator.title}
						onChange={({currentTarget}) => creator.changeTitle(currentTarget.value)}
					/> || <DetailTitleLoader/>}
				</CreatorHeaderLeft>
				<CreatorHeaderRight>
					{!creator.loading && <HeaderButton
						icon={faSave}
						label={'Uložit'}
						click={() => creator.submit()}
					/> || <HeaderButtonLoader/>}
					<Space36/>
					<CreatorClose onClick={() => creator.close()}>
						<FontAwesomeIcon icon={faTimes}/>
					</CreatorClose>
				</CreatorHeaderRight>
			</CreatorHeader>
			<CreatorContent>
				{!creator.loading && <CreatorData icon={faBox} label={'Položka'}>
					<TwoColumnsRow>
						<CreatorInput
							trailingIcon={faMapMarkerAlt}
							label={'Umístění'}
							value={creator.location}
							whisperer
							onChange={({currentTarget}) => creator.changeLocation(currentTarget.value)}
						/>
						<CreatorInput
							trailingIcon={faFolderOpen}
							label={'Typ'}
							value={creator.type}
							whisperer
							onChange={({currentTarget}) => creator.changeType(currentTarget.value)}
						/>
						<CreatorInput
							trailingIcon={faCoins}
							label={'Pořizovací cena'}
							value={creator.price?.toString() ?? ""}
							onChange={({currentTarget}) => creator.changePrice(currentTarget.value)}
						/>
						<CreatorInput disabled={picker.id === 'property-creator-age'}
													className={'toggle-button-property-creator-age'}
													value={moment(creator.age).format('D.M.YYYY')}
													onClick={() => picker.toggle('property-creator-age', { from: creator.age, to: creator.age }, {
														showPredefinedRanges: false,
														selectRange: false
													})}
													trailingIcon={faCalendar} endIcon={<FontAwesomeIcon icon={faCalendarDay}/>}
													label={'Stáří'}/>
					</TwoColumnsRow>
					<OneColumnRow topMargin>
						<ToggleButton
							id={'property-creator-state'}
							active={multiCheckboxPicker.id === 'property-creator-state'}
							selected={creator.state !== null}
							onClick={async () => {
								multiCheckboxPicker.toggle('property-creator-state', true);
								multiCheckboxPicker.setSelected(creator.state !== null ? [creator.state.toString()] : []);
								await property.loadStatesList();
							}}
							icon={faFlag}
							label={'Stav'}
						/>
						<Space12/>
						<ToggleButton
							id={'property-creator-owner'}
							active={multiCheckboxPicker.id === 'property-creator-owner'}
							selected={creator.owner !== null}
							onClick={async () => {
								multiCheckboxPicker.toggle('property-creator-owner', true);
								multiCheckboxPicker.setSelected(creator.owner !== null ? [creator.owner] : []);
								await users.loadList();
							}}
							icon={faUserFriends}
							label={'Vlastník'}
						/>
					</OneColumnRow>
				</CreatorData> || <DetailInfoLoader/>}
			</CreatorContent>
		</CreatorContainer>
	);
};

export default Creator;
