import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import NavigationSink from "../../../store/navigation-sink";
import FilterSink from "../../../store/filter-sink";
import {toast} from "react-hot-toast";
import Api from "../api";

type ShowType = 'brief' | 'detail';

export interface TimelineBriefTableProps {
	id: string;
	month: Date;
	total: number;
}

export interface TimelineDetailTableProps {
	userId: string;
	projectId: string;
	month: Date;
	estimatedTime: number;
}

@sink("timeline-tables", SinkFactory)
export default class TimelineTablesSink {

	constructor(factory: SinkContainer){
		this.navigation = factory.getSink(NavigationSink);
		this.filter = factory.getSink(FilterSink);
	}

	navigation: NavigationSink;

	filter: FilterSink;

	@state briefTable: TimelineBriefTableProps[] = [];

	@state briefTableLoading: boolean = true;

	@state detailTable: TimelineDetailTableProps[] = [];

	@state detailTableLoading: boolean = true;

	@state show: ShowType = 'brief';

	@effect
	changeShow(show: ShowType) {
		this.clearData();
		this.show = show;
	}

	@effect
	async loadBriefTable() {
		this.briefTableLoading = true;
		const result = await Api.fetchTimelineBriefTable(this.filter.from, this.filter.to, this.filter.users);
		if(result !== undefined && result !== null) {
			this.briefTable = result;
		}
		else toast.error('Nastala chyba!');
		this.briefTableLoading = false;
	}

	@effect
	async loadDetailTable() {
		this.detailTableLoading = true;
		const result = await Api.fetchTimelineDetailTable(this.filter.from, this.filter.to, this.filter.users, this.filter.projects, this.filter.customers, this.filter.search);
		if(result !== undefined && result !== null) {
			this.detailTable = result;
		}
		else toast.error('Nastala chyba!');
		this.detailTableLoading = false;
	}

	@effect
	clearData() {
		this.briefTable = [];
		this.briefTableLoading = true;
		this.detailTable = [];
		this.detailTableLoading = true;
	}

	@effect
	clear() {
		this.clearData();
		this.show = "brief";
	}

}
