import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import DetailSink from "./detail-sink";
import PropertySink from "./property-sink";
import moment from "moment";
import {tryParseFloat, tryParseInt} from "../../../lib/string-lib";
import {toast} from "react-hot-toast";

interface PropertyItemValidationProps {
	name: boolean;
	location: boolean;
	type: boolean;
	price: boolean;
	state: boolean;
}

@sink("property-creator", SinkFactory)
export default class CreatorSink {

	constructor(factory: SinkContainer){
		this.property = factory.getSink(PropertySink);
		this.detail = factory.getSink(DetailSink);
	}

	property: PropertySink;

	detail: DetailSink;

	@state opened: boolean = false;

	@state loading: boolean = true;

	@state id: string | null = null;

	@state title: string = "";

	@state location: string = "";

	@state type: string = "";

	@state price: string = "";

	@state age: Date = moment().toDate();

	@state state: number | null = null;

	@state owner: string | null = null;

	@state validation: PropertyItemValidationProps = {
		name: true,
		location: true,
		type: true,
		price: true,
		state: true
	};

	@effect
	changeTitle(title: string) {
		this.title = title;
	}

	@effect
	changeLocation(location: string) {
		this.location = location;
	}

	@effect
	changeType(type: string) {
		this.type = type;
	}

	@effect
	changePrice(price: string) {
		this.price = price;
	}

	@effect
	changeAge(age: Date) {
		this.age = age;
	}

	@effect
	changeState(state: number) {
		this.state = state;
	}

	@effect
	changeOwner(owner: string | null) {
		this.owner = owner;
	}

	@effect
	open(id: string | null) {
		this.opened = true;
		this.id = id;
	}

	@effect
	async load(id: string) {
		this.loading = true;
		const data = await Api.fetch(id);
		this.title = data.name;
		this.location = data.location;
		this.type = data.type;
		this.age = data.age;
		this.price = data.price.toString();
		this.state = data.stateId;
		this.owner = data.ownerId ?? null;
		this.loading = false;
	}

	@trigger("property-creator/id", { formatter: (id) => id })
	async idTrigger(id: string | null) {
		if(id !== null) await this.load(id as string);
		else this.loading = false;
	}

	@effect
	close() {
		this.clear();
	}

	@effect
	clear() {
		this.opened = false;
		this.id = null;
		this.title = "";
		this.location = "";
		this.type = "";
		this.age = moment().toDate();
		this.price = "";
		this.state = null;
		this.owner = null;
		this.loading = true;
		this.validation = {
			name: true,
			location: true,
			type: true,
			price: true,
			state: true
		};
	}

	private static isValid (obj: PropertyItemValidationProps): boolean {
		return obj.name && obj.location && obj.type && obj.price && obj.state;
	}

	private createValidation(): PropertyItemValidationProps {
		const price = tryParseFloat(this.price, null);
		let v = {
			name: true,
			location: true,
			type: true,
			price: true,
			state: true
		};
		if(price === null) v.price = false;
		if(this.location === "") v.location = false;
		if(this.type === "") v.type = false;
		if(this.title === "") v.name = false;
		if(this.state === null) v.state = false;
		return v;
	}

	@effect
	validate(obj: PropertyItemValidationProps) {
		this.validation = obj;
	}

	@effect
	async submit() {
		const valid = this.createValidation();
		this.validate(valid);
		if(CreatorSink.isValid(valid)) {
			this.loading = true;
			const toastId = toast.loading('Probíhá ukládání...');
			const result = await Api.save(
				this.id,
				this.title,
				this.location,
				this.type,
				this.age,
				this.state as number,
				parseFloat(this.price),
				this.owner ?? undefined
			);
			toast.dismiss(toastId);
			if(result) {
				toast.success('Uloženo');
				this.clear();
				this.detail.clear();
				await this.property.loadTable();
			}
			this.loading = false;
		}
		else toast.error('Formulář obsahuje nevalidní data');
	}

}
