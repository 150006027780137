import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import PaginationSink from "../../../store/pagination-sink";
import NavigationSink from "../../../store/navigation-sink";
import Routes from "../../../routes";
import {toast} from "react-hot-toast";
import FilterSink from "../../../store/filter-sink";

export interface ProjectsTableItemInterface {
	id: string;
	color: string;
	projectName: string;
	customer: {
		id: string;
		name: string;
	};
	start: Date;
	end?: Date;
	billingDate?: Date;
	hasEnd: boolean;
	trackedHours: number;
	estimatedTime: number;
	revenues: number;
	users: string[];
}

export interface ProjectsListItemInterface {
	id: string;
	name: string;
	customerName: string;
}

@sink("projects", SinkFactory)
export default class ProjectsSink {

	constructor(factory: SinkContainer){
		this.pagination = factory.getSink(PaginationSink);
		this.navigation = factory.getSink(NavigationSink);
		this.filter = factory.getSink(FilterSink);
	}

	pagination: PaginationSink;

	navigation: NavigationSink;

	filter: FilterSink;

	@state active: boolean = true;

	@state tableLoading: boolean = true;

	@state table: ProjectsTableItemInterface[] = [];

	@state listLoading: boolean = true;

	@state list: ProjectsListItemInterface[] = [];

	@effect
	async loadTable(active?: boolean) {
		if(active !== undefined) this.active = active;
		this.tableLoading = true;
		const result = await Api.fetchAll(
			this.active,
			this.pagination.page,
			this.pagination.limit,
			this.filter.search,
			this.filter.users,
			this.filter.customers
		);
		if(result !== undefined && result !== null && result.result !== null){
			this.table = result.result.list;
			this.pagination.changeTotal(result.result.totalCount);
		}
		else toast.error('Nastala chyba!');
		this.tableLoading = false;
	}

	@effect
	async loadList(active?: boolean, name?: string, userId?: string) {
		this.listLoading = true;
		this.list = await Api.fetchList(active, name, userId);
		this.listLoading = false;
	}

	@effect
	clearTable() {
		this.table = [];
		this.tableLoading = true;
	}

	@effect
	clearList() {
		this.list = [];
		this.listLoading = true;
	}

	@effect
	clear() {
		this.clearTable();
		this.clearList();
	}

}
