import {
	ApiCalls, FetchAllApiResult
} from ".";
import Config from "../../../../../client-config.json";
import {MultiItemApiResult, ItemApiResult} from "../../Users/api";

const ProdApi: ApiCalls = {
	fetchAll: (paid, page, limit, from, to, projects, customers, description) =>
		fetch(`${Config.api}/Finance/Project?pageNumber=${page}&pageSize=${limit}&paid=${paid}&from=${from.toISOString()}&to=${to.toISOString()}${description !== "" ? `&description=${encodeURIComponent(description)}` : ""}${projects.map(o => `&projects=${o}`).join("")}${customers.map(o => `&customers=${o}`).join("")}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchAllApiResult) => r),
	create: (description, amount, projectId, repeat) =>
		fetch(`${Config.api}/Finance/Project`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify({
				description,
				amount,
				projectId,
				expression: repeat
			})
		})
			.then((r) => r.json())
			.then((r: ItemApiResult) => r),
	delete: (ids) =>
		fetch(`${Config.api}/Finance/Project`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify(ids)
		})
			.then((r) => r.json())
			.then((r: MultiItemApiResult) => r),
	update: (id, description, amount, projectId) =>
		fetch(`${Config.api}/Finance/Project/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify({
				description,
				amount,
				projectId
			})
		})
			.then((r) => r.json())
			.then((r: ItemApiResult) => r),
	togglePay: (ids) =>
		fetch(`${Config.api}/Finance/Project/Pay`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify(ids)
		})
			.then((r) => r.json())
			.then((r: MultiItemApiResult) => r),
};
export default ProdApi;
