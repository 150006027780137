import React from "react";
import {
	faFlag, faFolders,
	faMapMarkerAlt,
	faPlus, faProjectDiagram, faSearch, faStopwatch, faUndo
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import {useSink} from "react-redux-sink";
import {ProjectItem, TextItem} from "../../components/Table/Items";
import {Pad24, Space12, Space36} from "../../components/Creator";
import Filters from "../../components/Filters";
import SearchInput from "../../components/Controls/SearchInput";
import ToggleButton from "../../components/Controls/ToggleButton";
import {faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import BorderButton from "../../components/Controls/BorderButton";
import ThemeSink from "../../store/theme-sink";

const DocumentsProjectsOverview: React.FC = () => {
	const theme = useSink(ThemeSink);
	return (
		<>
			<ListHeader
				title={'Projekty'}
				icon={faFolders}
				menu={[
					{label: "Aktivní", active: true, link: ""},
					{label: "Uzavřené", link: ""}
				]}
			/>
			<Pad24/>
			<Filters
				left={[
					<SearchInput icon={faSearch}/>,
					<Space36/>,
					<ToggleButton icon={faProjectDiagram} label={'Projekty'}/>,
					<Space12/>,
					<ToggleButton icon={faFolderOpen} label={'Typ'}/>,
					<Space12/>,
					<ToggleButton icon={faFlag} label={'Stav'}/>
				]}
				right={[<BorderButton icon={faUndo} label={'Zrušit filtry'} color={theme.colors.darkGray} />]}
			/>
			<Table
				header={[
					{label: "Název", sortable: true},
					{label: "Projekt", sortable: true},
					{label: "Typ"},
					{label: "Stav"},
					{label: "Podepsáno", sortable: true},
					{label: "Platnost", sortable: true}
				]}
				items={[
					{
						children: [
							<TextItem content={'6.8.2020'}/>,
							<TextItem content={'6.8.2020'}/>,
							<TextItem content={'6.8.2020'}/>,
							<TextItem content={'359 dní'}/>,
							<TextItem content={'98/600 h'}/>,
							<TextItem content={'JK, RP, DC, KB'}/>
						]
					}
				]}
			/>
			<Pagination/>
		</>
	);
};

export default DocumentsProjectsOverview;
