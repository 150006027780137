import {
	ApiCalls, CustomerSaveInterface, FetchAllApiResult, FetchDetailApiResult, FetchListApiResult, StatusApiResult
} from ".";
import Config from "../../../../client-config.json";
import {toast} from "react-hot-toast";

const ProdApi: ApiCalls = {
	fetchAll: (page, limit, search, projects) =>
		fetch(`${Config.api}/Customers?pageNumber=${page}&pageSize=${limit}${search !== "" ? `&name=${encodeURIComponent(search)}` : ""}${projects.map(o => `&projects=${o}`).join("")}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchAllApiResult) => r),
	fetchList: () =>
		fetch(`${Config.api}/Customers/List`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchListApiResult) => r.result.list)
			.catch(() => {
				toast.error('Nastala chyba');
			}),
	fetch: (id) =>
		fetch(`${Config.api}/Customers/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchDetailApiResult) => r.result)
			.catch(() => {
				toast.error('Nastala chyba');
			}),
	save: (id: string | null, data: CustomerSaveInterface) =>
		fetch(`${Config.api}/Customers/${id !== null ? id : ''}`, {
			method: id !== null ? 'PUT' : 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify(data)
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess)
			.catch(() => false),
	delete: (id: string) =>
		fetch(`${Config.api}/Customers/${id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess)
			.catch(() => false),
};
export default ProdApi;
