import moment from "moment";

export const buildTotalTimeFromDuration = (duration: number): string => {
	let d = moment.duration(Math.max(0, duration));
	return Math.floor(d.asHours()) + moment(Math.max(0, duration)).format(":mm:ss");
};

export const calculateRemainingProjectTime = (hasEnd: boolean, end: Date | null | undefined, billingDate: Date | undefined | null): number => {
	if (hasEnd && end && billingDate === null)
		return moment.utc(end).local().diff(moment().local(), "days");
	else if (hasEnd && end !== null && billingDate !== null)
		return (moment.utc(end).local().diff(moment.utc(billingDate).local(), "days"))
	return 0;
};

export const calculateTodayYesterdayPercentage = (today: number, yesterday: number): number => {
	if (today === 0 && yesterday === 0) return 0;
	if(today === 0 && yesterday > 0) return -100;
	if(today > 0 && yesterday === 0) return 100;

	return (today - yesterday) / yesterday * 100;
};
