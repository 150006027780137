import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

//#region styled
const Container = styled.button`
	border-radius: 4px;
	background-color: ${({theme}) => theme.colors.gold};
	width: 100%;
	padding: 15px;
	border: none;
	margin-top: 36px;
	font: ${({theme}) => theme.fonts.Montserrat(16, "bold")};
	color: ${({theme}) => theme.colors.white};
	transition: .25s background-color;
	cursor: pointer;
	&:hover {
		background-color: ${({theme}) => theme.colors.orange};
	}
`;
const FormButtonLoaderContainer = styled.div`
	border-radius: 4px;
	background-color: ${({theme}) => theme.colors.lightGray};;
	width: 100%;
	height: 48px;
	margin-top: 36px;
`;
//#endregion

const FormButton: React.FC<{
	content: string;
}> = ({
				content
			}) => {
	return (
		<Container>
			{content}
		</Container>
	);
};
export default FormButton;

export const FormButtonLoader: React.FC = () => {
	return (
		<FormButtonLoaderContainer/>
	);
};
