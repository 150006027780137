import React, {ReactElement} from "react";
import styled from "styled-components";

//#region styled
export const Divider = styled.div`
	background-color: ${({theme}) => theme.colors.lightGray};
	width: calc(100% - 72px);
	height: 1px;
	margin: 24px 36px;
`;
const FilterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 36px;
	width: calc(100% - 72px);
	margin-bottom: 24px;
`;
const Left = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
`;
const Right = styled.div`
	display: flex;
	justify-content: right;
	align-items: center;
`;
//#endregion

interface FiltersInterface {
	left: ReactElement[];
	right: ReactElement[];
}

const Filters: React.FC<FiltersInterface> =
	({
		 left,
		 right
	 }) => {
		return (
			<FilterContainer>
				<Left>{left.map((o) => o)}</Left>
				<Right>{right.map((o) => o)}</Right>
			</FilterContainer>
		);
	};

export default Filters;
