import Production from "./backend";
import {UsersFinanceTableItemInterface} from "../storage/user-finance-sink";

export interface FetchAllApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: UsersFinanceTableItemInterface[];
		pageNumber: number;
		pageSize: number;
		totalCount: number;
	};
}

export interface ItemApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		id: string;
	};
}

export interface MultiItemApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		ids: string[];
	};
}

export interface ApiCalls {
	fetchAll: (paid: boolean, page: number, limit: number, from: Date, to: Date, users: string[], projects: string[], customers: string[], description: string) => Promise<FetchAllApiResult>;
	create: (description: string, amount: number, userId: string, projectId: string) => Promise<ItemApiResult>;
	update: (id: string, description: string, amount: number, userId: string, projectId: string) => Promise<ItemApiResult>;
	delete: (ids: string[]) => Promise<MultiItemApiResult>;
	togglePay: (ids: string[]) => Promise<MultiItemApiResult>;
}

export default process.env.NODE_ENV === "production" ? Production : Production;
