import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import {toast} from "react-hot-toast";
import PaginationSink from "../../../store/pagination-sink";
import ProjectsSink from "./projects-sink";

export interface ProjectsDetailInterface {
	id: string;
	name: string;
	color: string;
	customer: {
		id: string;
		name: string;
	};
	manager: {
		id: string;
		name: string;
		hourlyWage: number;
		trackedHours: number;
		positions: { id: number; name: string; }[];
	};
	users: {
		id: string;
		name: string;
		hourlyWage: number;
		trackedHours: number;
		positions: { id: number; name: string; }[];
	}[];
	estimatedTime: number;
	start: Date;
	end: Date;
	billingDate: Date;
	hasEnd: boolean;
	note: string;
	asanaUrl: string;
	sharePointUrl: string;
	archived: boolean;
	totalTrackedHours: number;
	timerItemsCount: number;
}

@sink("projects-detail", SinkFactory)
export default class DetailSink {

	constructor(factory: SinkContainer) {
		this.pagination = factory.getSink(PaginationSink);
		this.projects = factory.getSink(ProjectsSink);
	}

	pagination: PaginationSink;

	projects: ProjectsSink;

	@state opened: boolean = false;

	@state id: string | null = null;

	@state loading: boolean = true;

	@state data: ProjectsDetailInterface | null = null;

	@effect
	open(id: string) {
		this.opened = true;
		this.id = id;
	}

	@effect
	async load(id: string) {
		this.loading = true;
		this.data = await Api.fetch(id);
		this.loading = false;
	}

	@trigger("projects-detail/id", { formatter: (id) => id })
	async idTrigger(id: string | null) {
		if(id !== null) await this.load(id as string);
	}

	@effect
	async toggleArchive() {
		if(this.id !== null) {
			this.loading = true;
			const toastId = toast.loading('Probíhá mazání...');
			const result = await Api.archive(this.id);
			toast.dismiss(toastId);
			if(result){
				this.clear();
				this.pagination.clear();
				await this.projects.loadTable();
				toast.success('Projekt odstraněn');
			}
			else toast.error('Nastala chyba!')
			this.loading = false;
		}
	}

	@effect
	async delete() {
		if(this.id !== null) {
			this.loading = true;
			const toastId = toast.loading('Probíhá mazání...');
			const result = await Api.delete(this.id);
			toast.dismiss(toastId);
			if(result){
				this.clear();
				this.pagination.clear();
				await this.projects.loadTable();
				toast.success('Projekt odstraněn');
			}
			else toast.error('Nastala chyba!')
			this.loading = false;
		}
	}

	@effect
	close() {
		this.clear();
	}

	@effect
	clear() {
		this.opened = false;
		this.loading = true;
		this.id = null;
		this.data = null;
	}

}
