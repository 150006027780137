import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import Navbar from "../../layout/Navbar";
import styled from "styled-components";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

//#region styled
const Container = styled.button`
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
	color: ${({theme}) => theme.colors.white};
	background-color: ${({theme}) => theme.colors.gold};
	border-radius: 4px;
	padding: 10px 16px;
	border: none;
	transition: .25s background-color;
	cursor: pointer;

	&:hover {
		background-color: ${({theme}) => theme.colors.orange};
	}
`;
const Text = styled.span<{ hasIcon?: boolean; }>`
	margin-left: ${({hasIcon}) => hasIcon ? 12 : 0}px;
`;

//#endregion

export interface HeaderButtonInterface {
	icon?: IconDefinition;
	label: string;
	click(): void;
	permissions?: string[];
}

const HeaderButton: React.FC<HeaderButtonInterface> = ({
																												 icon,
																												 label,
																												 click
																											 }) => {
	return (
		<Container onClick={click}>
			{icon && <FontAwesomeIcon icon={icon}/>}
			<Text hasIcon={icon !== undefined}>{label}</Text>
		</Container>
	);
};

export default HeaderButton;
