import styled from "styled-components";
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {useSink} from "react-redux-sink";
import ThemeSink from "../../store/theme-sink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faCoins, faPen, faUser, faProjectDiagram, faRepeat
} from "@fortawesome/pro-regular-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import ToggleButton from "../Controls/ToggleButton";
import HeaderButton from "../Controls/HeaderButton";
import UsersFinanceAddSink from "../../pages/Finance/Users/storage/user-finance-add-sink";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import UsersSink from "../../pages/Users/storage/users-sink";
import ProjectsSink from "../../pages/Projects/storage/projects-sink";
import ProjectsFinanceAddSink from "../../pages/Finance/Projects/storage/projects-finance-add-sink";
import {ToggleLoader} from "../Loaders";
import CreatorSink from "../../pages/Projects/storage/creator-sink";
import ColorPickerSink from "../../store/color-picker-sink";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import {toast} from "react-hot-toast";

//#region styled
export const CreatorContainer = styled.section<{ show?: boolean; }>`
	position: fixed;
	top: 0;
	left: ${({show}) => show ? '289px' : '100%'};
	width: calc(100% - 289px);
	height: 100vh;
	background-color: ${({theme}) => theme.colors.white};
	z-index: 1;
	transition: .25s left;
	overflow-y: auto;
`;
export const CreatorHeader = styled.div`
	width: calc(100% - 72px);
	background-color: ${({theme}) => theme.colors.white};
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 28px 36px 36px 36px;
`;
export const CreatorHeaderLeft = styled.div`
	display: flex;
	align-items: center;
`;
export const CreatorHeaderRight = styled.div`
	display: flex;
	align-items: center;
`;
export const CreatorClose = styled.button`
	background-color: transparent;
	border: none;
	font-size: 24px;
	color: ${({theme}) => theme.colors.iceBlue};
	cursor: pointer;
	transition: .25s color;

	&:hover {
		color: ${({theme}) => theme.colors.blueGray};
	}
`;
export const CreatorContent = styled.div`
	padding: 0 36px 24px 36px;
`;

export const CenteredContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

const CreatorDataContainer = styled.div`
	border: 1px solid ${({theme}) => theme.colors.lightGray};
	border-radius: 4px;

	&:not(:last-child) {
		margin-bottom: 24px;
	}
`;
const CreatorDataTitle = styled.div`
	background-color: ${({theme}) => theme.colors.lightWhite};
	border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
	width: calc(100% - 72px);
	padding: 14px 36px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	color: ${({theme}) => theme.colors.gold};
`;
const CreatorDataTitleText = styled.span`
	font: ${({theme}) => theme.fonts.Montserrat(18, "semibold")};
	color: ${({theme}) => theme.colors.blueGray};
	margin-left: 13px;
`;
const CreatorDataContent = styled.div`
	padding: 24px 36px;
	color: ${({theme}) => theme.colors.blueGray};
`;
export const CreatorTitleInputContainer = styled.input`
	border: none;
	border-bottom: 2px solid ${({theme}) => theme.colors.lightGray};
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(24, "semibold")};
	background-color: transparent;
	outline: none;
	margin-left: 24px;
`;
const CreatorTitleInputWithColorSelectorContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: 24px;
`;
export const CreatorTextArea = styled.textarea`
	width: 100%;
	height: 90px;
	border: none;
	background-color: transparent;
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	resize: none;
	outline: none;
`;

const CreatorInputContainer = styled.div`
	position: relative;
`;

const CreatorInputLabel = styled.label<{ color?: string; }>`
	font: ${({theme}) => theme.fonts.Montserrat(14, "bold")};
	color: ${({theme, color}) => color ?? theme.colors.darkGray};
	display: block;
	margin-bottom: 12px;
`;

const CreatorInputField = styled.input<{ hasIcon?: boolean; hasRightIcon?: boolean; hasFocus: boolean; }>`
	border: 1px solid ${({theme}) => theme.colors.iceBlue};
	background-color: transparent;
	border-radius: 4px 4px ${({hasFocus}) => hasFocus ? 0 : 4}px ${({hasFocus}) => hasFocus ? 0 : 4}px;
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	color: ${({theme}) => theme.colors.blueGray};
	padding: 14px ${({hasRightIcon}) => hasRightIcon ? 56 : 14}px 14px ${({hasIcon}) => hasIcon ? 48 : 14}px;
	width: calc(100% - ${({hasRightIcon}) => hasRightIcon ? 56 : 14}px - ${({hasIcon}) => hasIcon ? 48 : 14}px - 2px);
	outline: none;
	&:focus {
		border: 1px solid ${({theme}) => theme.colors.blueGray};
	}
`;

const CreatorInputRow = styled.div`
	width: 100%;
	position: relative;
`;

const CreatorInputIconContainer = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	font-size: 18px;
	color: ${({theme}) => theme.colors.gold};
`;

const CreatorInputEndIconContainer = styled(CreatorInputIconContainer)`
	left: unset;
	right: 5px;
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	color: ${({theme}) => theme.colors.iceBlue};
`;

const CreatorInputWhispererContainer = styled.div`
	position: absolute;
	top: 46px;
	left: 0;
	padding: 12px;
	width: calc(100% - 26px);
	height: auto;
	max-height: 150px;
	z-index: 1;
	background-color: ${({theme}) => theme.colors.lightWhite};
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	border: 1px solid ${({theme}) => theme.colors.blueGray};
	border-top: none;
`;

const CreatorInputWhispererInfo = styled.div`
	display: flex;
	align-items: center;
	font: ${({theme}) => theme.fonts.Montserrat(14, "regular")};
	opacity: .3;
	color: ${({theme}) => theme.colors.blueGray};
`;

const CreatorInputWhispererButton = styled.div`
	border: 1px solid ${({theme}) => theme.colors.blueGray};
	padding: 2px 6px;
	margin-right: 12px;
	border-radius: 4px;
`;

const CreatorInputWhispererList = styled.div`

`;

const CreatorInputWhispererItem = styled.div`
	
`;

export const CreatorGrayText = styled.div`
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	color: ${({theme}) => theme.colors.darkGray};
	display: flex;
	align-items: flex-end;
	height: calc(100% - 14px);
	padding-bottom: 14px;
`;

export const ThreeColumnsRow = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 252px);
	grid-gap: 24px 36px;
`;

export const TwoShortColumnsRow = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 180px);
	grid-gap: 24px 36px;
`;

export const TwoColumnsRow = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 252px);
	grid-gap: 24px 36px;
`;

export const OneColumnRow = styled.div<{ topMargin?: boolean; }>`
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: ${({topMargin}) => topMargin ? 24 : 0}px;
`;

export const TwoColumnsFixedAndFullRow = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: 180px 1fr;
	grid-gap: 24px 36px;
	width: 100%;
`;

export const ToggleRow = styled.div`
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	padding-bottom: 15px;
`;

export const SingleColumnRow = styled.div`
	display: block;
	width: 100%;

	&:not(:last-child) {
		margin-bottom: 24px;
	}
`;
export const InfoRow = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	color: ${({theme}) => theme.colors.darkGray};
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	margin: 24px 0 22px 0;
`;
export const InfoRowIcon = styled.div`
	color: ${({theme}) => theme.colors.darkGray};
	font-size: 14px;
	margin-right: 12px;
`;

export const Space36 = styled.div`
	width: 36px;
`;

export const Space12 = styled.div`
	width: 12px;
`;

export const Pad12 = styled.div`
	height: 12px;
`;
export const Pad24 = styled.div`
	height: 24px;
`;
export const Pad36 = styled.div`
	height: 36px;
`;
const ColorIndicator = styled.div<{ color: string; }>`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: ${({color}) => color};
	transition: .25s background-color, .25s opacity;
	cursor: pointer;

	&:hover {
		opacity: .75;
	}
`;

//#endregion

interface CreatorDataInterface {
	icon: IconDefinition;
	label: string;
}

export const CreatorData: React.FC<CreatorDataInterface> =
	({
		 icon,
		 label,
		 children
	 }) => {
		return (
			<CreatorDataContainer>
				<CreatorDataTitle>
					<FontAwesomeIcon icon={icon}/>
					<CreatorDataTitleText>{label}</CreatorDataTitleText>
				</CreatorDataTitle>
				<CreatorDataContent>{children}</CreatorDataContent>
			</CreatorDataContainer>
		);
	};

export const CreatorTitleInputWithColorSelector: React.FC = () => {
	const creator = useSink(CreatorSink);
	const picker = useSink(ColorPickerSink);

	useEffect(() => {
		if (picker.confirmed) {
			if (picker.id === 'project-creator-color') creator.changeColor(picker.color as string);
			picker.clear();
		}
	}, [picker.confirmed]);

	return (
		<CreatorTitleInputWithColorSelectorContainer>
			<ColorIndicator
				color={creator.color}
				className={`toggle-button-project-creator-color`}
				onClick={() => {
					picker.init('project-creator-color', creator.color);
				}}
			/>
			<CreatorTitleInputContainer
				value={creator.name}
				onChange={({currentTarget}) => creator.changeName(currentTarget.value)}
			/>
		</CreatorTitleInputWithColorSelectorContainer>
	);
};

interface InputBaseProps {
	label: string;
	labelColor?: string;
	trailingIcon?: IconDefinition;
	endIcon?: ReactElement;
	validationMessage?: React.ReactNode;
	whisperer?: boolean;
}

export const CreatorInput: React.FC<React.PropsWithChildren<InputBaseProps> &
	React.InputHTMLAttributes<HTMLInputElement>> =
	({
		 label,
		 labelColor,
		 trailingIcon,
		 endIcon,
		 validationMessage,
		 whisperer,
		 ...props
	 }) => {
		const ref = useRef<any>();
		const [hasFocus, setFocus] = useState<boolean>(false);

		useEffect(() => {
			if (document.hasFocus() && ref.current.contains(document.activeElement)) {
				setFocus(true);
			}
		}, []);

		return (
			<CreatorInputContainer>
				<CreatorInputLabel color={labelColor}>{label}</CreatorInputLabel>
				<CreatorInputRow>
					{trailingIcon && <CreatorInputIconContainer>
						<FontAwesomeIcon icon={trailingIcon}/>
					</CreatorInputIconContainer>}
					<CreatorInputField
						hasFocus={hasFocus}
						ref={ref}
						onKeyPress={e => {
							if(whisperer) {
								if(e.key === 'Enter') toast('Pracuje se na tom', {icon: '😎'});
							}
						}}
						onFocus={() => setFocus(true)}
						onBlur={() => setFocus(false)}
						hasIcon={trailingIcon !== undefined}
						hasRightIcon={endIcon !== undefined}
						type={'text'}
						{...props}
					/>
					{endIcon && <CreatorInputEndIconContainer>
						{endIcon}
					</CreatorInputEndIconContainer>}
					{whisperer && hasFocus && <CreatorInputWhispererContainer>
						<CreatorInputWhispererInfo>
							<CreatorInputWhispererButton>Enter</CreatorInputWhispererButton>
							Našeptat do ouška
						</CreatorInputWhispererInfo>
						<CreatorInputWhispererList>
							<CreatorInputWhispererItem></CreatorInputWhispererItem>
						</CreatorInputWhispererList>
					</CreatorInputWhispererContainer>}
				</CreatorInputRow>
			</CreatorInputContainer>
		);
	};

const FinanceItemAdderContainer = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin: 0 36px;
`;

const FinanceItemAdderLeft = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: 250px 180px 1fr;
	grid-column-gap: 36px;
`;

const FinanceItemAdderRight = styled.div`
	margin-bottom: 8px;
`;
const FinanceItemToggleContainer = styled.div`
	display: flex;
	align-items: flex-end;
	height: calc(100% - 15px);
`;
export const FinanceItemDivider = styled.div`
	margin: 24px 36px;
	width: calc(100% - 72px);
	height: 1px;
	background-color: ${({theme}) => theme.colors.lightGray};
`;

export const FinanceUserItemAdder: React.FC = () => {
	const theme = useSink(ThemeSink);
	const adder = useSink(UsersFinanceAddSink);
	const users = useSink(UsersSink);
	const projects = useSink(ProjectsSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed) {
			if (multiCheckboxPicker.id === 'users-finance-adder-user') adder.changeUser(multiCheckboxPicker.selected[0] ?? null);
			else if (multiCheckboxPicker.id === 'users-finance-adder-project') adder.changeProject(multiCheckboxPicker.selected[0] ?? null);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	return (
		<FinanceItemAdderContainer>
			<FinanceItemAdderLeft>
				<CreatorInput value={adder.description}
											onChange={({currentTarget}) => adder.changeDescription(currentTarget.value)} trailingIcon={faPen}
											labelColor={theme.colors.iceBlue} label={'Přidat fixní záznam'}/>
				<CreatorInput value={adder.amount} onChange={({currentTarget}) => adder.changeAmount(currentTarget.value)}
											endIcon={<>Kč</>} trailingIcon={faCoins} label={'Částka'}/>
				<FinanceItemToggleContainer>
					<ToggleButton
						id={'users-finance-adder-user'}
						active={multiCheckboxPicker.id === 'users-finance-adder-user'}
						selected={adder.user !== null}
						onClick={async () => {
							multiCheckboxPicker.toggle('users-finance-adder-user', true)
							multiCheckboxPicker.setSelected(adder.user !== null ? [adder.user] : []);
							await users.loadList();
						}}
						icon={faUser}
						label={'Uživatel'}
					/>
					<Space12/>
					{adder.user !== null && <ToggleButton
						id={'users-finance-adder-project'}
						active={multiCheckboxPicker.id === 'users-finance-adder-project'}
						selected={adder.project !== null}
						onClick={async () => {
							multiCheckboxPicker.toggle('users-finance-adder-project', true)
							multiCheckboxPicker.setSelected(adder.project !== null ? [adder.project] : []);
							await projects.loadList(undefined, undefined, adder.user as string);
						}}
						icon={faProjectDiagram}
						label={'Projekt'}
					/> || <ToggleLoader/>}
				</FinanceItemToggleContainer>
			</FinanceItemAdderLeft>
			<FinanceItemAdderRight>
				<HeaderButton label={'Přidat záznam'} click={() => adder.submit()}/>
			</FinanceItemAdderRight>
		</FinanceItemAdderContainer>
	)
		;
};


export const FinanceProjectItemAdder: React.FC = () => {
	const theme = useSink(ThemeSink);
	const adder = useSink(ProjectsFinanceAddSink);
	const projects = useSink(ProjectsSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const picker = useSink(DateTimePickerSink);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed) {
			if (multiCheckboxPicker.id === 'projects-finance-adder-project') adder.changeProject(multiCheckboxPicker.selected[0] ?? null);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		if (picker.confirmed) {
			if (picker.id === 'projects-finance-adder-repeat') adder.changeRepeat(picker.expression !== "" ? picker.expression : null);
			picker.clear();
		}
	}, [picker.confirmed]);

	return (
		<FinanceItemAdderContainer>
			<FinanceItemAdderLeft>
				<CreatorInput value={adder.description}
											onChange={({currentTarget}) => adder.changeDescription(currentTarget.value)} trailingIcon={faPen}
											labelColor={theme.colors.iceBlue} label={'Přidat fixní záznam'}/>
				<CreatorInput value={adder.amount} onChange={({currentTarget}) => adder.changeAmount(currentTarget.value)}
											endIcon={<>Kč</>} trailingIcon={faCoins} label={'Částka'}/>
				<FinanceItemToggleContainer>
					<ToggleButton
						id={'projects-finance-adder-project'}
						active={multiCheckboxPicker.id === 'projects-finance-adder-project'}
						selected={adder.project !== null}
						onClick={async () => {
							multiCheckboxPicker.toggle('projects-finance-adder-project', true);
							multiCheckboxPicker.setSelected(adder.project ? [adder.project] : []);
							await projects.loadList();
						}}
						icon={faProjectDiagram}
						label={'Projekt'}
					/>
					<Space12/>
					<ToggleButton
						id={'projects-finance-adder-repeat'}
						selected={adder.repeat !== null}
						active={picker.id === 'projects-finance-adder-repeat'}
						onClick={async () => {
							picker.toggle('projects-finance-adder-repeat', undefined, {showCron: true, showPredefinedRanges: false});
						}}
						icon={faRepeat}
						label={'Opakování'}
					/>
				</FinanceItemToggleContainer>
			</FinanceItemAdderLeft>
			<FinanceItemAdderRight>
				<HeaderButton label={'Přidat záznam'} click={() => adder.submit()}/>
			</FinanceItemAdderRight>
		</FinanceItemAdderContainer>
	);
};

const EditorInputContainer = styled.div`
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
	position: relative;
	font-size: 18px;
	color: ${({theme}) => theme.colors.iceBlue};
`;
const EditorInputIcon = styled.div<{ color?: string; }>`
	width: 50px;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({theme, color}) => color ?? theme.colors.iceBlue};
`;
const EditorInputField = styled.input<{ size?: number; }>`
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	padding: 14px 14px 14px 50px;
	width: ${({size}) => size ?? 100}px;
	border: 1px solid ${({theme}) => theme.colors.iceBlue};
	border-radius: 4px;
	background-color: transparent;
	color: ${({theme}) => theme.colors.blueGray};
`;


interface EditorInputInterface {
	icon: IconDefinition;
	size?: number;
	type?: string;
	placeholder?: string;
	iconColor?: string;
}

export const EditorInput: React.FC<React.InputHTMLAttributes<HTMLInputElement> & EditorInputInterface> =
	({
		 icon,
		 iconColor,
		 ...props
	 }) => {
		return (
			<EditorInputContainer>
				<EditorInputIcon color={iconColor}>
					<FontAwesomeIcon icon={icon}/>
				</EditorInputIcon>
				<EditorInputField {...props}/>
			</EditorInputContainer>
		);
	};
