import {sink, state, effect} from "react-redux-sink";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

interface DefaultDimensions {
	top?: number;
	left?: number;
	width?: number;
	height?: number;
}

interface InputProps {
	id: string;
	values: string[];
	type: InputType;
	icon: IconDefinition;
	label: string;
	size?: number;
}

type InputType = 'input' | 'single-select' | 'date' | 'date-time' | 'date-repeat';

@sink("table-editor")
export default class TableEditorSink {

	@state active: boolean = false;

	@state id: string | null = null;

	@state editor: string | null = null;

	@state confirmed: boolean = false;

	@state defaultDimensions?: DefaultDimensions = undefined;

	@effect
	show(id: string, editor: string, dimensions?: DefaultDimensions) {
		this.id = id;
		this.active = true;
		this.editor = editor;
		this.defaultDimensions = dimensions;
	}

	@effect
	setDimensions(dim?: DefaultDimensions) {
		this.defaultDimensions = dim;
	}

	@effect
	confirm() {
		this.confirmed = true;
	}

	@effect
	clear() {
		this.active = false;
		this.id = null;
		this.defaultDimensions = undefined;
		this.confirmed = false;
		this.editor = null;
	}

}
