import React from "react";
import styled from "styled-components";
import {CreatorInput} from "../Creator";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import {useSink} from "react-redux-sink";
import moment from "moment";
import {toast} from "react-hot-toast";

const TimePickerContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 12px;
	margin: 12px;
	padding-bottom: 24px;
	border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
`;

const TimePicker: React.FC = () => {
	const picker = useSink(DateTimePickerSink);

	const onFinish = (isFrom: boolean): void => {
		if (isFrom) {
			const testFrom = testTimeRegexPattern(picker.timeFrom);
			if (!testFrom) {
				picker.changeTimeFrom(moment(picker.dates.from).format("HH:mm").toString());
				toast.error('Zadali jste nevalidní čas začátku');
			} else {
				const newDate = moment(picker.dates.from).set({
					hour: parseInt(picker.timeFrom.split(':')[0]),
					minute: parseInt(picker.timeFrom.split(':')[1]),
					second: 0
				}).toDate();
				picker.changeFrom(newDate);
				if(moment(picker.dates.to).diff(moment(newDate), 'minutes') < 0) {
					if(!picker.blockEndTime) picker.changeTo(moment(picker.dates.to).set({
						hour: parseInt(picker.timeFrom.split(':')[0]),
						minute: parseInt(picker.timeFrom.split(':')[1]),
						second: 0
					}).toDate());
					else picker.changeFrom(moment(picker.dates.from).set({
						hour: parseInt(picker.timeTo.split(':')[0]),
						minute: parseInt(picker.timeTo.split(':')[1]),
						second: 0
					}).toDate());
				}
			}
		} else {
			const testTo = testTimeRegexPattern(picker.timeTo);
			if (!testTo) {
				picker.changeTimeTo(moment(picker.dates.to).format("HH:mm").toString());
				toast.error('Zadali jste nevalidní čas konce');
			} else {
				const newDate = moment(picker.dates.to).set({
					hour: parseInt(picker.timeTo.split(':')[0]),
					minute: parseInt(picker.timeTo.split(':')[1]),
					second: 0
				}).toDate();
				picker.changeTo(newDate);
				if(moment(newDate).diff(moment(picker.dates.from), 'minutes') < 0) picker.changeFrom(moment(picker.dates.from).set({
					hour: parseInt(picker.timeTo.split(':')[0]),
					minute: parseInt(picker.timeTo.split(':')[1]),
					second: 0
				}).toDate());
			}
		}
	};

	const testTimeRegexPattern = (value: string): boolean => {
		return (new RegExp("^([01]?[0-9]|2[0-3]):[0-5][0-9]$")).test(value);
	};

	return (
		<TimePickerContainer>
			<CreatorInput
				value={picker.timeFrom}
				onChange={({currentTarget}) => picker.changeTimeFrom(currentTarget.value)}
				onKeyPress={async (e) => {
					if (e.key === "Enter") onFinish(true)
				}}
				onBlur={() => onFinish(true)}
				endIcon={<>{moment(picker.dates.from).format('D.M.')}</>}
				label={'Start'}
			/>
			<CreatorInput
				disabled={picker.blockEndTime}
				value={picker.timeTo}
				onChange={({currentTarget}) => picker.changeTimeTo(currentTarget.value)}
				onKeyPress={async (e) => {
					if (e.key === "Enter") onFinish(false)
				}}
				onBlur={() => onFinish(false)}
				endIcon={<>{moment(picker.dates.to).format('D.M.')}</>}
				label={'Konec'}
			/>
		</TimePickerContainer>
	);
};

export default TimePicker;
