import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import PaginationSink from "../../../../store/pagination-sink";
import {toast} from "react-hot-toast";
import FilterSink from "../../../../store/filter-sink";

export interface ProjectsFinanceTableItemInterface {
	id: string;
	project: {
		id: string;
		color: string;
		projectName: string;
		customerName: string;
	};
	description: string;
	maturityDateTime: Date;
	insertedDateTime: Date;
	paidDateTime: Date;
	expression: string;
	amount: number;
}

@sink("projects-finance", SinkFactory)
export default class ProjectsFinanceSink {

	constructor(factory: SinkContainer){
		this.pagination = factory.getSink(PaginationSink);
		this.filter = factory.getSink(FilterSink);
	}

	pagination: PaginationSink;

	filter: FilterSink;

	@state paid: boolean = false;

	@state tableLoading: boolean = true;

	@state table: ProjectsFinanceTableItemInterface[] = [];

	@state selected: string[] = [];

	@effect
	checkAll() {
		this.selected = this.table.map(o => o.id);
	}

	@effect
	uncheckAll() {
		this.selected = [];
	}

	@effect
	toggleSelected(id: string) {
		if(this.selected.includes(id)) this.selected = this.selected.filter(o => o !== id);
		else this.selected = [...this.selected, id];
	}

	@effect
	async loadTable(paid?: boolean) {
		if(paid !== undefined) this.paid = paid;
		this.tableLoading = true;
		const result = await Api.fetchAll(
			this.paid,
			this.pagination.page,
			this.pagination.limit,
			this.filter.from,
			this.filter.to,
			this.filter.projects,
			this.filter.customers,
			this.filter.search
		);
		if(result !== undefined && result !== null && result.result !== null){
			this.table = result.result.list;
			this.pagination.changeTotal(result.result.totalCount);
		}
		else toast.error('Nastala chyba!');
		this.tableLoading = false;
	}

	@effect
	async delete() {
		this.tableLoading = true;
		const result = await Api.delete(this.selected);
		if(result !== undefined && result !== null && result.result !== null){
			toast.success('Položky smazány');
			this.pagination.clear();
			await this.loadTable();
			this.clearSelected();
		}
		else toast.error('Nastala chyba!');
		this.tableLoading = false;
	}

	@effect
	async togglePay() {
		this.tableLoading = true;
		const result = await Api.togglePay(this.selected);
		if(result !== undefined && result !== null && result.result !== null){
			toast.success(`Položky ${this.paid ? 'neuhrazeny' : 'uhrazeny'}`);
			this.pagination.clear();
			await this.loadTable();
			this.clearSelected();
		}
		else toast.error('Nastala chyba!');
		this.tableLoading = false;
	}

	@effect
	clearTable() {
		this.table = [];
		this.tableLoading = true;
	}

	@effect
	clearSelected() {
		this.selected = [];
	}

	@effect
	clear() {
		this.clearTable();
	}

}
