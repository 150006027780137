import {sink, state, effect} from "react-redux-sink";

export type DialogType = "warning";

export type ConfirmType = "confirm" | "cancel" | "none";

const DEFAULT_TITLE = "Varování";
const DEFAULT_DESCRIPTION = "";
const DEFAULT_TYPE = "warning";
const DEFAULT_CONFIRM = "none";

@sink("dialog")
export default class DialogSink {

	@state active: boolean = false;

	@state id: string | null = null;

	@state type: DialogType = DEFAULT_TYPE;

	@state confirm: ConfirmType = DEFAULT_CONFIRM;

	@state title: string = DEFAULT_TITLE;

	@state description: string = DEFAULT_DESCRIPTION;

	@effect
	init(id: string, title: string, description: string, type?: DialogType){
		this.id = id;
		this.title = title;
		this.description = description;
		this.type = type ?? DEFAULT_TYPE;
		this.active = true;
	}

	@effect
	action(confirm: ConfirmType) {
		this.confirm = confirm;
	}

	@effect
	clear() {
		this.id = null;
		this.active = false;
		this.type = DEFAULT_TYPE;
		this.confirm = DEFAULT_CONFIRM;
		this.title = DEFAULT_TITLE;
		this.description = DEFAULT_DESCRIPTION;
	}

}
