import React, {useEffect} from "react";
import styled from "styled-components";
import ThemeSink from "../../../store/theme-sink";
import {useSink} from "react-redux-sink";
import TrackedHoursBarChartSink from "../storage/tracked-hours-bar-chart-sink";
import {BarDatum, ResponsiveBar} from "@nivo/bar";
import FulfillmentWorkingHoursSink, {FulfillmentProps} from "../storage/fulfillment-working-hours-sink";

const FulfillmentWorkingHoursChartContainer = styled.div`
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "semibold")};
	display: flex;
	align-items: center;
	width: calc(100% - 72px);
	height: 375px;
	margin: 0 36px;
	cursor: pointer;
	transition: .25s color;
	&:hover {
		color: ${({theme}) => theme.colors.gold};
	}
`;

const Tooltip = styled.div`
	border-radius: 4px;
	background-color: ${({theme}) => theme.colors.white};
	padding: 6px 12px;
	color: ${({theme}) => theme.colors.pebbleBlack};
	font-weight: 400;
	box-shadow: 0 0 4px 0 ${({theme}) => theme.colors.darkGray}30;
`;

const colors: { [index: string]: string } = {
	"reported": "#FF6F00",
	"toBeReported": "#FFB200",
	"fullTime": "#FFB200"
}

const formatData = (items: FulfillmentProps[]): BarDatum[] => {
	const arr: any[] = [];
	items.forEach(o => {
		arr.push({
			"name": o.name,
			"reported": o.reported,
			"reportedOrig": o.reported,
			"toBeReported": Math.max(0, o.toBeReported - o.reported),
			"toBeReportedOrig": o.toBeReported,
			"fullTime": Math.max(0, o.fullTime - Math.max(o.reported, o.toBeReported)),
			"fullTimeOrig": o.fullTime
		});
	});
	return arr;
}

const FulfillmentWorkingHoursChart: React.FC = () => {
	const theme = useSink(ThemeSink);
	const fulfillment = useSink(FulfillmentWorkingHoursSink);
	const data = formatData(fulfillment.data);

	return (
		<FulfillmentWorkingHoursChartContainer>
			<ResponsiveBar
				data={data}
				keys={[ 'reported', 'toBeReported', 'fullTime' ]}
				indexBy="name"
				margin={{top: 50, right: 0, bottom: 50, left: 0}}
				padding={0.3}
				innerPadding={0}
				groupMode="stacked"
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				colors={(bar) => (colors[bar.id])}
				borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: '',
					legendPosition: 'middle',
					legendOffset: 0
				}}
				axisLeft={null}
				enableLabel={false}
				legendLabel={""}
				valueFormat={""}
				tooltip={(b) =>
					<Tooltip>
						Vykázáno: <b>{data[b.index]["reportedOrig"]} h</b><br/>
						Má být vykázáno: <b>{data[b.index]["toBeReportedOrig"]} h</b><br/>
						Celkový úvazek: <b>{data[b.index]["fullTimeOrig"]} h</b>
					</Tooltip>
				}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
				defs={[
					{
						id: 'lines',
						type: 'patternLines',
						background: 'inherit',
						color: '#FF9000',
						rotation: -45,
						lineWidth: 4,
						spacing: 6
					}
				]}
				fill={[
					{
						match: {
							id: 'toBeReported'
						},
						id: 'lines'
					}
				]}
			/>
		</FulfillmentWorkingHoursChartContainer>
	);
};

export default FulfillmentWorkingHoursChart;
