import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import {toast} from "react-hot-toast";
import RolesSink from "./roles-sink";
import PaginationSink from "../../../store/pagination-sink";

export interface RoleDetailInterface {
	id: string;
	name: string;
	usersCount: number;
	permissions: { id: string; name: string; module: string; }[];
}

@sink("roles-detail", SinkFactory)
export default class DetailSink {

	constructor(factory: SinkContainer) {
		this.roles = factory.getSink(RolesSink);
	}

	roles: RolesSink;

	@state opened: boolean = false;

	@state id: string | null = null;

	@state loading: boolean = true;

	@state data: RoleDetailInterface | null = null;

	@effect
	open(id: string) {
		this.opened = true;
		this.id = id;
	}

	@effect
	async load(id: string) {
		this.loading = true;
		this.data = await Api.fetch(id);
		this.loading = false;
	}

	@trigger("roles-detail/id", {formatter: (id) => id})
	async idTrigger(id: string | null) {
		if (id !== null) await this.load(id as string);
	}

	@effect
	close() {
		this.opened = false;
		this.clear();
	}

	@effect
	clear() {
		this.loading = true;
		this.opened = false;
		this.id = null;
		this.data = null;
	}

	@effect
	async delete() {
		if (this.id !== null) {
			this.loading = true
			const toastId = toast.loading('Probíhá mazání');
			const result = await Api.delete(this.id)
			toast.dismiss(toastId);
			if(result) {
				this.clear();
				await this.roles.loadTable();
				toast.success('Role smazána');
			}
			else toast.error('Nastala chyba');
			this.loading = false;
		}
	}

}
