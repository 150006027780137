import {effect, sink, state} from "react-redux-sink";
import Api from "../api";
import {toast} from "react-hot-toast";

export interface PositionItemInterface {
	id: number;
	name: string;
}

@sink("positions")
export default class PositionSink {

	@state loading: boolean = true;

	@state list: PositionItemInterface[] = [];

	@effect
	async load() {
		this.loading = true;
		const result = await Api.fetchPositions();
		if(result !== undefined && result !== null && result.result !== null) {
			this.list = result.result.list;
		}
		else toast.error('Při načítání seznamu pozic nastala chyba');
		this.loading = false;
	}

	@effect
	clear() {
		this.list = [];
		this.loading = true;
	}
}
