import {effect, sink, SinkContainer, SinkFactory, state} from "react-redux-sink";
import FilterSink from "../../../store/filter-sink";
import {BarDatum} from "@nivo/bar";
import Api from "../api";
import {toast} from "react-hot-toast";

export interface FulfillmentProps {
	name: string;
	reported: number;
	toBeReported: number;
	fullTime: number;
}

@sink("fulfillment-working-hours", SinkFactory)
export default class FulfillmentWorkingHoursSink {

	constructor(factory: SinkContainer){
		this.filter = factory.getSink(FilterSink);
	}

	filter: FilterSink;

	@state dataLoading: boolean = true;

	@state data: FulfillmentProps[] = [];

	@effect
	async loadTable() {
		this.dataLoading = true;
		const result = await Api.fetchFulfillmentWorkingHours(
			this.filter.from,
			this.filter.to,
			this.filter.users
		);
		if(result !== undefined && result !== null && result.result !== null){
			this.data = result.result.fulfillments;
		}
		else toast.error('Nastala chyba!');
		this.dataLoading = false;
	}

	@effect
	clearTable() {
		this.data = [];
		this.dataLoading = true;
	}
}
