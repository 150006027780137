import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
	faMoon,
	faSun
} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {useSink} from "react-redux-sink";
import ThemeSink from "../../store/theme-sink";

//#region styled
const Container = styled.div`
	position: absolute;
	top: 28px;
	right: 36px;
	border-radius: 50%;
	background-color: ${({theme}) => theme.colors.lightWhite};
	color: ${({theme}) => theme.colors.blueGray};
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;
//#endregion

const ThemeControl: React.FC = () => {
	const theme = useSink(ThemeSink);
	return (
		<Container onClick={() => theme.changeTheme(theme.theme === "light" ? "dark" : "light")}>
			<FontAwesomeIcon icon={faMoon}/>
		</Container>
	);
};
export default ThemeControl;
