import React, {useEffect} from "react";
import {FormContainer, Space, Title} from "../../components/Form";
import ThemeControl from "../../components/Controls/ThemeControl";
import FormInput from "../../components/Controls/FormInput";
import {faKey} from "@fortawesome/pro-light-svg-icons";
import FormButton from "../../components/Controls/FormButton";
import {
	Centered,
	Copyright,
	DarkLogo,
	LightLogo,
	RocketContainer,
	RocketIconDark,
	RocketIconLight
} from "./index";
import ResetPasswordSink from "./storage/reset-password-sink";
import {useSink} from "react-redux-sink";
import ThemeSink from "../../store/theme-sink";
import Routes from "../../routes";
import NavigationSink from "../../store/navigation-sink";
import IdentitySink from "../../store/identity-sink";

const ResetPassword: React.FC = () => {
	const resetPassword = useSink(ResetPasswordSink);
	const navigation = useSink(NavigationSink);
	const identity = useSink(IdentitySink);
	const theme = useSink(ThemeSink);

	useEffect(() => {
		if(identity.data !== null) navigation.history.push(Routes.paths.TIMER_OVERVIEW);
		return () => {
			resetPassword.clear();
		};
	}, []);

	return (
		<>
			<RocketContainer>
				{theme.theme === "dark" && <RocketIconDark
					width={window.innerWidth}
					height={window.innerHeight}
				/>}
				{theme.theme === "light" && <RocketIconLight
					width={window.innerWidth}
					height={window.innerHeight}
				/>}
			</RocketContainer>
			{theme.theme === "light" && <LightLogo/>}
			{theme.theme === "dark" && <DarkLogo/>}
			<ThemeControl/>
			<Centered>
				<FormContainer onSubmit={(e) => {
					e.preventDefault();
					resetPassword.submit().then();
					return false;
				}}>
					<Title>Reset hesla</Title>
					<FormInput icon={faKey} placeholder={'Heslo'} isSecret={true} value={resetPassword.password} onChange={e => resetPassword.changePassword(e.currentTarget.value)}/>
					<Space/>
					<FormInput icon={faKey} placeholder={'Heslo znovu'} isSecret={true} value={resetPassword.passwordAgain} onChange={e => resetPassword.changePasswordAgain(e.currentTarget.value)}/>
					<FormButton content={'Potvrdit změnu'}/>
				</FormContainer>
			</Centered>
			<Copyright>Informační systém TwoDo. Verze 2.0.0</Copyright>
		</>
	);
};
export default ResetPassword;
