import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api, {UploadApiResult} from "../api";
import {toast} from "react-hot-toast";
import StockSink from "./stock-sink";

export interface StockDetailInterface {
	id: string;
	name: string;
	location: string;
	type: string;
	total: number;
	available: number;
	photos: string[];
}

@sink("stock-detail", SinkFactory)
export default class DetailSink {

	constructor(factory: SinkContainer) {
		this.stock = factory.getSink(StockSink);
	}

	stock: StockSink;

	@state opened: boolean = false;

	@state id: string | null = null;

	@state loading: boolean = true;

	@state uploading: boolean = false;

	@state photoDeleting: boolean = false;

	@state data: StockDetailInterface | null = null;

	@effect
	open(id: string) {
		this.opened = true;
		this.id = id;
	}

	@effect
	async load(id: string) {
		this.loading = true;
		this.data = await Api.fetch(id);
		this.loading = false;
	}

	@effect
	async delete() {
		if (this.id !== null) {
			this.loading = true;
			const toastId = toast.loading('Probíhá mazání');
			const result = await Api.delete(this.id);
			toast.dismiss(toastId);
			if(result) {
				this.clear();
				await this.stock.loadTable();
				toast.success('Položka smazána');
			}
			else toast.error('Nastala chyba');
			this.loading = false
		}
	}

	@trigger("stock-detail/id", {formatter: (id) => id})
	async idTrigger(id: string | null) {
		if (id !== null) await this.load(id as string);
	}

	@effect
	close() {
		this.clear();
	}

	@effect
	clear() {
		this.loading = true;
		this.id = null;
		this.data = null;
		this.opened = false;
	}

	@effect
	async upload(files: File[]) {
		if (this.id !== null && this.data !== null) {
			this.uploading = true;
			const responses: UploadApiResult[] = [];
			for (let i = 0; i < files.length; i++) {
				const response = await Api.upload(this.id as string, files[i]);
				responses.push(response);
			}
			let anyFailed = false;
			const photos: string[] = [];
			for (let i = 0; i < responses.length; i++) {
				if (responses[i].status !== 200) anyFailed = true;
				else photos.push(responses[i].id);
			}
			if (anyFailed) toast.error('Některé soubory se nepovedlo nahrát');
			else toast.success('Nahrávání dokončeno')

			this.data = {
				...this.data,
				photos: [
					...this.data.photos,
					...photos
				]
			};
			this.uploading = false;
		}
	}

	@effect
	async deletePhoto(photo: string) {
		if (this.id !== null && this.data !== null) {
			this.photoDeleting = true;
			await toast.promise(Api.deletePhoto(this.id, photo), {
				loading: 'Probíhá mazání',
				success: (isSuccess) => {
					this.photoDeleting = false;
					if (isSuccess && this.data !== null) {
						this.data = {
							...this.data,
							photos: [
								...this.data.photos
							]
						};
						return 'Fotka smazána';
					} else return 'Nastala chyba';
				},
				error: 'Nastala chyba'
			});
		}
	}

}
