import styled from "styled-components";
import React, {useEffect} from "react";
import {faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Config from "../../../client-config.json"
import DialogSink from "../../store/dialog-sink";
import {useSink} from "react-redux-sink";

export const GalleryContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	width: 100%;
	grid-gap: 10px;
`;

const GalleryItemDelete = styled.div`
	background-color: ${({theme}) => theme.colors.white}AA;
	font-size: 14px;
	border-radius: 4px;
	color: ${({theme}) => theme.colors.iceBlue};
	width: 24px;
	height: 24px;
	position: absolute;
	right: 5px;
	top: 5px;
	display: none;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: .25s color, .25s background-color;

	&:hover {
		color: ${({theme}) => theme.colors.white};
		background-color: ${({theme}) => theme.colors.red};
	}
`;
const GalleryItemContainer = styled.div`
	width: 100%;
	aspect-ratio: 1;
	position: relative;

	&:hover ${GalleryItemDelete} {
		display: flex;
	}
`;
const GalleryItemImage = styled.img`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	object-fit: cover;
	object-position: center;
`;


export const GalleryItem: React.FC<{
	id: string;
	remove(): void;
}> = ({
				id,
				remove
			}) => {
	const dialog = useSink(DialogSink);

	useEffect(() => {
		if (dialog.id === `gallery-item-delete-${id}`) {
			remove();
			dialog.clear();
		}
	}, [dialog.confirm]);


	return (
		<GalleryItemContainer>
			<GalleryItemImage src={`${Config.api}/Storage/Public/${id}`}/>
			<GalleryItemDelete
				onClick={() => dialog.init(`gallery-item-delete-${id}`, 'Varování', 'Opravdu chcete odstranit tuto fotku?')}>
				<FontAwesomeIcon icon={faTrashAlt}/>
			</GalleryItemDelete>
		</GalleryItemContainer>
	);
};
