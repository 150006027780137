import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import PaginationSink from "../../../store/pagination-sink";
import Routes from "../../../routes";
import NavigationSink from "../../../store/navigation-sink";
import FilterSink from "../../../store/filter-sink";
import {toast} from "react-hot-toast";

export interface PropertyTableItemInterface {
	id: string;
	name: string;
	location: string;
	type: string;
	state: string;
	age: Date;
	ownerId?: string;
	ownerName?: string;
}

export interface PropertyStateInterface {
	id: number;
	name: string;
}

@sink("property", SinkFactory)
export default class PropertySink {

	constructor(factory: SinkContainer){
		this.pagination = factory.getSink(PaginationSink);
		this.navigation = factory.getSink(NavigationSink);
		this.filter = factory.getSink(FilterSink);
	}

	pagination: PaginationSink;

	navigation: NavigationSink;

	filter: FilterSink;

	@state tableLoading: boolean = true;

	@state table: PropertyTableItemInterface[] = [];

	@state locationsLoading: boolean = true;

	@state locationsList: string[] = [];

	@state typesLoading: boolean = true;

	@state typesList: string[] = [];

	@state statesLoading: boolean = true;

	@state statesList: PropertyStateInterface[] = [];

	@effect
	async loadTable() {
		this.tableLoading = true;
		const result = await Api.fetchAll(
			this.pagination.page,
			this.pagination.limit,
			this.filter.search,
			this.filter.locations,
			this.filter.types,
			this.filter.states
		);
		if(result !== undefined && result !== null && result.result !== null){
			this.table = result.result.list;
			this.pagination.changeTotal(result.result.totalCount);
		}
		else toast.error('Nastala chyba!');
		this.tableLoading = false;
	}

	@effect
	async loadLocationsList() {
		this.locationsLoading = true;
		this.locationsList = await Api.fetchLocationsList();
		this.locationsLoading = false;
	}

	@effect
	async loadTypesList() {
		this.typesLoading = true;
		this.typesList = await Api.fetchTypesList();
		this.typesLoading = false;
	}

	@effect
	async loadStatesList() {
		this.statesLoading = true;
		this.statesList = await Api.fetchStatesList();
		this.statesLoading = false;
	}

	@effect
	clearTable() {
		this.table = [];
		this.tableLoading = true;
	}

	@effect
	clearLocationsList() {
		this.locationsList = [];
		this.locationsLoading = true;
	}

	@effect
	clearTypesList() {
		this.typesList = [];
		this.typesLoading = true;
	}

	@effect
	clearStatesList() {
		this.statesList = [];
		this.statesLoading = true;
	}

	@effect
	clear() {
		this.clearTable();
		this.clearLocationsList();
		this.clearTypesList();
		this.clearStatesList();
	}
}
