import {effect, sink, state} from "react-redux-sink";
import Api from "../api";
import {Item} from "react-nestable";
import {toast} from "react-hot-toast";

export interface StructureTableItemInterface {
	id: string;
	name: string;
	image: string;
	children: StructureTableItemInterface[];
}

@sink("users-structure")
export default class StructureSink {

	@state tableLoading: boolean = true;

	@state table: StructureTableItemInterface[] = [];

	@effect
	async changeStructure(items: Item[]) {
		this.tableLoading = true;
		const toastId = toast.loading('Ukládání...');
		const result = false;
		toast.dismiss(toastId);
		if(result) {
			toast.success('Uloženo');
		}
		else toast.error('Nastala chyba!');
		this.tableLoading = false;
	}

	@effect
	async loadTable() {
		this.tableLoading = true;
		const result = await Api.fetchStructure();
		if(result !== undefined) {
			this.table = result;
			this.tableLoading = false;
		}
	}

	@effect
	clearTable() {
		this.table = [];
		this.tableLoading = true;
	}
}
