import React, {useEffect} from "react";
import {
	CopyButton,
	DetailClose,
	DetailContainer,
	DetailContent,
	DetailContentLeft,
	DetailContentRight,
	DetailHeader,
	DetailHeaderButtons,
	DetailHeaderLeft,
	DetailHeaderRight,
	DetailInfo, DetailInfoLink,
	DetailInfoRowGrid,
	DetailTitle
} from "../../../components/Detail";
import BorderButton from "../../../components/Controls/BorderButton";
import HeaderButton from "../../../components/Controls/HeaderButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faTimes} from "@fortawesome/pro-light-svg-icons";
import {useSink} from "react-redux-sink";
import DetailSink from "../storage/detail-sink";
import {
	faAddressCard,
	faCoins,
	faEdit, faProjectDiagram,
	faStickyNote, faStopwatch, faTrash
} from "@fortawesome/pro-regular-svg-icons";
import {BorderButtonLoader, DetailInfoLoader, DetailTitleLoader, HeaderButtonLoader} from "../../../components/Loaders";
import {Space12, Space36} from "../../../components/Creator";
import ThemeSink from "../../../store/theme-sink";
import DialogSink from "../../../store/dialog-sink";
import CreatorSink from "../storage/creator-sink";
import Permissions from "../../../permissions";
import Permission from "../../../components/Permissions";
import {toast} from "react-hot-toast";

const Detail: React.FC = () => {
	const detail = useSink(DetailSink);
	const creator = useSink(CreatorSink);
	const dialog = useSink(DialogSink);
	const theme = useSink(ThemeSink);

	useEffect(() => {
		if (dialog.confirm === 'confirm') {
			if (dialog.id === 'customer-delete') {
				detail.delete().then();
				dialog.clear();
			}
		}
	}, [dialog.confirm]);

	return (
		<DetailContainer show={detail.opened}>
			<DetailHeader>
				<DetailHeaderLeft>
					<DetailClose onClick={() => detail.close()}>
						<FontAwesomeIcon icon={faChevronLeft}/>
					</DetailClose>
					{!detail.loading && detail.data && <DetailTitle copyable title={detail.data?.info.name}/> || <DetailTitleLoader/>}
				</DetailHeaderLeft>
				<DetailHeaderRight>
					<Permission permissions={[Permissions.CUSTOMERS_CREATE]}>
						{!detail.loading && detail.data && <HeaderButton
							icon={faEdit}
							label={'Upravit'}
							click={() => creator.open(detail.id)}
						/> || <HeaderButtonLoader/>}
						<Space12/>
					</Permission>
					<Permission permissions={[Permissions.CUSTOMERS_DELETE]}>
						{!detail.loading && detail.data && <BorderButton
							color={theme.colors.darkGray}
							icon={faTrash}
							label={'Smazat'}
							click={() => dialog.init('customer-delete', 'Varování', 'Opravdu chcete smazat zákazníka?')}
						/> || <BorderButtonLoader/>}
					</Permission>
					<Permission permissions={[Permissions.CUSTOMERS_CREATE, Permissions.CUSTOMERS_DELETE]}>
						<Space36/>
					</Permission>
					<DetailClose onClick={() => detail.close()}>
						<FontAwesomeIcon icon={faTimes}/>
					</DetailClose>
				</DetailHeaderRight>
			</DetailHeader>
			<DetailHeaderButtons>
				{!detail.loading && detail.data &&
				<BorderButton
					color={theme.colors.orange}
					label={detail.data.statistics.projectsCount.toString()}
					icon={faProjectDiagram}
					click={() => toast('Pracuje se na tom', {icon: '😎'})}
				/> || <BorderButtonLoader/>}
				<Space12/>
				{!detail.loading && detail.data &&
				<BorderButton
					color={theme.colors.orange}
					label={detail.data.statistics.timerItemsCount.toString()}
					icon={faStopwatch}
					click={() => toast('Pracuje se na tom', {icon: '😎'})}
				/> || <BorderButtonLoader/>}
				<Space12/>
				{!detail.loading && detail.data &&
				<BorderButton
					color={theme.colors.orange}
					label={'Finance'}
					icon={faCoins}
					click={() => toast('Pracuje se na tom', {icon: '😎'})}
				/> || <BorderButtonLoader/>}
			</DetailHeaderButtons>
			<DetailContent>
				<DetailContentLeft>
					{!detail.loading && detail.data && <DetailInfo icon={faAddressCard} label={'Kontakty'}>
						<DetailInfoRowGrid>
							<div>Telefon</div>
							<div>
								<DetailInfoLink href={`tel:${detail.data.info.phoneNumber}`}>{detail.data.info.phoneNumber}</DetailInfoLink>
								<CopyButton/>
							</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Email</div>
							<div>
								<DetailInfoLink href={`mailto:${detail.data.info.emailAddress}`}>{detail.data.info.emailAddress}</DetailInfoLink>
								<CopyButton/>
							</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Adresa</div>
							<div>
								{detail.data.info.residence.streetAndNumber}, {detail.data.info.residence.cityAndPostalCode}
								<CopyButton/>
							</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>IČO</div>
							<div>
								{detail.data.info.companyIdNumber}
								<CopyButton/>
							</div>
						</DetailInfoRowGrid>
					</DetailInfo> || <DetailInfoLoader/>}
					{!detail.loading && detail.data && <DetailInfo icon={faStickyNote} label={'Poznámka'}>
						{detail.data.info.note}
					</DetailInfo> || <DetailInfoLoader/>}
				</DetailContentLeft>
				<DetailContentRight>
					{false && <DetailInfo icon={faCoins} label={'Celkové výsledky'}>
						Již brzy
					</DetailInfo> || <DetailInfoLoader/>}
					{false && <DetailInfo icon={faCoins} label={'Výsledky za rok'}>
						Již brzy
					</DetailInfo> || <DetailInfoLoader/>}
				</DetailContentRight>
			</DetailContent>
		</DetailContainer>
	);
};

export default Detail;
