import React, {ReactElement} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import Config from "../../../client-config.json";
import {faLongArrowDown, faLongArrowUp, faRepeat} from "@fortawesome/pro-regular-svg-icons";
import {Row} from "./index";
import {faPlayCircle} from "@fortawesome/pro-solid-svg-icons";
import RecorderSink from "../../pages/Timer/storage/record-sink";
import {useSink} from "react-redux-sink";
import moment from "moment";
import {buildTotalTimeFromDuration} from "../../lib/time-lib";
import ThemeSink from "../../store/theme-sink";
import {convertToPriceFormat, resolveRepeatString} from "../../lib/string-lib";
import {Space12} from "../Creator";

//#region styled
const ProjectItemContainer = styled.div<{ clickable?: boolean; }>`
	width: 100%;
	position: relative;
	cursor: ${({clickable}) => clickable ? 'pointer' : 'default'};
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	color: ${({theme}) => theme.colors.blueGray};
	display: flex;
	align-items: center;
`;
const ProjectItemColor = styled.div<{ color: string; }>`
	width: 16px;
	height: 16px;
	min-width: 16px;
	border-radius: 50%;
	margin-right: 20px;
	background-color: ${({color}) => color};
`;
const ProjectItemTitle = styled.span`
	position: relative;
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	color: ${({theme}) => theme.colors.blueGray};
`;
const ProjectItemCustomer = styled.span`
	color: ${({theme}) => theme.colors.iceBlue};
`;
const TextItemTitle = styled.span<{ color?: string; }>`
	position: relative;
	color: ${({theme, color}) => color ?? theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	transition: .25s color;
`;
const TextItemContainer = styled.div<{ clickable?: boolean; }>`
	width: fit-content;
	position: relative;
	cursor: ${({clickable}) => clickable ? 'pointer' : 'default'};
	${({theme, clickable}) => clickable ? `
	cursor: pointer;
	&:hover ${TextItemTitle} {
		color: ${theme.colors.gold};
	}
	` : ``}
`;
const ActivityItemContainer = styled.div`
	display: flex;
	align-items: center;
`;
const ActivityItemText = styled.span<{ active: boolean; }>`
	color: ${({theme, active}) => active ? theme.colors.red : theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "semibold")};
`;
const ActivityItemIndicator = styled.div<{ active: boolean; }>`
	width: ${({active}) => active ? 8 : 0}px;
	height: 8px;
	border-radius: 50%;
	background-color: ${({theme}) => theme.colors.red};
	display: inline-block;
	margin-right: ${({active}) => active ? 8 : 0}px;
`;
const UserItemContainer = styled.div`
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "semibold")};
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: .25s color;

	&:hover {
		color: ${({theme}) => theme.colors.gold};
	}
`;
const UserItemImage = styled.img<{ image?: string; }>`
	background-color: ${({theme}) => theme.colors.lightGray};
	width: 46px;
	height: 45px;
	border-radius: 50%;
	margin-right: 14px;
	object-position: center;
	object-fit: cover;
`;
const TimingItemContainer = styled.div`
	display: flex;
	align-items: center;
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	color: ${({theme}) => theme.colors.blueGray};
`;
const TimingItemIcon = styled.span`
	margin-right: 12px;
	color: ${({theme}) => theme.colors.gold};
`;
const TimingItemText = styled.span`
	color: ${({theme}) => theme.colors.iceBlue};
`;
const FinanceItemContainer = styled.div<{ color?: string; }>`
	color: ${({theme, color}) => color ?? theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
`;
const FinanceItemText = styled.span`
	color: ${({theme}) => theme.colors.iceBlue};
`;
const LinkItemContainer = styled.a`
	color: ${({theme}) => theme.colors.gold};
	transition: .25s color;
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	text-decoration: none;

	&:hover {
		color: ${({theme}) => theme.colors.orange};
	}
`;
export const InvisibleItemContainer = styled.div<{ right?: boolean; }>`
	display: none;
	width: 100%;
	justify-content: ${({right}) => right ? 'flex-end' : 'flex-start'};

	${Row}:hover & {
		display: flex;
	}
`;
const RecorderStartItemContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({theme}) => theme.colors.blueGray};
	font-size: 16px;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	background-color: ${({theme}) => theme.colors.lightGray};
	transition: .25s background-color, .25s color;
	cursor: pointer;

	&:hover {
		background-color: ${({theme}) => theme.colors.orange}0D;
		color: ${({theme}) => theme.colors.orange};
	}
`;
//#endregion

export const ProjectItem: React.FC<{
	project: string;
	color: string;
	customer?: string;
	onClick?(): void;
}> = ({
				project,
				color,
				customer = undefined,
				onClick = undefined
			}) => {
	return (
		<ProjectItemContainer onClick={onClick} clickable={onClick !== undefined}>
			<ProjectItemColor color={color}/>
			<ProjectItemTitle>
				{project}
				{customer && <ProjectItemCustomer>&nbsp;|&nbsp;{customer}</ProjectItemCustomer>}
			</ProjectItemTitle>
		</ProjectItemContainer>
	);
};

export const TextItem: React.FC<{
	content: string;
	color?: string;
	onClick?(): void;
}> = ({
				content,
				color,
				onClick = undefined
			}) => {
	return (
		<TextItemContainer onClick={onClick} clickable={onClick !== undefined}>
			<TextItemTitle color={color}>{content}</TextItemTitle>
		</TextItemContainer>
	);
};

export const ActivityItem: React.FC<{
	content: string;
	active: boolean;
}> = ({
				content,
				active
			}) => {
	return (
		<ActivityItemContainer>
			<ActivityItemIndicator active={active}/>
			<ActivityItemText active={active}>{content}</ActivityItemText>
		</ActivityItemContainer>
	);
};

export const UserItem: React.FC<{
	image?: string;
	content: string;
	onClick?(): void;
}> =
	({
		 image,
		 content,
		 onClick
	 }) => {
		return (
			<UserItemContainer onClick={onClick}>
				<UserItemImage src={`${Config.api}/Storage/Public/${image}`}/>
				{content}
			</UserItemContainer>
		);
	};

export const FinanceItem: React.FC<{
	price: number;
	subText: string;
}> =
	({
		 price,
		 subText
	 }) => {
		const theme = useSink(ThemeSink);
		return (
			<FinanceItemContainer
				color={price > 0 ? theme.colors.brightGreen : (price < 0 ? theme.colors.red : theme.colors.blueGray)}>
				{convertToPriceFormat(price)}&nbsp;Kč<FinanceItemText>&nbsp;({subText})</FinanceItemText>
			</FinanceItemContainer>
		);
	};

export const LinkItem: React.FC<{
	content: string;
	link: string;
}> = ({
				content,
				link
			}) => {
	return (
		<LinkItemContainer href={link} target={'_blank'} rel={'noopener'}>
			{content}
		</LinkItemContainer>
	);
};

export const InvisibleItem: React.FC<{ right?: boolean; }> =
	({
		 children,
		 right
	 }) => {
		return (
			<InvisibleItemContainer right={right}>
				{children}
			</InvisibleItemContainer>
		);
	};

export const RecorderStartItem: React.FC<{
	description: string,
	project?: string;
}> =
	({
		 description,
		 project
	 }) => {
		const recorder = useSink(RecorderSink);
		return (
			<RecorderStartItemContainer onClick={() => recorder.startRecording(description, project)}>
				<FontAwesomeIcon icon={faPlayCircle}/>
			</RecorderStartItemContainer>
		);
	};


const TimeDurationItemContainer = styled.div`
	color: ${({theme}) => theme.colors.iceBlue};
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	display: flex;
	align-items: center;
`;
const TimeDurationItemDuration = styled.span`
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "bold")};
	margin-left: 25px;
`;

interface TimeDurationItemProps {
	from: Date;
	to: Date;
	onClick?(): void;
}

export const TimeDurationItem: React.FC<TimeDurationItemProps> =
	({
		 from,
		 to,
		onClick
	 }) => {
		return (
			<TimeDurationItemContainer onClick={onClick}>
				<span>{moment.utc(from).local().format('D.M.YYYY H:mm:ss')} - {moment.utc(to).local().format('D.M.YYYY H:mm:ss')}</span>
				<TimeDurationItemDuration>{buildTotalTimeFromDuration(moment(to).diff(moment(from)))}</TimeDurationItemDuration>
			</TimeDurationItemContainer>
		);
	};

interface TrackedHoursItemProps {
	highlighted: number;
	total?: number;
}


const TrackedHoursItemContainer = styled.div`
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	display: flex;
	align-items: center;
`;
const TrackedHoursItemArrow = styled.span`
	color: ${({theme}) => theme.colors.gold};
	margin-right: 12px;
`;
const TrackedHoursItemTime = styled.span`
`;
const TrackedHoursItemTotal = styled.span`
	color: ${({theme}) => theme.colors.iceBlue};
`;

export const TrackedHoursItem: React.FC<TrackedHoursItemProps> =
	({
		 highlighted,
		 total
	 }) => {
		return (
			<TrackedHoursItemContainer>
				<TrackedHoursItemArrow>
					<FontAwesomeIcon icon={faLongArrowUp}/>
				</TrackedHoursItemArrow>
				<TrackedHoursItemTime>{highlighted}</TrackedHoursItemTime>
				{total && <TrackedHoursItemTotal>/{total}</TrackedHoursItemTotal>}
				<span>&nbsp;h</span>
			</TrackedHoursItemContainer>
		);
	};


const DarkLightItemContainer = styled.div`
	color: ${({theme}) => theme.colors.blueGray};
	display: flex;
	align-items: center;
`;
const DarkLightItemDark = styled.span`
	font: ${({theme}) => theme.fonts.Montserrat(16, "bold")};
`;
const DarkLightItemLight = styled.span`
	color: ${({theme}) => theme.colors.iceBlue};
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
`;

interface DarkLightItemProps {
	dark: string;
	light: string;
}

export const DarkLightItem: React.FC<DarkLightItemProps> =
	({
		 dark,
		 light
	 }) => {
		return (
			<DarkLightItemContainer>
				<DarkLightItemDark>{dark}</DarkLightItemDark>
				<DarkLightItemLight>/{light}</DarkLightItemLight>
			</DarkLightItemContainer>
		);
	};

const MaturityItemContainer = styled.div`
	display: flex;
	align-items: center;
	color: ${({theme}) => theme.colors.blueGray};
`;
const MaturityItemRepeat = styled.div`
	display: flex;
	align-items: center;
	color: ${({theme}) => theme.colors.iceBlue};
	margin-left: 12px;
`;

export const MaturityItem: React.FC<{
	expression: string;
	maturityDateTime?: Date;
	onClick?(): void;
}> = ({
				expression,
				maturityDateTime,
				onClick
			}) => {
	return (
		<>
			{maturityDateTime && <MaturityItemContainer onClick={onClick}>
				{moment(maturityDateTime).format('D.M.YYYY')}
				{expression !== "" && <MaturityItemRepeat>
					<FontAwesomeIcon icon={faRepeat}/>
					<Space12/>
					{resolveRepeatString(expression)}
				</MaturityItemRepeat>}
			</MaturityItemContainer> || <></>}
		</>
	);
};
