import React from "react";
import {useSink} from "react-redux-sink";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import styled from "styled-components";
import moment from "moment";
import {MonthType} from "@datepicker-react/hooks";


const SelectorContainer = styled.div<{ show: boolean; }>`
	width: calc(100% - 24px);
	height: calc(100% - 12px);
	position: absolute;
	top: 0;
	left: 0;
	background-color: ${({theme}) => theme.colors.white};
	z-index: 99;
	display: ${({show}) => show ? 'grid' : 'none'};
	grid-template-columns: repeat(3, 1fr);
	margin: 0 0 12px 0;
	padding: 0 12px;
	grid-auto-rows: min-content;
	overflow-y: auto;
`;
const SelectorItem = styled.div<{ active?: boolean; }>`
	width: calc(100% - 16px);
	height: min-content;
	padding: 5px 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	background-color: ${({theme, active}) => active ? `${theme.colors.orange}0D` : 'transparent'};
	color: ${({theme, active}) => active ? `${theme.colors.orange}` : theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	transition: .25s color, .25s background-color;
	cursor: pointer;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	&:hover {
		background-color: ${({theme}) => theme.colors.orange}0D;
		color: ${({theme}) => theme.colors.orange};
	}
`;

interface SelectorProps {
	goToDate(date: Date): void;

	active: MonthType[];
}

export const MonthSelector: React.FC<SelectorProps> =
	({
		 goToDate,
		 active
	 }) => {
		const picker = useSink(DateTimePickerSink);

		const getMonths = () => {
			moment.locale("cs");
			return moment.monthsShort();
		};

		return (
			<SelectorContainer show={picker.showMonthSelector}>
				{getMonths().map((o, i) => {
					return <SelectorItem
						key={i}
						active={active[0].month === i}
						onClick={() => {
							goToDate(moment(picker.dates.from).set({month: i}).toDate());
							picker.toggleMonthSelector();
						}}>{o}</SelectorItem>;
				})}
			</SelectorContainer>
		);
	};

export const YearSelector: React.FC<SelectorProps> =
	({
		 goToDate,
		 active
	 }) => {
		const picker = useSink(DateTimePickerSink);

		const INTERVAL = 50;

		const getYears = (): number[] => {
			const years = [];
			const actualYear = moment().get('year');
			for (let i = actualYear - INTERVAL; i < actualYear + INTERVAL; i++) {
				years.push(i);
			}
			return years;
		};

		return (
			<SelectorContainer show={picker.showYearSelector}>
				{getYears().map((o, i) => {
					return <SelectorItem
						key={i}
						active={active[0].year === o}
						onClick={() => {
						goToDate(moment(picker.dates.from).set({year: o}).toDate());
						picker.toggleYearSelector();
					}}>{o}</SelectorItem>;
				})}
			</SelectorContainer>
		);
	};
