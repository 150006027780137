import {sink, state, effect, SinkContainer, SinkFactory} from "react-redux-sink";
import Routes from "../routes";
import NavigationSink from "./navigation-sink";
import jwt from 'jsonwebtoken';
import {toast} from "react-hot-toast";

export interface IdentityInterface {
	id: string;
	name: string;
	email: string;
	token: string;
	permissions: string[];
}

@sink("identity", SinkFactory)
export default class IdentitySink {

	constructor(factory: SinkContainer) {
		this.navigation = factory.getSink(NavigationSink);
		const dataObject = localStorage.getItem("identity");
		const rememberObject = localStorage.getItem("remember");
		const devToolsObject = localStorage.getItem("devTools");
		this.data = dataObject !== null ? JSON.parse(dataObject) : null;
		this.remember = rememberObject !== null ? rememberObject === "true" : false;
		this.hasDevTools = devToolsObject !== null ? devToolsObject === "true" : false;
		this.validate();
	}

	navigation: NavigationSink;

	@state isValid: boolean = false;

	@state data: IdentityInterface | null = null;

	@state photoLoading: boolean = false;

	@state remember: boolean = false;

	@state hasDevTools: boolean = false;

	@effect
	toggleDevTools() {
		this.hasDevTools = !this.hasDevTools;
		localStorage.setItem("devTools", JSON.stringify(this.hasDevTools));
		if(this.hasDevTools) toast.success('DevTools aktivován');
		else toast.success('DevTools deaktivován');
	}

	private static parseToken(token: string): { exp: number; } | null {
		let decoded: string | {[key: string]: string} | null = jwt.decode(token);
		if(decoded !== null) return {
			exp: (decoded as {exp: any}).exp
		};
		return null;
	}

	@effect
	validate() {
		if(this.data === null) this.isValid = false;
		else {
			const token = IdentitySink.parseToken(this.data.token);
			if(token !== null) {
				if (token.exp === 0 || token.exp <= (Date.now() / 1000)) {
					this.isValid = false;
					this.clear();
				} else this.isValid = true;
			}
			else {
				this.isValid = false;
				this.clear();
			}
		}
	}

	@effect
	initialize(data: IdentityInterface, remember: boolean) {
		this.data = data;
		this.remember = remember;
		localStorage.setItem("identity", JSON.stringify(data));
		localStorage.setItem("remember", JSON.stringify(remember));
		localStorage.setItem("token", JSON.stringify(data.token));
		this.validate();
		this.navigation.history.push(Routes.paths.TIMER_OVERVIEW);
	}

	@effect
	clear() {
		this.data = null;
		this.remember = false;
		this.isValid = false;
		localStorage.removeItem("identity");
		localStorage.removeItem("remember");
		localStorage.removeItem("token");
		this.navigation.history.push(Routes.paths.SIGN_IN);
	}

}
