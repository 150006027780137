import Production from "./backend";
import {PermissionListItemInterface, PermissionTableItemInterface} from "../storage/permissions-sink";
import {RoleListItemInterface, RoleTableItemInterface} from "../storage/roles-sink";
import {RoleDetailInterface} from "../storage/detail-sink";

export interface FetchAllPermissionsApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: PermissionTableItemInterface[];
		pageNumber: number;
		pageSize: number;
		totalCount: number;
	};
}

export interface FetchAllRolesApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: RoleTableItemInterface[];
		pageNumber: number;
		pageSize: number;
		totalCount: number;
	};
}

export interface FetchRoleDetailApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: RoleDetailInterface;
}

export interface FetchPermissionsListApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: PermissionListItemInterface[];
	};
}

export interface FetchModulesListApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: string[];
	};
}

export interface FetchRolesListApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: RoleListItemInterface[];
	};
}

export interface StatusApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
}

export interface ApiCalls {
	fetchAllPermissions: (page: number, limit: number, search: string, users: string[], modules: string[], roles: string[]) => Promise<FetchAllPermissionsApiResult>;
	fetchPermissionsList: () => Promise<PermissionListItemInterface[]>;
	fetchModulesList: () => Promise<string[]>;
	fetchAllRoles: (page: number, limit: number, search: string, users: string[], permissions: string[]) => Promise<FetchAllRolesApiResult>;
	fetchRolesList: () => Promise<RoleListItemInterface[]>;
	fetch: (id: string) => Promise<RoleDetailInterface>;
	save: (id: string | null, name: string, permissions: string[]) => Promise<boolean>;
	delete: (id: string) => Promise<boolean>;
}

export default process.env.NODE_ENV === "production" ? Production : Production;
