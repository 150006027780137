import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCouch} from "@fortawesome/pro-light-svg-icons";
import {faStopwatch} from "@fortawesome/pro-regular-svg-icons";
import Navbar from "../../layout/Navbar";
import styled from "styled-components";
import HeaderButton, {HeaderButtonInterface} from "../Controls/HeaderButton";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import NavigationSink from "../../store/navigation-sink";
import {useSink} from "react-redux-sink";
import Permission from "../Permissions";

//#region styled
const Container = styled.header<{ hasMenu?: boolean; }>`
	padding: 27px 36px 0 36px;
	background-color: ${({theme, hasMenu}) => hasMenu ? theme.colors.lightWhite : theme.colors.white};
	width: calc(100% - 72px);
`;
const TopSection = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 36px;
`;
const Title = styled.div`
	font-size: 24px;
	color: ${({theme}) => theme.colors.iceBlue};
	display: flex;
	align-items: center;
`;
const TitleText = styled.span`
	color: ${({theme}) => theme.colors.blueGray};
	margin-left: 16px;
	font: ${({theme}) => theme.fonts.Montserrat(24, "semibold")};
`;
const Buttons = styled.div`
`;
const MenuSection = styled.div`
`;
const Item = styled.div<{ active?: boolean; }>`
	font: ${({theme}) => theme.fonts.Montserrat(16, "bold")};
	width: fit-content;
	color: ${({theme}) => theme.colors.blueGray};
	cursor: pointer;
	padding: 0 42px 8px 42px;
	border-bottom: 4px solid ${({theme, active}) => active ? theme.colors.gold : 'transparent'};
	display: inline-block;
	transition: .25s border-bottom-color;

	&:hover {
		border-bottom: 4px solid ${({theme}) => theme.colors.gold};
	}
`;

//#endregion

interface MenuItemInterface {
	label: string;
	active?: boolean;
	link?: string;

	onClick?(): void;
}

interface ListHeaderInterface {
	headerButton?: HeaderButtonInterface;

	onIconClick?(): void;

	title: string;
	icon: IconDefinition;
	menu?: MenuItemInterface[];
}

const ListHeader: React.FC<ListHeaderInterface> =
	({
		 headerButton,
		 onIconClick,
		 title,
		 icon,
		 menu
	 }) => {
		const navigation = useSink(NavigationSink);
		return (
			<Container hasMenu={menu && menu.length > 0}>
				<TopSection>
					<Title>
						<FontAwesomeIcon onClick={onIconClick} icon={icon}/>
						<TitleText>{title}</TitleText>
					</Title>
					<Buttons>
						{headerButton && <>
							{headerButton.permissions && <Permission permissions={headerButton.permissions ?? []}>
								<HeaderButton
									icon={headerButton.icon}
									label={headerButton.label}
									click={headerButton.click}
									permissions={headerButton.permissions}
								/>
							</Permission> || <HeaderButton
								icon={headerButton.icon}
								label={headerButton.label}
								click={headerButton.click}
								permissions={headerButton.permissions}
							/>}
						</>}
					</Buttons>
				</TopSection>
				{menu && menu.length > 0 && <MenuSection>
					{menu.map((o, i) => <Item key={i}
																		onClick={() => o.link ? navigation.history.push(o.link) : (o.onClick ? o.onClick() : null)}
																		active={o.active}>{o.label}</Item>)}
				</MenuSection>}
			</Container>
		);
	};

export default ListHeader;
