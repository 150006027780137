import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import {toast} from "react-hot-toast";
import UsersSink from "./users-sink";

export interface UsersDetailInterface {
	id: string;
	info: {
		name: string;
		image: string;
		email: string;
		phone: string;
		address: string;
		birthDate: Date;
		startDate: Date;
		senior?: {
			id: string;
			name: string;
		},
		hourlyWages: [
			{
				wage: number;
				start: Date;
				end: Date;
			}
		],
		positions: [
			{
				id: 0,
				name: string;
			}
		],
		roles: [
			{
				id: string;
				name: string;
			}
		],
		employment: {
			id: number;
			name: string;
		};
		hourlyLoad: string;
		availability: string;
		archived: boolean;
		asanaUrl: string;
		sharePointUrl: string;
		note: string;
		identificationNumber: string;
		bankAccount: string;
	};
	statistics: {
		projectsCount: number;
	};
}

@sink("users-detail", SinkFactory)
export default class DetailSink {

	constructor(factory: SinkContainer) {
		this.users = factory.getSink(UsersSink);
	}

	users: UsersSink;

	@state opened: boolean = false;

	@state id: string | null = null;

	@state loading: boolean = true;

	@state data: UsersDetailInterface | null = null;

	@effect
	open(id: string) {
		this.opened = true;
		this.id = id;
	}

	@effect
	async load(id: string) {
		this.loading = true;
		this.data = await Api.fetch(id);
		this.loading = false;
	}

	@trigger("users-detail/id", {formatter: (id) => id})
	async idTrigger(id: string | null) {
		if (id !== null) await this.load(id as string);
	}

	@effect
	close() {
		this.clear();
	}

	@effect
	clear() {
		this.opened = false;
		this.loading = true;
		this.id = null;
		this.data = null;
	}

	@effect
	async archive() {
		if (this.id !== null && this.data !== null) {
			this.loading = true
			const toastId = toast.loading('Probíhá ' + (this.data.info.archived ? 'aktivace...' : 'archivace...'));
			const result = await Api.archive(this.id)
			toast.dismiss(toastId);
			if(result) {
				await this.users.loadTable();
				toast.success('Uživatel ' + (this.data.info.archived ? 'aktivován' : 'archivován'));
				this.clear();
			}
			else toast.error('Nastala chyba');
			this.loading = false;
		}
	}

	@effect
	async reset() {
		if (this.id !== null) {
			this.loading = true
			const toastId = toast.loading('Probíhá reset hesla...');
			const result = await Api.resetPassword(this.id)
			toast.dismiss(toastId);
			if(result) {
				this.clear();
				await this.users.loadTable();
				toast.success('E-mail byl uživateli odeslán');
			}
			else toast.error('Nastala chyba');
			this.loading = false;
		}
	}

	@effect
	async delete() {
		if (this.id !== null) {
			this.loading = true
			const toastId = toast.loading('Probíhá mazání');
			const result = await Api.delete(this.id)
			toast.dismiss(toastId);
			if(result) {
				this.clear();
				await this.users.loadTable();
				toast.success('Uživatel smazán');
			}
			else toast.error('Nastala chyba');
			this.loading = false;
		}
	}

}
