import styled from "styled-components";
import React from "react";
import {useSink} from "react-redux-sink";
import ThemeSink from "../../store/theme-sink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faCopy
} from "@fortawesome/pro-light-svg-icons";
import {
	faCrown
} from "@fortawesome/pro-regular-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {toast} from "react-hot-toast";

//#region styled
export const DetailContainer = styled.section<{ show?: boolean; }>`
	position: fixed;
	top: 0;
	left: ${({show}) => show ? '289px' : '100%'};
	width: calc(100% - 289px);
	height: 100vh;
	background-color: ${({theme}) => theme.colors.white};
	z-index: 1;
	transition: .25s left;
	overflow-y: auto;
`;
export const DetailHeader = styled.div`
	width: calc(100% - 72px);
	background-color: ${({theme}) => theme.colors.lightWhite};
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 28px 36px;
`;
export const DetailHeaderLeft = styled.div`
	display: flex;
	align-items: center;
	max-width: 50%;
`;
export const DetailHeaderRight = styled.div`
	display: flex;
	align-items: center;
`;
export const DetailHeaderButtons = styled.div`
	width: calc(100% - 72px);
	background-color: ${({theme}) => theme.colors.lightWhite};
	display: flex;
	align-items: center;
	justify-content: left;
	padding: 0 36px 12px 36px;
`;
export const DetailClose = styled.button`
	background-color: transparent;
	border: none;
	font-size: 24px;
	color: ${({theme}) => theme.colors.iceBlue};
	cursor: pointer;
	transition: .25s color;

	&:hover {
		color: ${({theme}) => theme.colors.blueGray};
	}
`;
export const DetailContent = styled.div<{ noTop?: boolean; }>`
	padding: ${({noTop}) => noTop ? 0 : 24}px 36px 24px 36px;
	display: flex;
	justify-content: space-between;
`;
export const DetailContentLeft = styled.div`
	width: calc(50% - 18px);
`;
export const DetailContentRight = styled.div`
	width: calc(50% - 18px);
`;
const DetailTitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: left;
	margin-left: 24px;
`;
const TitleText = styled.span`
	font: ${({theme}) => theme.fonts.Montserrat(24, "semibold")};
	color: ${({theme}) => theme.colors.blueGray};
`;
const ProjectTitleColor = styled.div<{ color: string; }>`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: ${({color}) => color};
`;
const ProjectTitleText = styled.span`
	margin-left: 16px;
	font: ${({theme}) => theme.fonts.Montserrat(24, "semibold")};
	color: ${({theme}) => theme.colors.blueGray};
`;
const DetailInfoContainer = styled.div`
	border: 1px solid ${({theme}) => theme.colors.lightGray};
	border-radius: 4px;

	&:not(:last-child) {
		margin-bottom: 24px;
	}
`;
const DetailInfoTitle = styled.div`
	background-color: ${({theme}) => theme.colors.lightWhite};
	border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
	width: calc(100% - 72px);
	padding: 14px 36px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	color: ${({theme}) => theme.colors.gold};
`;
const DetailInfoTitleText = styled.span`
	font: ${({theme}) => theme.fonts.Montserrat(18, "semibold")};
	color: ${({theme}) => theme.colors.blueGray};
	margin-left: 13px;
`;
const DetailInfoContent = styled.div`
	padding: 24px 36px;
	color: ${({theme}) => theme.colors.blueGray};
`;
export const DetailInfoText = styled.div`
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	color: ${({theme}) => theme.colors.blueGray};
`;
export const DetailInfoRowGrid = styled.div`
	display: grid;
	grid-template-columns: 144px 1fr;

	&:not(:first-child) {
		margin-top: 12px;
	}

	& > div:first-child {
		font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
		color: ${({theme}) => theme.colors.iceBlue};
	}

	& > div:last-child {
		font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
		color: ${({theme}) => theme.colors.blueGray};
	}
`;
const ProjectUserHeaderContainer = styled.div`
	border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
	display: flex;
	align-items: center;
	padding-bottom: 12px;
	&:not(:first-child) {
		margin-top: 24px;
	}
`;
const ProjectUserHeaderIcon = styled.div`
	margin-right: 16px;
	color: ${({theme}) => theme.colors.gold};
`;
const ProjectUserHeaderTitle = styled.span`
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "bold")};
`;
const ProjectUserHeaderPositions = styled.span`
	color: ${({theme}) => theme.colors.darkGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
`;
export const Divider = styled.div`
	background-color: ${({theme}) => theme.colors.lightGray};
	width: 100%;
	height: 1px;
	margin-top: 24px;
	margin-bottom: 24px;
`;
export const DetailInfoLink = styled.a`
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	color: ${({theme}) => theme.colors.gold};
	text-decoration: none;
	transition: .25s color;

	&:hover {
		color: ${({theme}) => theme.colors.orange};
	}
`;
export const DetailInfoSmallGray = styled.span`
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	color: ${({theme}) => theme.colors.iceBlue};
	margin-left: 12px;
`;
export const WageItem = styled.div`
	&:not(:last-child) {
		margin-bottom: 8px;
	}
`;
export const CopyButtonContainer = styled.button`
	font-size: 14px;
	border: none;
	background-color: transparent;
	color: ${({theme}) => theme.colors.iceBlue};
	margin-left: 12px;
	transition: .25s color;
	cursor: pointer;

	&:hover {
		color: ${({theme}) => theme.colors.darkGray};
	}
`;

//#endregion

interface DetailTitleInterface {
	title: string;
	copyable?: boolean;
}

export const DetailTitle: React.FC<DetailTitleInterface> =
	({
		 title,
		 copyable
	 }) => {
		return (
			<DetailTitleContainer>
				<TitleText>{title}</TitleText>
				{copyable && <CopyButton/>}
			</DetailTitleContainer>
		);
	};

interface DetailProjectTitleProps {
	color: string;
	name: string;
}

export const DetailProjectTitle: React.FC<DetailProjectTitleProps> =
	({
		color,
		name
	 }) => {
	return (
		<DetailTitleContainer>
			<ProjectTitleColor color={color}/>
			<ProjectTitleText>{name}</ProjectTitleText>
			<CopyButton/>
		</DetailTitleContainer>
	);
};

interface DetailInfoInterface {
	label: string;
	icon: IconDefinition;
}

export const DetailInfo: React.FC<DetailInfoInterface> =
	({
		 label,
		 icon,
		 children
	 }) => {
		return (
			<DetailInfoContainer>
				<DetailInfoTitle>
					<FontAwesomeIcon icon={icon}/>
					<DetailInfoTitleText>{label}</DetailInfoTitleText>
				</DetailInfoTitle>
				<DetailInfoContent>{children}</DetailInfoContent>
			</DetailInfoContainer>
		);
	};

export const ProjectUserHeader: React.FC<{
	title: string;
	positions: string[];
	manager?: boolean;
}> = ({
				title,
				positions,
				manager
			}) => {
	return (
		<ProjectUserHeaderContainer>
			{manager && <ProjectUserHeaderIcon>
				<FontAwesomeIcon icon={faCrown}/>
			</ProjectUserHeaderIcon>}
			<ProjectUserHeaderTitle>{title}</ProjectUserHeaderTitle>
			{positions.length > 0 && <ProjectUserHeaderPositions>&nbsp;| {positions.join(", ")}</ProjectUserHeaderPositions>}
		</ProjectUserHeaderContainer>
	);
};

export const CopyButton: React.FC = () => {
	return (
		<CopyButtonContainer onClick={() => toast('Pracuje se na tom', {icon: '😎'})}>
			<FontAwesomeIcon icon={faCopy}/>
		</CopyButtonContainer>
	);
};
