import React from "react";
import styled from "styled-components";

//#region styled
const FullScreenPositionerContainer = styled.div<{ active: boolean; background?: boolean; }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: ${({theme, background}) => background ? `${theme.colors.blueGray}20` : 'transparent'};
	z-index: 36;
	display: ${({active}) => active ? 'flex' : 'none'};
	align-items: center;
	justify-content: center;
`;
const FullScreenCloseContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
`;
//#endregion

interface FullScreenPositionerInterface {
	close(): void;
	active: boolean;
	background?: boolean;
}

const FullScreenPositioner: React.FC<FullScreenPositionerInterface> =
	({
		close,
		active,
		background,
		children
	 }) => {
	return (
		<FullScreenPositionerContainer background={background} active={active}>
			<FullScreenCloseContainer onClick={close}/>
			{children}
		</FullScreenPositionerContainer>
	);
};

export default FullScreenPositioner;
