import {effect, sink, SinkContainer, SinkFactory, state} from "react-redux-sink";
import Api from "../api";
import {toast} from "react-hot-toast";
import FilterSink from "../../../store/filter-sink";

type ChartShow = "users" | "projects" | "customers";

export interface RatioItem {
	name: string;
	duration: number;
}

@sink("timer-ratio-charts", SinkFactory)
export default class RatioChartsSink {

	constructor(factory: SinkContainer){
		this.filter = factory.getSink(FilterSink);
	}

	filter: FilterSink;

	@state show: ChartShow = "users";

	@state byUsersLoading: boolean = true;

	@state byUsers: RatioItem[] = [];

	@state byProjectsLoading: boolean = true;

	@state byProjects: RatioItem[] = [];

	@state byCustomersLoading: boolean = true;

	@state byCustomers: RatioItem[] = [];

	@effect
	async loadByUsers() {
		this.byUsersLoading = true;
		const result = await Api.fetchRatioUsers(
			this.filter.from,
			this.filter.to,
			this.filter.users,
			this.filter.projects,
			this.filter.customers,
			this.filter.search
		);
		if(result !== undefined && result !== null && result.result !== null){
			this.byUsers = result.result.list;
		}
		else toast.error('Nastala chyba!');
		this.byUsersLoading = false;
	}

	@effect
	async loadByProjects() {
		this.byProjectsLoading = true;
		const result = await Api.fetchRatioProjects(
			this.filter.from,
			this.filter.to,
			this.filter.users,
			this.filter.projects,
			this.filter.customers,
			this.filter.search
		);
		if(result !== undefined && result !== null && result.result !== null){
			this.byProjects = result.result.list;
		}
		else toast.error('Nastala chyba!');
		this.byProjectsLoading = false;
	}

	@effect
	async loadByCustomers() {
		this.byCustomersLoading = true;
		const result = await Api.fetchRatioCustomers(
			this.filter.from,
			this.filter.to,
			this.filter.users,
			this.filter.projects,
			this.filter.customers,
			this.filter.search
		);
		if(result !== undefined && result !== null && result.result !== null){
			this.byCustomers = result.result.list;
		}
		else toast.error('Nastala chyba!');
		this.byCustomersLoading = false;
	}

	@effect
	changeShow(show: ChartShow) {
		this.show = show;
	}

	@effect
	clear() {
		this.show = "users";
		this.byUsersLoading = true;
		this.byUsers = [];
		this.byProjectsLoading = true;
		this.byProjects = [];
		this.byCustomersLoading = true;
		this.byCustomers = [];
	}

}
