import styled from "styled-components";

export const ProjectUserControlGrid = styled.div`
	display: grid;
	grid-template-columns: 250px 184px 1fr;
	grid-gap: 24px;
`;

export const ProjectUserTitle = styled.div`
	display: flex;
	align-items: center;
	color: ${({theme}) => theme.colors.darkGray};
	font: ${({theme}) => theme.fonts.Montserrat(14, "bold")};
	margin-bottom: 18px;
`;

export const ProjectUserManagerIcon = styled.div`
	margin-right: 18px;
	color: ${({theme}) => theme.colors.gold};
`;

export const ProjectUserPositions = styled.div`
	margin-top: 30px;
	display: flex;
	align-items: center;
`;
