import Production from "./backend";

export interface StatusApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
}

export interface UploadApiResult {
	status: number;
	id: string;
}

export interface ApiCalls {
	changePassword: (old: string, newPass: string) => Promise<boolean>;
	deletePhoto: () => Promise<boolean>;
	uploadPhoto: (file: File) => Promise<UploadApiResult>;
}

export default process.env.NODE_ENV === "production" ? Production : Production;
