import React, {useEffect} from "react";
import {
	faBalanceScale, faBoxOpen, faFlag, faMapMarker, faMapMarkerAlt,
	faPlus, faSearch, faStopwatch, faUndo, faUserFriends
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import {DarkLightItem, TextItem} from "../../components/Table/Items";
import Detail from "./components/Detail";
import Creator from "./components/Creator";
import {useSink} from "react-redux-sink";
import CreatorSink from "./storage/creator-sink";
import DetailSink from "./storage/detail-sink";
import Filters from "../../components/Filters";
import SearchInput from "../../components/Controls/SearchInput";
import {Pad24, Space12, Space36} from "../../components/Creator";
import ToggleButton from "../../components/Controls/ToggleButton";
import BorderButton from "../../components/Controls/BorderButton";
import ThemeSink from "../../store/theme-sink";
import {faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import StockSink from "./storage/stock-sink";
import {
	BorderButtonLoader,
	PaginationLoader,
	SearchInputLoader,
	TableLoader,
	ToggleLoader
} from "../../components/Loaders";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import PaginationSink from "../../store/pagination-sink";
import FilterSink from "../../store/filter-sink";
import Permissions from "../../permissions";

const StockOverview: React.FC = () => {
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const creator = useSink(CreatorSink);
	const detail = useSink(DetailSink);
	const stock = useSink(StockSink);
	const theme = useSink(ThemeSink);
	const pagination = useSink(PaginationSink);
	const filter = useSink(FilterSink);

	useEffect(() => {
		return () => {
			stock.clear();
			pagination.clear();
			filter.clear();
			multiCheckboxPicker.clear();
			creator.clear();
			detail.clear();
		};
	}, []);

	useEffect(() => {
		multiCheckboxPicker.items = stock.locationsList.map((o) => {
			return {
				label: o,
				value: o
			};
		})
	}, [stock.locationsList]);

	useEffect(() => {
		multiCheckboxPicker.items = stock.typesList.map((o) => {
			return {
				label: o,
				value: o
			};
		})
	}, [stock.typesList]);

	useEffect(() => {
		if(multiCheckboxPicker.confirmed) {
			if(multiCheckboxPicker.id === 'stock-filter-locations') filter.changeLocations(multiCheckboxPicker.selected);
			else if(multiCheckboxPicker.id === 'stock-filter-types') filter.changeTypes(multiCheckboxPicker.selected);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		stock.loadTable().then();
	}, [pagination.page, pagination.limit, filter.search, filter.locations, filter.types]);

	return (
		<>
			<Detail/>
			<Creator/>
			<ListHeader
				title={'Přehled'}
				icon={faBoxOpen}
				headerButton={{
					label: "Přidat položku",
					icon: faPlus,
					click: () => creator.open(null),
					permissions: [Permissions.STOCK_CREATE]
				}}
			/>
			<Filters
				left={[
					<>{!stock.tableLoading && <SearchInput icon={faSearch}/> || <SearchInputLoader/>}</>,
					<Space36/>,
					<>{!stock.tableLoading && <ToggleButton
						id={'stock-filter-locations'}
						selected={filter.locations.length > 0}
						active={multiCheckboxPicker.id === 'stock-filter-locations'}
						onClick={async () => {
							multiCheckboxPicker.toggle('stock-filter-locations');
							multiCheckboxPicker.setSelected(filter.locations);
							await stock.loadLocationsList();
						}}
						icon={faMapMarkerAlt}
						label={'Umístění'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!stock.tableLoading && <ToggleButton
						id={'stock-filter-types'}
						selected={filter.types.length > 0}
						active={multiCheckboxPicker.id === 'stock-filter-types'}
						onClick={async () => {
							multiCheckboxPicker.toggle('stock-filter-types');
							multiCheckboxPicker.setSelected(filter.types);
							await stock.loadTypesList();
						}}
						icon={faFolderOpen}
						label={'Typ'}
					/> || <ToggleLoader/>}</>
				]}
				right={[
					<>{!stock.tableLoading &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'} color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			{!stock.tableLoading && <Table
				header={[
					{label: "Název", sortable: true},
					{label: "Umístění", sortable: true},
					{label: "Typ", sortable: true},
					{label: "Počet", sortable: true}
				]}
				items={stock.table.map((o) => {
					return {
						children: [
							<TextItem onClick={() => detail.open(o.id)} content={o.name}/>,
							<TextItem content={o.location}/>,
							<TextItem content={o.type}/>,
							<DarkLightItem dark={o.available.toString()} light={o.total.toString()}/>
						]
					};
				})}
			/> || <TableLoader columns={4} rows={5}/>}
			{!stock.tableLoading && <Pagination/> || <PaginationLoader/>}
			<Pad24/>
		</>
	);
};

export default StockOverview;
