import {sink, state, effect} from "react-redux-sink";

export interface MultiCheckboxItemInterface {
	value: string;
	label: string;
	group?: string;
}

@sink("multi-checkbox-picker")
export default class MultiCheckboxPickerSink {

	@state id: string | null = null;

	@state items: MultiCheckboxItemInterface[] | null = null;

	@state selected: string[] = [];

	@state isSearchable: boolean = true;

	@state searchText: string = "";

	@state confirmed: boolean = false;

	@state single: boolean = false;

	@effect
	toggle(id: string, single?: boolean) {
		if (this.id === id) this.clear();
		else {
			if(single) this.single = single;
			this.id = id;
		}
	}

	@effect
	setSelected(selected: string[]) {
		if(this.single) this.selected = selected[0] ? [selected[0]] : [];
		else this.selected = selected;
	}

	@effect
	toggleCheckItem(value: string) {
		if (this.selected.includes(value)) this.selected = this.selected.filter(o => o !== value);
		else {
			if(this.single) this.selected = [value];
			else this.selected = [...this.selected, value];
		}
	}

	@effect
	confirm() {
		this.confirmed = true;
	}

	@effect
	clear() {
		this.id = null;
		this.items = null;
		this.selected = [];
		this.isSearchable = true;
		this.searchText = "";
		this.confirmed = false;
		this.single = false;
	}

}
