import {
	ApiCalls,
	AuthorizeApiResult
} from ".";
import Config from "../../../../client-config.json";

const ProdApi: ApiCalls = {
	authorize: (email, password) =>
		fetch(`${Config.api}/Auth/Token`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email,
				password
			})
		})
			.then((r) => r.json())
			.then((r: AuthorizeApiResult) => r.result),
	resetPassword: (email, password, token) =>
		fetch(`${Config.api}/Auth/ResetPassword`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email,
				token,
				password
			})
		})
			.then((r) => r.json())
			.then((r: AuthorizeApiResult) => r.result),
};
export default ProdApi;
