import { sink, state, effect, trigger } from "react-redux-sink";
import { DefaultTheme } from "styled-components";
import {
	Font,
	FontSize,
	FontWeight,
	FontStyle,
	FontFamily,
	ColorScheme,
} from "../../styled";

const Family = {
	Montserrat: "'Montserrat', sans-serif",
	Philosopher: "'Philosopher', sans-serif",
};
const Weight = {
	regular: 400,
	medium: 500,
	semibold: 600,
	bold: 700,
};
const Lines: { [K in FontSize]: number } = {
	10: 14,
	14: 18,
	16: 19,
	18: 22,
	24: 29,
	60: 67,
};
const ToFont = (
	family: FontFamily,
	size: FontSize,
	weight: FontWeight = "regular",
	style: FontStyle = "normal"
) => `${style} ${Weight[weight]} ${size}px/${Lines[size]}px ${Family[family]}`;

type ThemeTypes = "light" | "dark";
@sink("theme")
export default class ThemeSink implements DefaultTheme {

	constructor() {
		const object = localStorage.getItem("theme") as ThemeTypes | null;
		this.theme = object ?? "light";
		this.colors = ThemeSink.themes[object ?? "light"];
		if(object === null) localStorage.setItem("theme", object ?? "light");
	}

	private static themes: { [K in ThemeTypes]: ColorScheme } = {
		light: {
			blueGray: "#263238",
			darkGray: "#5C656A",
			white: "#FFFFFF",
			iceBlue: "#C2C9CD",
			gold: "#FFB200",
			pebbleBlack: "#141A1D",
			orange: "#FF6F00",
			lightGray: "#EEEEEE",
			lightWhite: "#FAFAFA",
			red: "#D7263D",
			blue: "#345FFC",
			brightGreen: "#1BB157",
		},
		dark: {
			blueGray: "#ECEFF1",
			darkGray: "#B0BEC5",
			white: "#00121C",
			iceBlue: "#37474F",
			gold: "#FFB200",
			pebbleBlack: "#ECEFF1",
			orange: "#FF6F00",
			lightGray: "#263238",
			lightWhite: "#0F1E26",
			red: "#FF6168",
			blue: "#7B8CFF",
			brightGreen: "#5FE485",
		},
	};
	@state theme: ThemeTypes;

	@state colors: ColorScheme;
	public readonly breakpoints = {
		mobile: "320px",
		tablet: "768px",
		desktop: "1200px",
		betweenDesktopFullhd: "1450px",
		fullhd: "1920px",
	};
	public readonly fonts: { [K in FontFamily]: Font } = {
		Montserrat: (size, weight, style) =>
			ToFont("Montserrat", size, weight, style),
		Philosopher: (size, weight, style) =>
			ToFont("Philosopher", size, weight, style),
	};

	@effect changeTheme(to: ThemeTypes) {
		this.theme = to;
		this.colors = ThemeSink.themes[to];
		localStorage.setItem("theme", to);
	}
}
