import Production from "./backend";
import {ProjectsListItemInterface, ProjectsTableItemInterface} from "../storage/projects-sink";
import {ProjectsDetailInterface} from "../storage/detail-sink";
import {TimelineBriefTableProps, TimelineDetailTableProps} from "../storage/timeline-tables-sink";

export interface FetchAllApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: ProjectsTableItemInterface[];
		pageNumber: number;
		pageSize: number;
		totalCount: number;
	};
}

export interface FetchDetailApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: ProjectsDetailInterface;
}

export interface StatusApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
}

export interface FetchListApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: ProjectsListItemInterface[];
	};
}
export interface FetchTimelineBriefTableApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		data: TimelineBriefTableProps[];
	};
}
export interface FetchTimelineDetailTableApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		data: TimelineDetailTableProps[];
	};
}
interface UserApiProps{
	userId: string;
	hourlyWage: number;
	positions: number[];
}

export interface ApiCalls {
	fetchAll: (active: boolean, page: number, limit: number, search: string, users: string[], customers: string[]) => Promise<FetchAllApiResult>;
	fetchList: (active?: boolean, name?: string, userId?: string) => Promise<ProjectsListItemInterface[]>;
	fetch: (id: string) => Promise<ProjectsDetailInterface>;
	save: (id: string | null, name: string, color: string, customerId: string, manager: UserApiProps, users: UserApiProps[], estimatedTime: number, start: Date, end: Date  | null, isInvoiced: boolean, billingDate: Date | null, hasEnd: boolean, note: string, asanaUrl: string, sharePointUrl: string) => Promise<boolean>;
	archive: (id: string) => Promise<boolean>;
	delete: (id: string) => Promise<boolean>;
	fetchTimelineBriefTable: (from: Date, to: Date, users: string[]) => Promise<TimelineBriefTableProps[]>;
	fetchTimelineDetailTable: (from: Date, to: Date, users: string[], projects: string[], customers: string[], description: string) => Promise<TimelineDetailTableProps[]>;
}

export default process.env.NODE_ENV === "production" ? Production : Production;
