import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import {faChevronLeft, faChevronRight} from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

//#region styled
const DateRangeControlContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid ${({theme}) => theme.colors.lightGray};
	border-radius: 4px;
`;
const DateRangeLeftButton = styled.button`
	width: 35px;
	height: 37px;
	background-color: ${({theme}) => theme.colors.white};
	border: none;
	border-right: 1px solid ${({theme}) => theme.colors.lightGray};
	color: ${({theme}) => theme.colors.darkGray};
	border-radius: 4px 0 0 4px;
	cursor: pointer;
	transition: .25s background-color;
	&:disabled {
		color: ${({theme}) => theme.colors.lightGray};
	}
	&:hover {
		background-color: ${({theme}) => theme.colors.lightWhite};
	}
`;
const DateRangeRightButton = styled.button`
	width: 35px;
	height: 37px;
	background-color: ${({theme}) => theme.colors.white};
	border: none;
	border-left: 1px solid ${({theme}) => theme.colors.lightGray};
	color: ${({theme}) => theme.colors.darkGray};
	border-radius: 0 4px 4px 0;
	cursor: pointer;
	transition: .25s background-color;
	&:disabled {
		color: ${({theme}) => theme.colors.lightGray};
	}
	&:hover {
		background-color: ${({theme}) => theme.colors.lightWhite};
	}
`;
const DateRangeText = styled.div`
	padding: 9px 31px 10px 31px;
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
	color: ${({theme}) => theme.colors.darkGray};
	cursor: pointer;
	transition: .25s background-color;
	&:hover {
		background-color: ${({theme}) => theme.colors.lightWhite};
	}
`;
//#endregion

interface DateRangeControlProps {
	id: string;
	from: Date;
	to: Date;
	rangeStart?: Date;
	rangeEnd?: Date;
	goPrev?(from: Date, to: Date): void;
	goNext?(from: Date, to: Date): void;
	onClick(): void;
}

const DateRangeControl: React.FC<DateRangeControlProps> =
	({
		id,
		from,
		to,
		rangeStart,
		rangeEnd,
		goPrev,
		goNext,
		onClick
	 }) => {
	return (
		<DateRangeControlContainer className={`toggle-button-${id}`}>
			<DateRangeLeftButton onClick={() => goPrev ? goPrev(from, to) : null}>
				<FontAwesomeIcon icon={faChevronLeft}/>
			</DateRangeLeftButton>
			<DateRangeText onClick={onClick}>{moment(from).format('DD.MM.YYYY')} - {moment(to).format('DD.MM.YYYY')}</DateRangeText>
			<DateRangeRightButton onClick={() => goNext ? goNext(from, to) : null}>
				<FontAwesomeIcon icon={faChevronRight}/>
			</DateRangeRightButton>
		</DateRangeControlContainer>
	);
};

export default DateRangeControl;
