import {effect, sink, state} from "react-redux-sink";
import Api from "../api";
import {tryParseFloat, tryParseInt} from "../../../../lib/string-lib";
import {toast} from "react-hot-toast";

interface UsersFinanceAddValidationProps {
	amount: boolean;
	user: boolean;
	project: boolean;
}

@sink("users-finance-add")
export default class UsersFinanceAddSink {

	@state description: string = "";

	@state amount: string = "";

	@state user: string | null = null;

	@state project: string | null = null;

	@state saving: boolean = false;

	@state validation: UsersFinanceAddValidationProps = {
		amount: true,
		user: true,
		project: true
	};

	@effect
	changeDescription(description: string) {
		this.description = description;
	}

	@effect
	changeAmount(amount: string) {
		this.amount = amount;
	}

	@effect
	changeUser(user: string | null) {
		this.user = user;
	}

	@effect
	changeProject(project: string | null) {
		this.project = project;
	}

	@effect
	async submit() {
		const valid = this.createValidation();
		this.validate(valid);
		if(UsersFinanceAddSink.isValid(valid)) {
			this.saving = true;
			const toastId = toast.loading('Probíhá ukládání...');
			const result = await Api.create(this.description, parseFloat(this.amount), this.user!, this.project!);
			toast.dismiss(toastId);
			if(result !== undefined && result !== null && result.result !== null) {
				toast.success('Uloženo');
				this.clear();
			}
			this.saving = false;
		}
		else toast.error('Formulář obsahuje nevalidní data');
	}

	private static isValid (obj: UsersFinanceAddValidationProps): boolean {
		return obj.amount && obj.project && obj.user;
	}

	private createValidation(): UsersFinanceAddValidationProps {
		const amount = tryParseFloat(this.amount, null);
		let v = {
			amount: true,
			user: true,
			project: true
		};
		if(amount === null) v.amount = false;
		if(this.user === null) v.user = false;
		if(this.project === null) v.project = false;
		return v
	}

	@effect
	validate(obj: UsersFinanceAddValidationProps) {
		this.validation = obj;
	}

	@effect
	clear() {
		this.description = "";
		this.amount = "";
		this.project = null;
		this.user = null;
		this.validation = {
			amount: true,
			user: true,
			project: true
		};
	}

}
