import {
	ApiCalls, FetchAllApiResult, FetchDetailApiResult, FetchListApiResult, StatusApiResult, UploadApiResult
} from ".";
import Config from "../../../../client-config.json";
import {toast} from "react-hot-toast";

const ProdApi: ApiCalls = {
	fetchAll: (page, limit, search, locations, types) =>
		fetch(`${Config.api}/Stock?pageNumber=${page}&pageSize=${limit}${search !== "" ? `&name=${encodeURIComponent(search)}` : ""}${locations.map(o => `&locations=${o}`)}${types.map(o => `&types=${o}`)}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchAllApiResult) => r),
	fetchLocationsList: () =>
		fetch(`${Config.api}/Stock/Locations`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchListApiResult) => r.result.list),
	fetchTypesList: () =>
		fetch(`${Config.api}/Stock/Types`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchListApiResult) => r.result.list),
	fetch: (id) =>
		fetch(`${Config.api}/Stock/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchDetailApiResult) => r.result),
	save: (id, name, location, type, available, total) =>
		fetch(`${Config.api}/Stock/${id !== null ? id : ''}`, {
			method: id !== null ? 'PUT' : 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify({
				name,
				location,
				type,
				available,
				total
			})
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	delete: (id) =>
		fetch(`${Config.api}/Stock/${id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	upload: async (id, file) => {
		let image = new FormData();
		image.append("file", file);
		const response = await fetch(`${Config.api}/Stock/Photo/${id}`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: image
		});

		return Promise.resolve({
			status: response.status,
			id: (await response.json()).result.id
		});
	},
	deletePhoto: async (id, photo) =>
		fetch(`${Config.api}/Stock/Photo/${id}/${photo}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess)
			.catch(() => false),
};
export default ProdApi;
