import {effect, sink, SinkContainer, SinkFactory, state} from "react-redux-sink";
import {toast} from "react-hot-toast";
import Api from "../api";
import IdentitySink from "../../../store/identity-sink";

@sink("sign-in", SinkFactory)
export default class SignInSink {

	constructor(factory: SinkContainer) {
		this.identity = factory.getSink(IdentitySink);
	}

	identity: IdentitySink;

	@state email: string = "";

	@state password: string = "";

	@state remember: boolean = false;

	@state loading: boolean = false;

	@effect
	changeEmail(email: string) {
		this.email = email;
	}

	@effect
	changePassword(password: string) {
		this.password = password;
	}

	@effect
	async submit() {
		this.loading = true;
		const toastId = toast.loading('Probíhá přihlášení...');
		const result = await Api.authorize(this.email, this.password);
		toast.dismiss(toastId);
		if(result !== undefined && result !== null) {
			this.identity.initialize(result, this.remember);
			toast.success('Přihlášení úspěšné');
			this.clear();
		}
		else toast.error('Uživatel nenalezen!');
		this.loading = false;
	}

	@effect
	clear() {
		this.loading = false;
		this.email = "";
		this.password = "";
	}

}
