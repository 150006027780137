import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

//#region styled
const Container = styled.div<{ active?: boolean; selected?: boolean; }>`
	position: relative;
	font-size: 14px;
	color: ${({
							theme,
							active,
							selected
						}) => selected ? theme.colors.orange : (active ? theme.colors.blueGray : theme.colors.darkGray)};
	display: flex;
	align-items: center;
	padding: 9px 16px;
	border-radius: 4px;
	background-color: ${({
												 theme,
												 active,
												 selected
											 }) => selected ? `${theme.colors.orange}0D` : (active ? theme.colors.lightGray : 'transparent')};
	transition: .25s background-color, .25s color;
	cursor: pointer;
	width: fit-content;

	&:hover {
		background-color: ${({theme}) => theme.colors.lightWhite};
	}
`;
const Text = styled.span`
	margin-left: 12px;
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
`;

//#endregion

interface ToggleButtonInterface {
	id?: string;
	icon: IconDefinition;
	label: string;
	active?: boolean;
	selected?: boolean;
	onClick?(): void;
}

const ToggleButton: React.FC<ToggleButtonInterface> =
	({
		 id,
		 icon,
		 label,
		 active,
		 selected,
		 onClick
	 }) => {
		return (
			<Container className={'toggle-button-' + id} selected={selected} active={active} onClick={onClick}>
				<FontAwesomeIcon icon={icon}/>
				<Text>{label}</Text>
			</Container>
		);
	};

export default ToggleButton;
