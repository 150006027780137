import React, {useRef, useContext, ReactElement} from "react";
import { useDay } from "@datepicker-react/hooks";
import DatepickerContext from "./dateTimePicker.context";
import styled, {DefaultTheme} from "styled-components";

const getDayButtonColor = (isSelected: boolean, isSelectedStartOrEnd: boolean, isToday: boolean, theme: DefaultTheme) => {
	if(isSelectedStartOrEnd) return theme.colors.white;
	if(isToday) return theme.colors.gold;
	return theme.colors.blueGray;
};

const getDayButtonBackgroundColor = (isSelected: boolean, isSelectedStartOrEnd: boolean, isToday: boolean, theme: DefaultTheme) => {
	if(isSelected || isSelectedStartOrEnd) return theme.colors.gold + "0D";
	return "transparent";
};

const DayButton = styled.button<{ isSelected: boolean, isSelectedStartOrEnd: boolean, isToday: boolean }>`
    color: ${props => getDayButtonColor(props.isSelected, props.isSelectedStartOrEnd, props.isToday, props.theme)};
    background-color: ${props => getDayButtonBackgroundColor(props.isSelected, props.isSelectedStartOrEnd, props.isToday, props.theme)};
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: ${props => props.isToday ? 700 : 500};
    border: none;
    height: 43.14px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: ${props => props.theme.colors.gold};
        display: ${props => props.isSelectedStartOrEnd ? 'block' : 'none'};
    }
`;

const DayLabel = styled.span`
	position: relative;
	z-index: 2;
`;

export interface DayInterface {
	dayLabel?: string;
	date: Date;
}

const Day: React.FC<DayInterface> =
	({
		 dayLabel,
		 date
	}): ReactElement => {
	const dayRef = useRef(null);
	const {
		focusedDate,
		isDateFocused,
		isDateSelected,
		isDateHovered,
		isDateBlocked,
		isFirstOrLastSelectedDate,
		onDateSelect,
		onDateFocus,
		onDateHover
	} = useContext(DatepickerContext);
	const {
		isSelected,
		isSelectedStartOrEnd,
		onClick,
		onKeyDown,
		onMouseEnter,
		tabIndex
	} = useDay({
		date,
		focusedDate,
		isDateFocused,
		isDateSelected,
		isDateHovered,
		isDateBlocked,
		isFirstOrLastSelectedDate,
		onDateFocus,
		onDateSelect,
		onDateHover,
		dayRef
	});
	if (!dayLabel) return <div />;
	return (
		<DayButton
			onClick={onClick}
			onKeyDown={onKeyDown}
			onMouseEnter={onMouseEnter}
			tabIndex={tabIndex}
			ref={dayRef}
			isSelected={isSelected}
			isSelectedStartOrEnd={isSelectedStartOrEnd}
			isToday={date.toDateString() === (new Date()).toDateString()}>
			<DayLabel>{dayLabel}</DayLabel>
		</DayButton>
	);
};

export default Day;
