import Production from "./backend";
import {TimerTableItemInterface} from "../storage/timer-sink";
import {ProjectsSummaryProps} from "../storage/projects-summary-sink";
import {RatioItem} from "../storage/ratio-charts-sink";
import {FulfillmentProps} from "../storage/fulfillment-working-hours-sink";

export interface FetchAllApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: TimerTableItemInterface[];
		pageNumber: number;
		pageSize: number;
		totalCount: number;
	};
}
export interface FetchTrackedHoursBarDataApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		durationsPerDays: {
			[index: string]: number;
		};
	};
}
export interface FetchFulfillmentWorkingHoursDataApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		fulfillments: FulfillmentProps[]
	};
}
export interface FetchProjectsSummaryApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: ProjectsSummaryProps[];
		pageNumber: number;
		pageSize: number;
		totalCount: number;
	};
}
export interface StatusApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
}
export interface FetchActiveRecordApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		item: {
			projectId?: string;
			description?: string;
			start: Date;
		};
	};
}
export interface FetchDurationApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		duration: number;
	};
}
export interface FetchRatioApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: RatioItem[];
	};
}

export interface ApiCalls {
	fetchAll: (page: number, limit: number, users: string[], projects: string[], customers: string[], description?: string, from?: Date, to?: Date) => Promise<FetchAllApiResult>;
	fetchTrackedHoursPerDays: (from: Date, to: Date, users: string[], projects: string[], customers: string[], description?: string) => Promise<FetchTrackedHoursBarDataApiResult>;
	fetchFulfillmentWorkingHours: (from: Date, to: Date, users: string[]) => Promise<FetchFulfillmentWorkingHoursDataApiResult>;
	fetchRatioUsers: (from: Date, to: Date, users: string[], projects: string[], customers: string[], description?: string) => Promise<FetchRatioApiResult>;
	fetchRatioProjects: (from: Date, to: Date, users: string[], projects: string[], customers: string[], description?: string) => Promise<FetchRatioApiResult>;
	fetchRatioCustomers: (from: Date, to: Date, users: string[], projects: string[], customers: string[], description?: string) => Promise<FetchRatioApiResult>;
	fetchProjectsSummary: (from: Date, to: Date, users: string[], projects: string[], customers: string[], description?: string) => Promise<FetchProjectsSummaryApiResult>;
	createRecord: (description: string, from: Date, to: Date, project?: string, user?: string) => Promise<boolean>;
	updateRecord: (id: string, description: string, from: Date, to: Date, project?: string, user?: string) => Promise<boolean>;
	startRecording: (from: Date, description?: string, project?: string) => Promise<boolean>;
	endRecording: (to: Date) => Promise<boolean>;
	updateRecording: (from?: Date, description?: string, project?: string) => Promise<boolean>;
	fetchActive: () => Promise<FetchActiveRecordApiResult>;
	deleteActive: () => Promise<boolean>;
	hasActiveRecord: () => Promise<boolean>;
	fetchDuration: (from: Date, to: Date, users: string[], projects: string[], customers: string[], description?: string) => Promise<FetchDurationApiResult>;
	deleteItems: (ids: string[]) => Promise<boolean>;
}

export default process.env.NODE_ENV === "production" ? Production : Production;
