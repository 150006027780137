import styled from "styled-components";

export const getDropZoneColor = (props: any) => {
	if (props.isDragAccept) {
		return props.theme.colors.gold;
	}
	if (props.isDragReject) {
		return props.theme.colors.red;
	}
	if (props.isDragActive) {
		return props.theme.colors.blue;
	}
	return props.theme.colors.darkGray;
};

export const UploadImagesContainer = styled.div`
	width: calc(100% - 4px);
	position: relative;
	height: 100px;
	border-width: 2px;
	border-style: dashed;
	border-color: ${(props) => getDropZoneColor(props)};
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CircleUploadImagesContainer = styled.div`
	width: 250px;
	position: relative;
	height: 250px;
	border-width: 2px;
	border-radius: 50%;
	border-style: dashed;
	border-color: ${(props) => getDropZoneColor(props)};
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const UploadInfo = styled.div`
	color: ${({theme}) => theme.colors.darkGray};
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	text-align: center;
`;

export const UploaderImage = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
`;
