import React, {useEffect} from "react";
import {
	faAlien,
	faIdCardAlt, faMapMarkerAlt,
	faPlus, faProjectDiagram, faSearch, faStopwatch, faUndo, faUserFriends, faUserTie
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import {FinanceItem, ProjectItem, TextItem, TrackedHoursItem} from "../../components/Table/Items";
import Creator from "./components/Creator";
import Detail from "./components/Detail";
import {useSink} from "react-redux-sink";
import CreatorSink from "./storage/creator-sink";
import DetailSink from "./storage/detail-sink";
import {Pad24, Space12, Space36} from "../../components/Creator";
import Filters from "../../components/Filters";
import SearchInput from "../../components/Controls/SearchInput";
import ToggleButton from "../../components/Controls/ToggleButton";
import BorderButton from "../../components/Controls/BorderButton";
import ThemeSink from "../../store/theme-sink";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import ProjectsSink, {ProjectsTableItemInterface} from "./storage/projects-sink";
import {
	BorderButtonLoader,
	PaginationLoader,
	SearchInputLoader,
	TableLoader,
	ToggleLoader
} from "../../components/Loaders";
import PaginationSink from "../../store/pagination-sink";
import FilterSink from "../../store/filter-sink";
import CustomersSink from "../Customers/storage/customers-sink";
import UsersSink from "../Users/storage/users-sink";
import NavigationSink from "../../store/navigation-sink";
import Routes from "../../routes";
import {hasAnyPermission} from "../../lib/application-lib";
import Permissions from "../../permissions";
import IdentitySink from "../../store/identity-sink";
import moment from "moment";
import {calculateRemainingProjectTime} from "../../lib/time-lib";
import Permission from "../../components/Permissions";
import {calculateHourRevenue} from "../../lib/math-lib";
import {convertToPriceFormat} from "../../lib/string-lib";

const ProjectsOverview: React.FC = () => {
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const creator = useSink(CreatorSink);
	const detail = useSink(DetailSink);
	const projects = useSink(ProjectsSink);
	const theme = useSink(ThemeSink);
	const pagination = useSink(PaginationSink);
	const filter = useSink(FilterSink);
	const customers = useSink(CustomersSink);
	const users = useSink(UsersSink);
	const navigation = useSink(NavigationSink);
	const identity = useSink(IdentitySink);

	const loadTable = () => {
		if (navigation.location.pathname.includes('uzavrene')) projects.loadTable(false).then();
		else projects.loadTable(true).then();
	}

	useEffect(() => {
		return () => {
			customers.clear();
			pagination.clear();
			filter.clear();
			multiCheckboxPicker.clear();
			creator.clear();
			detail.clear();
			projects.clear();
			users.clear();
		};
	}, []);

	useEffect(() => {
		if(!creator.opened) multiCheckboxPicker.items = users.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		});
	}, [users.list]);

	useEffect(() => {
		multiCheckboxPicker.items = customers.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [customers.list]);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed) {
			if (multiCheckboxPicker.id === 'projects-filter-users') filter.changeUsers(multiCheckboxPicker.selected);
			else if (multiCheckboxPicker.id === 'projects-filter-customers') filter.changeCustomers(multiCheckboxPicker.selected);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		loadTable();
	}, [navigation.location.pathname, pagination.page, pagination.limit, filter.search, filter.users, filter.customers]);

	const getTableHeaders = () => {
		const headers = [];
		const hasListAllPermission = hasAnyPermission([Permissions.PROJECTS_LIST_ALL], identity.data?.permissions ?? []);

		headers.push({label: "Projekt", sortable: true});
		headers.push({label: "Zákazník", sortable: true});
		headers.push({label: "Začátek", sortable: true});
		headers.push({label: "Konec", sortable: true});
		headers.push({label: "Časováno", sortable: true});
		if (hasListAllPermission) headers.push({label: "Výnosy", sortable: true});
		headers.push({label: "Uživatelé"});

		return headers;
	}

	const resolveEndColor = (hasEnd: boolean, end?: Date, billingDate?: Date) => {
		if (!hasEnd) return theme.colors.iceBlue;
		if (hasEnd && !billingDate) {
			const diff = moment(end).diff(moment());
			if (diff < 0) return theme.colors.red;
			else if (diff > 0) return theme.colors.brightGreen;
			else return theme.colors.darkGray;
		}
		const diff = moment(end).diff(moment(billingDate as Date), 'days');
		if (diff < 0) return theme.colors.red;
		else if (diff > 0) return theme.colors.brightGreen;
		else return theme.colors.darkGray;
	};

	const getTableItem = (o: ProjectsTableItemInterface) => {
		const children = [];
		const hasListAllPermission = hasAnyPermission([Permissions.PROJECTS_LIST_ALL], identity.data?.permissions ?? []);

		children.push(<ProjectItem
			onClick={() => hasAnyPermission([Permissions.PROJECTS_VIEW], identity.data?.permissions ?? []) ? detail.open(o.id) : null}
			project={o.projectName} color={o.color}/>);
		children.push(<TextItem content={o.customer.name}/>);
		children.push(<TextItem content={moment(o.start).format('D.M.YYYY')}/>);
		children.push(<TextItem
			color={resolveEndColor(o.hasEnd, o.end, o.billingDate)}
			content={(o.hasEnd && o.end) ? `${calculateRemainingProjectTime(o.hasEnd, o.end, o.billingDate) ?? '?'} dní` : 'Bez konce'}/>);
		children.push(<TrackedHoursItem highlighted={o.trackedHours} total={o.estimatedTime}/>);
		if (hasListAllPermission) children.push(<FinanceItem price={o.revenues}
																												 subText={`${convertToPriceFormat(calculateHourRevenue(o.revenues, o.trackedHours))} Kč/h`}/>);
		children.push(<TextItem content={o.users.map((o) => {
			let split = o.split(" ");
			if (split.length > 1) return split[0][0].toUpperCase() + split[split.length - 1][0].toUpperCase()
			else
				return split[0][0].toUpperCase();
		}).join(', ')}/>);

		return {children};
	};

	return (
		<>
			<Creator/>
			<Detail/>
			<ListHeader
				title={'Přehled'}
				icon={faProjectDiagram}
				headerButton={{
					label: "Přidat položku",
					icon: faPlus,
					click: () => creator.open(null),
					permissions: [Permissions.PROJECTS_CREATE]
				}}
				menu={[
					{
						label: "Aktivní",
						active: !navigation.location.pathname.includes('uzavrene'),
						link: Routes.paths.PROJECTS_ACTIVE_OVERVIEW
					},
					{
						label: "Uzavřené",
						active: navigation.location.pathname.includes('uzavrene'),
						link: Routes.paths.PROJECTS_CLOSED_OVERVIEW
					}
				]}
			/>
			<Pad24/>
			<Filters
				left={[
					<>{!projects.tableLoading && <SearchInput icon={faSearch}/> || <SearchInputLoader/>}</>,
					<Space36/>,
					<>{!projects.tableLoading && <ToggleButton
						id={'projects-filter-customers'}
						selected={filter.customers.length > 0}
						active={multiCheckboxPicker.id === 'projects-filter-customers'}
						onClick={async () => {
							multiCheckboxPicker.toggle('projects-filter-customers');
							multiCheckboxPicker.setSelected(filter.customers);
							await customers.loadList();
						}}
						icon={faUserTie}
						label={'Zákazníci'}
					/> || <ToggleLoader/>}</>,
					<Permission permissions={[Permissions.PROJECTS_LIST_ALL]}>
						<Space12/>
						{!projects.tableLoading && <ToggleButton
							id={'projects-filter-users'}
							selected={filter.users.length > 0}
							active={multiCheckboxPicker.id === 'projects-filter-users'}
							onClick={async () => {
								multiCheckboxPicker.toggle('projects-filter-users');
								multiCheckboxPicker.setSelected(filter.users);
								await users.loadList();
							}}
							icon={faUserFriends}
							label={'Uživatelé'}
						/> || <ToggleLoader/>}
					</Permission>
				]}
				right={[
					<>{!projects.tableLoading &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'}
												color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			{!projects.tableLoading && <Table
				header={getTableHeaders()}
				items={projects.table.map((o) => getTableItem(o))}
			/> ||
			<TableLoader columns={hasAnyPermission([Permissions.PROJECTS_LIST_ALL], identity.data?.permissions ?? []) ? 7 : 6}
									 rows={5}/>}
			{!projects.tableLoading && <Pagination/> || <PaginationLoader/>}
			<Pad24/>
		</>
	);
};

export default ProjectsOverview;
