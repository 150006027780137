import React from "react";
import styled from "styled-components";
import LoadingBars from "../components/LoadingBars";

import { ReactComponent as BackgroundSVG } from "../images/background.svg";

//#region styled
const LoadingComponent = styled.div`
	position: fixed;
	z-index: 20;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: ${({ theme }) => theme.colors.white};
	& > svg {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: auto;
	}
	& > div {
		position: absolute;
		bottom: 10%;
		left: calc(50% - 32px);
	}
`;
//#endregion

const Loading: React.FC = () => {
	return (
		<LoadingComponent>
			<BackgroundSVG />
			<LoadingBars size={32} />
		</LoadingComponent>
	);
};
export default Loading;
