import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faProjectDiagram} from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import {useSink} from "react-redux-sink";
import ThemeSink from "../../store/theme-sink";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

//#region styled
const Container = styled.div<{ color: string; }>`
	position: relative;
	font-size: 14px;
	color: ${({color}) => color};
	display: flex;
	align-items: center;
	padding: 9px 16px;
	border: 1px solid ${({theme}) => theme.colors.lightGray};
	border-radius: 4px;
	background-color: ${({theme}) => theme.colors.white};
	transition: .25s background-color;
	cursor: pointer;
	width: fit-content;

	&:hover {
		background-color: ${({color}) => color}0D;
	}
`;
const Text = styled.span`
	margin-left: 12px;
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
`;

//#endregion

interface BorderButtonInterface {
	color: string;
	label: string;
	icon: IconDefinition;

	click?(): void;
}

const BorderButton: React.FC<BorderButtonInterface> =
	({
		 color,
		 label,
		 icon,
		 click
	 }) => {
		return (
			<Container onClick={click} color={color}>
				<FontAwesomeIcon icon={icon}/>
				<Text>{label}</Text>
			</Container>
		);
	};

export default BorderButton;
