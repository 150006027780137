import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import PaginationSink from "../../../store/pagination-sink";
import Api from "../api";
import {toast} from "react-hot-toast";
import Routes from "../../../routes";
import NavigationSink from "../../../store/navigation-sink";
import FilterSink from "../../../store/filter-sink";

export interface PermissionTableItemInterface {
	id: string;
	name: string;
	module: string;
	activeRoles: number;
	activeUsers: number;
}

export interface PermissionListItemInterface {
	id: string;
	name: string;
	module: string;
}

@sink("permissions", SinkFactory)
export default class PermissionsSink {

	constructor(factory: SinkContainer){
		this.pagination = factory.getSink(PaginationSink);
		this.navigation = factory.getSink(NavigationSink);
		this.filter = factory.getSink(FilterSink);
		this.loadList().then();
		this.loadModules().then();
	}

	pagination: PaginationSink;

	navigation: NavigationSink;

	filter: FilterSink;

	@state tableLoading: boolean = true;

	@state table: PermissionTableItemInterface[] = [];

	@state listLoading: boolean = true;

	@state list: PermissionListItemInterface[] = [];

	@state modulesLoading: boolean = true;

	@state modules: string[] = [];

	@effect
	async loadTable() {
		this.tableLoading = true;
		const result = await Api.fetchAllPermissions(
			this.pagination.page,
			this.pagination.limit,
			this.filter.search,
			this.filter.users,
			this.filter.modules,
			this.filter.roles
		);
		if(result !== undefined && result !== null) {
			this.table = result.result.list;
			this.pagination.changeTotal(result.result.totalCount);
		}
		else toast.error('Nastala chyba!');
		this.tableLoading = false;
	}

	@effect
	async loadList() {
		this.listLoading = true;
		this.list = await Api.fetchPermissionsList();
		this.listLoading = false;
	}

	@effect
	async loadModules() {
		this.listLoading = true;
		this.modules = await Api.fetchModulesList();
		this.listLoading = false;
	}

	@effect
	clear() {
		this.tableLoading = true;
		this.table = [];
		this.listLoading = true;
		this.list = [];
		this.modulesLoading = true;
		this.modules = [];
	}
}
