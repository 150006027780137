import React, {useEffect} from "react";
import {
	faArrowToBottom,
	faPen, faPlus,
	faProjectDiagram, faStopwatch, faUndo, faUserFriends, faUserTie
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import ToggleButton from "../../components/Controls/ToggleButton";
import {
	Pad24, Pad36,
	Space12, Space36
} from "../../components/Creator";
import Filters from "../../components/Filters";
import {useSink} from "react-redux-sink";
import ThemeSink from "../../store/theme-sink";
import {ProjectItem, TextItem} from "../../components/Table/Items";
import BorderButton from "../../components/Controls/BorderButton";
import {
	BorderButtonLoader,
	ColumnGraphLoader,
	PaginationLoader,
	PieGraphLoader, RangeControlLoader,
	TableLoader, ToggleLoader
} from "../../components/Loaders";
import TrackedHoursBarChart from "./components/TrackedHoursBarChart";
import ProjectsSummarySink from "./storage/projects-summary-sink";
import TrackedHoursBarChartSink from "./storage/tracked-hours-bar-chart-sink";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import FilterSink from "../../store/filter-sink";
import {toast} from "react-hot-toast";
import Routes from "../../routes";
import NavigationSink from "../../store/navigation-sink";
import CustomersSink from "../Customers/storage/customers-sink";
import ProjectsSink from "../Projects/storage/projects-sink";
import UsersSink from "../Users/storage/users-sink";
import DateRangeControl from "../../components/Controls/DateRangeControl";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import moment from "moment";
import PaginationSink from "../../store/pagination-sink";
import {buildTotalTimeFromDuration} from "../../lib/time-lib";
import {hasAnyPermission} from "../../lib/application-lib";
import IdentitySink from "../../store/identity-sink";
import Permissions from "../../permissions";
import Permission from "../../components/Permissions";
import {anyValueGreaterThanZero} from "../../lib/math-lib";
import Switcher from "../../components/Controls/Switcher";
import RatioChartsSink from "./storage/ratio-charts-sink";
import PieChart from "./components/PieChart";
import FulfillmentWorkingHoursChart from "./components/FulfillmentWorkingHoursChart";
import FulfillmentWorkingHoursSink from "./storage/fulfillment-working-hours-sink";

const TimerReportBrief: React.FC = () => {
	const theme = useSink(ThemeSink);
	const projectsSummary = useSink(ProjectsSummarySink);
	const trackedHours = useSink(TrackedHoursBarChartSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const filter = useSink(FilterSink);
	const navigation = useSink(NavigationSink);
	const pagination = useSink(PaginationSink);
	const identity = useSink(IdentitySink);
	const users = useSink(UsersSink);
	const projects = useSink(ProjectsSink);
	const customers = useSink(CustomersSink);
	const picker = useSink(DateTimePickerSink);
	const ratio = useSink(RatioChartsSink);
	const fulfillment = useSink(FulfillmentWorkingHoursSink);

	useEffect(() => {
		return () => {
			projectsSummary.clearTable();
			trackedHours.clearTable();
			multiCheckboxPicker.clear();
			pagination.clear();
			filter.clear();
			users.clear();
			projects.clear();
			customers.clear();
			picker.clear();
			ratio.clear();
			fulfillment.clearTable();
		};
	}, []);

	useEffect(() => {
		multiCheckboxPicker.items = users.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [users.list]);

	useEffect(() => {
		multiCheckboxPicker.items = projects.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [projects.list]);

	useEffect(() => {
		multiCheckboxPicker.items = customers.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [customers.list]);

	useEffect(() => {
		fulfillment.loadTable().then();
		projectsSummary.loadTable().then();
		trackedHours.loadTable().then();
		ratio.loadByUsers().then();
		ratio.loadByProjects().then();
		ratio.loadByCustomers().then();
	}, [filter.from, filter.to, filter.users, filter.customers, filter.projects, filter.search]);

	useEffect(() => {
		projectsSummary.loadTable().then();
	}, [pagination.page, pagination.limit]);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed) {
			if (multiCheckboxPicker.id === 'timer-brief-filter-users') filter.changeUsers(multiCheckboxPicker.selected);
			else if (multiCheckboxPicker.id === 'timer-brief-filter-customers') filter.changeCustomers(multiCheckboxPicker.selected);
			else if (multiCheckboxPicker.id === 'timer-brief-filter-projects') filter.changeProjects(multiCheckboxPicker.selected);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		if (picker.confirmed) {
			if (picker.id === 'timer-brief-filter-dates') filter.changeDates(picker.dates.from, picker.dates.to);
			picker.clear();
		}
	}, [picker.confirmed]);

	const isRatioLoading = (): boolean => {
		if (ratio.show === 'users') return ratio.byUsersLoading;
		else if (ratio.show === 'projects') return ratio.byProjectsLoading;
		else if (ratio.show === 'customers') return ratio.byCustomersLoading;
		return true;
	};

	return (
		<>
			<ListHeader
				title={'Report'}
				icon={faStopwatch}
				headerButton={{
					label: "Stáhnout report",
					icon: faArrowToBottom,
					click: () => toast('Pracuje se na tom', {icon: '😎'}),
					permissions: [Permissions.TIMER_LIST_ALL, Permissions.TIMER_LIST]
				}}
				menu={[
					{
						label: "Souhrně",
						active: navigation.location.pathname.includes('souhrne'),
						link: Routes.paths.TIMER_REPORT_BRIEF
					},
					{
						label: "Podrobně",
						active: navigation.location.pathname.includes('podrobne'),
						link: Routes.paths.TIMER_REPORT_DETAIL
					}
				]}
			/>
			<Pad24/>
			<Filters
				left={[
					<>{!projectsSummary.listLoading && !trackedHours.dataLoading && <DateRangeControl
						id={'timer-brief-filter-dates'}
						from={filter.from}
						to={filter.to}
						rangeStart={moment().toDate()}
						rangeEnd={moment().toDate()}
						goPrev={(from, to) => {
							toast('Téměř hotovo', {icon: '😎'})
						}}
						goNext={(from, to) => {
							toast('Téměř hotovo', {icon: '😎'})
						}}
						onClick={() => {
							picker.toggle('timer-brief-filter-dates', {from: filter.from, to: filter.to});
						}}
					/> || <RangeControlLoader/>}</>,
					<Space36/>,
					<Permission permissions={[Permissions.TIMER_LIST_ALL]}>
						{!projectsSummary.listLoading && !trackedHours.dataLoading && <ToggleButton
							id={'timer-brief-filter-users'}
							selected={filter.users.length > 0}
							active={multiCheckboxPicker.id === 'timer-brief-filter-users'}
							onClick={async () => {
								multiCheckboxPicker.toggle('timer-brief-filter-users');
								multiCheckboxPicker.setSelected(filter.users);
								await users.loadList();
							}}
							icon={faUserFriends}
							label={'Uživatelé'}
						/> || <ToggleLoader/>}
						<Space12/>
					</Permission>,
					<>{!projectsSummary.listLoading && !trackedHours.dataLoading && <ToggleButton
						id={'timer-brief-filter-customers'}
						selected={filter.customers.length > 0}
						active={multiCheckboxPicker.id === 'timer-brief-filter-customers'}
						onClick={async () => {
							multiCheckboxPicker.toggle('timer-brief-filter-customers');
							multiCheckboxPicker.setSelected(filter.customers);
							await customers.loadList();
						}}
						icon={faUserTie}
						label={'Zákazníci'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!projectsSummary.listLoading && !trackedHours.dataLoading && <ToggleButton
						id={'timer-brief-filter-projects'}
						selected={filter.projects.length > 0}
						active={multiCheckboxPicker.id === 'timer-brief-filter-projects'}
						onClick={async () => {
							multiCheckboxPicker.toggle('timer-brief-filter-projects');
							multiCheckboxPicker.setSelected(filter.projects);
							await projects.loadList();
						}}
						icon={faProjectDiagram}
						label={'Projekty'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!projectsSummary.listLoading && !trackedHours.dataLoading && <ToggleButton
						id={'timer-brief-filter-description'}
						selected={filter.search.length > 0}
						active={multiCheckboxPicker.id === 'timer-brief-filter-description'}
						onClick={async () => {
							multiCheckboxPicker.toggle('timer-brief-filter-description');
						}}
						icon={faPen}
						label={'Popis'}
					/> || <ToggleLoader/>}</>
				]}
				right={[
					<>{!projectsSummary.listLoading && !trackedHours.dataLoading &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'}
												color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			{!trackedHours.dataLoading &&
			anyValueGreaterThanZero(trackedHours.data, "hours") && <TrackedHoursBarChart/> || <ColumnGraphLoader
				noData={!trackedHours.dataLoading}
				count={10}
			/>}
			<Pad36/>
			<Switcher items={[
				{title: 'Dle uživatele', active: ratio.show === 'users', onClick: () => ratio.changeShow('users')},
				{title: 'Dle projektu', active: ratio.show === 'projects', onClick: () => ratio.changeShow('projects')},
				{title: 'Dle zákazníka', active: ratio.show === 'customers', onClick: () => ratio.changeShow('customers')}
			]}/>
			<Pad24/>
			{!isRatioLoading() && <PieChart/> || <PieGraphLoader/>}
			<Pad36/>
			{!projectsSummary.listLoading && projectsSummary.list.length > 0 && <Table
				header={[
					{label: "Projekt"},
					{label: "Záznamů"},
					{label: "Čas"}
				]}
				items={projectsSummary.list.map(o => {
					return {
						children: [
							<>{o.projectId ? <ProjectItem project={o.projectName} color={o.color} customer={o.customerName}/> :
								<TextItem content={'Bez projektu'}/>}</>,
							<TextItem
								content={hasAnyPermission([Permissions.TIMER_LIST_ALL], identity.data?.permissions ?? []) ? `${o.recordsCount} | ${o.usersCount} uživatel` : `${o.recordsCount}`}/>,
							<TextItem content={buildTotalTimeFromDuration(o.duration)}/>
						]
					}
				})}
			/> || <TableLoader columns={3} rows={5} noData={!projectsSummary.listLoading}/>}
			{!projectsSummary.listLoading && projectsSummary.list.length > 0 && <Pagination/> || <PaginationLoader/>}
			<Pad36/>
			<Switcher items={[
				{
					title: 'Plnění úvazku', active: true, onClick: () => {
					}
				}
			]}/>
			<Pad24/>
			{!fulfillment.dataLoading && fulfillment.data.length > 0 && <FulfillmentWorkingHoursChart/> || <ColumnGraphLoader
				noData={!fulfillment.dataLoading}
				count={10}
			/>}
			<Pad24/>
		</>
	);
}

export default TimerReportBrief;
