import {
	ApiCalls, FetchAllApiResult, FetchDetailApiResult, FetchListApiResult, FetchStatesListApiResult, StatusApiResult
} from ".";
import Config from "../../../../client-config.json";

const ProdApi: ApiCalls = {
	fetchAll: (page, limit, search, locations, types, states) =>
		fetch(`${Config.api}/Property?pageNumber=${page}&pageSize=${limit}${search !== "" ? `&name=${encodeURIComponent(search)}` : ""}${locations.map(o => `&locations=${o}`)}${types.map(o => `&types=${o}`)}${states.map(o => `&states=${o}`)}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchAllApiResult) => r),
	fetchLocationsList: () =>
		fetch(`${Config.api}/Property/Locations`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchListApiResult) => r.result.list),
	fetchTypesList: () =>
		fetch(`${Config.api}/Property/Types`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchListApiResult) => r.result.list),
	fetchStatesList: () =>
		fetch(`${Config.api}/Property/States`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchStatesListApiResult) => r.result.list),
	fetch: (id) =>
		fetch(`${Config.api}/Property/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchDetailApiResult) => r.result),
	save: (id, name, location, type, age, state, price, owner) =>
		fetch(`${Config.api}/Property/${id !== null ? id : ''}`, {
			method: id !== null ? 'PUT' : 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify({
				name,
				location,
				type,
				age,
				state,
				price,
				ownerId: owner
			})
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	delete: (id: string) =>
		fetch(`${Config.api}/Property/${id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	upload: async (id: string, file: File) => {
		let image = new FormData();
		image.append("file", file);
		const response = await fetch(`${Config.api}/Property/Photo/${id}`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: image
		});

		return Promise.resolve({
			status: response.status,
			id: (await response.json()).result.id
		});
	},
	deletePhoto: async (id: string, photo: string) =>
		fetch(`${Config.api}/Property/Photo/${id}/${photo}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess)
			.catch(() => false),
};
export default ProdApi;
