import React from "react";
import {
	faHistory,
	faPlus, faSearch, faStopwatch, faUndo, faUser, faUserFriends
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import {TextItem} from "../../components/Table/Items";
import {useSink} from "react-redux-sink";
import Filters from "../../components/Filters";
import SearchInput from "../../components/Controls/SearchInput";
import {Space36} from "../../components/Creator";
import ToggleButton from "../../components/Controls/ToggleButton";
import BorderButton from "../../components/Controls/BorderButton";
import ThemeSink from "../../store/theme-sink";

const UsersRecords: React.FC = () => {
	const theme = useSink(ThemeSink);
	return (
		<>
			<ListHeader
				title={'Záznamy'}
				icon={faHistory}
			/>
			<Filters
				left={[
					<SearchInput icon={faSearch}/>,
					<Space36/>,
					<ToggleButton icon={faUser} label={'Uživatel'}/>
				]}
				right={[<BorderButton icon={faUndo} label={'Zrušit filtry'} color={theme.colors.darkGray} />]}
			/>
			<Table
				header={[
					{label: "Popis", sortable: true},
					{label: "Uživatel", sortable: true},
					{label: "Datum", sortable: true}
				]}
				items={[
					{
						children: [
							<TextItem content={'5'}/>,
							<TextItem content={'5'}/>,
							<TextItem content={'122'}/>,
						]
					}
				]}
			/>
			<Pagination/>
		</>
	);
};

export default UsersRecords;
