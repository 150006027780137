import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
	faEnvelope,
	faEye,
	faEyeSlash
} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

//#region styled
const Container = styled.div`
	position: relative;
`;
const IconContainer = styled.div`
	position: absolute;
	width: 50px;
	height: 100%;
	color: ${({theme}) => theme.colors.gold};
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Input = styled.input<{ secret?: boolean; }>`
	border: 1px solid ${({theme}) => theme.colors.iceBlue};
	border-radius: 4px;
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	color: ${({theme}) => theme.colors.blueGray};
	width: calc(100% - ${({secret}) => secret ? 50 : 15}px - 50px);
	padding: 15px ${({secret}) => secret ? 50 : 15}px 15px 50px;
	background-color: ${({theme}) => theme.colors.white};
`;
const SecretContainer = styled.div<{ show?: boolean; }>`
	display: ${({show}) => show ? 'flex' : 'none'};
	position: absolute;
	width: 50px;
	height: 100%;
	right: 0;
	top: 0;
	color: ${({theme}) => theme.colors.blueGray};
	font-size: 18px;
	align-items: center;
	justify-content: center;
`;
const FormInputLoaderContainer = styled.div`
	border-radius: 4px;
	background-color: ${({theme}) => theme.colors.lightGray};;
	width: 100%;
	height: 50px;
`;
//#endregion

const FormInput: React.FC<React.InputHTMLAttributes<HTMLInputElement> & {
	icon: IconDefinition;
	isSecret?: boolean;
}> = ({
				icon,
				value,
				onChange,
				placeholder = "",
				isSecret = false
			}) => {
	const [show, setShow] = useState<boolean>(false);
	return (
		<Container>
			<IconContainer>
				<FontAwesomeIcon icon={icon}/>
			</IconContainer>
			<Input
				value={value}
				onChange={onChange}
				secret={isSecret}
				type={isSecret ? (show ? 'text' : 'password') : 'email'}
				placeholder={placeholder}
			/>
			<SecretContainer show={isSecret}>
				{!show && <FontAwesomeIcon onClick={() => setShow(true)} icon={faEye}/>}
				{show && <FontAwesomeIcon onClick={() => setShow(false)} icon={faEyeSlash}/>}
			</SecretContainer>
		</Container>
	);
};
export default FormInput;


export const FormInputLoader: React.FC = () => {
	return (
		<FormInputLoaderContainer/>
	);
};
