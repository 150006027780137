import {
	ApiCalls, StatusApiResult
} from ".";
import Config from "../../../../client-config.json";

const ProdApi: ApiCalls = {
	changePassword: async (old, newPass) =>
		fetch(`${Config.api}/Account/Password`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify({
				oldPassword: old,
				newPassword: newPass
			})
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess)
			.catch(() => false),
	uploadPhoto: async (file) => {
		let image = new FormData();
		image.append("file", file);
		const response = await fetch(`${Config.api}/Account/Image`, {
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: image
		});

		return Promise.resolve({
			status: response.status,
			id: (await response.json()).result.id
		});
	},
	deletePhoto: async () =>
		fetch(`${Config.api}/Account/Image`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess)
			.catch(() => false),
};
export default ProdApi;
