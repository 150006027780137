import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import NavigationSink from "../store/navigation-sink";
import {useSink} from "react-redux-sink";
import {ReactComponent as TwoDoLogo} from "../images/TwoDoLogoDark.svg";
import {
	faChevronRight,
	faTimes,
	faCog,
	faSignOutAlt
} from "@fortawesome/pro-light-svg-icons";
import {
	faMoon
} from "@fortawesome/pro-solid-svg-icons";
import ThemeSink from "../store/theme-sink";
import {NavigationInterface, NavigationItemInterface} from "../navigation";
import Navigation from "../navigation";
import IdentitySink from "../store/identity-sink";
import {ReactComponent as DarkLogoSVG} from "../images/TwoDoLogoDark.svg";
import {ReactComponent as LightLogoSVG} from "../images/TwoDoLogoLight.svg";
import Config from "../../client-config.json";
import Routes from "../routes";
import {hasAnyPermission} from "../lib/application-lib";

//#region styled
const NavbarElement = styled.nav<{ visible?: boolean; expanded?: boolean; }>`
	position: fixed;
	left: ${({expanded}) => expanded ? 0 : '-289px'};
	top: 0;
	width: 288px;
	height: 100vh;
	border: none;
	border-right: 1px solid ${({theme}) => theme.colors.lightGray};
	background-color: ${({theme}) => theme.colors.lightWhite};
	transition: .25s left;
	display: ${({visible}) => visible ? 'block' : 'none'};
	z-index: 35;
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		left: 0;
	}
`;
const LogoContainer = styled.div`
	width: calc(100% - 72px);
	padding: 24px 36px;
	position: relative;
`;
const CloseContainer = styled.div`
	width: 24px;
	height: 24px;
	position: absolute;
	left: 28px;
	top: 22px;
	font-size: 24px;
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		display: none;
	}
`;
const DarkLogo = styled(DarkLogoSVG)`
	width: 138.8px;
	height: 36px;
	position: relative;
	left: 36px;
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		left: unset;
	}
`;
const LightLogo = styled(LightLogoSVG)`
	width: 138.8px;
	height: 36px;
	position: relative;
	left: 36px;
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		left: unset;
	}
`;
const MenuContainer = styled.menu<{ expanded?: boolean; }>`
	width: calc(100% - 24px);
	height: calc(100vh - 88px - ${({expanded}) => expanded ? 146 : 67}px);
	padding: 0 12px;
	margin: unset;
	overflow-y: auto;
	transition: .25s height;
`;
const MenuItem = styled.div<{ active?: boolean; }>`
	width: 100%;
	background-color: ${({theme, active}) => active ? `${theme.colors.orange}0D` : theme.colors.lightGray};
	padding: unset;
	margin: unset;
	transition: .25s background-color;

	&:not(:first-child) {
		margin-top: 12px;
	}

	${({theme, active}) => `
		${!active && `
			&:hover {
				background-color: ${theme.colors.orange}0D;
			}
		`}
	`}
`;
const ItemTitle = styled.div<{ active?: boolean; }>`
	width: calc(100% - 48px);
	padding: 12px 24px;
	margin: unset;
	color: ${({theme, active}) => active ? theme.colors.orange : theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, 'bold')};
	cursor: pointer;
	transition: .25s color;
	${({theme, active}) => `
		${!active && `
			&:hover {
				color: ${theme.colors.orange};
			}
		`}
	`}
`;
const ItemIconContainer = styled.div`
	width: 36px;
	display: inline-block;
`;
const Children = styled.div<{ active?: boolean; }>`
	max-height: ${({active}) => active ? '250px' : 0};
	height: auto;
	padding: ${({active}) => active ? '8px' : 0} 24px ${({active}) => active ? '12px' : 0} 24px;
	overflow: hidden;
	transition: .25s max-height;
`;
const MenuSubItem = styled.div<{ active?: boolean; }>`
	color: ${({theme, active}) => active ? theme.colors.orange : theme.colors.blueGray};
	transition: .25s color;
	cursor: pointer;

	&:not(:first-child) {
		margin-top: 16px;
	}

	&:hover {
		color: ${({theme}) => theme.colors.orange};
	}
`;
const SubItemTitle = styled.span`
	margin-left: 30px;
	font: ${({theme}) => theme.fonts.Montserrat(16, 'medium')};
`;
const UserSectionContainer = styled.div`
	width: calc(100% - 24px);
	position: absolute;
	left: 12px;
	bottom: 12px;
	background-color: ${({theme}) => theme.colors.lightWhite};
`;
const UserContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const UserMenuContainer = styled.div<{ expanded?: boolean; }>`
	width: 100%;
	height: auto;
	transition: .4s max-height;
	max-height: ${({expanded}) => expanded ? '250px' : 0};
	overflow: hidden;
`;
const UserMenuItem = styled.div`
	width: calc(100% - 48px);
	margin-bottom: 16px;
	padding: 0 24px;
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	transition: .25s color;
	cursor: pointer;

	& > svg {
		margin-right: 20px;
	}

	&:first-child {
		margin-top: 14px;
	}

	&:last-child {
		margin-bottom: 12px;
	}

	&:hover {
		color: ${({theme}) => theme.colors.orange};
	}
`;
const CardContainer = styled.div<{ expanded?: boolean; }>`
	padding: 12px 12px 12px 72px;
	position: relative;
	background-color: ${({theme, expanded}) => expanded ? `${theme.colors.orange}0D` : theme.colors.lightGray};
	width: 128px;
	cursor: pointer;
	transition: .25s background-color;

	&:hover {
		background-color: ${({theme}) => theme.colors.lightWhite};
	}
`;
const ImageContainer = styled.div`
	width: 52px;
	height: 52px;
	border-radius: 50%;
	background-color: ${({theme}) => theme.colors.white};
	position: absolute;
	left: 12px;
	top: -4px;
`;
const Image = styled.img`
	width: 48px;
	height: 48px;
	object-position: center;
	object-fit: cover;
	border-radius: 50%;
`;
const Status = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 2px solid ${({theme}) => theme.colors.white};
	background-color: ${({theme}) => theme.colors.brightGreen};
	position: absolute;
	bottom: 0;
	right: 0;
`;
const CardName = styled.div<{ expanded?: boolean; }>`
	font: ${({theme}) => theme.fonts.Montserrat(16, "bold")};
	transition: .25s color;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: ${({theme, expanded}) => expanded ? theme.colors.orange : theme.colors.blueGray};
`;
const ThemeContainer = styled.div`
	width: 40px;
	height: 43px;
	background-color: ${({theme}) => theme.colors.lightGray};
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: ${({theme}) => theme.colors.blueGray};
	transition: .25s background-color;

	&:hover {
		background-color: ${({theme}) => theme.colors.lightWhite};
	}
`;
//#endregion

const Navbar: React.FC = () => {
	const navigation = useSink(NavigationSink);
	const identity = useSink(IdentitySink);
	const theme = useSink(ThemeSink);
	const {navbarVisible, navbarExpanded, userbarExpanded} = useSink(NavigationSink);
	return (
		<NavbarElement expanded={navbarExpanded} visible={navbarVisible && identity.isValid}>
			<LogoContainer>
				<CloseContainer>
					<FontAwesomeIcon onClick={() => navigation.navbarExpanded = false} icon={faTimes}/>
				</CloseContainer>
				{theme.theme === "light" && <LightLogo/>}
				{theme.theme === "dark" && <DarkLogo/>}
			</LogoContainer>
			<MenuContainer expanded={userbarExpanded}>
				{Navigation.map((o, i) =>
					(identity.isValid && (o.permissions ? hasAnyPermission(o.permissions, identity.data!.permissions) : true)) ? <Item
						key={i}
						icon={o.icon}
						label={o.label}
						link={o.link}
						permissions={o.permissions}
						startsWith={o.startsWith}
						children={o.children}
					/> : ''
				)}
			</MenuContainer>
			<UserSectionContainer>
				<UserContainer>
					<CardContainer expanded={userbarExpanded} onClick={() => navigation.userbarExpanded = !userbarExpanded}>
						<ImageContainer>
							{!identity.photoLoading && <Image src={`${Config.api}/Account/Image?access_token=${identity.data?.token}`}/>
							|| <div/>}
							<Status/>
						</ImageContainer>
						<CardName expanded={userbarExpanded}>{identity.data?.name ?? "Uživatel"}</CardName>
					</CardContainer>
					<ThemeContainer onClick={() => theme.changeTheme(theme.theme === "dark" ? "light" : "dark")}>
						<FontAwesomeIcon icon={faMoon}/>
					</ThemeContainer>
				</UserContainer>
				<UserMenuContainer expanded={userbarExpanded}>
					<UserMenuItem onClick={() => navigation.history.push(Routes.paths.SETTINGS)}>
						<FontAwesomeIcon icon={faCog}/>
						Nastavení
					</UserMenuItem>
					<UserMenuItem onClick={() => identity.clear()}>
						<FontAwesomeIcon icon={faSignOutAlt}/>
						Odhlásit se
					</UserMenuItem>
				</UserMenuContainer>
			</UserSectionContainer>
		</NavbarElement>
	);
};
const Item: React.FC<NavigationInterface> =
	({
		 icon,
		 label,
		 link,
		 startsWith,
		 children
	 }) => {
		const navigation = useSink(NavigationSink);
		const identity = useSink(IdentitySink);
		const active = startsWith.includes(navigation.location.pathname);
		return (
			<MenuItem
				onClick={() => !active ? navigation.history.push(link) : null}
				active={active}
			>
				<ItemTitle active={active}>
					<ItemIconContainer>
						<FontAwesomeIcon icon={icon}/>
					</ItemIconContainer>
					<span>{label}</span>
				</ItemTitle>
				{children.length > 0 && <Children active={active}>
					{children.map((o, i) =>
						(identity.isValid && (o.permissions ? hasAnyPermission(o.permissions, identity.data!.permissions) : true)) ? <SubItem
							label={o.label}
							link={o.link}
							permissions={o.permissions}
							startsWith={o.startsWith}
						/> : ''
					)}
				</Children>}
			</MenuItem>
		);
	};
const SubItem: React.FC<NavigationItemInterface> =
	({
		 label,
		 link,
		 startsWith
	 }) => {
		const navigation = useSink(NavigationSink);
		const active = startsWith.includes(navigation.location.pathname);
		return (
			<MenuSubItem
				onClick={() => !active ? navigation.history.push(link) : null}
				active={active}
			>
				<FontAwesomeIcon fontSize={16} icon={faChevronRight}/>
				<SubItemTitle>{label}</SubItemTitle>
			</MenuSubItem>
		);
	};

export default Navbar;
