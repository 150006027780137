import styled from "styled-components";
import React from "react";
import TimerStatisticsSink from "../../pages/Timer/storage/timer-statistics-sink";
import {useSink} from "react-redux-sink";
import {buildTotalTimeFromDuration, calculateTodayYesterdayPercentage} from "../../lib/time-lib";
import {TimerStatisticsLoader} from "../Loaders";

const TimerStatisticsContainer = styled.div`
	min-width: 225px;
	padding: 12px 24px;
	border: 1px solid ${({theme}) => theme.colors.lightGray};
	border-radius: 4px;
`;
const TimerStatisticsGrid = styled.div`
	display: grid;
	grid-template-columns: 60px 100px 1fr;
	grid-gap: 6px 12px;
	align-items: center;
`;
const TimerStatisticsTodayLabel = styled.div`
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
`;
const TimerStatisticsTodayValue = styled.div`
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(18, "medium")};
`;
const TimerStatisticsYesterdayLabel = styled.div`
	color: ${({theme}) => theme.colors.iceBlue};
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
`;
const TimerStatisticsYesterdayValue = styled.div`
	color: ${({theme}) => theme.colors.iceBlue};
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
`;
const TimerStatisticsTitle = styled.div`
	color: ${({theme}) => theme.colors.darkGray};
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	margin-bottom: 8px;
`;
const TimerStatisticsDuration = styled.div`
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(18, "medium")};
`;
const TimerStatisticsTodayPercentage = styled.div<{ value: number; }>`
	color: ${({theme, value}) => value < 0 ? theme.colors.red : (value > 0 ? theme.colors.brightGreen : theme.colors.darkGray)};
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
`;


export const TimerStatistics: React.FC<{
	isTodayYesterday?: boolean;
}> =
	({
		 isTodayYesterday
	 }) => {
		const statistics = useSink(TimerStatisticsSink);

		return (
			<>
				{statistics.loading && <TimerStatisticsLoader/> || <TimerStatisticsContainer>
					{isTodayYesterday && <TimerStatisticsGrid>
						<TimerStatisticsTodayLabel>Dnes</TimerStatisticsTodayLabel>
						<TimerStatisticsTodayValue>{buildTotalTimeFromDuration(statistics.today)}</TimerStatisticsTodayValue>
						<TimerStatisticsTodayPercentage value={calculateTodayYesterdayPercentage(statistics.today, statistics.yesterday)}>{calculateTodayYesterdayPercentage(statistics.today, statistics.yesterday).toFixed(1)}%</TimerStatisticsTodayPercentage>
						<TimerStatisticsYesterdayLabel>Včera</TimerStatisticsYesterdayLabel>
						<TimerStatisticsYesterdayValue>{buildTotalTimeFromDuration(statistics.yesterday)}</TimerStatisticsYesterdayValue>
					</TimerStatisticsGrid>}
					{!isTodayYesterday && <>
						<TimerStatisticsTitle>Celkově vykázáno</TimerStatisticsTitle>
						<TimerStatisticsDuration>{buildTotalTimeFromDuration(statistics.total)}</TimerStatisticsDuration>
					</>}
				</TimerStatisticsContainer>}
			</>

		);
	};
