import {effect, sink, state} from "react-redux-sink";
import moment from "moment";
import {toast} from "react-hot-toast";
import Api from "../api";
import {tryParseFloat} from "../../../lib/string-lib";

interface ReportAdderValidationProps {
	user: boolean;
	project: boolean;
	time: boolean;
}

@sink("timer-report-adder")
export default class ReportAdderSink {

	@state description: string = "";

	@state user: string | null = null;

	@state project: string | null = null;

	@state from: Date | null = null;

	@state to: Date | null = null;

	@state saving: boolean = false;

	@state validation: ReportAdderValidationProps = {
		user: true,
		project: true,
		time: true
	};

	@effect
	changeDescription(description: string) {
		this.description = description;
	}

	@effect
	changeUser(user: string | null) {
		this.user = user;
	}

	@effect
	changeProject(project: string | null) {
		this.project = project;
	}

	@effect
	changeDates(from: Date, to: Date) {
		this.from = from;
		this.to = to;
	}

	@effect
	async submit() {
		const valid = this.createValidation();
		this.validate(valid);
		if(ReportAdderSink.isValid(valid)) {
			this.saving = true;
			const toastId = toast.loading('Probíhá ukládání...');
			const result = await Api.createRecord(this.description, this.from as Date, this.to as Date, this.project!, this.user ?? undefined);
			toast.dismiss(toastId);
			if(result) {
				toast.success('Záznam vytvořen');
				this.clear();
			}
			this.saving = false;
		}
		else toast.error('Formulář obsahuje nevalidní data');
	}

	private static isValid (obj: ReportAdderValidationProps): boolean {
		return obj.time && obj.project && obj.user;
	}

	private createValidation(): ReportAdderValidationProps {
		let v = {
			user: true,
			project: true,
			time: true
		};
		if(this.user === null) v.user = false;
		if(this.project === null) v.project = false;
		if(!this.from || !this.to || (this.from && this.to && moment(this.to).isBefore(moment(this.from)))) v.time = false;
		return v;
	}

	@effect
	validate(obj: ReportAdderValidationProps) {
		this.validation = obj;
	}

	@effect
	clear() {
		this.description = "";
		this.user = null;
		this.project = null;
		this.from = null;
		this.to = null;
		this.saving = false;
		this.validation = {
			user: true,
			project: true,
			time: true
		};
	}

}
