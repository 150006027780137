import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import PaginationSink from "../../../store/pagination-sink";
import {toast} from "react-hot-toast";
import FilterSink from "../../../store/filter-sink";

export interface CustomersTableItemInterface {
	id: string;
	name: string;
	projectsCount?: number;
	totalTrackedTime?: number;
	trackedTimeThisYear?: number;
}

export interface CustomersListItemInterface {
	id: string;
	name: string;
}

@sink("customers", SinkFactory)
export default class CustomersSink {

	constructor(factory: SinkContainer){
		this.pagination = factory.getSink(PaginationSink);
		this.filter = factory.getSink(FilterSink);
	}

	pagination: PaginationSink;

	filter: FilterSink;

	@state tableLoading: boolean = true;

	@state table: CustomersTableItemInterface[] = [];

	@state listLoading: boolean = true;

	@state list: CustomersListItemInterface[] = [];

	@effect
	async loadTable() {
		this.tableLoading = true;
		const result = await Api.fetchAll(
			this.pagination.page,
			this.pagination.limit,
			this.filter.search,
			this.filter.projects
		);
		if(result !== undefined && result !== null && result.result !== null){
			this.table = result.result.list;
			this.pagination.changeTotal(result.result.totalCount);
		}
		else toast.error('Nastala chyba!');
		this.tableLoading = false;
	}

	@effect
	async loadList() {
		this.listLoading = true;
		const result = await Api.fetchList();
		if(result !== undefined) {
			this.list = result;
			this.listLoading = false;
		}
	}

	@effect
	clearTable() {
		this.table = [];
		this.tableLoading = true;
	}

	@effect
	clearList() {
		this.list = [];
		this.listLoading = true;
	}

	@effect
	clear() {
		this.clearTable();
		this.clearList();
	}
}
