import {BarDatum} from "@nivo/bar";

export const randomNumber = (lower: number, upper: number) => {
	return Math.floor(Math.random() * (upper - lower + 1)) + lower;
};

export const anyValueGreaterThanZero = (data: BarDatum[], param: string): boolean => {
	let result = false;
	for (let i = 0; i < data.length; i++) {
		if (data[i][param] > 0) {
			result = true;
			break;
		}
	}
	return result;
};

export const calculateHourRevenue = (price: number, hours: number): number => {
	if (hours === 0) return Infinity;
	return price / hours;
};
