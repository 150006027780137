import React, {useEffect} from "react";
import {
	faPlus, faServer, faStopwatch
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import {TextItem} from "../../components/Table/Items";
import {useSink} from "react-redux-sink";
import {Pad24} from "../../components/Creator";
import Routes from "../../routes";
import NavigationSink from "../../store/navigation-sink";
import {PaginationLoader, TableLoader} from "../../components/Loaders";
import {toast} from "react-hot-toast";

const ServicesDevicesOverview: React.FC = () => {
	const navigation = useSink(NavigationSink);

	useEffect(() => {
		toast('Pracuje se na tom', {icon: '😎'})
	}, []);

	return (
		<>
			<ListHeader
				title={'Provoz'}
				icon={faServer}
				menu={[
					{label: "Aplikace", active: navigation.location.pathname.includes('aplikace'), link: Routes.paths.SERVICES_APPLICATIONS_OVERVIEW},
					{label: "Stroje", active: navigation.location.pathname.includes('stroje'), link: Routes.paths.SERVICES_DEVICES_OVERVIEW}
				]}
			/>
			<Pad24/>
			{/**<Table
				header={[
					{label: "Server", sortable: true},
					{label: "Zabráno/Rezervováno", sortable: true},
					{label: "Zbývá", sortable: true},
					{label: "Celková velikost", sortable: true},
					{label: "Zátěž hostingu", sortable: true},
				]}
				items={[
					{
						children: [
							<TextItem content={'Klíče od sprchy'}/>,
							<TextItem content={'5'}/>,
							<TextItem content={'122'}/>,
							<TextItem content={'122'}/>,
							<TextItem content={'122'}/>
						]
					}
				]}
			/>**/}
			<TableLoader columns={5} rows={5}/>
			<PaginationLoader/>
			<Pad24/>
		</>
	);
};

export default ServicesDevicesOverview;
