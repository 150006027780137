import React from "react";
import Loader from "react-loading";
import { useSink } from "react-redux-sink";
import ThemeSink from "../store/theme-sink";

const LoadingBars: React.FC<{ size?: 8 | 16 | 32 }> = ({ size = 2 }) => {
	const theme = useSink(ThemeSink);
	return (
		<Loader
			type="bars"
			color={theme.colors.orange}
			height={size}
			width={size * 2}
		/>
	);
};

export default LoadingBars;
