import styled from "styled-components";

export const DragHandle = styled.strong<{ isTop?: boolean; }>`
	width: 40px;
	height: 4px;
	background-color: ${({theme}) => theme.colors.lightGray};
	border-radius: 50px;
	position: absolute;
	${({isTop}) => isTop ? 'top' : 'bottom'}: -16px;
	left: calc(50% - 20px);
	cursor: move;
	transition: .25s background-color;
	&:hover {
		background-color: ${({theme}) => theme.colors.darkGray};
	}
`;
