import React, {useEffect} from "react";
import {
	CopyButton,
	DetailClose,
	DetailContainer,
	DetailContent,
	DetailContentLeft,
	DetailContentRight,
	DetailHeader,
	DetailHeaderButtons,
	DetailHeaderLeft,
	DetailHeaderRight,
	DetailInfo,
	DetailInfoLink,
	DetailInfoRowGrid, DetailInfoSmallGray,
	DetailInfoText,
	DetailProjectTitle,
	DetailTitle,
	ProjectUserHeader, WageItem
} from "../../../components/Detail";
import BorderButton from "../../../components/Controls/BorderButton";
import HeaderButton from "../../../components/Controls/HeaderButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faTimes} from "@fortawesome/pro-light-svg-icons";
import {useSink} from "react-redux-sink";
import DetailSink from "../storage/detail-sink";
import {
	faAlien, faBoxCheck,
	faCloud, faCoins, faCouch,
	faEdit, faInfo,
	faProjectDiagram, faStickyNote,
	faStopwatch,
	faTasks,
	faTrash, faUnlockAlt, faUser, faUserCowboy,
	faUserFriends
} from "@fortawesome/pro-regular-svg-icons";
import {BorderButtonLoader, DetailInfoLoader, DetailTitleLoader, HeaderButtonLoader} from "../../../components/Loaders";
import {Space12, Space36} from "../../../components/Creator";
import ThemeSink from "../../../store/theme-sink";
import DialogSink from "../../../store/dialog-sink";
import CreatorSink from "../storage/creator-sink";
import Permissions from "../../../permissions";
import Permission from "../../../components/Permissions";
import {toast} from "react-hot-toast";
import moment from "moment";

const Detail: React.FC = () => {
	const detail = useSink(DetailSink);
	const creator = useSink(CreatorSink);
	const theme = useSink(ThemeSink);
	const dialog = useSink(DialogSink);

	useEffect(() => {
		if (dialog.confirm === 'confirm') {
			if (dialog.id === 'user-delete') detail.delete().then();
			else if (dialog.id === 'user-archive') detail.archive().then();
			else if (dialog.id === 'user-reset') detail.reset().then();
			dialog.clear();
		}
	}, [dialog.confirm]);

	return (
		<DetailContainer show={detail.opened}>
			<DetailHeader>
				<DetailHeaderLeft>
					<DetailClose onClick={() => detail.close()}>
						<FontAwesomeIcon icon={faChevronLeft}/>
					</DetailClose>
					{!detail.loading && detail.data && <DetailTitle copyable title={detail.data?.info.name}/> || <DetailTitleLoader/>}
				</DetailHeaderLeft>
				<DetailHeaderRight>
					<Permission permissions={[Permissions.USERS_CREATE]}>
						{!detail.loading && detail.data && <HeaderButton
							icon={faEdit}
							label={'Upravit'}
							click={() => creator.open(detail.id)}
						/> || <HeaderButtonLoader/>}
					</Permission>
					<Permission permissions={[Permissions.USERS_ARCHIVE]}>
						<Space12/>
						{!detail.loading && detail.data && <BorderButton
							color={theme.colors.darkGray}
							icon={faBoxCheck}
							label={detail.data.info.archived ? 'Aktivovat' : 'Archivovat'}
							click={() => dialog.init('user-archive', 'Varování', `Opravdu chcete ${detail.data!.info.archived ? 'aktivovat' : 'archivovat'} uživatele?`)}
						/> || <BorderButtonLoader/>}
					</Permission>
					<Permission permissions={[Permissions.USERS_RESET]}>
						<Space12/>
						{!detail.loading && detail.data && <BorderButton
							color={theme.colors.darkGray}
							icon={faUnlockAlt}
							label={'Resetovat'}
							click={() => dialog.init('user-reset', 'Varování', 'Uživateli bude odeslán email s odkaz pro reset hesla. Opravdu chcete resetovat heslo uživatele?')}
						/> || <BorderButtonLoader/>}
					</Permission>
					<Permission permissions={[Permissions.USERS_DELETE]}>
						<Space12/>
						{!detail.loading && detail.data && <BorderButton
							color={theme.colors.darkGray}
							icon={faTrash}
							label={'Smazat'}
							click={() => dialog.init('user-delete', 'Varování', 'Opravdu chcete smazat uživatele?')}
						/> || <BorderButtonLoader/>}
					</Permission>
					<Permission permissions={[Permissions.USERS_CREATE, Permissions.USERS_DELETE]}>
						<Space36/>
					</Permission>
					<DetailClose onClick={() => detail.close()}>
						<FontAwesomeIcon icon={faTimes}/>
					</DetailClose>
				</DetailHeaderRight>
			</DetailHeader>
			<DetailHeaderButtons>
				{!detail.loading && detail.data &&
				<BorderButton
					color={theme.colors.orange}
					label={detail.data.statistics.projectsCount.toString()}
					icon={faProjectDiagram}
					click={() => toast('Pracuje se na tom', {icon: '😎'})}
				/> || <BorderButtonLoader/>}
				<Space12/>
				{!detail.loading && detail.data &&
				<BorderButton
					color={theme.colors.orange}
					label={'Časovač'}
					icon={faStopwatch}
					click={() => toast('Pracuje se na tom', {icon: '😎'})}
				/> || <BorderButtonLoader/>}
				{/**<Space12/>
			!detail.loading && detail.data &&
			<BorderButton
				color={theme.colors.orange}
				label={'2'}
				icon={faCouch}
			/> || <BorderButtonLoader/>**/}
				<Space12/>
				{!detail.loading && detail.data &&
				<BorderButton
					color={theme.colors.orange}
					label={'Finance'}
					icon={faCoins}
				/> || <BorderButtonLoader/>}
				<Space12/>
				{detail.data?.info.asanaUrl !== "" && <>
					{!detail.loading && detail.data &&
					<BorderButton
						color={theme.colors.gold}
						label={'Asana'}
						icon={faTasks}
					/> || <BorderButtonLoader/>}
				</>}
				<Space12/>
				{detail.data?.info.sharePointUrl !== "" && <>
					{!detail.loading && detail.data &&
					<BorderButton
						color={theme.colors.gold}
						label={'SharePoint'}
						icon={faCloud}
					/> || <BorderButtonLoader/>}
				</>}
			</DetailHeaderButtons>
			<DetailContent>
				<DetailContentLeft>
					{!detail.loading && detail.data && <DetailInfo icon={faUser} label={'Osobní informace'}>
						<DetailInfoRowGrid>
							<div>Email</div>
							<div>
								<DetailInfoLink href={`mailto:${detail.data.info.email}`}>{detail.data.info.email}</DetailInfoLink>
								<CopyButton/>
							</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Telefon</div>
							<div>
								<DetailInfoLink href={`tel:${detail.data.info.phone}`}>{detail.data.info.phone}</DetailInfoLink>
								<CopyButton/>
							</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Adresa</div>
							<div>
								{detail.data.info.address}
								<CopyButton/>
							</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Bankovní účet</div>
							<div>
								{detail.data.info.bankAccount}
								<CopyButton/>
							</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Rodné číslo</div>
							<div>
								{detail.data.info.identificationNumber}
								<CopyButton/>
							</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Narozeniny</div>
							<div>
								{moment(detail.data.info.birthDate).format('D.M.YYYY')}
								<DetailInfoSmallGray>{moment().diff(moment(detail.data.info.birthDate), 'years')} let {moment().diff(moment(detail.data.info.birthDate), 'month') - (moment().diff(moment(detail.data.info.birthDate), 'years') * 12)} měsíců</DetailInfoSmallGray>
							</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Nástup</div>
							<div>
								{moment(detail.data.info.startDate).format('D.M.YYYY')}
								<DetailInfoSmallGray>{moment().diff(moment(detail.data.info.startDate), 'years')} let {moment().diff(moment(detail.data.info.startDate), 'month') - (moment().diff(moment(detail.data.info.startDate), 'years') * 12)} měsíců</DetailInfoSmallGray>
							</div>
						</DetailInfoRowGrid>
					</DetailInfo> || <DetailInfoLoader/>}
					{!detail.loading && detail.data && <DetailInfo icon={faUserCowboy} label={'Firemní informace'}>
						<DetailInfoRowGrid>
							<div>Pozice</div>
							<div>{detail.data.info.positions.map((o, i) => <div key={i}>{o.name}</div>)}</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Poměr</div>
							<div>{detail.data.info.employment.name}</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Aktivita</div>
							<div>{detail.data.info.hourlyLoad} h/den, {detail.data.info.availability}</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Hodinovka</div>
							<div>{detail.data.info.hourlyWages.map((o, i) => <WageItem key={i}>
								{o.wage}&nbsp;Kč
								<DetailInfoSmallGray>od {moment(o.start).format('D.M.YYYY')} do {o.end ? moment(o.end).format('D.M.YYYY') : 'nyní'}</DetailInfoSmallGray>
							</WageItem>)}</div>
						</DetailInfoRowGrid>
					</DetailInfo> || <DetailInfoLoader/>}
				</DetailContentLeft>
				<DetailContentRight>
					{!detail.loading && detail.data && <DetailInfo icon={faAlien} label={'Role a práva'}>
						<DetailInfoRowGrid>
							<div>Role</div>
							<div>{detail.data.info.roles.map((o, i) => <div key={i}>{o.name}</div>)}</div>
						</DetailInfoRowGrid>
					</DetailInfo> || <DetailInfoLoader/>}
					{!detail.loading && detail.data && <DetailInfo icon={faStickyNote} label={'Poznámka'}>
						{detail.data.info.note}
					</DetailInfo> || <DetailInfoLoader/>}
				</DetailContentRight>
			</DetailContent>
		</DetailContainer>
	);
};

export default Detail;
