import React, {useEffect} from "react";
import {
	faPlus, faProjectDiagram, faSearch, faUndo, faUserTie
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import Creator from "./components/Creator";
import {useSink} from "react-redux-sink";
import CreatorSink from "./storage/creator-sink";
import DetailSink from "./storage/detail-sink";
import Detail from "./components/Detail";
import {TextItem, TrackedHoursItem} from "../../components/Table/Items";
import {Pad24, Space36} from "../../components/Creator";
import Filters from "../../components/Filters";
import SearchInput from "../../components/Controls/SearchInput";
import ToggleButton from "../../components/Controls/ToggleButton";
import BorderButton from "../../components/Controls/BorderButton";
import ThemeSink from "../../store/theme-sink";
import {
	BorderButtonLoader,
	PaginationLoader,
	SearchInputLoader,
	TableLoader,
	ToggleLoader
} from "../../components/Loaders";
import CustomersSink, {CustomersTableItemInterface} from "./storage/customers-sink";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import PaginationSink from "../../store/pagination-sink";
import FilterSink from "../../store/filter-sink";
import ProjectsSink from "../Projects/storage/projects-sink";
import {hasAnyPermission} from "../../lib/application-lib";
import Permissions from "../../permissions";
import IdentitySink from "../../store/identity-sink";

const CustomersOverview: React.FC = () => {
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const customers = useSink(CustomersSink);
	const creator = useSink(CreatorSink);
	const detail = useSink(DetailSink);
	const theme = useSink(ThemeSink);
	const projects = useSink(ProjectsSink);
	const pagination = useSink(PaginationSink);
	const filter = useSink(FilterSink);
	const identity = useSink(IdentitySink);

	useEffect(() => {
		return () => {
			customers.clear();
			pagination.clear();
			filter.clear();
			multiCheckboxPicker.clear();
			creator.clear();
			detail.clear();
			projects.clear();
		};
	}, []);

	useEffect(() => {
		multiCheckboxPicker.items = projects.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [projects.list]);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed) {
			if (multiCheckboxPicker.id === 'customers-filter-projects') filter.changeProjects(multiCheckboxPicker.selected);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		customers.loadTable().then();
	}, [pagination.page, pagination.limit, filter.search, filter.projects]);

	const getTableHeaders = () => {
		const headers = [];
		const hasListAllPermission = hasAnyPermission([Permissions.CUSTOMERS_LIST_ALL], identity.data?.permissions ?? []);
		headers.push({label: "Zákazník", sortable: true});
		if (hasListAllPermission) headers.push({label: "Projektů", sortable: true});
		if (hasListAllPermission) headers.push({label: "Časováno", sortable: true});
		if (hasListAllPermission) headers.push({label: "Časováno/rok", sortable: true});
		//if (hasListAllPermission) headers.push({label: "Výsledky", sortable: true});
		//if (hasListAllPermission) headers.push({label: "Výsledky/rok", sortable: true});
		return headers;
	};

	const getTableItem = (o: CustomersTableItemInterface) => {
		const children = [];
		const hasListAllPermission = hasAnyPermission([Permissions.CUSTOMERS_LIST_ALL], identity.data?.permissions ?? []);
		children.push(<TextItem
			onClick={() => hasAnyPermission([Permissions.CUSTOMERS_VIEW], identity.data?.permissions ?? []) ? detail.open(o.id) : null}
			content={o.name}/>);
		if (hasListAllPermission) children.push(<TextItem content={o.projectsCount!.toString()}/>);
		if (hasListAllPermission) children.push(<TextItem content={o.totalTrackedTime!.toString()}/>);
		if (hasListAllPermission) children.push(<TrackedHoursItem highlighted={o.trackedTimeThisYear!}/>);
		//if (hasListAllPermission) children.push(<FinanceItem price={198200} subText={'?% marže'}/>);
		//if (hasListAllPermission) children.push(<TextItem content={'?'}/>);
		return {children};
	};

	return (
		<>
			<Detail/>
			<Creator/>
			<ListHeader
				title={'Přehled'}
				icon={faUserTie}
				headerButton={{
					label: "Přidat zákazníka",
					icon: faPlus,
					click: () => creator.open(null),
					permissions: [Permissions.CUSTOMERS_CREATE]
				}}
			/>
			<Filters
				left={[
					<>{!customers.tableLoading && <SearchInput icon={faSearch}/> || <SearchInputLoader/>}</>,
					<Space36/>,
					<>{!customers.tableLoading && <ToggleButton
						id={'customers-filter-projects'}
						selected={filter.projects.length > 0}
						active={multiCheckboxPicker.id === 'customers-filter-projects'}
						onClick={async () => {
							multiCheckboxPicker.toggle('customers-filter-projects');
							multiCheckboxPicker.setSelected(filter.projects);
							await projects.loadList();
						}}
						icon={faProjectDiagram}
						label={'Projekty'}
					/> || <ToggleLoader/>}</>
				]}
				right={[
					<>{!customers.tableLoading &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'}
												color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			{!customers.tableLoading && <Table
				header={getTableHeaders()}
				items={customers.table.map((o) => getTableItem(o))}
			/> || <TableLoader
				columns={hasAnyPermission([Permissions.CUSTOMERS_LIST_ALL], identity.data?.permissions ?? []) ? 6 : 1}
				rows={5}/>}
			{!customers.tableLoading && <Pagination/> || <PaginationLoader/>}
			<Pad24/>
		</>
	);
};

export default CustomersOverview;
