import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import DetailSink from "./detail-sink";
import Api from "../api";
import ProjectsSink from "./projects-sink";
import moment from "moment";
import {tryParseInt} from "../../../lib/string-lib";
import {toast} from "react-hot-toast";
import {DEFINED_COLORS} from "../../../components/Pickers";
import UsersSink from "../../Users/storage/users-sink";

interface ProjectUserInterface {
	userId: string;
	hourlyWage: string;
	positions: number[];
}

interface ProjectUserValidationProps {
	id: boolean;
	wage: boolean;
}

interface ProjectItemValidationProps {
	name: boolean;
	color: boolean;
	customer: boolean;
	manager: ProjectUserValidationProps;
	users: ProjectUserValidationProps[];
	estimatedTime: boolean;
}

@sink("projects-creator", SinkFactory)
export default class CreatorSink {

	constructor(factory: SinkContainer) {
		this.projects = factory.getSink(ProjectsSink);
		this.detail = factory.getSink(DetailSink);
		this.usersList = factory.getSink(UsersSink);
	}

	usersList: UsersSink;

	projects: ProjectsSink;

	detail: DetailSink;

	@state opened: boolean = false;

	@state loading: boolean = true;

	@state id: string | null = null;

	@state name: string = "";

	@state color: string = DEFINED_COLORS[0];

	@state customerId: string | null = null;

	@state manager: ProjectUserInterface | null = null;

	@state users: ProjectUserInterface[] = [];

	@state estimatedTime: string = "";

	@state start: Date = moment().toDate();

	@state end?: Date = undefined;

	@state billingDate?: Date = undefined;

	@state isInvoiced: boolean = false;

	@state hasEnd: boolean = true;

	@state note: string = "";

	@state asanaUrl: string = "";

	@state sharePointUrl: string = "";

	@state validation: ProjectItemValidationProps = {
		name: true,
		color: true,
		customer: true,
		manager: {id: true, wage: true},
		users: [],
		estimatedTime: true
	};

	@effect
	changeName(name: string) {
		this.name = name;
	}

	@effect
	changeColor(color: string) {
		this.color = color;
	}

	@effect
	changeCustomer(customerId: string | null) {
		this.customerId = customerId;
	}

	@effect
	changeBillingDate(date?: Date) {
		this.billingDate = date;
	}

	@effect
	changeStart(date: Date) {
		this.start = date;
	}

	@effect
	changeEnd(date?: Date) {
		this.end = date;
	}

	@effect
	changeEstimatedTime(estimatedTime: string) {
		this.estimatedTime = estimatedTime;
	}

	@effect
	toggleIsInvoiced() {
		this.isInvoiced = !this.isInvoiced;
	}

	@effect
	toggleHasEnd() {
		this.hasEnd = !this.hasEnd;
	}

	@effect
	changeNote(note: string) {
		this.note = note;
	}

	@effect
	changeAsanaUrl(url: string) {
		this.asanaUrl = url;
	}

	@effect
	changeSharePointUrl(url: string) {
		this.sharePointUrl = url;
	}

	@effect
	open(id: string | null) {
		this.opened = true;
		this.id = id;
	}

	@effect
	async load(id: string) {
		this.loading = true;
		const data = await Api.fetch(id);
		if (data !== undefined) {
			this.name = data.name;
			this.color = data.color;
			this.customerId = data.customer.id;
			this.manager = {
				userId: data.manager.id,
				hourlyWage: data.manager.hourlyWage.toString(),
				positions: data.manager.positions.map(o => o.id)
			};
			this.users = data.users.map(o => {
				return {userId: o.id, hourlyWage: o.hourlyWage.toString(), positions: o.positions.map(o => o.id)};
			});
			this.estimatedTime = data.estimatedTime.toString();
			this.start = data.start;
			this.end = data.end;
			this.billingDate = data.billingDate;
			this.isInvoiced = data.billingDate !== null;
			this.hasEnd = data.hasEnd;
			this.note = data.note;
			this.asanaUrl = data.asanaUrl;
			this.sharePointUrl = data.sharePointUrl;
			this.loading = false;
		}
	}

	@trigger("projects-creator/id", {formatter: (id) => id})
	async idTrigger(id: string | null) {
		if (id !== null) await this.load(id as string);
		else this.loading = false;
	}

	@effect
	close() {
		this.clear();
	}

	@effect clear() {
		this.opened = false;
		this.loading = true;
		this.id = null;
		this.name = "";
		this.color = DEFINED_COLORS[0];
		this.customerId = null;
		this.manager = null;
		this.users = [];
		this.estimatedTime = "";
		this.start = moment().toDate();
		this.end = undefined;
		this.billingDate = undefined;
		this.isInvoiced = false;
		this.hasEnd = false;
		this.note = "";
		this.asanaUrl = "";
		this.sharePointUrl = "";
		this.validation = {
			name: true,
			color: true,
			customer: true,
			manager: {id: true, wage: true},
			users: [],
			estimatedTime: true
		};
	}

	private static usersValid(array: ProjectUserValidationProps[]): boolean {
		let result = true;
		for (let i = 0; i < array.length; i++) {
			result &&= array[i].id;
			result &&= array[i].wage;
		}
		return result;
	}

	private static isValid(obj: ProjectItemValidationProps): boolean {
		return obj.name && obj.color && obj.customer && obj.manager.id && obj.manager.wage
			&& obj.estimatedTime && CreatorSink.usersValid(obj.users);
	}

	private createValidation(): ProjectItemValidationProps {
		const estimatedTime = tryParseInt(this.estimatedTime, null);
		let v: ProjectItemValidationProps = {
			name: true,
			color: true,
			customer: true,
			manager: {id: true, wage: true},
			users: [],
			estimatedTime: true
		};
		if (this.manager === null) {
			v.manager.id = false;
			v.manager.wage = false;
		} else {
			const managerWage = tryParseInt(this.manager.hourlyWage, null);
			if (managerWage === null) v.manager.wage = false;
			if (this.manager.userId === "") v.manager.id = false;
		}
		if (this.color === "") v.color = false;
		if (this.name === "") v.name = false;
		if (this.customerId === "") v.customer = false;
		if (estimatedTime === null) v.estimatedTime = false;

		for (let i = 0; i < this.users.length; i++) {
			const val = {id: true, wage: true};
			const wage = tryParseInt(this.users[i].hourlyWage, null);
			if (wage === null) val.wage = false;
			if (this.users[i].userId === "") val.id = false;
			v.users.push(val);
		}

		return v;
	}

	@effect
	validate(obj: ProjectItemValidationProps) {
		this.validation = obj;
	}

	@effect
	async submit() {
		const valid = this.createValidation();
		this.validate(valid);
		if (CreatorSink.isValid(valid)) {
			this.loading = true;
			const toastId = toast.loading('Probíhá ukládání...');
			const result = await Api.save(
				this.id,
				this.name,
				this.color,
				this.customerId as string,
				{
					userId: this.manager!.userId,
					hourlyWage: parseInt(this.manager!.hourlyWage),
					positions: this.manager!.positions
				},
				this.users.map(o => {
					return { userId: o.userId, hourlyWage: parseInt(o.hourlyWage), positions: o.positions };
				}),
				parseInt(this.estimatedTime),
				this.start,
				this.end ?? null,
				this.isInvoiced,
				this.billingDate ?? null,
				this.hasEnd,
				this.note,
				this.asanaUrl,
				this.sharePointUrl
			);
			toast.dismiss(toastId);
			if (result) {
				toast.success('Uloženo');
				this.clear();
				this.detail.clear();
				await this.projects.loadTable();
			} else toast.error('Nastala chyba');
			this.loading = false;
		} else toast.error('Formulář obsahuje nevalidní data');
	}

	@effect
	setManager(id: string | null) {
		if (id !== null) {
			if (this.manager !== null && id === this.manager.userId) return;
			const user = this.usersList.list.filter(o => o.id === id);
			if (user.length === 1) {
				this.manager = {
					userId: id,
					hourlyWage: user[0].wage.toString(),
					positions: []
				}
			} else toast.error('Uživatel nenalezen');
		} else this.manager = null;
	}

	@effect
	addUser(id: string | null) {
		if (id !== null && !this.users.map(o => o.userId).includes(id)) {
			const user = this.usersList.list.filter(o => o.id === id);
			if (user.length === 1) {
				this.users = [
					...this.users,
					{
						userId: id,
						hourlyWage: user[0].wage.toString(),
						positions: []
					}
				];
			} else toast.error('Uživatel nenalezen');
		}
	}

	@effect
	changeManagerWage(wage: string) {
		const int = tryParseInt(wage, null);
		if (this.manager !== null && int !== null) {
			this.manager = {
				...this.manager,
				hourlyWage: int.toString()
			}
		}
	}

	@effect
	changeManagerPositions(positions: number[]) {
		if (this.manager !== null) {
			this.manager = {
				...this.manager,
				positions: positions
			}
		}
	}

	@effect
	changeUserWage(id: string, wage: string) {
		const index = this.users.findIndex(o => o.userId === id);
		const int = tryParseInt(wage, null);
		if (index > -1 && int !== null) {
			this.users = [
				...this.users.slice(0, index),
				{
					userId: id,
					hourlyWage: int.toString(),
					positions: this.users[index].positions
				},
				...this.users.slice(index + 1, this.users.length)
			];
		}
	}

	@effect
	changeUserPositions(id: string, positions: number[]) {
		const index = this.users.findIndex(o => o.userId === id);
		if (index > -1) {
			this.users = [
				...this.users.slice(0, index),
				{
					userId: id,
					hourlyWage: this.users[index].hourlyWage,
					positions: positions
				},
				...this.users.slice(index + 1, this.users.length)
			];
		}
	}

	@effect
	removeUser(id: string) {
		this.users = this.users.filter(o => o.userId !== id);
	}

}
