import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

//#region styled
const Container = styled.button`
	position: relative;
	font-size: 18px;
	background-color: ${({theme}) => theme.colors.gold};
	color: ${({theme}) => theme.colors.white};
	width: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	height: 36px;
	border-radius: 50%;
	border: none;
	transition: .25s background-color;
	&:hover {
		background-color: ${({theme}) => theme.colors.orange};
	}
`;
//#endregion

interface CircleButtonProps {
	icon: IconDefinition;
	onClick(): void;
}

const CircleButton: React.FC<CircleButtonProps> =
	({
		icon,
		onClick
	 }) => {
	return (
		<Container onClick={onClick}>
			<FontAwesomeIcon icon={icon}/>
		</Container>
	);
};

export default CircleButton;
