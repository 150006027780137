import {
	ApiCalls,
	FetchActiveRecordApiResult,
	FetchAllApiResult, FetchDurationApiResult, FetchFulfillmentWorkingHoursDataApiResult,
	FetchProjectsSummaryApiResult, FetchRatioApiResult,
	FetchTrackedHoursBarDataApiResult,
	StatusApiResult
} from ".";
import Config from "../../../../client-config.json";
import moment from "moment";

const ProdApi: ApiCalls = {
	fetchAll: (page, limit, users, projects, customers, description, from, to) =>
		fetch(`${Config.api}/Timer?pageNumber=${page}&pageSize=${limit}${from ? `&from=${from.toISOString()}` : ''}${to ? `&to=${to.toISOString()}` : ''}${users.map(o => `&users=${o}`).join('')}${projects.map(o => `&projects=${o}`).join('')}${customers.map(o => `&customers=${o}`).join('')}${description ? `&description=${description}` : ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchAllApiResult) => r),
	fetchFulfillmentWorkingHours: (from, to, users) =>
		fetch(`${Config.api}/Timer/Fulfillment?from=${from.toISOString()}&to=${to.toISOString()}${users.map(o => `&users=${o}`).join('')}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchFulfillmentWorkingHoursDataApiResult) => r),
	fetchTrackedHoursPerDays: (from, to, users, projects, customers, description) =>
		fetch(`${Config.api}/Timer/Summary/Days?from=${from.toISOString()}&to=${to.toISOString()}${users.map(o => `&users=${o}`).join('')}${projects.map(o => `&projects=${o}`).join('')}${customers.map(o => `&customers=${o}`).join('')}${description ? `&description=${description}` : ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchTrackedHoursBarDataApiResult) => r),
	fetchRatioUsers: (from, to, users, projects, customers, description) =>
		fetch(`${Config.api}/Timer/Report/Ratio/Users?from=${from.toISOString()}&to=${to.toISOString()}${users.map(o => `&users=${o}`).join('')}${projects.map(o => `&projects=${o}`).join('')}${customers.map(o => `&customers=${o}`).join('')}${description ? `&description=${description}` : ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchRatioApiResult) => r),
	fetchRatioProjects: (from, to, users, projects, customers, description) =>
		fetch(`${Config.api}/Timer/Report/Ratio/Projects?from=${from.toISOString()}&to=${to.toISOString()}${users.map(o => `&users=${o}`).join('')}${projects.map(o => `&projects=${o}`).join('')}${customers.map(o => `&customers=${o}`).join('')}${description ? `&description=${description}` : ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchRatioApiResult) => r),
	fetchRatioCustomers: (from, to, users, projects, customers, description) =>
		fetch(`${Config.api}/Timer/Report/Ratio/Customers?from=${from.toISOString()}&to=${to.toISOString()}${users.map(o => `&users=${o}`).join('')}${projects.map(o => `&projects=${o}`).join('')}${customers.map(o => `&customers=${o}`).join('')}${description ? `&description=${description}` : ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchRatioApiResult) => r),
	fetchProjectsSummary: (from, to, users, projects, customers, description) =>
		fetch(`${Config.api}/Timer/Summary/Projects?from=${from.toISOString()}&to=${to.toISOString()}${users.map(o => `&users=${o}`).join('')}${projects.map(o => `&projects=${o}`).join('')}${customers.map(o => `&customers=${o}`).join('')}${description ? `&description=${description}` : ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchProjectsSummaryApiResult) => r),
	createRecord: (description, from, to, project, user) =>
		fetch(`${Config.api}/Timer`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify({
				userId: user,
				projectId: project,
				description,
				start: from.toISOString(),
				end: to.toISOString()
			})
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	updateRecord: (id, description, from, to, project, user) =>
		fetch(`${Config.api}/Timer/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify({
				userId: user,
				projectId: project,
				description,
				start: from.toISOString(),
				end: to.toISOString()
			})
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	startRecording: (from: Date, description?: string, project?: string) =>
		fetch(`${Config.api}/Timer/Start`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify({
				projectId: project,
				description,
				start: moment.utc(from).local().toISOString(),
			})
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	endRecording: (to: Date) =>
		fetch(`${Config.api}/Timer/End`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify(moment.utc(to).local().toISOString())
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	updateRecording: (from?: Date, description?: string, project?: string) =>
		fetch(`${Config.api}/Timer/Active/Update`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify({
				projectId: project,
				description,
				start: from ? moment(from).toISOString() : null,
			})
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	fetchActive: () =>
		fetch(`${Config.api}/Timer/Active`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: FetchActiveRecordApiResult) => r),
	deleteActive: () =>
		fetch(`${Config.api}/Timer/Active`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	hasActiveRecord: () =>
		fetch(`${Config.api}/Timer/Active`, {
			method: 'HEAD',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.status === 200),
	fetchDuration: (from, to, users, projects, customers, description) =>
		fetch(`${Config.api}/Timer/Duration/Range?from=${from.toISOString()}&to=${to.toISOString()}${users.map(o => `&users=${o}`).join('')}${projects.map(o => `&projects=${o}`).join('')}${customers.map(o => `&customers=${o}`).join('')}${description ? `&description=${description}` : ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: FetchDurationApiResult) => r),
	deleteItems: (ids) =>
		fetch(`${Config.api}/Timer`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify(ids)
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
};
export default ProdApi;
