import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import NavigationSink from "../../../store/navigation-sink";
import FilterSink from "../../../store/filter-sink";
@sink("timeline-graphs", SinkFactory)
export default class TimelineGraphsSink {

	constructor(factory: SinkContainer){
		this.navigation = factory.getSink(NavigationSink);
		this.filter = factory.getSink(FilterSink);
	}

	navigation: NavigationSink;

	filter: FilterSink;

	@effect
	clear() {
	}

}
