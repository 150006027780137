import React, {useEffect, useState} from "react";
import {
	faAlien,
	faCheck,
	faPen,
	faProjectDiagram, faSearch, faStopwatch, faTrash
} from "@fortawesome/pro-regular-svg-icons";
import {
	faPlay,
	faStop
} from "@fortawesome/pro-solid-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table, {DeleteButton} from "../../components/Table";
import Pagination from "../../components/Pagination";
import SearchInput from "../../components/Controls/SearchInput";
import ToggleButton from "../../components/Controls/ToggleButton";
import CircleButton from "../../components/Controls/CircleButton";
import IconButton from "../../components/Controls/IconButton";
import {
	Pad24,
	Space36
} from "../../components/Creator";
import Filters from "../../components/Filters";
import {useSink} from "react-redux-sink";
import ThemeSink from "../../store/theme-sink";
import {InvisibleItem, ProjectItem, RecorderStartItem, TextItem, TimeDurationItem} from "../../components/Table/Items";
import {
	CircleButtonLoader, IconButtonLoader,
	PaginationLoader,
	SearchInputLoader,
	TableLoader,
	ToggleLoader
} from "../../components/Loaders";
import TimerSink, {TimerTableItemInterface} from "./storage/timer-sink";
import TableEditorSink from "../../store/table-editor-sink";
import IdentitySink from "../../store/identity-sink";
import PaginationSink from "../../store/pagination-sink";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import RecorderSink from "./storage/record-sink";
import ProjectsSink from "../Projects/storage/projects-sink";
import moment from "moment";
import {buildTotalTimeFromDuration} from "../../lib/time-lib";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import Checkbox from "../../components/Controls/Checkbox";
import DialogSink from "../../store/dialog-sink";
import {TimerStatistics} from "../../components/Statistics";
import TimerStatisticsSink from "./storage/timer-statistics-sink";
import TimerItemEditorSink from "./storage/timer-item-editor-sink";

const TimerOverview: React.FC = () => {
	const timer = useSink(TimerSink);
	const tableEditor = useSink(TableEditorSink);
	const theme = useSink(ThemeSink);
	const identity = useSink(IdentitySink);
	const pagination = useSink(PaginationSink);
	const projects = useSink(ProjectsSink);
	const recorder = useSink(RecorderSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const picker = useSink(DateTimePickerSink);
	const [actualDate, setActualDate] = useState<Date>(moment().toDate());
	const dialog = useSink(DialogSink);
	const statistics = useSink(TimerStatisticsSink);
	const editor = useSink(TimerItemEditorSink);

	useEffect(() => {
		const worker = new Worker("/workers/trackingWorker.js");
		worker.addEventListener('message', () => setActualDate(moment().toDate()));
		worker.postMessage('startTicking');
		recorder.loadActive().then();
		statistics.loadTodayYesterday().then();
		return () => {
			timer.clearTable();
			tableEditor.clear();
			pagination.clear();
			projects.clear();
			recorder.clear();
			multiCheckboxPicker.clear();
			picker.clear();
			statistics.clear();
			dialog.clear();
			worker.terminate();
		};
	}, []);

	useEffect(() => {
		multiCheckboxPicker.items = projects.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [projects.list]);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed) {
			if (multiCheckboxPicker.id === 'timer-record-project') recorder.changeProject(multiCheckboxPicker.selected[0] ?? null).then();
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		if(multiCheckboxPicker.id === 'table-editor-project') projects.loadList(true, undefined, identity.data?.id).then();
	}, [multiCheckboxPicker.id]);

	useEffect(() => {
		if (picker.confirmed) {
			if (picker.id === 'timer-record-time') recorder.changeTimes(picker.dates.from, recorder.active ? null : picker.dates.to).then();
			picker.clear();
		}
	}, [picker.confirmed]);

	useEffect(() => {
		if (dialog.confirm === 'confirm') {
			if (dialog.id === 'timer-record-delete') recorder.deleteActive().then();
			else if (dialog.id === 'timer-table-delete') timer.deleteSelected().then();
			dialog.clear();
		}
	}, [dialog.confirm]);

	useEffect(() => {
		timer.loadTable(true, identity.data?.id).then();
	}, [pagination.page, pagination.limit]);

	const showEditor = (o: TimerTableItemInterface) => {
		tableEditor.show('row-' + o.id, 'timer-item-editor');
		editor.initialize(o.id, o.description, o.projectId ?? null, identity.data?.id ?? null, moment.utc(o.start).toDate(), moment.utc(o.end).toDate());
	};

	return (
		<>
			<ListHeader
				title={'Přehled'}
				icon={faStopwatch}
			/>
			<Filters
				left={[
					<>{!recorder.loading && <SearchInput
						icon={faPen}
						iconColor={theme.colors.gold}
						value={recorder.description}
						onChange={({currentTarget}) => recorder.changeDescription(currentTarget.value)}
						onKeyPress={async (e) => {
							if (e.key === "Enter") await recorder.confirmDescription()
						}}
						onBlur={() => recorder.confirmDescription()}
					/> || <SearchInputLoader/>}</>,
					<Space36/>,
					<>{!recorder.loading && <ToggleButton
						id={'timer-record-project'}
						active={multiCheckboxPicker.id === 'timer-record-project'}
						selected={recorder.project !== null}
						onClick={async () => {
							multiCheckboxPicker.toggle('timer-record-project', true);
							multiCheckboxPicker.setSelected(recorder.project ? [recorder.project] : []);
							await projects.loadList(true, undefined, identity.data?.id);
						}}
						icon={faProjectDiagram}
						label={'Projekt'}
					/> || <ToggleLoader/>}</>
				]}
				right={[
					<>{!recorder.loading && <ToggleButton
						id={'timer-record-time'}
						active={picker.id === 'timer-record-time'}
						selected={(recorder.from !== null && recorder.to !== null) || recorder.active}
						onClick={() => {
							const def = moment().set({second: 0}).toDate();
							picker.toggle('timer-record-time',
								{ from: recorder.from ?? def, to: recorder.to ?? def },
								{
									rightPosition: true,
									showPredefinedRanges: false,
									showTime: true,
									blockFutureDates: true,
									blockEndTime: recorder.active
								}
							);
						}}
						icon={faStopwatch}
						label={recorder.active
							? buildTotalTimeFromDuration(moment(actualDate).diff(recorder.from))
							: ((recorder.to !== null && recorder.from !== null) ? buildTotalTimeFromDuration(moment(recorder.to).diff(recorder.from)) : '0:00:00')}
					/> || <ToggleLoader/>}</>,
					<Space36/>,
					<>{!recorder.loading && <CircleButton
						icon={recorder.active ? faStop : (recorder.from && recorder.to ? faCheck : faPlay)}
						onClick={async () => {
							if (recorder.active) await recorder.endRecording();
							else {
								if (recorder.from && recorder.to) await recorder.createRecord();
								else await recorder.startRecording();
							}
						}}
					/> || <CircleButtonLoader/>}</>,
					<Space36/>,
					<>{!recorder.loading && <IconButton
						icon={faTrash}
						onClick={async () => {
							if (recorder.active) dialog.init('timer-record-delete', 'Varování', 'Opravdu chcete odstranit aktivní záznam?');
							else recorder.clear();
						}}
					/> || <IconButtonLoader/>}</>
				]}
			/>
			{!timer.tableLoading && timer.table.length > 0 && <Table
				sizes={["55px", "1fr", "1fr", "1fr", "50px"]}
				additional={{
					left: <>
						<DeleteButton onClick={() => timer.toDelete.length > 0 ? dialog.init('timer-table-delete', 'Varování', 'Opravdu chcete odstranit vybrané záznamy?') : null}/>
					</>,
					right: <>
					 <TimerStatistics isTodayYesterday/>
					</>
				}}
				header={[
					{
						label: <Checkbox checked={timer.toDelete.length === timer.table.length} value={'all'} onChange={(e) => {
							if (timer.toDelete.length === timer.table.length) timer.uncheckAll();
							else timer.checkAll();
						}}/>
					},
					{label: "Popis"},
					{label: "Projekt"},
					{label: "Čas"},
					{label: ""}
				]}
				items={timer.table.map((o) => {
					return {
						id: o.id,
						onHover: theme.colors.lightWhite,
						children: [
							<Checkbox checked={timer.toDelete.includes(o.id)} value={o.id}
												onChange={(e) => timer.toggleToDelete(o.id)}/>,
							<TextItem color={o.description !== "" ? undefined : theme.colors.iceBlue}
												onClick={() => showEditor(o)}
												content={o.description !== "" ? o.description : "Bez popisu"}/>,
							o.projectId ? <ProjectItem
								onClick={() => showEditor(o)}
								project={o.projectName as string}
								color={o.color as string}
								customer={o.customerName}
							/> : <TextItem
								onClick={() => showEditor(o)} color={o.projectId ? undefined : theme.colors.iceBlue} content={'Bez projektu'}/>,
							<TimeDurationItem
								onClick={() => showEditor(o)} from={o.start} to={o.end}/>,
							<InvisibleItem right>
								<RecorderStartItem
									description={o.description}
									project={o.projectId}
								/>
							</InvisibleItem>
						]
					};
				})}
			/> || <TableLoader columns={3} rows={5} noData={!timer.tableLoading}/>}
			{!timer.tableLoading && timer.table.length > 0 && <Pagination/> || <PaginationLoader/>}
			<Pad24/>
		</>
	);
};

export default TimerOverview;
