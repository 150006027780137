import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import DetailSink from "./detail-sink";
import CustomersSink from "./customers-sink";
import {toast} from "react-hot-toast";

interface CustomerItemValidationProps {
	name: boolean;
	email: boolean;
}

@sink("customers-creator", SinkFactory)
export default class CreatorSink {

	constructor(factory: SinkContainer){
		this.customers = factory.getSink(CustomersSink);
		this.detail = factory.getSink(DetailSink);
	}

	customers: CustomersSink;

	detail: DetailSink;

	@state opened: boolean = false;

	@state loading: boolean = true;

	@state id: string | null = null;

	@state name: string = "";

	@state phone: string = "";

	@state email: string = "";

	@state companyIdNumber: string = "";

	@state streetAndNumber: string = "";

	@state postalCodeAndCity: string = "";

	@state note: string = "";

	@state validation: CustomerItemValidationProps = {
		name: true,
		email: true
	};

	@effect
	open(id: string | null) {
		this.opened = true;
		this.id = id;
	}

	@effect
	async load(id: string) {
		this.loading = true;
		const data = await Api.fetch(id);
		if(data !== undefined) {
			this.name = data.info.name;
			this.phone = data.info.phoneNumber;
			this.email = data.info.emailAddress;
			this.companyIdNumber = data.info.companyIdNumber;
			this.streetAndNumber = data.info.residence.streetAndNumber;
			this.postalCodeAndCity = data.info.residence.cityAndPostalCode;
			this.note = data.info.note;
			this.loading = false;
		}
	}

	@trigger("customers-creator/id", { formatter: (id) => id })
	async idTrigger(id: string | null) {
		if(id !== null) await this.load(id as string);
		else this.loading = false;
	}

	@effect
	close() {
		this.clear();
	}

	@effect clear() {
		this.opened = false;
		this.loading = true;
		this.id = null;
		this.name = "";
		this.phone = "";
		this.email = "";
		this.companyIdNumber = "";
		this.streetAndNumber = "";
		this.postalCodeAndCity = "";
		this.note = "";
		this.validation = {
			name: true,
			email: true
		};
	}

	@effect changeName(name: string) {
		this.name = name;
	}

	@effect changePhone(phone: string) {
		this.phone = phone;
	}

	@effect changeEmail(email: string) {
		this.email = email;
	}

	@effect changeCompanyIdNumber(companyIdNumber: string) {
		this.companyIdNumber = companyIdNumber;
	}

	@effect changeStreetAndNumber(streetAndNumber: string) {
		this.streetAndNumber = streetAndNumber;
	}

	@effect changePostalCodeAndCity(postalCodeAndCity: string) {
		this.postalCodeAndCity = postalCodeAndCity;
	}

	@effect changeNote(note: string) {
		this.note = note;
	}

	private static isValid (obj: CustomerItemValidationProps): boolean {
		return obj.name && obj.email;
	}

	private createValidation(): CustomerItemValidationProps {
		let v = {
			name: true,
			email: true
		};
		if(this.name === "") v.name = false;
		if(this.email === "") v.name = false;
		return v;
	}

	@effect
	validate(obj: CustomerItemValidationProps) {
		this.validation = obj;
	}

	@effect
	async save() {
		const valid = this.createValidation();
		this.validate(valid);
		if(CreatorSink.isValid(valid)) {
			this.loading = true;
			const toastId = toast.loading('Probíhá ukládání...');
			const result = await Api.save(this.id, {
				name: this.name,
				phoneNumber: this.phone,
				email: this.email,
				companyIdentificationNumber: this.companyIdNumber,
				streetAndNumber: this.streetAndNumber,
				cityAndPostalCode: this.postalCodeAndCity,
				note: this.note
			});
			toast.dismiss(toastId);
			if(result) {
				toast.success('Uloženo');
				this.clear();
				this.detail.clear();
				await this.customers.loadTable();
			}
			this.loading = false;
		}
		else toast.error('Formulář obsahuje nevalidní data');
	}
}
