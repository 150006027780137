import React, {useEffect} from "react";
import {
	DetailClose,
	DetailContainer, DetailContent,
	DetailContentLeft, DetailContentRight,
	DetailHeader,
	DetailHeaderButtons, DetailHeaderLeft,
	DetailHeaderRight, DetailInfo, DetailInfoRowGrid, DetailTitle,
	Divider
} from "../../../components/Detail";
import BorderButton from "../../../components/Controls/BorderButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faTimes} from "@fortawesome/pro-light-svg-icons";
import {useSink} from "react-redux-sink";
import DetailSink from "../storage/detail-sink";
import {faBalanceScale, faUserFriends, faEdit, faTrash} from "@fortawesome/pro-regular-svg-icons";
import ThemeSink from "../../../store/theme-sink";
import HeaderButton from "../../../components/Controls/HeaderButton";
import {Space12, Space36} from "../../../components/Creator";
import {BorderButtonLoader, DetailInfoLoader, DetailTitleLoader, HeaderButtonLoader} from "../../../components/Loaders";
import DialogSink from "../../../store/dialog-sink";
import CreatorSink from "../storage/creator-sink";
import {toast} from "react-hot-toast";

const Detail: React.FC = () => {
	const detail = useSink(DetailSink);
	const creator = useSink(CreatorSink);
	const theme = useSink(ThemeSink);
	const dialog = useSink(DialogSink);

	useEffect(() => {
		if(dialog.id === 'delete-role') {
			detail.delete().then();
			dialog.clear();
		}
	}, [dialog.confirm]);


	return (
		<DetailContainer show={detail.opened}>
			<DetailHeader>
				<DetailHeaderLeft>
					<DetailClose onClick={() => detail.close()}>
						<FontAwesomeIcon icon={faChevronLeft}/>
					</DetailClose>
					{!detail.loading && detail.data && <DetailTitle title={detail.data?.name}/> || <DetailTitleLoader/>}
				</DetailHeaderLeft>
				<DetailHeaderRight>
					{!detail.loading && detail.data && <HeaderButton
						icon={faEdit}
						label={'Upravit'}
						click={() => creator.open(detail.id)}
					/> || <HeaderButtonLoader/>}
					<Space12/>
					{!detail.loading && detail.data && <BorderButton
						color={theme.colors.darkGray}
						icon={faTrash}
						label={'Smazat'}
						click={() => dialog.init('delete-role', 'Varování', 'Opravdu chcete odstranit roli?')}
					/> || <BorderButtonLoader/>}
					<Space36/>
					<DetailClose onClick={() => detail.close()}>
						<FontAwesomeIcon icon={faTimes}/>
					</DetailClose>
				</DetailHeaderRight>
			</DetailHeader>
			<DetailHeaderButtons>
				{!detail.loading && detail.data &&
				<BorderButton
					color={theme.colors.orange}
					label={detail.data.usersCount.toString()}
					icon={faUserFriends}
					click={() => toast('Pracuje se na tom', {icon: '😎'})}
				/> || <BorderButtonLoader/>}
			</DetailHeaderButtons>
			<DetailContent>
				<DetailContentLeft>
					{!detail.loading && detail.data && <DetailInfo icon={faBalanceScale} label={'Seznam práv role'}>
						{detail.data.permissions.sort((a: any, b: any): number => {
							const textA = a.module.toUpperCase();
							const textB = b.module.toUpperCase();
							return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
						}).map((o, i, list) => {
							return <>
								{i > 0 && !(i > 0 && list[i - 1].module === o.module) && <Divider/>}
								<DetailInfoRowGrid key={i}>
									{!(i > 0 && list[i - 1].module === o.module) &&
									<div>{o.module}</div>}
									{(i > 0 && list[i - 1].module === o.module) && <div/>}
									<div>{o.name}</div>
								</DetailInfoRowGrid>
							</>;
						})}
					</DetailInfo> || <DetailInfoLoader/>}
				</DetailContentLeft>
				<DetailContentRight/>
			</DetailContent>
		</DetailContainer>
	);
};

export default Detail;
