import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import Permissions from "../../../permissions";
import FilterSink from "../../../store/filter-sink";
import {toast} from "react-hot-toast";
import moment from "moment";
import IdentitySink from "../../../store/identity-sink";
import {hasAnyPermission} from "../../../lib/application-lib";
import NavigationSink from "../../../store/navigation-sink";
import Routes from "../../../routes";

export interface TimerTableItemInterface {
	id: string;
	userId: string;
	userName: string;
	description: string;
	projectId?: string;
	color?: string;
	projectName?: string;
	customerName?: string;
	start: Date;
	end: Date;
}

@sink("timer-statistics", SinkFactory)
export default class TimerStatisticsSink {

	constructor(factory: SinkContainer) {
		this.identity = factory.getSink(IdentitySink);
		this.filter = factory.getSink(FilterSink);
		this.navigation = factory.getSink(NavigationSink);
	}

	identity: IdentitySink;

	filter: FilterSink;

	navigation: NavigationSink;

	@state loading: boolean = true;

	@state today: number = 0;

	@state yesterday: number = 0;

	@state total: number = 0;

	@effect
	async loadTodayYesterday() {
		this.loading = true;
		const today = await Api.fetchDuration(moment().toDate(), moment().toDate(), this.identity.data !== null ? [this.identity.data.id] : [], [], [], undefined);
		const yesterday = await Api.fetchDuration(moment().add(-1, 'day').toDate(), moment().add(-1, 'day').toDate(), this.identity.data !== null ? [this.identity.data.id] : [], [], [], undefined);
		if(today !== null && today !== undefined && today.result !== null &&
			yesterday !== null && yesterday !== undefined && yesterday.result !== null) {
			this.today = today.result.duration;
			this.yesterday = yesterday.result.duration;
		}
		else toast.error('Nastala chyba při načítání statistik');
		this.loading = false;
	}

	@effect
	async loadTotal() {
		this.loading = true;
		const result = await Api.fetchDuration(
			this.filter.from,
			this.filter.to,
			(!hasAnyPermission([Permissions.TIMER_LIST_ALL], this.identity.data?.permissions ?? []) && this.identity.data !== null) ? [this.identity.data.id] : this.filter.users,
			this.filter.projects,
			this.filter.customers,
			this.filter.search,
		);
		if(result !== null && result !== undefined && result.result !== null) {
			this.total = result.result.duration;
		}
		else toast.error('Nastala chyba při načítání statistik');
		this.loading = false;
	}

	@effect
	async refreshStatistics() {
		if(this.navigation.location.pathname.startsWith(Routes.paths.TIMER_REPORT_DETAIL)) await this.loadTotal();
		else if(this.navigation.location.pathname.startsWith(Routes.paths.TIMER_OVERVIEW)) await this.loadTodayYesterday();
	}

	@effect
	clear() {
		this.loading = true;
		this.today = 0;
		this.yesterday = 0;
		this.total = 0;
	}
}
