import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

//#region styled
const Container = styled.div`
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
	position: relative;
	font-size: 18px;
	color: ${({theme}) => theme.colors.iceBlue};
`;
const Icon = styled.div<{ color?: string; }>`
	width: 50px;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({theme, color}) => color ?? theme.colors.iceBlue};
`;
const Input = styled.input`
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	padding: 14px 14px 14px 50px;
	width: 258px;
	border: 1px solid ${({theme}) => theme.colors.iceBlue};
	border-radius: 4px;
	background-color: transparent;
	color: ${({theme}) => theme.colors.blueGray};
`;

//#endregion

interface SearchInputInterface {
	icon: IconDefinition;
	type?: string;
	placeholder?: string;
	iconColor?: string;
}

const SearchInput: React.FC<React.InputHTMLAttributes<HTMLInputElement> & SearchInputInterface> =
	({
		 icon,
		 iconColor,
		 ...props
	 }) => {
		return (
			<Container>
				<Icon color={iconColor}>
					<FontAwesomeIcon icon={icon}/>
				</Icon>
				<Input {...props}/>
			</Container>
		);
	};

export default SearchInput;
