import React from "react";
import {
	CreatorClose, CreatorContainer, CreatorContent, CreatorData,
	CreatorHeader,
	CreatorHeaderLeft,
	CreatorHeaderRight, CreatorInput, CreatorTextArea,
	CreatorTitleInputContainer, SingleColumnRow, Space36, ThreeColumnsRow
} from "../../../components/Creator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-light-svg-icons";
import HeaderButton from "../../../components/Controls/HeaderButton";
import {
	faTimes,
	faAddressCard,
	faSave,
	faPhone,
	faEnvelope,
	faBuilding,
	faExternalLink,
	faLink,
	faCalendarAlt, faStickyNote
} from "@fortawesome/pro-regular-svg-icons";
import {useSink} from "react-redux-sink";
import CreatorSink from "../storage/creator-sink";
import {DetailInfoLoader, DetailTitleLoader, HeaderButtonLoader} from "../../../components/Loaders";

const Creator: React.FC = () => {
	const creator = useSink(CreatorSink);

	return (
		<CreatorContainer show={creator.opened}>
			<CreatorHeader>
				<CreatorHeaderLeft>
					<CreatorClose onClick={() => creator.close()}>
						<FontAwesomeIcon icon={faChevronLeft}/>
					</CreatorClose>
					{!creator.loading && <CreatorTitleInputContainer
						value={creator.name}
						onChange={({currentTarget}) => creator.changeName(currentTarget.value)}
					/> || <DetailTitleLoader/>}
				</CreatorHeaderLeft>
				<CreatorHeaderRight>
					{!creator.loading && <HeaderButton
						icon={faSave}
						label={'Uložit'}
						click={() => creator.save()}
					/> || <HeaderButtonLoader/>}
					<Space36/>
					<CreatorClose onClick={() => creator.close()}>
						<FontAwesomeIcon icon={faTimes}/>
					</CreatorClose>
				</CreatorHeaderRight>
			</CreatorHeader>
			<CreatorContent>
				{!creator.loading && <CreatorData icon={faAddressCard} label={'Kontakty'}>
					<ThreeColumnsRow>
						<CreatorInput
							trailingIcon={faPhone}
							label={'Telefon'}
							value={creator.phone}
							onChange={({currentTarget}) => creator.changePhone(currentTarget.value)}
						/>
						<CreatorInput
							trailingIcon={faEnvelope}
							label={'Email'}
							value={creator.email}
							onChange={({currentTarget}) => creator.changeEmail(currentTarget.value)}
						/>
						<CreatorInput
							trailingIcon={faBuilding}
							label={'IČO'}
							value={creator.companyIdNumber}
							onChange={({currentTarget}) => creator.changeCompanyIdNumber(currentTarget.value)}
						/>
						<CreatorInput
							label={'Ulice a číslo'}
							value={creator.streetAndNumber}
							onChange={({currentTarget}) => creator.changeStreetAndNumber(currentTarget.value)}
						/>
						<CreatorInput
							label={'PSČ a město'}
							value={creator.postalCodeAndCity}
							onChange={({currentTarget}) => creator.changePostalCodeAndCity(currentTarget.value)}
						/>
					</ThreeColumnsRow>
				</CreatorData> || <DetailInfoLoader/>}
				{!creator.loading && <CreatorData icon={faStickyNote} label={'Poznámka'}>
					<CreatorTextArea
						value={creator.note}
						onChange={({currentTarget}) => creator.changeNote(currentTarget.value)}
					/>
				</CreatorData> || <DetailInfoLoader/>}
			</CreatorContent>
		</CreatorContainer>
	);
};

export default Creator;
