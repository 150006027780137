import React from "react";
import styled from "styled-components";
import IdentitySink from "../../store/identity-sink";
import {useSink} from "react-redux-sink";
import {hasAnyPermission} from "../../lib/application-lib";

interface PermissionProps {
	permissions: string[];
}

const Permission: React.FC<PermissionProps> =
	({
		permissions,
		children
	 }) => {
	const identity = useSink(IdentitySink);
	return (
		<>{(identity.isValid && identity.data && hasAnyPermission(permissions, identity.data!.permissions)) ? children : ''}</>
	);
};

export default Permission;
