import Routes from "./routes";
import Permissions from "./permissions";
import {
	faBalanceScale,
	faBoxOpen,
	faCoins, faCouch, faProjectDiagram, faServer, faStopwatch, faTelescope, faUserFriends, faUserTie
} from "@fortawesome/pro-regular-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

export interface NavigationInterface {
	icon: IconDefinition;
	label: string;
	link: string;
	startsWith: string[];
	permissions?: string[];
	children: NavigationItemInterface[];
}

export interface NavigationItemInterface {
	label: string;
	link: string;
	permissions?: string[];
	startsWith: string[];
}

const Navigation: any[] = [
	{
		icon: faTelescope,
		label: "Rozcestník",
		link: Routes.paths.MAP,
		startsWith: [Routes.paths.MAP],
		children: []
	},
	{
		icon: faStopwatch,
		label: "Časovač",
		link: Routes.paths.TIMER_OVERVIEW,
		permissions: [Permissions.TIMER_ACTIVITY, Permissions.TIMER_LIST, Permissions.TIMER_LIST_ALL],
		startsWith: [Routes.paths.TIMER_OVERVIEW, Routes.paths.TIMER_ACTIVITY, Routes.paths.TIMER_RECORDS, Routes.paths.TIMER_REPORT_BRIEF, Routes.paths.TIMER_REPORT_DETAIL],
		children: [
			{label: "Přehled", link: Routes.paths.TIMER_OVERVIEW, startsWith: [Routes.paths.TIMER_OVERVIEW], permissions: [Permissions.TIMER_LIST]},
			{
				label: "Report",
				link: Routes.paths.TIMER_REPORT_BRIEF,
				startsWith: [Routes.paths.TIMER_REPORT_BRIEF, Routes.paths.TIMER_REPORT_DETAIL],
				permissions: [Permissions.TIMER_LIST, Permissions.TIMER_LIST_ALL]
			},
			{
				label: "Aktivita",
				link: Routes.paths.TIMER_ACTIVITY,
				startsWith: [Routes.paths.TIMER_ACTIVITY],
				permissions: [Permissions.TIMER_ACTIVITY]
			},
			//{ label: "Záznamy", link: Routes.paths.TIMER_RECORDS, startsWith: [Routes.paths.TIMER_RECORDS] }
		]
	},
	{
		icon: faProjectDiagram,
		label: "Projekty",
		link: Routes.paths.PROJECTS_ACTIVE_OVERVIEW,
		permissions: [Permissions.PROJECTS_LIST, Permissions.PROJECTS_LIST_ALL],
		startsWith: [
			Routes.paths.PROJECTS_ACTIVE_OVERVIEW,
			Routes.paths.PROJECTS_CLOSED_OVERVIEW,
			Routes.paths.PROJECTS_REPORT,
			Routes.paths.PROJECTS_RECORDS,
			Routes.paths.PROJECTS_TIMELINE_GRAPHS,
			Routes.paths.PROJECTS_TIMELINE_TABLES
		],
		children: [
			{
				label: "Přehled",
				link: Routes.paths.PROJECTS_ACTIVE_OVERVIEW,
				startsWith: [Routes.paths.PROJECTS_ACTIVE_OVERVIEW, Routes.paths.PROJECTS_CLOSED_OVERVIEW],
				permissions: [Permissions.PROJECTS_LIST, Permissions.PROJECTS_LIST_ALL]
			},
			{
				label: "Timeline",
				link: Routes.paths.PROJECTS_TIMELINE_GRAPHS,
				startsWith: [Routes.paths.PROJECTS_TIMELINE_GRAPHS, Routes.paths.PROJECTS_TIMELINE_TABLES]
			},
			/*{
				label: "Report",
				link: Routes.paths.PROJECTS_REPORT,
				startsWith: [Routes.paths.PROJECTS_REPORT],
				permissions: []
			},*/
			//{ label: "Záznamy", link: Routes.paths.PROJECTS_RECORDS, startsWith: [Routes.paths.PROJECTS_RECORDS] }
		]
	},
	{
		icon: faUserTie,
		label: "Zákazníci",
		link: Routes.paths.CUSTOMERS_OVERVIEW,
		permissions: [Permissions.CUSTOMERS_LIST, Permissions.CUSTOMERS_LIST_ALL],
		startsWith: [Routes.paths.CUSTOMERS_OVERVIEW, Routes.paths.CUSTOMERS_REPORT, Routes.paths.CUSTOMERS_RECORDS],
		children: [
			{
				label: "Přehled",
				link: Routes.paths.CUSTOMERS_OVERVIEW,
				startsWith: [Routes.paths.CUSTOMERS_OVERVIEW],
				permissions: [Permissions.CUSTOMERS_LIST, Permissions.CUSTOMERS_LIST_ALL]
			},
			/*{
				label: "Report",
				link: Routes.paths.CUSTOMERS_REPORT,
				startsWith: [Routes.paths.CUSTOMERS_REPORT],
				permissions: []
			},*/
			//{ label: "Záznamy", link: Routes.paths.CUSTOMERS_RECORDS, startsWith: [Routes.paths.CUSTOMERS_RECORDS] }
		]
	},
	{
		icon: faCoins,
		label: "Finance",
		link: Routes.paths.FINANCE_USERS_NOT_PAID_OVERVIEW,
		permissions: [Permissions.FINANCE_USER_LIST, Permissions.FINANCE_PROJECT_LIST],
		startsWith: [Routes.paths.FINANCE_USERS_NOT_PAID_OVERVIEW, Routes.paths.FINANCE_USERS_PAID_OVERVIEW, Routes.paths.FINANCE_PROJECTS_NOT_PAID_OVERVIEW, Routes.paths.FINANCE_PROJECTS_PAID_OVERVIEW, Routes.paths.FINANCE_REPORT, Routes.paths.FINANCE_RECORDS],
		children: [
			{
				label: "Uživatelé",
				link: Routes.paths.FINANCE_USERS_NOT_PAID_OVERVIEW,
				startsWith: [Routes.paths.FINANCE_USERS_NOT_PAID_OVERVIEW, Routes.paths.FINANCE_USERS_PAID_OVERVIEW],
				permissions: [Permissions.FINANCE_USER_LIST]
			},
			{
				label: "Projekty",
				link: Routes.paths.FINANCE_PROJECTS_NOT_PAID_OVERVIEW,
				startsWith: [Routes.paths.FINANCE_PROJECTS_NOT_PAID_OVERVIEW, Routes.paths.FINANCE_PROJECTS_PAID_OVERVIEW],
				permissions: [Permissions.FINANCE_PROJECT_LIST]
			},
			{label: "Report", link: Routes.paths.FINANCE_REPORT, startsWith: [Routes.paths.FINANCE_REPORT], permissions: [Permissions.FINANCE_USER_LIST, Permissions.FINANCE_PROJECT_LIST]},
			//{ label: "Záznamy", link: Routes.paths.FINANCE_RECORDS, startsWith: [Routes.paths.FINANCE_RECORDS] }
		]
	},
	{
		icon: faUserFriends,
		label: "Uživatelé",
		link: Routes.paths.USERS_ACTIVE_OVERVIEW,
		permissions: [Permissions.USERS_LIST, Permissions.USERS_LIST_ALL, Permissions.USERS_STRUCTURE_LIST],
		startsWith: [Routes.paths.USERS_ACTIVE_OVERVIEW, Routes.paths.USERS_NOT_ACTIVE_OVERVIEW, Routes.paths.USERS_STRUCTURE, Routes.paths.USERS_RECORDS],
		children: [
			{
				label: "Přehled",
				link: Routes.paths.USERS_ACTIVE_OVERVIEW,
				startsWith: [Routes.paths.USERS_ACTIVE_OVERVIEW, Routes.paths.USERS_NOT_ACTIVE_OVERVIEW],
				permissions: [Permissions.USERS_LIST_ALL, Permissions.USERS_LIST]
			},
			{
				label: "Struktura",
				link: Routes.paths.USERS_STRUCTURE,
				startsWith: [Routes.paths.USERS_STRUCTURE],
				permissions: [Permissions.USERS_STRUCTURE_LIST]
			},
			//{ label: "Záznamy", link: Routes.paths.USERS_RECORDS, startsWith: [Routes.paths.USERS_RECORDS] }
		]
	},
	/**{
		icon: faFolders,
		label: "Dokumenty",
		link: Routes.paths.DOCUMENTS_USERS_OVERVIEW,
		startsWith: [Routes.paths.DOCUMENTS_USERS_OVERVIEW, Routes.paths.DOCUMENTS_PROJECTS_OVERVIEW, Routes.paths.DOCUMENTS_CUSTOMERS_OVERVIEW, Routes.paths.DOCUMENTS_DOCUMENTATIONS_OVERVIEW, Routes.paths.DOCUMENTS_RECORDS],
		children: [
			{ label: "Uživatelé", link: Routes.paths.DOCUMENTS_USERS_OVERVIEW, startsWith: [Routes.paths.DOCUMENTS_USERS_OVERVIEW] },
			{ label: "Projekty", link: Routes.paths.DOCUMENTS_PROJECTS_OVERVIEW, startsWith: [Routes.paths.DOCUMENTS_PROJECTS_OVERVIEW] },
			{ label: "Zákazníci", link: Routes.paths.DOCUMENTS_CUSTOMERS_OVERVIEW, startsWith: [Routes.paths.DOCUMENTS_CUSTOMERS_OVERVIEW] },
			{ label: "Dokumentace", link: Routes.paths.DOCUMENTS_DOCUMENTATIONS_OVERVIEW, startsWith: [Routes.paths.DOCUMENTS_DOCUMENTATIONS_OVERVIEW] },
			{ label: "Záznamy", link: Routes.paths.DOCUMENTS_RECORDS, startsWith: [Routes.paths.DOCUMENTS_RECORDS] }
		]
	},**/
	{
		icon: faCouch,
		label: "Majetek",
		link: Routes.paths.PROPERTY_OVERVIEW,
		permissions: [Permissions.PROPERTY_LIST, Permissions.PROPERTY_LIST_ALL],
		startsWith: [Routes.paths.PROPERTY_OVERVIEW, Routes.paths.PROPERTY_RECORDS],
		children: [
			{
				label: "Přehled",
				link: Routes.paths.PROPERTY_OVERVIEW,
				startsWith: [Routes.paths.PROPERTY_OVERVIEW],
				permissions: [Permissions.PROPERTY_LIST_ALL, Permissions.PROPERTY_LIST]
			},
			//{ label: "Záznamy", link: Routes.paths.PROPERTY_RECORDS, startsWith: [Routes.paths.PROPERTY_RECORDS] }
		]
	},
	{
		icon: faBoxOpen,
		label: "Sklad",
		link: Routes.paths.STOCK_OVERVIEW,
		permissions: [Permissions.STOCK_LIST],
		startsWith: [Routes.paths.STOCK_OVERVIEW, Routes.paths.STOCK_RECORDS],
		children: [
			{label: "Přehled", link: Routes.paths.STOCK_OVERVIEW, startsWith: [Routes.paths.STOCK_OVERVIEW], permissions: [Permissions.STOCK_LIST]},
			//{ label: "Záznamy", link: Routes.paths.STOCK_RECORDS, startsWith: [Routes.paths.STOCK_RECORDS] }
		]
	},
	{
		icon: faBalanceScale,
		label: "Role a práva",
		link: Routes.paths.ROLES_OVERVIEW,
		permissions: [Permissions.ROLES_LIST, Permissions.PERMISSIONS_LIST],
		startsWith: [Routes.paths.ROLES_OVERVIEW, Routes.paths.PERMISSIONS_OVERVIEW, Routes.paths.ROLES_RECORDS],
		children: [
			{label: "Role", link: Routes.paths.ROLES_OVERVIEW, startsWith: [Routes.paths.ROLES_OVERVIEW], permissions: [Permissions.ROLES_LIST]},
			{
				label: "Práva",
				link: Routes.paths.PERMISSIONS_OVERVIEW,
				startsWith: [Routes.paths.PERMISSIONS_OVERVIEW],
				permissions: [Permissions.PERMISSIONS_LIST]
			},
			//{ label: "Záznamy", link: Routes.paths.ROLES_RECORDS, startsWith: [Routes.paths.ROLES_RECORDS] }
		]
	},
	{
		icon: faServer,
		label: "Provoz",
		link: Routes.paths.SERVICES_APPLICATIONS_OVERVIEW,
		startsWith: [Routes.paths.SERVICES_APPLICATIONS_OVERVIEW, Routes.paths.SERVICES_DEVICES_OVERVIEW, Routes.paths.SERVICES_RECORDS],
		children: [
			{ label: "Přehled", link: Routes.paths.SERVICES_APPLICATIONS_OVERVIEW, startsWith: [Routes.paths.SERVICES_APPLICATIONS_OVERVIEW, Routes.paths.SERVICES_DEVICES_OVERVIEW] },
			//{ label: "Report", link: Routes.paths.SERVICES_DEVICES_OVERVIEW, startsWith: [Routes.paths.SERVICES_DEVICES_OVERVIEW] },
			//{ label: "Záznamy", link: Routes.paths.SERVICES_RECORDS, startsWith: [Routes.paths.SERVICES_RECORDS] }
		]
	}
];

export default Navigation;
