import {sink, state, effect} from "react-redux-sink";

@sink("color-picker")
export default class ColorPickerSink {

	@state active: boolean = false;

	@state id: string | null = null;

	@state confirmed: boolean = false;

	@state color: string | null = null;

	@effect
	init(id: string, color: string){
		this.id = id;
		this.color = color === "" ? null : color;
		this.active = true;
	}

	@effect
	confirm(color: string) {
		this.color = color;
		this.confirmed = true;
	}

	@effect
	clear() {
		this.id = null;
		this.active = false;
		this.confirmed = false;
		this.color = null;
	}

}
