import {effect, sink, state} from "react-redux-sink";

type ShowType = "users" | "projects" | "customers";

@sink("finance-report")
export default class FinanceReportSink {

	@state show: ShowType = "users";

	@effect
	changeShow(show: ShowType) {
		this.show = show;
	}

	@effect
	clear() {
	}
}
