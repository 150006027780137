import React, {useEffect} from "react";
import ListHeader from "../../components/Headers/ListHeader";
import {faPen, faProjectDiagram, faTable, faUndo, faUserFriends, faUserTie} from "@fortawesome/pro-regular-svg-icons";
import Routes from "../../routes";
import {toast} from "react-hot-toast";
import {
	BorderButtonLoader,
	PaginationLoader,
	RangeControlLoader,
	TableLoader,
	ToggleLoader
} from "../../components/Loaders";
import {Pad24, Pad36, Space12, Space36} from "../../components/Creator";
import {useSink} from "react-redux-sink";
import TimelineTablesSink from "./storage/timeline-tables-sink";
import Switcher from "../../components/Controls/Switcher";
import Filters from "../../components/Filters";
import DateRangeControl from "../../components/Controls/DateRangeControl";
import moment from "moment";
import Permission from "../../components/Permissions";
import Permissions from "../../permissions";
import ToggleButton from "../../components/Controls/ToggleButton";
import BorderButton from "../../components/Controls/BorderButton";
import ThemeSink from "../../store/theme-sink";
import FilterSink from "../../store/filter-sink";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import UsersSink from "../Users/storage/users-sink";
import ProjectsSink from "./storage/projects-sink";
import CustomersSink from "../Customers/storage/customers-sink";
import TimelineTableHourlyLoads from "./components/TimelineTableHourlyLoads";
import TimelineTableLayout from "./components/TimelineTableLayout";

const TimelineTables: React.FC = () => {
	const timeline = useSink(TimelineTablesSink);
	const theme = useSink(ThemeSink);
	const filter = useSink(FilterSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const picker = useSink(DateTimePickerSink);
	const users = useSink(UsersSink);
	const projects = useSink(ProjectsSink);
	const customers = useSink(CustomersSink);

	useEffect(() => {
		multiCheckboxPicker.items = users.list.map(o => {
			return {
				value: o.id,
				label: o.name
			}
		})
	}, [users.list]);

	useEffect(() => {
		multiCheckboxPicker.items = projects.list.map(o => {
			return {
				value: o.id,
				label: o.name
			}
		})
	}, [projects.list]);

	useEffect(() => {
		multiCheckboxPicker.items = customers.list.map(o => {
			return {
				value: o.id,
				label: o.name
			}
		})
	}, [customers.list]);

	useEffect(() => {
		if(picker.confirmed) {
			if(picker.id === 'timeline-filter-dates') filter.changeDates(picker.dates.from, picker.dates.to);
			picker.clear();
		}
	}, [picker.confirmed]);

	useEffect(() => {
		if(multiCheckboxPicker.confirmed) {
			if(multiCheckboxPicker.id === 'timeline-filter-users') filter.changeUsers(multiCheckboxPicker.selected);
			else if(multiCheckboxPicker.id === 'timeline-filter-projects') filter.changeProjects(multiCheckboxPicker.selected);
			else if(multiCheckboxPicker.id === 'timeline-filter-customers') filter.changeCustomers(multiCheckboxPicker.selected);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		//if(timeline.show === 'brief') timeline.loadBriefTable().then();
		//if(timeline.show === 'detail') timeline.loadDetailTable().then();
	}, [timeline.show, filter.from, filter.to, filter.users]);

	useEffect(() => {
		users.loadList(undefined, undefined, true).then();
		projects.loadList(true).then();
		return () => {
			users.clear();
			projects.clear();
		};
	}, [timeline.show]);

	useEffect(() => {
		toast('Pracuje se na tom', {icon: '😎'});
		return () => {
			timeline.clear();
			filter.clear();
			multiCheckboxPicker.clear();
			picker.clear();
			users.clear();
			projects.clear();
			customers.clear();
		};
	}, []);

	return (
		<>
			<ListHeader
				title={'Timeline'}
				icon={faTable}
				menu={[
					{ label: 'Grafy', active: false, link: Routes.paths.PROJECTS_TIMELINE_GRAPHS },
					{ label: 'Tabulky', active: true, link: Routes.paths.PROJECTS_TIMELINE_TABLES }
				]}
			/>
			<Pad24/>
			<Filters
				left={[
					<>{(!timeline.briefTableLoading || !timeline.detailTableLoading) && <DateRangeControl
						id={'timeline-filter-dates'}
						from={filter.from}
						to={filter.to}
						rangeStart={moment().toDate()}
						rangeEnd={moment().toDate()}
						goPrev={(from, to) => {
							toast('Téměř hotovo', {icon: '😎'})
						}}
						goNext={(from, to) => {
							toast('Téměř hotovo', {icon: '😎'})
						}}
						onClick={() => {
							picker.toggle('timeline-filter-dates', {from: filter.from, to: filter.to});
						}}
					/> || <RangeControlLoader/>}</>,
					<Space36/>,
					<Permission permissions={[Permissions.TIMER_LIST_ALL]}>
						{(!timeline.briefTableLoading || !timeline.detailTableLoading) && <ToggleButton
							id={'timeline-filter-users'}
							selected={filter.users.length > 0}
							active={multiCheckboxPicker.id === 'timeline-filter-users'}
							onClick={async () => {
								multiCheckboxPicker.toggle('timeline-filter-users');
								multiCheckboxPicker.setSelected(filter.users);
								await users.loadList(true, undefined, true);
							}}
							icon={faUserFriends}
							label={'Uživatelé'}
						/> || <ToggleLoader/>}
						<Space12/>
					</Permission>,
					<>{timeline.show === 'detail' && !timeline.detailTableLoading && <ToggleButton
						id={'timeline-filter-projects'}
						selected={filter.projects.length > 0}
						active={multiCheckboxPicker.id === 'timeline-filter-projects'}
						onClick={async () => {
							multiCheckboxPicker.toggle('timeline-filter-projects');
							multiCheckboxPicker.setSelected(filter.projects);
							await projects.loadList();
						}}
						icon={faProjectDiagram}
						label={'Projekty'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{timeline.show === 'detail' && !timeline.detailTableLoading && <ToggleButton
						id={'timeline-filter-customers'}
						selected={filter.customers.length > 0}
						active={multiCheckboxPicker.id === 'timeline-filter-customers'}
						onClick={async () => {
							multiCheckboxPicker.toggle('timeline-filter-customers');
							multiCheckboxPicker.setSelected(filter.customers);
							await customers.loadList();
						}}
						icon={faUserTie}
						label={'Zákazníci'}
					/> || <ToggleLoader/>}</>
				]}
				right={[
					<>{(!timeline.briefTableLoading || !timeline.detailTableLoading) &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'}
												color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			<Switcher items={[
				{title: 'Souhrn', active: timeline.show === 'brief', onClick: () => timeline.changeShow('brief')},
				{title: 'Rozložení', active: timeline.show === 'detail', onClick: () => timeline.changeShow('detail')}
			]}/>
			<Pad24/>
			{timeline.show === 'brief' && <>
				{!timeline.briefTableLoading && <TimelineTableHourlyLoads/> || <TableLoader columns={5} rows={5}/>}
			</>}
			{timeline.show === 'detail' && <>
				{!timeline.detailTableLoading && <TimelineTableLayout/> || <TableLoader columns={5} rows={5}/>}
			</>}
			<Pad24/>
		</>
	);
};

export default TimelineTables;
