import React, {useEffect} from "react";
import {EditorInput, Space12, Space36} from "../Creator";
import {faCoins, faPen, faProjectDiagram, faStopwatch} from "@fortawesome/pro-regular-svg-icons";
import {useSink} from "react-redux-sink";
import TimerItemEditorSink from "../../pages/Timer/storage/timer-item-editor-sink";
import ToggleButton from "../Controls/ToggleButton";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import ProjectsSink from "../../pages/Projects/storage/projects-sink";
import IdentitySink from "../../store/identity-sink";
import {buildTotalTimeFromDuration} from "../../lib/time-lib";
import moment from "moment";
import ThemeSink from "../../store/theme-sink";
import TableEditorSink from "../../store/table-editor-sink";
import {hasAnyPermission} from "../../lib/application-lib";
import Permissions from "../../permissions";
import ProjectFinanceItemEditorSink from "../../pages/Finance/Projects/storage/project-finance-item-editor-sink";

const ProjectFinanceItemEditor: React.FC = () => {
	const editor = useSink(ProjectFinanceItemEditorSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const picker = useSink(DateTimePickerSink);
	const projects = useSink(ProjectsSink);
	const identity = useSink(IdentitySink);
	const theme = useSink(ThemeSink);
	const tableEditor = useSink(TableEditorSink);

	useEffect(() => {
		if(tableEditor.confirmed) {
			if(tableEditor.editor === 'project-finance-item-editor') editor.submit().then();
			tableEditor.clear();
		}
	}, [tableEditor.confirmed]);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed) {
			if (multiCheckboxPicker.id === 'table-editor-project') editor.changeProject(multiCheckboxPicker.selected[0] ?? null);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	return <>
		<EditorInput
			iconColor={theme.colors.gold}
			value={editor.description}
			onChange={({currentTarget}) => editor.changeDescription(currentTarget.value)}
			icon={faPen}
			size={200}
		/>
		<Space12/>
		<EditorInput
			iconColor={theme.colors.gold}
			value={editor.amount}
			onChange={({currentTarget}) => editor.changeAmount(currentTarget.value)}
			icon={faCoins}
			size={100}
		/>
		<Space36/>
		<ToggleButton
			id={'table-editor-project'}
			icon={faProjectDiagram}
			label={'Projekt'}
			selected={editor.project !== null}
			active={multiCheckboxPicker.id === 'table-editor-project'}
			onClick={async () => {
				multiCheckboxPicker.toggle('table-editor-project', true);
				multiCheckboxPicker.setSelected(editor.project !== null ? [editor.project] : []);
				await projects.loadList(false, undefined, undefined);
			}}
		/>
	</>;
};

export default ProjectFinanceItemEditor;
