import React, {useEffect} from "react";
import styled from "styled-components";
import {useSink} from "react-redux-sink";
import FilterSink from "../../../store/filter-sink";
import moment from "moment";
import UsersSink, {UsersListItemInterface} from "../../Users/storage/users-sink";
import TimelineTablesSink from "../storage/timeline-tables-sink";
import {ProjectsFinanceTableItemInterface} from "../../Finance/Projects/storage/projects-finance-sink";
import {faCoins, faPen, faProjectDiagram, faRepeat, faStopwatch} from "@fortawesome/pro-regular-svg-icons";
import TableEditorSink from "../../../store/table-editor-sink";

const TimelineTableHourlyLoadsContainer = styled.div`
	width: calc(100% - 72px);
	margin: 0 36px;
	overflow-y: auto;
`;
const Table = styled.div<{ count: number; }>`
	display: grid;
	grid-template-columns: repeat(${({count}) => count}, 200px);
`;
const Column = styled.div<{ count: number; }>`
	display: grid;
	grid-template-rows: repeat(${({count}) => count}, 1fr);
	& > div {
		border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
		padding: 12px;
	}
`;

const TimelineTableHourlyLoads: React.FC = () => {
	const filter = useSink(FilterSink);
	const users = useSink(UsersSink);
	const timeline = useSink(TimelineTablesSink);
	const tableEditor = useSink(TableEditorSink);

	const getMonthNames = (): string[] => {
		const months = [];
		let date = filter.from;
		while(moment(date).isBefore(moment(filter.to))) {
			months.push(`${moment(date).format("MMMM")} ${moment(date).format("YYYY")}`);
			date = moment(date).add(1, 'month').toDate();
		}
		return months;
	};

	const getRenderedMonths = (): Date[] => {
		const months = [];
		let date = filter.from;
		while(moment(date).isBefore(moment(filter.to))) {
			months.push(date);
			date = moment(date).add(1, 'month').toDate();
		}
		return months;
	};

	const showEditor = (o: UsersListItemInterface) => {
		tableEditor.show('cell-' + o.id, '', { width: 250 });
	};

	const count = moment(filter.to).diff(moment(filter.from), 'months') + 2;
	const months = getRenderedMonths();
	const filteredUsers = filter.users.length > 0 ? users.list.filter(o => filter.users.includes(o.id)) : users.list;
	const filteredUsersCount = filteredUsers.length;

	return (
			<TimelineTableHourlyLoadsContainer>
				<Table count={users.list.length + 1}>
					<Column count={count}>
						<div/>
						{getMonthNames().map((o, i) => <div key={i}>{o}</div>)}
					</Column>
					{filteredUsers.map((o, i) => {
						const data = timeline.briefTable.filter(a => a.id === o.id);
						return <Column onClick={() => showEditor(o)} className={'table-cell-' + o.id} key={i} count={count}>
							<div>{o.name}</div>
							{[...Array(count - 1)].map((p, j) =>
								<div key={j}>{data.filter(a => moment(a.month).diff(moment(months[j]), "months") === 0)[0]?.total ?? 0}/{o.hourlyLoad * 20}</div>
							)}
						</Column>;
					})}
				</Table>
		</TimelineTableHourlyLoadsContainer>
	);
};

export default TimelineTableHourlyLoads;
