import React, {useEffect} from "react";
import styled from "styled-components";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PredefinedRanges from "./PredefinedRanges";
import {FocusedInput, OnDatesChangeProps, START_DATE, useDatepicker} from "@datepicker-react/hooks";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import {useSink} from "react-redux-sink";
import moment from "moment";
import DatepickerContext from "./dateTimePicker.context";
import Month from "./Month";
import TimePicker from "./Time";
import {MonthSelector, YearSelector} from "./Selectors";
import CronGenerator from "./CronGenerator";
import Draggable from "react-draggable";
import {DragHandle} from "../Drag";

const PickerContainer = styled.div<{ top: number; left?: number; right?: number; active: boolean; withRanges?: boolean; }>`
	width: ${({withRanges}) => withRanges ? 507 : 327}px;
	border-radius: 8px;
	background-color: ${({theme}) => theme.colors.white};
	box-shadow: 0 0 4px 0 ${({theme}) => theme.colors.darkGray}30;
	position: absolute;
	top: ${({top}) => top}px;
	display: ${({active}) => active ? 'block' : 'none'};
	${({left, right}) => left ? `
		left: ${left}px;
	` : `
		right: ${right}px;
	`}
`;
const ButtonContainer = styled.div`
	padding: 12px 24px;
	width: calc(100% - 48px);
	border: 1px solid ${({theme}) => theme.colors.lightGray};
`;
const Button = styled.button`
	border: none;
	background-color: ${({theme}) => theme.colors.gold};
	color: ${({theme}) => theme.colors.white};
	border-radius: 4px;
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
	width: 100%;
	padding: 12px;
	transition: .25s background-color;
	cursor: pointer;

	&:hover {
		background-color: ${({theme}) => theme.colors.orange};
	}
`;
const ButtonText = styled.span`
	margin-left: 12px;
`;
const Boxes = styled.div`
	display: flex;
	align-items: flex-start;
`;
const Positioner = styled.div`
	position: relative;
`;
const Left = styled.div`
`;
const Right = styled.div`
	width: 100%;
	border-left: 1px solid ${({theme}) => theme.colors.lightGray};
`;

const DateTimePicker: React.FC = () => {
	const picker = useSink(DateTimePickerSink);
	const element = document.querySelector('.toggle-button-' + picker.id)?.getBoundingClientRect();

	const onResize = () => picker.clear();

	useEffect(() => {
		window.addEventListener('resize', onResize);
		return () => window.removeEventListener('resize', onResize);
	}, []);

	const handleDateChange = (data: OnDatesChangeProps): void => {
		let dates = {
			startDate: moment(data.startDate ? data.startDate : picker.dates.from).set({
				hour: moment(picker.dates.from).get('hours'),
				minute: moment(picker.dates.from).get('minutes'),
				second: moment(picker.dates.from).get('seconds')
			}).toDate(),
			endDate: moment(data.endDate ? data.endDate : picker.dates.to).set({
				hour: moment(picker.dates.to).get('hours'),
				minute: moment(picker.dates.to).get('minutes'),
				second: moment(picker.dates.to).get('seconds')
			}).toDate(),
			focusedInput: !data.focusedInput ? START_DATE : data.focusedInput
		};
		if (!data.endDate && data.startDate && moment(picker.dates.to).diff(moment(data.startDate), 'day') < 0)
			dates.endDate = data.startDate;
		picker.changeDates(dates.startDate, dates.endDate, dates.focusedInput);
	};
	const {
		firstDayOfWeek,
		activeMonths,
		isDateSelected,
		isDateHovered,
		isFirstOrLastSelectedDate,
		isDateBlocked,
		isDateFocused,
		focusedDate,
		onDateHover,
		onDateSelect,
		onDateFocus,
		goToPreviousMonths,
		goToNextMonths,
		goToDate
	} = useDatepicker({
		startDate: moment(picker.dates.from).set({second: 0}).toDate(),
		exactMinBookingDays: !picker.selectRange,
		minBookingDays: 1,
		endDate: !picker.selectRange ? null : moment(!picker.selectRange ? picker.dates.from : picker.dates.to).set({second: 1}).toDate(),
		isDateBlocked: (date: Date): boolean => {
			if (picker.blockFutureDates) return moment(date).isAfter(moment());
			return false;
		},
		focusedInput: picker.focusedInput as FocusedInput,
		onDatesChange: handleDateChange,
		numberOfMonths: 1
	});

	useEffect(() => {
		if (picker.id !== null) goToDate(picker.dates.from);
	}, [picker.id]);

	return (
		<Draggable
			bounds={'parent'}
			handle={'strong'}
		>
			<PickerContainer
				active={element !== undefined}
				top={(element?.top ?? 0) + (element?.height ?? 0)}
				left={picker.rightPosition ? undefined : element?.left ?? 0}
				right={picker.rightPosition ? (window.innerWidth - (element?.right ?? 0)) : undefined}
				withRanges={picker.showPredefinedRanges}
			>
				<DragHandle isTop/>
				<DatepickerContext.Provider
					value={{
						focusedDate,
						isDateFocused,
						isDateSelected,
						isDateHovered,
						isDateBlocked,
						isFirstOrLastSelectedDate,
						onDateSelect,
						onDateFocus,
						onDateHover
					}}
				>
					<Boxes>
						<Left>
							{picker.selectRange && picker.showPredefinedRanges && <PredefinedRanges/>}
						</Left>
						<Right>
							{picker.showTime && <TimePicker/>}
							<Positioner>
								<MonthSelector active={activeMonths} goToDate={goToDate}/>
								<YearSelector active={activeMonths} goToDate={goToDate}/>
								{activeMonths.map(month => {
									if (!isNaN(month.year)) return <Month
										key={`${month.year}-${month.month}`}
										year={month.year}
										month={month.month}
										firstDayOfWeek={firstDayOfWeek}
										goToPreviousMonths={goToPreviousMonths}
										goToNextMonths={goToNextMonths}
									/>;
									return '';
								})}
							</Positioner>
							{picker.cron.show && <CronGenerator/>}
						</Right>
					</Boxes>
				</DatepickerContext.Provider>
				<ButtonContainer>
					<Button onClick={() => picker.confirm()}>
						<FontAwesomeIcon icon={faCheck}/>
						<ButtonText>Potvrdit vybrané</ButtonText>
					</Button>
				</ButtonContainer>
				<DragHandle/>
			</PickerContainer>
		</Draggable>
	);
};

export default DateTimePicker;
