import React from "react";
import styled from "styled-components";

//#region styled
const Mark = styled.div`
	position: relative;
	top: 0;
	left: 0;
	height: 14px;
	width: 14px;
	background-color: ${({theme}) => theme.colors.white};
	border: 2px solid ${({theme}) => theme.colors.blueGray};
	border-radius: 4px;
	transition: .25s border, .25s background-color;
	&:after {
		content: "";
		position: absolute;
		display: none;
		left: 4px;
		top: 0;
		width: 5px;
		height: 8px;
		border: solid ${({theme}) => theme.colors.white};
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;
const Text = styled.div`
	margin-left: 12px;
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	color: ${({theme}) => theme.colors.blueGray};
`;
const Input = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	&:checked ~ ${Mark} {
		border: 2px solid ${({theme}) => theme.colors.gold};
		background-color: ${({theme}) => theme.colors.gold};
	}
	&:checked ~ ${Mark}:after {
		display: block;
	}
`;
const Container = styled.label<{ checked?: boolean; isLabel?: boolean; }>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: left;
	cursor: pointer;
	font-size: 22px;
	user-select: none;
	padding: 6px ${({isLabel}) => isLabel ? 16 : 6}px;
	background-color: ${({theme, checked}) => checked ? `${theme.colors.gold}0D` : theme.colors.white};
	border-radius: 4px;
`;
//#endregion

interface CheckboxInterface {
	checked: boolean;
	value: string;
	label?: string;
	onChange(value: boolean): void;
}

const Checkbox: React.FC<CheckboxInterface> =
	({
		checked,
		value,
		label,
		onChange
	 }) => {
	return (
		<Container isLabel={label !== undefined} checked={checked}>
			<Input type="checkbox" onChange={() => onChange(!checked)} checked={checked} value={value}/>
			<Mark/>
			{label && <Text>{label}</Text>}
		</Container>
	);
};
export default Checkbox;
