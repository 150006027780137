import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";
import NavigationSink from "../../store/navigation-sink";
import {useSink} from "react-redux-sink";
import {useTranslation} from "react-i18next";
import ThemeSink from "../../store/theme-sink";

//#region styled
const Container = styled.div<{ visible?: boolean; }>`
	width: calc(100% - 48px);
	height: 72px;
	background-color: ${({theme}) => theme.colors.white};
	border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
	position: fixed;
	top: 0;
	left: 0;
	display: ${({visible}) => visible ? 'flex' : 'none'};
	justify-content: space-between;
	align-items: center;
	padding: 0 24px;
	z-index: 35;
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		display: none;
	}
`;
const Hamburger = styled.div`
	font-size: 24px;
	color: ${({theme}) => theme.colors.blueGray};
`;
const User = styled.div`
	width: 48px;
	height: 48px;
	position: relative;
	cursor: pointer;
`;
const Image = styled.div`
	border-radius: 50%;
	width: 48px;
	height: 48px;
	background-color: ${({theme}) => theme.colors.lightWhite};
`;
const Status = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 2px solid ${({theme}) => theme.colors.white};
	background-color: ${({theme}) => theme.colors.brightGreen};
	position: absolute;
	right: 0;
	bottom: 0;
`;
const Menu = styled.div<{ expanded?: boolean; }>`
	width: 244px;
	border-radius: 4px;
	position: absolute;
	right: 24px;
	top: 72px;
	display: ${({expanded}) => expanded ? 'block' : 'none'};
`;
const Section = styled.div`
	border: 1px solid ${({theme}) => theme.colors.lightGray};
	background-color: ${({theme}) => theme.colors.white};
	padding: 6px 24px;
	&:first-child{
		margin-bottom: 6px;
	}
`;
const Item = styled.div`
	padding: 6px 0;
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	cursor: pointer;
`;
//#endregion

const MobileHeader: React.FC = () => {
	const {t} = useTranslation();
	const navigation = useSink(NavigationSink, sink => [sink.userbarExpanded]);
	const theme = useSink(ThemeSink, sink => [sink.theme]);
	return (
		<Container visible={navigation.navbarVisible}>
			<Hamburger>
				<FontAwesomeIcon onClick={() => navigation.navbarExpanded = true} icon={faBars}/>
			</Hamburger>
			<User onClick={() => navigation.userbarExpanded = !navigation.userbarExpanded}>
				<Image/>
				<Status/>
			</User>
			<Menu expanded={navigation.userbarExpanded}>
				<Section>
					{theme.theme === "light" && <Item onClick={() => theme.changeTheme("dark")}>{t("darkMode")}</Item>}
					{theme.theme === "dark" && <Item onClick={() => theme.changeTheme("light")}>{t("lightMode")}</Item>}
				</Section>
				<Section>
					<Item>{t("settings")}</Item>
					<Item>{t("signOut")}</Item>
				</Section>
			</Menu>
		</Container>
	);
};

export default MobileHeader;
