import React from "react";
import styled, {createGlobalStyle} from "styled-components";
import Navbar from "./Navbar";
import "./root-styling.css";
import {useSink} from "react-redux-sink";
import NavigationSink from "../store/navigation-sink";
import MobileHeader from "../components/Headers/MobileHeader";
import FullScreenPositioner from "../components/Controls/FullScreenPositioner";
import MultiCheckboxPickerSink from "../store/multi-checkbox-picker-sink";
import MultiCheckPicker, {ColorPicker} from "../components/Pickers";
import TableEditorSink from "../store/table-editor-sink";
import TableEditor from "../components/Table/Editor";
import DialogSink from "../store/dialog-sink";
import Dialog from "../components/Dialogs";
import DateTimePicker from "../components/DateTime";
import DateTimePickerSink from "../store/date-time-picker-sink";
import ColorPickerSink from "../store/color-picker-sink";
import ThemeSink from "../store/theme-sink";

const GlobalStyle = createGlobalStyle<{ scrollbarColor: string; }>`
	* {
		::-webkit-scrollbar {
			width: 5px;
		}
	
		::-webkit-scrollbar-track {
			background-color: transparent;
		}
	
		::-webkit-scrollbar-thumb {
			background-color: ${({scrollbarColor}) => scrollbarColor};
		}
	}
	.nestable-item.is-dragging::before {
		border-width: 2px;
		border-color: ${props => props.theme.colors.gold};
		border-style: dashed;
		border-radius: 0;
		background-color: transparent;
	}
	.nestable-item, .nestable-item .nestable-list { margin: 0 !important; }
	.nestable > ol {
		margin: unset;
		padding: unset;
	}
`;

//#region styled
const ContentComponent = styled.main<{ navbarVisible?: boolean; }>`
	background-color: ${({theme}) => theme.colors.white};
	margin: unset;
	overflow-x: hidden;
	height: 100vh;
	position: relative;
	width: 100%;
	left: 0;
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		width: ${({navbarVisible}) => navbarVisible ? 'calc(100% - 289px)' : '100%'};
		position: fixed;
		top: 0;
		left: ${({navbarVisible}) => navbarVisible ? '289px' : 0};
	}
`;
//#endregion

const Layout: React.FC = ({children}) => {
	const {navbarVisible} = useSink(NavigationSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const tableEditor = useSink(TableEditorSink);
	const dialog = useSink(DialogSink);
	const dateTimePicker = useSink(DateTimePickerSink);
	const colorPicker = useSink(ColorPickerSink);
	const theme = useSink(ThemeSink);
	return (
		<>
			<GlobalStyle scrollbarColor={theme.colors.lightGray}/>
			<FullScreenPositioner background active={dialog.active} close={() => dialog.clear()}>
				{dialog.active && <Dialog/>}
			</FullScreenPositioner>
			<FullScreenPositioner active={tableEditor.active} close={() => tableEditor.clear()}>
				{tableEditor.active && <TableEditor/>}
			</FullScreenPositioner>
			<FullScreenPositioner active={multiCheckboxPicker.id !== null} close={() => multiCheckboxPicker.clear()}>
				{multiCheckboxPicker.id !== null && <MultiCheckPicker/>}
			</FullScreenPositioner>
			<FullScreenPositioner active={dateTimePicker.id !== null} close={() => dateTimePicker.clear()}>
				{dateTimePicker.id !== null && <DateTimePicker/>}
			</FullScreenPositioner>
			<FullScreenPositioner active={colorPicker.id !== null} close={() => colorPicker.clear()}>
				{colorPicker.id !== null && <ColorPicker/>}
			</FullScreenPositioner>
			<MobileHeader/>
			<Navbar/>
			<ContentComponent navbarVisible={navbarVisible}>{children}</ContentComponent>
		</>
	);
};
export default Layout;
