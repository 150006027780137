import React from "react";
import styled from "styled-components";
import ThemeSink from "../../../store/theme-sink";
import {useSink} from "react-redux-sink";
import TrackedHoursBarChartSink from "../storage/tracked-hours-bar-chart-sink";
import {ResponsiveBar} from "@nivo/bar";
import moment from "moment";

const TrackedHoursBarChartContainer = styled.div`
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "semibold")};
	display: flex;
	align-items: center;
	width: calc(100% - 72px);
	height: 350px;
	margin: 0 36px;
	cursor: pointer;
	transition: .25s color;
	&:hover {
		color: ${({theme}) => theme.colors.gold};
	}
`;

const Tooltip = styled.div`
	border-radius: 4px;
	background-color: ${({theme}) => theme.colors.white};
	padding: 6px 12px;
	color: ${({theme}) => theme.colors.pebbleBlack};
	font-weight: 400;
	box-shadow: 0 0 4px 0 ${({theme}) => theme.colors.darkGray}30;
`;

const TrackedHoursBarChart: React.FC = () => {
	const theme = useSink(ThemeSink);
	const trackedHours = useSink(TrackedHoursBarChartSink);
	return (
		<TrackedHoursBarChartContainer>
			<ResponsiveBar
				data={trackedHours.data}
				keys={['hours']}
				indexBy="date"
				margin={{top: 50, right: 25, bottom: 50, left: 0}}
				padding={0.1}
				colors={[theme.colors.gold, theme.colors.orange]}
				borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
				axisTop={null}
				axisRight={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					format: (value: number | string | Date) => `${value} h`
				}}
				axisBottom={null}
				axisLeft={null}
				label={(d: any) => `${d.value} h`}
				isInteractive={true}
				tooltip={(b) =>
					<Tooltip>
						{moment(b.data["date"] as string).format("D. M. YYYY")}: <b>{b.value} h</b>
					</Tooltip>
				}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={theme.colors.white}
				animate={false}
				theme={{
					axis: {
						ticks: {
							text: {
								fill: theme.colors.iceBlue,
							},
						},
					},
				}}
			/>
		</TrackedHoursBarChartContainer>
	);
};

export default TrackedHoursBarChart;
