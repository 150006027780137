import React, {useEffect} from "react";
import {
	faCheck,
	faCoins,
	faPen,
	faProjectDiagram, faRepeat, faStopwatch,
	faUndo, faUserFriends, faUserTie
} from "@fortawesome/pro-regular-svg-icons";

import {useSink} from "react-redux-sink";
import {FinanceItemDivider, FinanceProjectItemAdder, Pad24, Space12, Space36} from "../../../components/Creator";
import ToggleButton from "../../../components/Controls/ToggleButton";
import Table, {DeleteButton} from "../../../components/Table";
import {MaturityItem, ProjectItem, TextItem} from "../../../components/Table/Items";
import ThemeSink from "../../../store/theme-sink";
import MultiCheckboxPickerSink from "../../../store/multi-checkbox-picker-sink";
import {
	BorderButtonLoader,
	PaginationLoader, RangeControlLoader,
	TableLoader,
	ToggleLoader
} from "../../../components/Loaders";
import Pagination from "../../../components/Pagination";
import Filters from "../../../components/Filters";
import BorderButton from "../../../components/Controls/BorderButton";
import ListHeader from "../../../components/Headers/ListHeader";
import ProjectsFinanceSink, {ProjectsFinanceTableItemInterface} from "./storage/projects-finance-sink";
import Routes from "../../../routes";
import PaginationSink from "../../../store/pagination-sink";
import FilterSink from "../../../store/filter-sink";
import NavigationSink from "../../../store/navigation-sink";
import ProjectsSink from "../../Projects/storage/projects-sink";
import CustomersSink from "../../Customers/storage/customers-sink";
import UsersSink from "../../Users/storage/users-sink";
import DateRangeControl from "../../../components/Controls/DateRangeControl";
import ProjectsFinanceAddSink from "./storage/projects-finance-add-sink";
import moment from "moment";
import DateTimePickerSink from "../../../store/date-time-picker-sink";
import {toast} from "react-hot-toast";
import DialogSink from "../../../store/dialog-sink";
import Checkbox from "../../../components/Controls/Checkbox";
import HeaderButton from "../../../components/Controls/HeaderButton";
import {TimerTableItemInterface} from "../../Timer/storage/timer-sink";
import TableEditorSink from "../../../store/table-editor-sink";
import ProjectFinanceItemEditorSink from "./storage/project-finance-item-editor-sink";

const ProjectsFinanceOverview: React.FC = () => {
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const finance = useSink(ProjectsFinanceSink);
	const theme = useSink(ThemeSink);
	const pagination = useSink(PaginationSink);
	const filter = useSink(FilterSink);
	const navigation = useSink(NavigationSink);
	const projects = useSink(ProjectsSink);
	const customers = useSink(CustomersSink);
	const users = useSink(UsersSink);
	const adder = useSink(ProjectsFinanceAddSink);
	const picker = useSink(DateTimePickerSink);
	const dialog = useSink(DialogSink);
	const tableEditor = useSink(TableEditorSink);
	const editor = useSink(ProjectFinanceItemEditorSink);

	const loadTable = () => {
		if(navigation.location.pathname.includes('neuhrazene')) finance.loadTable(false).then();
		else finance.loadTable(true).then();
	}

	useEffect(() => {
		return () => {
			multiCheckboxPicker.clear();
			finance.clear();
			pagination.clear();
			filter.clear();
			users.clear();
			customers.clear();
			projects.clear();
			adder.clear();
			picker.clear();
			dialog.clear();
		};
	}, []);

	useEffect(() => {
		multiCheckboxPicker.items = users.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [users.list]);

	useEffect(() => {
		multiCheckboxPicker.items = projects.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [projects.list]);

	useEffect(() => {
		multiCheckboxPicker.items = customers.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [customers.list]);

	useEffect(() => {
		if(multiCheckboxPicker.confirmed) {
			if(multiCheckboxPicker.id === 'finance-projects-filter-users') filter.changeUsers(multiCheckboxPicker.selected);
			else if(multiCheckboxPicker.id === 'finance-projects-filter-customers') filter.changeCustomers(multiCheckboxPicker.selected);
			else if(multiCheckboxPicker.id === 'finance-projects-filter-projects') filter.changeProjects(multiCheckboxPicker.selected);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		if(picker.confirmed) {
			if(picker.id === 'finance-projects-filter-dates') filter.changeDates(picker.dates.from, picker.dates.to);
			picker.clear();
		}
	}, [picker.confirmed]);

	useEffect(() => {
		if(dialog.confirm === 'confirm') {
			if(dialog.id === 'finance-projects-table-delete') finance.delete().then();
			dialog.clear();
		}
	}, [dialog.confirm]);

	useEffect(() => {
		loadTable();
	}, [navigation.location.pathname, pagination.page, pagination.limit, filter.from, filter.to, filter.search, filter.users, filter.projects, filter.customers]);

	const showEditor = (o: ProjectsFinanceTableItemInterface) => {
		tableEditor.show('row-' + o.id, 'project-finance-item-editor');
		editor.initialize(o.id, o.description, o.project.id, o.amount);
	};

	return (
		<>
			<ListHeader
				title={'Projekty'}
				icon={faCoins}
				menu={[
					{ label: "Neuhrazené", active: navigation.location.pathname.includes('neuhrazene'), link: Routes.paths.FINANCE_PROJECTS_NOT_PAID_OVERVIEW },
					{ label: "Uhrazené", active: !navigation.location.pathname.includes('neuhrazene'), link: Routes.paths.FINANCE_PROJECTS_PAID_OVERVIEW }
				]}
			/>
			<Pad24/>
			{!adder.saving && <FinanceProjectItemAdder/>}
			<FinanceItemDivider/>
			<Filters
				left={[
					<>{!finance.tableLoading && <DateRangeControl
						id={'finance-projects-filter-dates'}
						from={filter.from}
						to={filter.to}
						rangeStart={moment().toDate()}
						rangeEnd={moment().toDate()}
						goPrev={(from, to) => {toast('Téměř hotovo', { icon: '😎' })}}
						goNext={(from, to) => {toast('Téměř hotovo', { icon: '😎' })}}
						onClick={() => {
							picker.toggle('finance-projects-filter-dates', { from: filter.from, to: filter.to });
						}}
					/> || <RangeControlLoader/>}</>,
					<Space36/>,
					<>{!finance.tableLoading && <ToggleButton
						id={'finance-projects-filter-users'}
						active={multiCheckboxPicker.id === 'finance-projects-filter-users'}
						selected={filter.users.length > 0}
						onClick={async () => {
							multiCheckboxPicker.toggle('finance-projects-filter-users');
							multiCheckboxPicker.setSelected(filter.users)
							await users.loadList();
						}}
						icon={faUserFriends}
						label={'Uživatelé'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!finance.tableLoading && <ToggleButton
						id={'finance-projects-filter-projects'}
						active={multiCheckboxPicker.id === 'finance-projects-filter-projects'}
						selected={filter.projects.length > 0}
						onClick={async () => {
							multiCheckboxPicker.toggle('finance-projects-filter-projects');
							multiCheckboxPicker.setSelected(filter.projects)
							await projects.loadList();
						}}
						icon={faProjectDiagram}
						label={'Projekty'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!finance.tableLoading && <ToggleButton
						id={'finance-projects-filter-customers'}
						active={multiCheckboxPicker.id === 'finance-projects-filter-customers'}
						selected={filter.customers.length > 0}
						onClick={async () => {
							multiCheckboxPicker.toggle('finance-projects-filter-customers');
							multiCheckboxPicker.setSelected(filter.customers)
							await customers.loadList();
						}}
						icon={faUserTie}
						label={'Zákazníci'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!finance.tableLoading && <ToggleButton
						id={'finance-projects-filter-description'}
						active={multiCheckboxPicker.id === 'finance-projects-filter-description'}
						onClick={async () => {
							multiCheckboxPicker.toggle('finance-projects-filter-description');
						}}
						icon={faPen}
						label={'Popis'}
					/> || <ToggleLoader/>}</>
				]}
				right={[
					<>{!finance.tableLoading &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'} color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			{!finance.tableLoading && finance.table.length > 0 && <Table
				sizes={["55px", "1fr", "1fr", "1fr", "1fr"]}
				additional={{
					left: <>
						<DeleteButton
							onClick={() => finance.selected.length > 0 ? dialog.init('finance-projects-table-delete', 'Varování', 'Opravdu chcete odstranit vybrané záznamy?') : null}/>
						{finance.selected.length > 0 && <>
							<Space12/>
							<HeaderButton click={() => finance.togglePay()} icon={faCheck} label={`${finance.paid ? 'Neuhrazeno' : 'Uhrazeno'} ${finance.table.filter(o => finance.selected.includes(o.id)).map(o => o.amount).reduce((a, b) => a + b, 0).toFixed(2)} Kč`}/>
						</>}
					</>
				}}
				header={[
					{
						label: <Checkbox checked={finance.selected.length === finance.table.length} value={'all'} onChange={(e) => {
							if (finance.selected.length === finance.table.length) finance.uncheckAll();
							else finance.checkAll();
						}}/>
					},
					{label: "Projekt", sortable: true},
					{label: "Popis", sortable: true},
					{label: "Splatnost", sortable: true},
					{label: "Částka", sortable: true}
				]}
				items={finance.table.map((o) => {
					return {
						id: o.id,
						children: [
							<Checkbox checked={finance.selected.includes(o.id)} value={o.id}
												onChange={(e) => finance.toggleSelected(o.id)}/>,
							<ProjectItem onClick={() => showEditor(o)} color={o.project.color} project={o.project.projectName} customer={o.project.customerName}/>,
							<TextItem onClick={() => showEditor(o)} content={o.description}/>,
							<MaturityItem onClick={() => showEditor(o)} maturityDateTime={o.maturityDateTime} expression={o.expression}/>,
							<TextItem onClick={() => showEditor(o)} content={o.amount.toFixed(2) + ' Kč'}/>
						]
					};
				})}
			/> || <TableLoader columns={4} rows={5} noData={!finance.tableLoading}/>}
			{!finance.tableLoading && finance.table.length > 0 && <Pagination/> || <PaginationLoader/>}
			<Pad24/>
		</>
	);
};

export default ProjectsFinanceOverview;
