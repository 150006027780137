import {effect, sink, SinkContainer, SinkFactory, state} from "react-redux-sink";
import {toast} from "react-hot-toast";
import Api from "../api";
import IdentitySink from "../../../store/identity-sink";

interface PasswordProps {
	oldPassword: string;
	password: string;
	passwordAgain: string;
}

type PasswordsType = "oldPassword" | "password" | "passwordAgain";

@sink("settings", SinkFactory)
export default class SettingsSink {

	constructor(factory: SinkContainer) {
		this.identity = factory.getSink(IdentitySink);
	}

	identity: IdentitySink;

	@state passwordsLoading: boolean = false;

	@state passwords: PasswordProps = { oldPassword: "", password: "", passwordAgain: "" };

	@state profileLoading: boolean = false;

	@effect
	changePasswords(name: PasswordsType, value: string) {
		this.passwords = {
			...this.passwords,
			[name]: value
		};
	}

	@effect
	async savePasswords() {
		if(this.passwords.oldPassword === '' || this.passwords.password === '') {
			toast.error('Vyplňte všechna pole');
			return;
		}
		if(this.passwords.password === this.passwords.passwordAgain) {
			this.passwordsLoading = true;
			const toastId = toast.loading('Probíhá změna hesla...');
			const result = await Api.changePassword(
				this.passwords.oldPassword,
				this.passwords.password
			);
			toast.dismiss(toastId);
			if (result) {
				toast.success('Heslo změněno');
				this.clearPasswords();
			} else toast.error('Nastala chyba!');
			this.passwordsLoading = false;
		}
		else toast.error('Nové heslo se neshoduje');
	}

	@effect
	async deletePhoto() {
		this.profileLoading = true;
		this.identity.photoLoading = true;
		const toastId = toast.loading('Probíhá mazání...');
		const result = await Api.deletePhoto();
		toast.dismiss(toastId);
		if(result) {
			toast.success('Fotka odstraněna');
		}
		else toast.error('Nastala chyba!');
		this.identity.photoLoading = false;
		this.profileLoading = false;
	}

	@effect
	async uploadPhoto(file: File) {
		this.profileLoading = true;
		this.identity.photoLoading = true;
		const toastId = toast.loading('Probíhá nahrávání...');
		const result = await Api.uploadPhoto(file);
		toast.dismiss(toastId);
		if(result) {
			toast.success('Fotka nahrána');
		}
		else toast.error('Nastala chyba!');
		this.identity.photoLoading = false;
		this.profileLoading = false;
	}

	@effect
	clearPasswords() {
		this.passwords = { oldPassword: "", password: "", passwordAgain: "" };
		this.passwordsLoading = false;
	}

	@effect
	clear() {
		this.profileLoading = false;
		this.clearPasswords();
	}

}
