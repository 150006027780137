import { createBrowserHistory, History, Location } from "history";
import { sink, state, SinkFactory, effect } from "react-redux-sink";
import Routes from "../routes";

/**
 * Redux Sink used for react-router navigation.
 *
 * @class NavigationSink
 */
@sink("navigation")
export default class NavigationSink {
	/**
	 * Browser History state.
	 *
	 * @type {History}
	 * @memberof NavigationSink
	 */
	@state history: History = createBrowserHistory();
	/**
	 * Browser Location state.
	 *
	 * @type {Location}
	 * @memberof NavigationSink
	 */
	@state location: Location = this.history.location;
	/**
	 * State of navbar
	 *
	 * @type {boolean}
	 * @memberof NavigationSink
	 */
	@state navbarExpanded: boolean = false;
	/**
	 * State of userbar
	 *
	 * @type {boolean}
	 * @memberof NavigationSink
	 */
	@state userbarExpanded: boolean = false;
	/**
	 * Visibility of navbar
	 *
	 * @type {boolean}
	 * @memberof NavigationSink
	 */
	@state navbarVisible: boolean = false;
}

/**
 * Initializes a browser history Redux store/sink
 * @see https://jiaronggu.gitbook.io/react-redux-sink/examples/navigation-sink
 * @returns {History} `History` instance to be used with Router
 */
export const createNavigationHistory = () => {
	// Set accessed URL as the current location in app
	const baseUrl = document
		.getElementsByTagName("base")[0]
		.getAttribute("href") as string;
	const history = createBrowserHistory({ basename: baseUrl });
	// Get Redux store/sink for navigation
	const navigation = SinkFactory.getSink(NavigationSink);
	// Update history based on location changes
	history.listen((location) => {
		navigation.location = location;
		navigation.navbarVisible = resolveNavbarVisibility(location);
	});
	navigation.history = history;
	navigation.location = history.location;
	navigation.navbarVisible = resolveNavbarVisibility(history.location);
	return history;
};

const pagesWithoutNavbar = [Routes.paths.SIGN_IN, Routes.paths.RESET_PASSWORD];

const resolveNavbarVisibility = (location: Location<any>): boolean => {
	return !pagesWithoutNavbar.includes(location.pathname);
}
