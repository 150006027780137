import React, {ReactElement} from "react";
import styled from "styled-components";
import ListHeader from "../../components/Headers/ListHeader";
import {faLongArrowRight, faStopwatch, faTelescope} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ReactComponent as ITeams} from "../../images/ms-teams.svg";
import {ReactComponent as IPlanner} from "../../images/ms-planner.svg";
import {ReactComponent as IExcel} from "../../images/ms-excel.svg";
import {ReactComponent as IDevOps} from "../../images/az-devops.svg";
import {ReactComponent as IStorybook} from "../../images/storybook.svg";

const MapGrid = styled.div`
	display: grid;
	margin: 0 36px 36px 36px;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 36px;
	width: calc(100% - 72px);
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (min-width: ${({theme}) => theme.breakpoints.betweenDesktopFullhd}) {
		grid-template-columns: repeat(3, 1fr);
	}
`;
const MapItemContainer = styled.div<{ background: string; }>`
	width: calc(100% - 72px);
	height: calc(100% - 72px);
	padding: 36px;
	min-height: 100px;
	border: 1px solid ${({theme}) => theme.colors.lightGray};
	background-color: ${({background}) => background}0A;
	border-radius: 4px;
	transition: .25s background-color;
	&:hover {
		background-color: ${({background}) => background}20;		
	}
`;
const MapItemPlace = styled.div`
	display: flex;
	align-items: center;
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "semibold")};
`;
const MapItemTitle = styled.div`
	color: ${({theme}) => theme.colors.darkGray};
	font: ${({theme}) => theme.fonts.Montserrat(24, "regular")};
	margin: 24px 0;
`;
const MapItemDescription = styled.div`
	margin-bottom: 24px;
	color: ${({theme}) => theme.colors.darkGray};
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	line-height: 24px;
`;
const MapItemLinkContainer = styled.div<{ color: string; }>`
	color: ${({color}) => color};
`;
const MapItemLinkText = styled.span`
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
	margin-left: 12px;
`;
const MapItemLink = styled.a`
	text-decoration: none;
`;
const TeamsIcon = styled(ITeams)`
	width: 20.5px;
	height: 19px;
	margin-right: 15.5px;
`;
const ExcelIcon = styled(IExcel)`
	width: 20.5px;
	height: 19px;
	margin-right: 15.5px;
`;
const DevOpsIcon = styled(IDevOps)`
	width: 19px;
	height: 19px;
	margin-right: 14px;
`;
const PlannerIcon = styled(IPlanner)`
	width: 17px;
	height: 19px;
	margin-right: 19px;
`;
const StorybookIcon = styled(IStorybook)`
	width: 17px;
	height: 19px;
	margin-right: 19px;
`;

interface MapItemProps {
	link: string;
	icon: ReactElement;
	place: string;
	title: string;
	description: string;
	linkText: string;
	color: string;
}

const MapItem: React.FC<MapItemProps> =
	({
		link,
		icon,
		place,
		title,
		description,
		linkText,
		color
	 }) => {
	return (
		<MapItemLink href={link} target={'_blank'} rel={'noreferrer noopener'}>
			<MapItemContainer background={color}>
				<MapItemPlace>
					{icon}{place}
				</MapItemPlace>
				<MapItemTitle>{title}</MapItemTitle>
				<MapItemDescription>{description}</MapItemDescription>
				<MapItemLinkContainer color={color}>
					<FontAwesomeIcon icon={faLongArrowRight}/>
					<MapItemLinkText>Přejít do {linkText}</MapItemLinkText>
				</MapItemLinkContainer>
			</MapItemContainer>
		</MapItemLink>
	);
};

const Map: React.FC = () => {
	return (
		<>
			<ListHeader
				title={'Rozcestník TwoDo'}
				icon={faTelescope}
			/>
			<MapGrid>
				<MapItem
					link={'http://teams.microsoft.com'}
					icon={<TeamsIcon/>}
					place={'Microsoft Teams'}
					title={'Firemní komunikace'}
					description={'Společné chaty, schůzky a účast v týmech. Skrze kanály týmů se lze dostat přes záložky do příspěvků, wiki nebo souborů.'}
					linkText={'firemní komunikace'}
					color={'#5058BE'}
				/>
				<MapItem
					link={'https://teams.microsoft.com/l/channel/19%3a7298fdc65d374ff9b9ff1813eeb143ec%40thread.tacv2/General?groupId=ffdf2b36-5405-4898-8910-6e2d16a37ba6&tenantId=04a6a094-b731-4c6d-a88a-f95cfb0fafd3'}
					icon={<TeamsIcon/>}
					place={'Microsoft Teams'}
					title={'Pravidelný stand-up'}
					description={'V týmu TwoDo - Stand-upy se opakuje každé Po, St a Pá v 10:00 krátká schůzka, kde každý vyhodnocuje aktuální a budoucí postup na projektech.'}
					linkText={'pravidelných stand-upů'}
					color={'#5058BE'}
				/>
				<MapItem
					link={'https://twodocz.sharepoint.com/:x:/s/all/EduFz-I_evZErZthANAHr84BDGPHKTY1xzLuYYSLx20YNA?e=PPAzm7'}
					icon={<ExcelIcon/>}
					place={'Microsoft Excel'}
					title={'Kalendář příjezdů'}
					description={'Návštěvy kanceláře nejsou povinné, ale vítané v rámci zdravé kultury. Kalendář příjezdů slouží k plánování návštěv kolegů v kanceláři.'}
					linkText={'kalendáře příjezdů'}
					color={'#107A40'}
				/>
				<MapItem
					link={'https://teams.microsoft.com/l/channel/19%3A6813ae79701c4d419944f962a61ea66e%40thread.skype/tab%3A%3A6c89bec9-1e98-4c0c-a199-02837da44f1c?groupId=9c9a83ce-4cff-4d30-81b2-8584cc25ab5a&tenantId=04a6a094-b731-4c6d-a88a-f95cfb0fafd3'}
					icon={<TeamsIcon/>}
					place={'Microsoft Teams'}
					title={'Obecná wiki'}
					description={'Obsahuje důležité metodiky a sdělení, která je nutno dodržet, aby byl zajištěn konzistentní a správný chod.'}
					linkText={'obecné wiki'}
					color={'#5058BE'}
				/>
				<MapItem
					link={'https://teams.microsoft.com/l/channel/19%3Ad7821cd61b8945d7a022cca497dbedb7%40thread.skype/tab%3A%3A2078e8e1-b0be-4c56-84e4-25aad8d872ea?groupId=9c9a83ce-4cff-4d30-81b2-8584cc25ab5a&tenantId=04a6a094-b731-4c6d-a88a-f95cfb0fafd3'}
					icon={<TeamsIcon/>}
					place={'Microsoft Teams'}
					title={'Designerská wiki'}
					description={'Obsahuje metodiky, zdroje a inspiraci užitečné pro tým designerů k zajištění sjednoceného postupu a výstupu.'}
					linkText={'designerské wiki'}
					color={'#5058BE'}
				/>
				<MapItem
					link={'https://teams.microsoft.com/l/channel/19%3A6c68703fb2c94950970a3e8b95ead0c3%40thread.skype/tab%3A%3Ace605e7d-0f1f-4fe6-bf58-2f9e11b39adc?groupId=9c9a83ce-4cff-4d30-81b2-8584cc25ab5a&tenantId=04a6a094-b731-4c6d-a88a-f95cfb0fafd3'}
					icon={<TeamsIcon/>}
					place={'Microsoft Teams'}
					title={'Vývojářská wiki'}
					description={'Obsahuje metodiky, zdroje a inspiraci užitečné pro tým vývojářů k zajištění sjednoceného postupu a výstupu.'}
					linkText={'vývojářské wiki'}
					color={'#5058BE'}
				/>
				<MapItem
					link={'https://tasks.office.com/two.do/cs-CZ/Home/Planner/#/plantaskboard?groupId=ffdf2b36-5405-4898-8910-6e2d16a37ba6&planId=QAhkMfqZ-UCmbHUhwQvqF5cACKD8'}
					icon={<PlannerIcon/>}
					place={'Microsoft Planner'}
					title={'Úkolovník a plánovač'}
					description={'Evidence úkolů k jednodušším a menším projektům. Úkoly v projektech běžně obsahují název, řešitele, podúkoly, termíny a stav rozpracování.'}
					linkText={'úkolovníku'}
					color={'#0F773E'}
				/>
				<MapItem
					link={'http://dev.azure.com/TwoDoCZ/'}
					icon={<DevOpsIcon/>}
					place={'Azure DevOps'}
					title={'Projektový správce'}
					description={'Vývojářský nástroj pro plánování a nasazování projektů. Mimo jiné umožňuje evidenci požadavků na změny, testování nebo ukládání zdrojového kódu.'}
					linkText={'projektového správce'}
					color={'#0078D4'}
				/>
				<MapItem
					link={'http://storybook.two.do'}
					icon={<StorybookIcon/>}
					place={'Storybook'}
					title={'Portál komponent'}
					description={'Přehled využívaných a vyvinutých komponent v izolovaném prostředí. Vývojáři ocení znovupoužitelnost, designeři inspiraci a management testování.'}
					linkText={'storybooku'}
					color={'#FF4785'}
				/>
			</MapGrid>
		</>
	);
};

export default Map;
