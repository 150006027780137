import {
	ApiCalls,
	FetchAllApiResult,
	FetchDetailApiResult,
	FetchListApiResult,
	FetchTimelineBriefTableApiResult, FetchTimelineDetailTableApiResult,
	StatusApiResult
} from ".";
import Config from "../../../../client-config.json";

const ProdApi: ApiCalls = {
	fetchAll: (active, page, limit, search, users, customers) =>
		fetch(`${Config.api}/Projects?pageNumber=${page}&pageSize=${limit}&state=${active ? 1 : 2}${search !== "" ? `&name=${encodeURIComponent(search)}` : ""}${users.map(o => `&users=${o}`).join("")}${customers.map(o => `&customers=${o}`).join("")}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchAllApiResult) => r),
	fetchList: (onlyActive, name, userId) =>
		fetch(`${Config.api}/Projects/List?onlyActive=${onlyActive ? onlyActive : false}${name ? `&name=${encodeURIComponent(name)}` : ''}${userId ? `&userId=${encodeURIComponent(userId)}` : ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchListApiResult) => r.result.list),
	fetch: (id) =>
		fetch(`${Config.api}/Projects/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchDetailApiResult) => r.result),
	save: (id, name, color, customerId, manager, users, estimatedTime, start, end,
				 isInvoiced, billingDate, hasEnd, note, asanaUrl, sharePointUrl) =>
		fetch(`${Config.api}/Projects/${id !== null ? id : ''}`, {
			method: id !== null ? 'PUT' : 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify({
				name,
				color,
				customerId,
				manager,
				users,
				estimatedTime,
				start,
				end,
				isInvoiced,
				billingDate,
				hasEnd,
				note,
				asanaUrl,
				sharePointUrl
			})
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	archive: (id: string) =>
		fetch(`${Config.api}/Projects/Archive/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	delete: (id: string) =>
		fetch(`${Config.api}/Projects/${id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	fetchTimelineBriefTable: (from, to, users) =>
		fetch(`${Config.api}/Projects/Timeline/Tables/Brief?from=${from.toISOString()}&to=${to.toISOString()}${users.map(o => `&users=${o}`).join("")}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: FetchTimelineBriefTableApiResult) => r.result.data),
	fetchTimelineDetailTable: (from, to, users, projects, customers, description) =>
		fetch(`${Config.api}/Projects/Timeline/Tables/Detail?from=${from.toISOString()}&to=${to.toISOString()}${users.map(o => `&users=${o}`).join("")}${projects.map(o => `&projects=${o}`).join("")}${customers.map(o => `&customers=${o}`).join("")}${description !== "" ? `&description=${encodeURIComponent(description)}` : ""}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: FetchTimelineDetailTableApiResult) => r.result.data),
};
export default ProdApi;
