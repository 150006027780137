import SignIn from "./pages/SignIn";
import Map from "./pages/Map";
import ProjectsOverview from "./pages/Projects";
import ProjectsRecords from "./pages/Projects/ProjectsRecords";
import CustomersOverview from "./pages/Customers";
import CustomersRecords from "./pages/Customers/CustomersRecords";
import DocumentsUsersOverview from "./pages/Documents";
import DocumentsRecords from "./pages/Documents/DocumentsRecords";
import DocumentsCustomersOverview from "./pages/Documents/DocumentsCustomersOverview";
import DocumentsProjectsOverview from "./pages/Documents/DocumentsProjectsOverview";
import DocumentsDocumentationsOverview from "./pages/Documents/DocumentsDocumentationsOverview";
import TimerOverview from "./pages/Timer";
import StockOverview from "./pages/Stock";
import StockRecords from "./pages/Stock/StockRecords";
import PropertyRecords from "./pages/Property/PropertyRecords";
import PropertyOverview from "./pages/Property";
import UsersOverview from "./pages/Users";
import UsersRecords from "./pages/Users/UsersRecords";
import RolesRecords from "./pages/Roles/RolesRecords";
import PermissionsOverview from "./pages/Roles/PermissionsOverview";
import RolesOverview from "./pages/Roles";
import ServicesOverview from "./pages/Services";
import ServicesDevicesOverview from "./pages/Services/ServicesDevicesOverview";
import ServicesRecords from "./pages/Services/ServicesRecords";
import TimerActivity from "./pages/Timer/Activity";
import TimerRecords from "./pages/Timer/TimerRecords";
import UsersFinanceOverview from "./pages/Finance/Users";
import ProjectsFinanceOverview from "./pages/Finance/Projects";
import FinanceReport from "./pages/Finance/FinanceReport";
import TimerReportBrief from "./pages/Timer/TimerReportBrief";
import TimerReportDetail from "./pages/Timer/TimerReportDetail";
import UsersStructure from "./pages/Users/UsersStructure";
import ResetPassword from "./pages/SignIn/ResetPassword";
import Settings from "./pages/Other/Settings";
import TimelineGraphs from "./pages/Projects/TimelineGraphs";
import TimelineTables from "./pages/Projects/TimelineTables";

//#region TIMER
const TIMER_OVERVIEW = "/casovac";
const TIMER_ACTIVITY = "/casovac/aktivita";
const TIMER_RECORDS = "/casovac/zaznamy";
const TIMER_REPORT_BRIEF = "/casovac/report/souhrne";
const TIMER_REPORT_DETAIL = "/casovac/report/podrobne";
//#endregion TIMER

//#region PROJECTS
const PROJECTS_ACTIVE_OVERVIEW = "/projekty/aktivni";
const PROJECTS_CLOSED_OVERVIEW = "/projekty/uzavrene";
const PROJECTS_REPORT = "/projekty/report";
const PROJECTS_RECORDS = "/projekty/zaznamy";
const PROJECTS_TIMELINE_GRAPHS = "/projekty/timeline/grafy";
const PROJECTS_TIMELINE_TABLES = "/projekty/timeline/tabulky";
//#endregion PROJECTS

//#region CUSTOMERS
const CUSTOMERS_OVERVIEW = "/zakaznici";
const CUSTOMERS_REPORT = "/zakaznici/report";
const CUSTOMERS_RECORDS = "/zakaznici/zaznamy";
//#endregion CUSTOMERS

//#region FINANCE
const FINANCE_USERS_PAID_OVERVIEW = "/finance/uzivatele/uhrazene";
const FINANCE_USERS_NOT_PAID_OVERVIEW = "/finance/uzivatele/neuhrazene";
const FINANCE_PROJECTS_PAID_OVERVIEW = "/finance/projekty/uhrazene";
const FINANCE_PROJECTS_NOT_PAID_OVERVIEW = "/finance/projekty/neuhrazene";
const FINANCE_REPORT = "/finance/report";
const FINANCE_RECORDS = "/finance/zaznamy";
//#endregion FINANCE

//#region DOCUMENTS
const DOCUMENTS_USERS_OVERVIEW = "/dokumenty/uzivatele";
const DOCUMENTS_PROJECTS_OVERVIEW = "/dokumenty/projekty";
const DOCUMENTS_CUSTOMERS_OVERVIEW = "/dokumenty/zakaznici";
const DOCUMENTS_DOCUMENTATIONS_OVERVIEW = "/dokumenty/dokumentace";
const DOCUMENTS_RECORDS = "/dokumenty/zaznamy";
//#endregion DOCUMENTS

//#region STOCK
const STOCK_OVERVIEW = "/sklad";
const STOCK_RECORDS = "/sklad/zaznamy";
//#endregion STOCK

//#region PROPERTY
const PROPERTY_OVERVIEW = "/majetek";
const PROPERTY_RECORDS = "/majetek/zaznamy";
//#endregion PROPERTY

//#region USERS
const USERS_ACTIVE_OVERVIEW = "/uzivatele/aktivni";
const USERS_NOT_ACTIVE_OVERVIEW = "/uzivatele/neaktivni";
const USERS_STRUCTURE = "/uzivatele/struktura";
const USERS_RECORDS = "/uzivatele/zaznamy";
//#endregion USERS

//#region ROLES
const ROLES_OVERVIEW = "/role";
const PERMISSIONS_OVERVIEW = "/prava";
const ROLES_RECORDS = "/role/zaznamy";
//#endregion ROLES

//#region SERVICES
const SERVICES_APPLICATIONS_OVERVIEW = "/provoz/aplikace";
const SERVICES_DEVICES_OVERVIEW = "/provoz/stroje";
const SERVICES_RECORDS = "/provoz/zaznamy";
//#endregion SERVICES

//#region OTHERS
const SIGN_IN = "/prihlaseni/";
const RESET_PASSWORD = "/reset-hesla/";
const MAP = "/rozcestnik";
const SETTINGS = "/nastaveni";
//#endregion OTHERS

export default {
	routes: [
		{path: MAP, component: Map},
		{path: SIGN_IN, component: SignIn},
		{path: SETTINGS, component: Settings},
		{path: RESET_PASSWORD, component: ResetPassword},
		{path: TIMER_OVERVIEW, component: TimerOverview},
		{path: TIMER_ACTIVITY, component: TimerActivity},
		{path: TIMER_RECORDS, component: TimerRecords},
		{path: TIMER_REPORT_BRIEF, component: TimerReportBrief},
		{path: TIMER_REPORT_DETAIL, component: TimerReportDetail},
		{path: PROJECTS_ACTIVE_OVERVIEW, component: ProjectsOverview},
		{path: PROJECTS_CLOSED_OVERVIEW, component: ProjectsOverview},
		{path: PROJECTS_REPORT, component: ProjectsRecords},
		{path: PROJECTS_RECORDS, component: ProjectsRecords},
		{path: PROJECTS_TIMELINE_GRAPHS, component: TimelineGraphs},
		{path: PROJECTS_TIMELINE_TABLES, component: TimelineTables},
		{path: CUSTOMERS_OVERVIEW, component: CustomersOverview},
		{path: CUSTOMERS_REPORT, component: CustomersRecords},
		{path: CUSTOMERS_RECORDS, component: CustomersRecords},
		{path: FINANCE_USERS_NOT_PAID_OVERVIEW, component: UsersFinanceOverview},
		{path: FINANCE_USERS_PAID_OVERVIEW, component: UsersFinanceOverview},
		{path: FINANCE_PROJECTS_NOT_PAID_OVERVIEW, component: ProjectsFinanceOverview},
		{path: FINANCE_PROJECTS_PAID_OVERVIEW, component: ProjectsFinanceOverview},
		{path: FINANCE_REPORT, component: FinanceReport},
		{path: FINANCE_RECORDS, component: CustomersRecords},
		{path: DOCUMENTS_USERS_OVERVIEW, component: DocumentsUsersOverview},
		{path: DOCUMENTS_PROJECTS_OVERVIEW, component: DocumentsProjectsOverview},
		{path: DOCUMENTS_CUSTOMERS_OVERVIEW, component: DocumentsCustomersOverview},
		{path: DOCUMENTS_DOCUMENTATIONS_OVERVIEW, component: DocumentsDocumentationsOverview},
		{path: DOCUMENTS_RECORDS, component: DocumentsRecords},
		{path: STOCK_OVERVIEW, component: StockOverview},
		{path: STOCK_RECORDS, component: StockRecords},
		{path: PROPERTY_OVERVIEW, component: PropertyOverview},
		{path: PROPERTY_RECORDS, component: PropertyRecords},
		{path: USERS_ACTIVE_OVERVIEW, component: UsersOverview},
		{path: USERS_NOT_ACTIVE_OVERVIEW, component: UsersOverview},
		{path: USERS_STRUCTURE, component: UsersStructure},
		{path: USERS_RECORDS, component: UsersRecords},
		{path: ROLES_OVERVIEW, component: RolesOverview},
		{path: PERMISSIONS_OVERVIEW, component: PermissionsOverview},
		{path: ROLES_RECORDS, component: RolesRecords},
		{path: SERVICES_APPLICATIONS_OVERVIEW, component: ServicesOverview},
		{path: SERVICES_DEVICES_OVERVIEW, component: ServicesDevicesOverview},
		{path: SERVICES_RECORDS, component: ServicesRecords},
	],
	paths: {
		TIMER_OVERVIEW,
		TIMER_ACTIVITY,
		TIMER_RECORDS,
		TIMER_REPORT_BRIEF,
		TIMER_REPORT_DETAIL,

		PROJECTS_ACTIVE_OVERVIEW,
		PROJECTS_CLOSED_OVERVIEW,
		PROJECTS_REPORT,
		PROJECTS_RECORDS,
		PROJECTS_TIMELINE_GRAPHS,
		PROJECTS_TIMELINE_TABLES,

		CUSTOMERS_OVERVIEW,
		CUSTOMERS_REPORT,
		CUSTOMERS_RECORDS,

		FINANCE_USERS_PAID_OVERVIEW,
		FINANCE_USERS_NOT_PAID_OVERVIEW,
		FINANCE_PROJECTS_PAID_OVERVIEW,
		FINANCE_PROJECTS_NOT_PAID_OVERVIEW,
		FINANCE_REPORT,
		FINANCE_RECORDS,

		DOCUMENTS_USERS_OVERVIEW,
		DOCUMENTS_PROJECTS_OVERVIEW,
		DOCUMENTS_CUSTOMERS_OVERVIEW,
		DOCUMENTS_DOCUMENTATIONS_OVERVIEW,
		DOCUMENTS_RECORDS,

		STOCK_OVERVIEW,
		STOCK_RECORDS,

		PROPERTY_OVERVIEW,
		PROPERTY_RECORDS,

		USERS_ACTIVE_OVERVIEW,
		USERS_NOT_ACTIVE_OVERVIEW,
		USERS_STRUCTURE,
		USERS_RECORDS,

		ROLES_OVERVIEW,
		PERMISSIONS_OVERVIEW,
		ROLES_RECORDS,

		SERVICES_APPLICATIONS_OVERVIEW,
		SERVICES_DEVICES_OVERVIEW,
		SERVICES_RECORDS,

		SIGN_IN,
		RESET_PASSWORD,
		MAP,
		SETTINGS
	}
};
