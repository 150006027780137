import Production from "./backend";
import {CustomersDetailInterface} from "../storage/detail-sink";
import {CustomersListItemInterface, CustomersTableItemInterface} from "../storage/customers-sink";

export interface FetchAllApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: CustomersTableItemInterface[];
		pageNumber: number;
		pageSize: number;
		totalCount: number;
	};
}

export interface FetchDetailApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: CustomersDetailInterface;
}

export interface StatusApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
}

export interface FetchListApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: CustomersListItemInterface[];
	};
}

export interface CustomerSaveInterface {
	name: string;
	phoneNumber: string;
	email: string;
	companyIdentificationNumber: string;
	streetAndNumber: string;
	cityAndPostalCode: string;
	note: string;
}

export interface ApiCalls {
	fetchAll: (page: number, limit: number, search: string, projects: string[]) => Promise<FetchAllApiResult>;
	fetchList: () => Promise<void | CustomersListItemInterface[]>;
	fetch: (id: string) => Promise<void | CustomersDetailInterface>;
	save: (id: string | null, data: CustomerSaveInterface) => Promise<boolean>;
	delete: (id: string) => Promise<boolean>;
}

export default process.env.NODE_ENV === "production" ? Production : Production;
