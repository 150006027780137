import {sink, state, effect} from "react-redux-sink";
import moment from "moment";
import {FocusedInput, START_DATE} from "@datepicker-react/hooks";
import {DEFAULT_REPEATS} from "../components/DateTime/CronGenerator";
import {tryParseInt} from "../lib/string-lib";

interface ConfigInterface {
	selectRange?: boolean;
	showPredefinedRanges?: boolean;
	blockFutureDates?: boolean;
	focusedInput?: FocusedInput;
	rightPosition?: boolean;
	showTime?: boolean;
	blockEndTime?: boolean;
	showCron?: boolean;
}

interface CronProps {
	selected: string;
	every: number;
	show: boolean;
}

interface DateProps {
	from: Date;
	to: Date;
}

@sink("date-time-picker")
export default class DateTimePickerSink {

	constructor() {
		const def = moment().set({ second: 0 }).toDate();
		this.dates = {
			from: def,
			to: def
		};
		this.timeFrom = moment(def).format("HH:mm").toString();
		this.timeTo = moment(def).format("HH:mm").toString();
	}

	@state id: string | null = null;

	@state dates: DateProps;

	@state expression: string = "";

	@state timeFrom: string;

	@state timeTo: string;

	@state selectRange: boolean = true;

	@state showPredefinedRanges: boolean = true;

	@state blockFutureDates: boolean = false;

	@state focusedInput: FocusedInput = START_DATE;

	@state confirmed: boolean = false;

	@state rightPosition: boolean = false;

	@state blockEndTime: boolean = false;

	@state showTime: boolean = false;

	@state showMonthSelector: boolean = false;

	@state showYearSelector: boolean = false;

	@state cron: CronProps = { selected: DEFAULT_REPEATS[0].id, every: 1, show: false };

	@effect
	toggleMonthSelector() {
		this.showMonthSelector = !this.showMonthSelector;
	}

	@effect
	selectMonth() {
		this.toggleMonthSelector();
	}

	@effect
	toggleYearSelector() {
		this.showYearSelector = !this.showYearSelector;
	}

	@effect
	selectYear() {
		this.toggleYearSelector();
	}

	@effect
	changeFrom(value: Date) {
		this.dates = {
			...this.dates,
			from: moment(value).set({ second: 0 }).toDate()
		};
		this.timeFrom = moment(value).format("HH:mm").toString();
	}

	@effect
	changeTo(value: Date) {
		this.dates = {
			...this.dates,
			to: moment(value).set({ second: 1 }).toDate()
		};
		this.timeTo = moment(value).format("HH:mm").toString();
	}

	@effect
	changeTimeFrom(value: string) {
		this.timeFrom = value;
	}

	@effect
	changeTimeTo(value: string) {
		this.timeTo = value;
	}

	@effect
	changeDates(from: Date, to: Date, focusedInput?: FocusedInput) {
		this.dates = {
			from: moment(from).set({ second: 0 }).toDate(),
			to: moment(to).set({ second: 1 }).toDate()
		};
		this.focusedInput = focusedInput ?? START_DATE;
	}

	@effect
	toggle(id: string, dates?: DateProps, config?: ConfigInterface) {
		if (this.id === id) this.clear();
		else {
			this.id = id;
			if (dates !== undefined) {
				this.dates = {
					from: moment(dates.from).set({ second: 0 }).toDate(),
					to: moment(dates.to).set({ second: 1 }).toDate()
				};
				this.timeFrom = moment(dates.from).format("HH:mm").toString();
				this.timeTo = moment(dates.to).format("HH:mm").toString();
			}
			else {
				const date = moment().toDate();
				this.dates = {
					from: date,
					to: date
				};
				this.timeFrom = moment(date).format("HH:mm").toString();
				this.timeTo = moment(date).format("HH:mm").toString();
			}
			if (config !== undefined) {
				if (config.selectRange !== undefined) this.selectRange = config.selectRange;
				if (config.showPredefinedRanges !== undefined) this.showPredefinedRanges = config.showPredefinedRanges;
				if (config.blockFutureDates !== undefined) this.blockFutureDates = config.blockFutureDates;
				if (config.focusedInput !== undefined) this.focusedInput = config.focusedInput;
				if (config.rightPosition !== undefined) this.rightPosition = config.rightPosition;
				if (config.showTime !== undefined) this.showTime = config.showTime;
				if (config.blockEndTime !== undefined) this.blockEndTime = config.blockEndTime;
				if (config.showCron !== undefined) this.cron = { ...this.cron, show: config.showCron };
			}
		}
	}

	@effect
	changeEvery(value: string) {
		const number = tryParseInt(value, null);
		if(number !== null) this.cron = { ...this.cron, every: number };
	}

	@effect
	changeExpression(expression: string) {
		this.expression = expression;
	}

	@effect
	changeSelected(selected: string) {
		this.cron = { ...this.cron, selected: selected };
	}

	@effect
	confirm() {
		this.confirmed = true;
	}

	@effect
	clear() {
		this.id = null;
		this.confirmed = false;
		const def = moment().set({ second: 0 }).toDate();
		this.focusedInput = START_DATE;
		this.dates = {
			from: def,
			to: def
		};
		this.timeFrom = moment(def).format("HH:mm").toString();
		this.timeTo = moment(def).format("HH:mm").toString();
		this.selectRange = true;
		this.showPredefinedRanges = true;
		this.blockFutureDates = false;
		this.rightPosition = false;
		this.blockEndTime = false;
		this.showTime = false;
		this.showMonthSelector = false;
		this.showYearSelector = false;
		this.cron = { selected: DEFAULT_REPEATS[0].id, every: 1, show: false };
	}
}
