import React, {useEffect, useState} from "react";
import {
	faPlus, faStopwatch
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import {ActivityItem, ProjectItem, TextItem, UserItem} from "../../components/Table/Items";
import {useSink} from "react-redux-sink";
import ActivitySink, {ActivityTableItemInterface} from "./storage/activity-sink";
import {HubConnectionBuilder} from "@microsoft/signalr";
import {TableLoader} from "../../components/Loaders";
import ThemeSink from "../../store/theme-sink";
import {Pad24} from "../../components/Creator";
import moment from "moment";
import {buildTotalTimeFromDuration} from "../../lib/time-lib";

const TimerActivity: React.FC = () => {
	const activity = useSink(ActivitySink);
	const theme = useSink(ThemeSink);
	const [time, setTime] = useState<Date>(moment().toDate());

	useEffect(() => {
		const timer = setTimeout(() => {
			setTime(moment().toDate());
		}, 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [time]);

	useEffect(() => {
		let connection = new HubConnectionBuilder().withUrl('/hubs/timer/activity', {
			accessTokenFactory: (): string => {
				return JSON.parse(localStorage.getItem('token')!);
			},
		}).build();

		connection.on('activity-changed', (data: any) => console.log(data));
		connection.on('activity-init', (data: ActivityTableItemInterface[]) => activity.setTable(data));
		connection.start().then();

		return () => {
			connection.stop().then();
		};
	}, []);

	const getTableHeaders = () => {
		const headers = [];
		headers.push({label: "Uživatel"});
		headers.push({label: "Projekt"});
		headers.push({label: "Popis"});
		headers.push({label: "Čas"});
		//headers.push({label: "Celkem/den"});
		return headers;
	};

	const getTableItem = (o: ActivityTableItemInterface) => {
		const children = [];
		children.push(<UserItem image={o.image} content={o.userName}/>);
		children.push(<>{o.startDateTime === null ?
			<TextItem color={theme.colors.iceBlue} content={'Žádný záznam'}/> : (o.projectName === "" ?
				<TextItem color={theme.colors.iceBlue} content={'Bez projektu'}/> :
				<ProjectItem project={o.projectName} color={o.projectColor} customer={o.customerName}/>)}</>);
		children.push(<TextItem
			color={(o.startDateTime === null || o.description === "") ? theme.colors.iceBlue : undefined}
			content={o.startDateTime === null ? 'Žádný záznam' : (o.description === "" ? 'Bez popisu' : o.description)}/>);
		children.push(<ActivityItem active={o.startDateTime !== null && o.endDateTime === null}
																content={o.startDateTime !== null ? buildTotalTimeFromDuration(moment(o.endDateTime ?? time).diff(moment(o.startDateTime))) : 'Žádný záznam'}/>);
		//children.push(<TextItem content={'-'}/>);
		return {
			background: (o.startDateTime !== null && o.endDateTime === null) ? `${theme.colors.red}0D` : 'transparent',
			children
		};
	};

	return (
		<>
			<ListHeader
				title={'Aktivita'}
				icon={faStopwatch}
			/>
			{!activity.tableLoading && <Table
				header={getTableHeaders()}
				items={activity.table.map((o) => getTableItem(o))}
			/> || <TableLoader columns={5} rows={5}/>}
			<Pad24/>
		</>
	);
};

export default TimerActivity;
