import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import {
	faChevronLeft,
	faChevronDoubleLeft,
	faChevronDoubleRight,
	faChevronRight
} from "@fortawesome/pro-light-svg-icons";
import PaginationSink from "../../store/pagination-sink";
import {useSink} from "react-redux-sink";

//#region styled
const Container = styled.div`
	width: 360px;
	margin: 52px auto 0 auto;
	position: relative;
	display: block;
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		display: flex;
		width: calc(100% - 72px);
		align-items: center;
		justify-content: space-between;
	}
`;
const PageContainer = styled.div`
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	color: ${({theme}) => theme.colors.darkGray};
`;
const Left = styled.div`
	display: block;
	align-items: center;

	& > ${PageContainer} {
		display: none;
	}

	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		display: flex;
		& > ${PageContainer} {
			display: block;
		}
	}
`;
const Right = styled.div`
	margin-top: 24px;

	& > ${PageContainer} {
		display: block;
	}

	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		margin-top: unset;
		& > ${PageContainer} {
			display: none;
		}
	}
`;
const PageInput = styled.input`
	margin-left: 20px;
	border: 1px solid ${({theme}) => theme.colors.lightGray};
	border-radius: 4px;
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(14, "bold")};
	padding: 8px 16px;
	width: 28px;
	background-color: transparent;
`;
const ArrowsContainer = styled.div`
	text-align: center;
	display: flex;
	justify-content: space-between;
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		text-align: left;
		display: block;
	}
`;
const ArrowButton = styled.button`
	width: 36px;
	height: 36px;
	border: 1px solid ${({theme}) => theme.colors.lightGray};
	border-radius: 4px;
	background-color: transparent;
	color: ${({theme}) => theme.colors.darkGray};
	cursor: pointer;
	transition: .25s color, .25s background-color;
	&:disabled {
		color: ${({theme}) => theme.colors.lightGray};
	}
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		margin-left: 12px;
	}
`;
const PageButton = styled.button<{ active?: boolean; }>`
	width: 28px;
	height: 28px;
	border-radius: 50%;
	background-color: ${({theme, active}) => active ? theme.colors.gold : 'transparent'};
	margin-left: 12px;
	border: none;
	font: ${({theme}) => theme.fonts.Montserrat(14, "bold")};
	color: ${({theme, active}) => active ? theme.colors.white : theme.colors.blueGray};
	cursor: pointer;
`;
const InfoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: right;
`;
const ItemsCount = styled.div`
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	color: ${({theme}) => theme.colors.darkGray};
`;
const TotalCount = styled.span`
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
	color: ${({theme}) => theme.colors.darkGray};
`;
const LimitInput = styled.select`
	font: ${({theme}) => theme.fonts.Montserrat(14, "bold")};
	color: ${({theme}) => theme.colors.blueGray};
	padding: 9px 16px;
	width: 68px;
	border-radius: 4px;
	appearance: none;
	margin-left: 12px;
	border: 1px solid ${({theme}) => theme.colors.lightGray};
	background-color: ${({theme}) => theme.colors.white};
`;
//#endregion

const Pagination: React.FC = () => {
	const pagination = useSink(PaginationSink);
	return (
		<Container>
			<Left>
				<Page/>
				<Arrows/>
			</Left>
			<Right>
				<Page/>
				<InfoContainer>
					<ItemsCount>
						{(pagination.page - 1) * pagination.limit + 1} - {Math.min(pagination.page * pagination.limit, pagination.total)} z <TotalCount>{pagination.total}</TotalCount>
					</ItemsCount>
					<LimitInput
						value={pagination.limit.toString()}
						onChange={({currentTarget}) => pagination.changeLimit(parseInt(currentTarget.value))}
					>
						<option value={'10'}>10</option>
						<option value={'25'}>25</option>
						<option value={'50'}>50</option>
						<option value={'75'}>75</option>
						<option value={'100'}>100</option>
					</LimitInput>
				</InfoContainer>
			</Right>
		</Container>
	);
}
const Page: React.FC = () => {
	const pagination = useSink(PaginationSink);
	return (
		<PageContainer>
			<span>Strana</span>
			<PageInput
				value={pagination.page.toString()}
			/>
		</PageContainer>
	);
}
const Arrows: React.FC = () => {
	const pagination = useSink(PaginationSink);
	return (
		<ArrowsContainer>
			<ArrowButton disabled={pagination.page <= 1} onClick={() => pagination.first()}>
				<FontAwesomeIcon icon={faChevronDoubleLeft}/>
			</ArrowButton>
			<ArrowButton disabled={pagination.page <= 1} onClick={() => pagination.previous()}>
				<FontAwesomeIcon icon={faChevronLeft}/>
			</ArrowButton>
			{/**<PageButton active>1</PageButton>
			<PageButton>2</PageButton>
			<PageButton>3</PageButton>**/}
			<ArrowButton disabled={pagination.page >= Math.ceil(pagination.total / pagination.limit)} onClick={() => pagination.next()}>
				<FontAwesomeIcon icon={faChevronRight}/>
			</ArrowButton>
			<ArrowButton disabled={pagination.page >= Math.ceil(pagination.total / pagination.limit)} onClick={() => pagination.last()}>
				<FontAwesomeIcon icon={faChevronDoubleRight}/>
			</ArrowButton>
		</ArrowsContainer>
	);
}

export default Pagination;
