import React, {ReactElement} from "react";
import {useMonth} from "@datepicker-react/hooks";
import moment from 'moment';
import 'moment/locale/cs';
import Day, {DayInterface} from "./Day";
import styled from "styled-components";
import {capitalizeFirstLetter} from "../../lib/string-lib";
import {faChevronLeft, faChevronRight} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import {useSink} from "react-redux-sink";

const DatePickerContainer = styled.div`
	padding: 12px;
	width: calc(100% - 24px);
`;

const MonthLabelContainer = styled.div`
	text-align: center;
	margin: 0 0 16px;
	display: flex;
	justify-content: space-between;
`;

const MonthLabel = styled.div`
	color: ${props => props.theme.colors.blueGray};
	font-size: 14px;
	font-weight: 500;
	font-family: 'Montserrat', sans-serif;
`;

const Clickable = styled.span`
	cursor: pointer;
	color: ${props => props.theme.colors.darkGray};
	font-weight: 600;
	transition: .3s color;

	&:hover {
		color: ${props => props.theme.colors.blueGray};
	}
`;

const MonthButton = styled.button`
	border: none;
	background-color: transparent;
	color: ${({theme}) => theme.colors.iceBlue};
	cursor: pointer;
	font-size: 18px;
	transition: .25s color;
	&:hover {
		color: ${({theme}) => theme.colors.darkGray};
	}
`;

const WeekLabelsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	justify-content: center;
`;

const WeekLabel = styled.div`
	justify-content: center;
	color: ${props => props.theme.colors.darkGray};
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	height: 43.14px;
	display: flex;
	align-items: center;
	font-weight: 500;
`;

const DaysSectionContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	justify-content: center;
`;

const Month = ({year, month, firstDayOfWeek, goToPreviousMonths, goToNextMonths}
								 : { year: any; month: any; firstDayOfWeek: any; goToPreviousMonths: any; goToNextMonths: any; }): ReactElement => {
	const {days, weekdayLabels, monthLabel} = useMonth({
		year,
		month,
		firstDayOfWeek,
		dayLabelFormat(date: Date): string {
			return moment(date).format('D');
		},
		weekdayLabelFormat(date: Date): string {
			moment.locale('cs');
			return capitalizeFirstLetter(moment(date).format('dd'));
		},
		monthLabelFormat(date: Date): string {
			moment.locale('cs');
			return capitalizeFirstLetter(moment(date).format('MMMM'));
		}
	});
	const picker = useSink(DateTimePickerSink);

	return (
		<DatePickerContainer>
			<MonthLabelContainer>
				<MonthButton type="button" onClick={goToPreviousMonths}>
					<FontAwesomeIcon icon={faChevronLeft}/>
				</MonthButton>
				<MonthLabel>
					<Clickable onClick={() => picker.toggleMonthSelector()}>{monthLabel}</Clickable>
					&nbsp;<Clickable onClick={() => picker.toggleYearSelector()}>{year}</Clickable>
				</MonthLabel>
				<MonthButton type="button" onClick={goToNextMonths}>
					<FontAwesomeIcon icon={faChevronRight}/>
				</MonthButton>
			</MonthLabelContainer>
			<WeekLabelsContainer>
				{weekdayLabels.map((dayLabel: string) => {
					return <WeekLabel key={dayLabel}>{dayLabel}</WeekLabel>;
				})}
			</WeekLabelsContainer>
			<DaysSectionContainer>
				{days.map((day) => {
					return <Day date={(day as DayInterface).date} key={(day as DayInterface).dayLabel}
											dayLabel={(day as DayInterface).dayLabel}/>;
				})}
			</DaysSectionContainer>
		</DatePickerContainer>
	);
};

export default Month;
