import React, {useEffect} from "react";
import {
	faUserFriends
} from "@fortawesome/pro-regular-svg-icons";
import {
	faBars
} from "@fortawesome/pro-light-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import {Pad24} from "../../components/Creator";
import Nestable, {Item} from "react-nestable";
import StructureSink from "./storage/structure-sink";
import {useSink} from "react-redux-sink";
import {TableLoader} from "../../components/Loaders";
import styled from "styled-components";
import {UserItem} from "../../components/Table/Items";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Row = styled.div`
	display: flex;
	align-items: center;
	color: ${({theme}) => theme.colors.blueGray};
	padding: 7px;
	border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
`;
const Icon = styled.div`
	font-size: 24px;
	margin-right: 39px;
`;
const TableContainer = styled.div`
	margin: 0 36px;
	width: calc(100% - 72px);
`;

const UsersStructure: React.FC = () => {
	const structure = useSink(StructureSink);

	useEffect(() => {
		structure.loadTable().then();
		return () => structure.clearTable();
	}, []);

	return (
		<>
			<ListHeader
				title={'Struktura'}
				icon={faUserFriends}
			/>

			{!structure.tableLoading && <TableContainer>
				<Nestable
					maxDepth={4}
					items={structure.table}
					renderItem={({item}: { item: Item }) => {
						return <Row key={item.id}>
							<Icon>
								<FontAwesomeIcon icon={faBars}/>
							</Icon>
							<UserItem content={item.name} image={item.image}/>
						</Row>;
					}}
					onChange={(str) => structure.changeStructure(str.items)}
					confirmChange={() => {
						return true;
					}}/>
			</TableContainer> || <TableLoader columns={1} rows={5}/>}
			<Pad24/>
		</>
	);
};

export default UsersStructure;
