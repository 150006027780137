import React, {useEffect} from "react";
import {
	faCheck,
	faCoins,
	faPen,
	faProjectDiagram,
	faUndo, faUserFriends, faUserTie
} from "@fortawesome/pro-regular-svg-icons";

import {useSink} from "react-redux-sink";
import UsersFinanceSink from "./storage/user-finance-sink";
import {FinanceItemDivider, FinanceUserItemAdder, Pad24, Space12, Space36} from "../../../components/Creator";
import ToggleButton from "../../../components/Controls/ToggleButton";
import Table, {DeleteButton} from "../../../components/Table";
import {ProjectItem, TextItem, TimeDurationItem, TrackedHoursItem} from "../../../components/Table/Items";
import ThemeSink from "../../../store/theme-sink";
import MultiCheckboxPickerSink from "../../../store/multi-checkbox-picker-sink";
import {
	BorderButtonLoader,
	PaginationLoader, RangeControlLoader,
	TableLoader,
	ToggleLoader
} from "../../../components/Loaders";
import Pagination from "../../../components/Pagination";
import Filters from "../../../components/Filters";
import BorderButton from "../../../components/Controls/BorderButton";
import ListHeader from "../../../components/Headers/ListHeader";
import {buildTotalTimeFromDuration} from "../../../lib/time-lib";
import PaginationSink from "../../../store/pagination-sink";
import FilterSink from "../../../store/filter-sink";
import Routes from "../../../routes";
import NavigationSink from "../../../store/navigation-sink";
import UsersSink from "../../Users/storage/users-sink";
import CustomersSink from "../../Customers/storage/customers-sink";
import ProjectsSink from "../../Projects/storage/projects-sink";
import DateRangeControl from "../../../components/Controls/DateRangeControl";
import UsersFinanceAddSink from "./storage/user-finance-add-sink";
import moment from "moment";
import DateTimePickerSink from "../../../store/date-time-picker-sink";
import {toast} from "react-hot-toast";
import Checkbox from "../../../components/Controls/Checkbox";
import {TimerStatistics} from "../../../components/Statistics";
import DialogSink from "../../../store/dialog-sink";
import HeaderButton from "../../../components/Controls/HeaderButton";

const UsersFinanceOverview: React.FC = () => {
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const finance = useSink(UsersFinanceSink);
	const theme = useSink(ThemeSink);
	const pagination = useSink(PaginationSink);
	const filter = useSink(FilterSink);
	const navigation = useSink(NavigationSink);
	const projects = useSink(ProjectsSink);
	const customers = useSink(CustomersSink);
	const users = useSink(UsersSink);
	const adder = useSink(UsersFinanceAddSink);
	const picker = useSink(DateTimePickerSink);
	const dialog = useSink(DialogSink);

	const loadTable = () => {
		if (navigation.location.pathname.includes('neuhrazene')) finance.loadTable(false).then();
		else finance.loadTable(true).then();
	}

	useEffect(() => {
		filter.changeDates(moment().startOf('month').toDate(), moment().endOf('month').toDate());
		return () => {
			multiCheckboxPicker.clear();
			finance.clear();
			pagination.clear();
			filter.clear();
			users.clear();
			customers.clear();
			projects.clear();
			adder.clear();
			picker.clear();
			dialog.clear();
		};
	}, []);

	useEffect(() => {
		multiCheckboxPicker.items = users.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [users.list]);

	useEffect(() => {
		multiCheckboxPicker.items = projects.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [projects.list]);

	useEffect(() => {
		multiCheckboxPicker.items = customers.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [customers.list]);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed) {
			if (multiCheckboxPicker.id === 'finance-users-filter-users') filter.changeUsers(multiCheckboxPicker.selected);
			else if (multiCheckboxPicker.id === 'finance-users-filter-customers') filter.changeCustomers(multiCheckboxPicker.selected);
			else if (multiCheckboxPicker.id === 'finance-users-filter-projects') filter.changeProjects(multiCheckboxPicker.selected);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		if (picker.confirmed) {
			if (picker.id === 'finance-users-filter-dates') filter.changeDates(picker.dates.from, picker.dates.to);
			picker.clear();
		}
	}, [picker.confirmed]);

	useEffect(() => {
		if (dialog.confirm === 'confirm') {
			if (dialog.id === 'finance-user-table-delete') finance.delete().then();
			dialog.clear();
		}
	}, [dialog.confirm]);

	useEffect(() => {
		finance.clearSelected()
		loadTable();
	}, [navigation.location.pathname, pagination.page, pagination.limit, filter.search, filter.users, filter.projects, filter.customers, filter.from, filter.to]);

	return (
		<>
			<ListHeader
				title={'Uživatelé'}
				icon={faCoins}
				menu={[
					{
						label: "Neuhrazené",
						active: navigation.location.pathname.includes('neuhrazene'),
						link: Routes.paths.FINANCE_USERS_NOT_PAID_OVERVIEW
					},
					{
						label: "Uhrazené",
						active: !navigation.location.pathname.includes('neuhrazene'),
						link: Routes.paths.FINANCE_USERS_PAID_OVERVIEW
					}
				]}
			/>
			<Pad24/>
			{!adder.saving && <FinanceUserItemAdder/>}
			<FinanceItemDivider/>
			<Filters
				left={[
					<>{!finance.tableLoading && <DateRangeControl
						id={'finance-users-filter-dates'}
						from={filter.from}
						to={filter.to}
						rangeStart={finance.from}
						rangeEnd={finance.to}
						goPrev={(from, to) => {
							toast('Téměř hotovo', {icon: '😎'})
						}}
						goNext={(from, to) => {
							toast('Téměř hotovo', {icon: '😎'})
						}}
						onClick={() => {
							picker.toggle('finance-users-filter-dates', {from: filter.from, to: filter.to});
						}}
					/> || <RangeControlLoader/>}</>,
					<Space36/>,
					<>{!finance.tableLoading && <ToggleButton
						id={'finance-users-filter-users'}
						selected={filter.users.length > 0}
						active={multiCheckboxPicker.id === 'finance-users-filter-users'}
						onClick={async () => {
							multiCheckboxPicker.toggle('finance-users-filter-users');
							multiCheckboxPicker.setSelected(filter.users);
							await users.loadList();
						}}
						icon={faUserFriends}
						label={'Uživatelé'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!finance.tableLoading && <ToggleButton
						id={'finance-users-filter-projects'}
						selected={filter.projects.length > 0}
						active={multiCheckboxPicker.id === 'finance-users-filter-projects'}
						onClick={async () => {
							multiCheckboxPicker.toggle('finance-users-filter-projects');
							multiCheckboxPicker.setSelected(filter.projects);
							await projects.loadList();
						}}
						icon={faProjectDiagram}
						label={'Projekty'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!finance.tableLoading && <ToggleButton
						id={'finance-users-filter-customers'}
						selected={filter.customers.length > 0}
						active={multiCheckboxPicker.id === 'finance-users-filter-customers'}
						onClick={async () => {
							multiCheckboxPicker.toggle('finance-users-filter-customers');
							multiCheckboxPicker.setSelected(filter.customers);
							await customers.loadList();
						}}
						icon={faUserTie}
						label={'Zákazníci'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!finance.tableLoading && <ToggleButton
						id={'finance-users-filter-description'}
						active={multiCheckboxPicker.id === 'finance-users-filter-description'}
						onClick={() => {
							multiCheckboxPicker.toggle('finance-users-filter-description');
						}}
						icon={faPen}
						label={'Popis'}
					/> || <ToggleLoader/>}</>
				]}
				right={[
					<>{!finance.tableLoading &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'}
												color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			{!finance.tableLoading && finance.table.length > 0 && <Table
				sizes={["55px", "1fr", "1fr", "1fr", "1fr", "1fr"]}
				additional={{
					left: <>
						<DeleteButton
							onClick={() => finance.selected.length > 0 ? dialog.init('finance-user-table-delete', 'Varování', 'Odstranit lze pouze fixní záznamy. Označené záznamy z časovače budou ponechány. Opravdu chcete odstranit vybrané záznamy?') : null}/>
						{finance.selected.length > 0 && <>
							<Space12/>
							<HeaderButton click={() => finance.togglePay()} icon={faCheck} label={`${finance.paid ? 'Neuhrazeno' : 'Uhrazeno'} ${finance.table.filter(o => finance.selected.includes(o.id)).map(o => o.amount).reduce((a, b) => a + b, 0).toFixed(2)} Kč`}/>
						</>}
					</>
				}}
				header={[
					{
						label: <Checkbox checked={finance.selected.length === finance.table.length} value={'all'} onChange={(e) => {
							if (finance.selected.length === finance.table.length) finance.uncheckAll();
							else finance.checkAll();
						}}/>
					},
					{label: "Uživatel", sortable: true},
					{label: "Projekt", sortable: true},
					{label: "Popis", sortable: true},
					{label: "Čas", sortable: true},
					{label: "Částka", sortable: true}
				]}
				items={finance.table.map((o) => {
					return {
						children: [
							<Checkbox checked={finance.selected.includes(o.id)} value={o.id}
												onChange={(e) => finance.toggleSelected(o.id)}/>,
							<TextItem content={o.user.name}/>,
							<>
								{o.project !== null ? <ProjectItem project={o.project.projectName} color={o.project.color}
																									 customer={o.project.customerName}/> :
									<TextItem color={theme.colors.iceBlue} content={'Bez projektu'}/>}
							</>,
							<TextItem color={o.description === "" ? theme.colors.iceBlue : undefined}
												content={o.description !== "" ? o.description : 'Bez popisu'}/>,
							<>
								{o.dateTime ? <TimeDurationItem from={o.dateTime.from} to={o.dateTime.to}/> : ''}
							</>,
							<TextItem content={`${o.amount.toFixed(2)} Kč`}/>
						]
					};
				})}
			/> || <TableLoader columns={4} rows={5} noData={!finance.tableLoading}/>}
			{!finance.tableLoading && finance.table.length > 0 && <Pagination/> || <PaginationLoader/>}
			<Pad24/>
		</>
	);
};

export default UsersFinanceOverview;
