import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import PaginationSink from "../../../store/pagination-sink";
import Routes from "../../../routes";
import NavigationSink from "../../../store/navigation-sink";
import {toast} from "react-hot-toast";
import FilterSink from "../../../store/filter-sink";

export interface UsersTableItemInterface {
	id: string;
	imageId?: string;
	name: string;
	positions: string[];
	employment?: string;
	email: string;
	phone: string;
}

export interface UsersListItemInterface {
	id: string;
	name: string;
	wage: number;
	hourlyLoad: number;
}

@sink("users", SinkFactory)
export default class UsersSink {

	constructor(factory: SinkContainer){
		this.pagination = factory.getSink(PaginationSink);
		this.navigation = factory.getSink(NavigationSink);
		this.filter = factory.getSink(FilterSink);
	}

	pagination: PaginationSink;

	navigation: NavigationSink;

	filter: FilterSink;

	@state active: boolean = true;

	@state tableLoading: boolean = true;

	@state table: UsersTableItemInterface[] = [];

	@state listLoading: boolean = true;

	@state list: UsersListItemInterface[] = [];

	@effect
	async loadTable(active?: boolean) {
		if(active !== undefined) this.active = active;
		this.tableLoading = true;
		const result = await Api.fetchAll(
			this.active,
			this.pagination.page,
			this.pagination.limit,
			this.filter.search,
			this.filter.roles,
			this.filter.positions,
			this.filter.employments
		);
		if(result !== undefined && result !== null && result.result !== null){
			this.table = result.result.list;
			this.pagination.changeTotal(result.result.totalCount);
		}
		else toast.error('Nastala chyba!');
		this.tableLoading = false;
	}

	@effect
	async loadList(withWage?: boolean, name?: string, onlyActive?: boolean) {
		this.listLoading = true;
		this.list = await Api.fetchList(withWage, name, onlyActive);
		this.listLoading = false;
	}

	@effect
	clearTable() {
		this.table = [];
		this.tableLoading = true;
	}

	@effect
	clearList() {
		this.list = [];
		this.listLoading = true;
	}

	@effect
	clear() {
		this.clearTable();
		this.clearList();
	}
}
