import React, {useEffect} from "react";
import {
	DetailClose,
	DetailContainer,
	DetailContent,
	DetailContentLeft,
	DetailContentRight,
	DetailHeader,
	DetailHeaderButtons,
	DetailHeaderLeft,
	DetailHeaderRight,
	DetailInfo,
	DetailInfoRowGrid,
	DetailInfoText,
	DetailProjectTitle,
	DetailTitle,
	Divider,
	ProjectUserHeader
} from "../../../components/Detail";
import BorderButton from "../../../components/Controls/BorderButton";
import HeaderButton from "../../../components/Controls/HeaderButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronLeft, faTimes } from "@fortawesome/pro-light-svg-icons";
import {useSink} from "react-redux-sink";
import DetailSink from "../storage/detail-sink";
import {BorderButtonLoader, DetailInfoLoader, DetailTitleLoader, HeaderButtonLoader} from "../../../components/Loaders";
import {faBalanceScale, faEdit, faInfo, faPhotoVideo, faTrash, faUpload} from "@fortawesome/pro-regular-svg-icons";
import {Space12, Space36} from "../../../components/Creator";
import ThemeSink from "../../../store/theme-sink";
import {useDropzone} from "react-dropzone";
import {UploadImagesContainer, UploadInfo} from "../../../components/Uploader";
import {GalleryContainer, GalleryItem} from "../../../components/Gallery";
import DialogSink from "../../../store/dialog-sink";
import CreatorSink from "../storage/creator-sink";

const Detail: React.FC = () => {
	const detail = useSink(DetailSink);
	const creator = useSink(CreatorSink);
	const dialog = useSink(DialogSink);
	const theme = useSink(ThemeSink);
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({
		disabled: detail.uploading,
		onDrop: async (acceptedFiles) => {
			if (acceptedFiles.length > 0) detail.upload(acceptedFiles).then();
		}
	});

	useEffect(() => {
		if(dialog.confirm === "confirm") {
			if(dialog.id === 'stock-delete') {
				detail.delete().then();
				dialog.clear();
			}
		}
	}, [dialog.confirm]);

	return (
		<DetailContainer show={detail.opened}>
			<DetailHeader>
				<DetailHeaderLeft>
					<DetailClose onClick={() => detail.close()}>
						<FontAwesomeIcon icon={faChevronLeft}/>
					</DetailClose>
					{!detail.loading && detail.data && <DetailTitle title={detail.data?.name}/> || <DetailTitleLoader/>}
				</DetailHeaderLeft>
				<DetailHeaderRight>
					{!detail.loading && detail.data && <HeaderButton
						icon={faEdit}
						label={'Upravit'}
						click={() => creator.open(detail.id)}
					/> || <HeaderButtonLoader/>}
					<Space12/>
					{!detail.loading && detail.data && <BorderButton
						color={theme.colors.darkGray}
						icon={faTrash}
						label={'Smazat'}
						click={() => dialog.init('stock-delete', 'Varování', 'Opravdu chcete smazat položku skladu?')}
					/> || <BorderButtonLoader/>}
					<Space36/>
					<DetailClose onClick={() => detail.close()}>
						<FontAwesomeIcon icon={faTimes}/>
					</DetailClose>
				</DetailHeaderRight>
			</DetailHeader>
			<DetailHeaderButtons>
			</DetailHeaderButtons>
			<DetailContent>
				<DetailContentLeft>
					{!detail.loading && detail.data && <DetailInfo icon={faInfo} label={'Informace'}>
						<DetailInfoRowGrid>
							<div>Umístění</div>
							<div>{detail.data.location}</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Typ</div>
							<div>{detail.data.type}</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Počet</div>
							<div>{detail.data.available}/{detail.data.total}</div>
						</DetailInfoRowGrid>
					</DetailInfo> || <DetailInfoLoader/>}
				</DetailContentLeft>
				<DetailContentRight>
					{!detail.uploading && !detail.loading && detail.data && <DetailInfo icon={faUpload} label={'Nahrát fotky'}>
						<UploadImagesContainer {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
							<UploadInfo>
								Přetáhněte soubory nebo klikněte pro výběr
							</UploadInfo>
							<input {...getInputProps()} />
						</UploadImagesContainer>
					</DetailInfo> || <DetailInfoLoader/>}
					{!detail.uploading && !detail.loading && detail.data && detail.data.photos.length > 0 && <DetailInfo icon={faPhotoVideo} label={'Fotky'}>
						<GalleryContainer>
							{detail.data.photos.map((o, i) => <GalleryItem
								key={i}
								id={o}
								remove={() => detail.deletePhoto(o)}
							/>)}
						</GalleryContainer>
					</DetailInfo> || <DetailInfoLoader/>}
				</DetailContentRight>
			</DetailContent>
		</DetailContainer>
	);
};

export default Detail;
