import TimerItemEditor from "../components/Editors/TimerItemEditor";
import ProjectFinanceItemEditor from "../components/Editors/ProjectFinanceItemEditor";


export const getEditorComponent = (id: string) => {
	switch (id) {
		case 'timer-item-editor': return <TimerItemEditor/>;
		case 'timer-report-item-editor': return <TimerItemEditor/>;
		case 'project-finance-item-editor': return <ProjectFinanceItemEditor/>;
		default: return <></>;
	}
};
