import {effect, sink, SinkContainer, SinkFactory, state} from "react-redux-sink";
import {toast} from "react-hot-toast";
import Api from "../api";
import IdentitySink from "../../../store/identity-sink";
import NavigationSink from "../../../store/navigation-sink";

@sink("reset-password", SinkFactory)
export default class ResetPasswordSink {

	constructor(factory: SinkContainer) {
		this.identity = factory.getSink(IdentitySink);
		const params = new URLSearchParams(window.location.search);
		this.token = params.has("token") ? (params.get("token") ?? "") : "";
		this.email = params.has("user") ? (params.get("user") ?? "") : "";
	}

	identity: IdentitySink;

	@state email: string = "";

	@state token: string = "";

	@state password: string = "";

	@state passwordAgain: string = "";

	@state loading: boolean = false;

	@effect
	changePassword(password: string) {
		this.password = password;
	}

	@effect
	changePasswordAgain(password: string) {
		this.passwordAgain = password;
	}

	@effect
	async submit() {
		if(this.passwordAgain === '' || this.password === '') {
			toast.error('Vyplňte všechna pole');
			return;
		}
		if(this.password === this.passwordAgain) {
			this.loading = true;
			const toastId = toast.loading('Probíhá nastavení hesla...');
			const result = await Api.resetPassword(
				this.email,
				this.password,
				this.token
			);
			toast.dismiss(toastId);
			if (result !== null && result !== undefined) {
				toast.success('Heslo nastaveno');
				this.clear();
				this.identity.clear();
				this.identity.initialize(result, false);
			} else toast.error('Nastala chyba!');
			this.loading = false;
		}
		else toast.error('Nové heslo se neshoduje');
	}

	@effect
	clear() {
		this.password = "";
		this.passwordAgain = "";
		this.loading = false;
	}

}
