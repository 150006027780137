import Production from "./backend";
import {IdentityInterface} from "../../../store/identity-sink";

export interface AuthorizeApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result?: IdentityInterface;
}

export interface ApiCalls {
	authorize: (email: string, password: string) => Promise<void | IdentityInterface | null>;
	resetPassword: (email: string, password: string, token: string) => Promise<void | IdentityInterface>;
}

export default process.env.NODE_ENV === "production" ? Production : Production;
