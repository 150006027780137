export const hasAnyPermission = (required: string[], list: string[]): boolean => {
	let result = false;

	for(let i = 0; i < required.length; i++){
		if(list.includes(required[i])) {
			result = true;
			break;
		}
	}

	return result;
};
