import {effect, sink, SinkContainer, SinkFactory, state} from "react-redux-sink";
import moment from "moment";
import {toast} from "react-hot-toast";
import ProjectsFinanceSink from "./projects-finance-sink";
import {tryParseFloat} from "../../../../lib/string-lib";
import Api from "../api";

interface ProjectFinanceItemEditorValidationProps {
	project: boolean;
	amount: boolean;
}

@sink("project-finance-item-editor", SinkFactory)
export default class ProjectFinanceItemEditorSink {

	constructor(factory: SinkContainer) {
		this.finance = factory.getSink(ProjectsFinanceSink);
	}

	finance: ProjectsFinanceSink;

	@state id: string | null = null;

	@state description: string = "";

	@state project: string | null = null;

	@state amount: string = "";

	@state saving: boolean = false;

	@state validation: ProjectFinanceItemEditorValidationProps = {
		project: true,
		amount: true
	};

	@effect
	initialize(id: string, description: string, project: string | null, amount: number) {
		this.id = id;
		this.description = description;
		this.project = project;
		this.amount = amount.toString();
	}

	@effect
	changeDescription(description: string) {
		this.description = description;
	}

	@effect
	changeAmount(amount: string) {
		this.amount = amount;
	}

	@effect
	changeProject(project: string | null) {
		this.project = project;
	}

	@effect
	async submit() {
		const valid = this.createValidation();
		this.validate(valid);
		if(ProjectFinanceItemEditorSink.isValid(valid) && this.id !== null) {
			this.saving = true;
			const toastId = toast.loading('Probíhá ukládání...');
			const result = await Api.update(this.id, this.description, parseFloat(this.amount), this.project!);
			toast.dismiss(toastId);
			if(result) {
				toast.success('Záznam upraven');
				this.clear();
				await this.finance.loadTable();
			}
			this.saving = false;
		}
		else toast.error('Formulář obsahuje nevalidní data');
	}

	private static isValid (obj: ProjectFinanceItemEditorValidationProps): boolean {
		return obj.amount && obj.project;
	}

	private createValidation(): ProjectFinanceItemEditorValidationProps {
		const amount = tryParseFloat(this.amount, null);
		let v = {
			amount: true,
			project: true
		};
		if(amount === null) v.amount = false;
		if(this.project === null) v.project = false;
		return v
	}

	@effect
	validate(obj: ProjectFinanceItemEditorValidationProps) {
		this.validation = obj;
	}

	@effect
	clear() {
		this.description = "";
		this.project = null;
		this.amount = "";
		this.saving = false;
		this.id = null;
		this.validation = {
			project: true,
			amount: true
		};
	}

}
