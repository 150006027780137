import {sink, state, effect} from "react-redux-sink";
import moment from "moment";

@sink("filter")
export default class FilterSink {

	@state search: string = "";

	@state users: string[] = [];

	@state roles: string[] = [];

	@state modules: string[] = [];

	@state permissions: string[] = [];

	@state locations: string[] = [];

	@state types: string[] = [];

	@state states: string[] = [];

	@state positions: string[] = [];

	@state employments: string[] = [];

	@state projects: string[] = [];

	@state customers: string[] = [];

	@state from: Date = moment().startOf('week').toDate();

	@state to: Date = moment().endOf('week').toDate();

	@effect
	changeSearch(search: string) {
		this.search = search;
	}

	@effect
	changeUsers(users: string[]) {
		this.users = users;
	}

	@effect
	changeRoles(roles: string[]) {
		this.roles = roles;
	}

	@effect
	changeModules(modules: string[]) {
		this.modules = modules;
	}

	@effect
	changePermissions(permissions: string[]) {
		this.permissions = permissions;
	}

	@effect
	changeLocations(locations: string[]) {
		this.locations = locations;
	}

	@effect
	changeTypes(types: string[]) {
		this.types = types;
	}

	@effect
	changeStates(states: string[]) {
		this.states = states;
	}

	@effect
	changePositions(positions: string[]) {
		this.positions = positions;
	}

	@effect
	changeEmployments(employments: string[]) {
		this.employments = employments;
	}

	@effect
	changeProjects(projects: string[]) {
		this.projects = projects;
	}

	@effect
	changeCustomers(customers: string[]) {
		this.customers = customers;
	}

	@effect
	changeFrom(from: Date) {
		this.from = from;
	}

	@effect
	changeTo(to: Date) {
		this.to = to;
	}

	@effect
	changeDates(from: Date, to: Date) {
		this.changeFrom(from);
		this.changeTo(to);
	}

	@effect
	clear() {
		this.search = "";
		this.users = [];
		this.roles = [];
		this.modules = [];
		this.permissions = [];
		this.locations = [];
		this.types = [];
		this.states = [];
		this.positions = [];
		this.employments = [];
		this.projects = [];
		this.customers = [];
		this.from = moment().startOf('week').toDate();
		this.to = moment().endOf('week').toDate();
	}

}
