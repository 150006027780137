import Production from "./backend";
import {PropertyStateInterface, PropertyTableItemInterface} from "../storage/property-sink";
import {PropertyDetailInterface} from "../storage/detail-sink";

export interface FetchAllApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: PropertyTableItemInterface[];
		pageNumber: number;
		pageSize: number;
		totalCount: number;
	};
}

export interface FetchDetailApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: PropertyDetailInterface;
}

export interface StatusApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
}

export interface FetchListApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: string[];
	};
}

export interface FetchStatesListApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: PropertyStateInterface[];
	};
}

export interface UploadApiResult {
	status: number;
	id: string;
}

export interface ApiCalls {
	fetchAll: (page: number, limit: number, search: string, locations: string[], types: string[], states: string[]) => Promise<FetchAllApiResult>;
	fetchLocationsList: () => Promise<string[]>;
	fetchTypesList: () => Promise<string[]>;
	fetchStatesList: () => Promise<PropertyStateInterface[]>;
	fetch: (id: string) => Promise<PropertyDetailInterface>;
	save: (id: string | null, name: string, location: string, type: string, age: Date, state: number, price: number, owner?: string) => Promise<boolean>;
	delete: (id: string) => Promise<boolean>;
	upload: (id: string, file: File) => Promise<UploadApiResult>;
	deletePhoto: (id: string, photo: string) => Promise<boolean>;
}

export default process.env.NODE_ENV === "production" ? Production : Production;
