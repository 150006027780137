import React, {useEffect} from "react";
import {EditorInput, Space12, Space36} from "../Creator";
import {faPen, faProjectDiagram, faStopwatch} from "@fortawesome/pro-regular-svg-icons";
import {useSink} from "react-redux-sink";
import TimerItemEditorSink from "../../pages/Timer/storage/timer-item-editor-sink";
import ToggleButton from "../Controls/ToggleButton";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import ProjectsSink from "../../pages/Projects/storage/projects-sink";
import IdentitySink from "../../store/identity-sink";
import {buildTotalTimeFromDuration} from "../../lib/time-lib";
import moment from "moment";
import ThemeSink from "../../store/theme-sink";
import TableEditorSink from "../../store/table-editor-sink";
import {hasAnyPermission} from "../../lib/application-lib";
import Permissions from "../../permissions";

const TimerItemEditor: React.FC = () => {
	const editor = useSink(TimerItemEditorSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const picker = useSink(DateTimePickerSink);
	const projects = useSink(ProjectsSink);
	const identity = useSink(IdentitySink);
	const theme = useSink(ThemeSink);
	const tableEditor = useSink(TableEditorSink);

	useEffect(() => {
		if(tableEditor.confirmed) {
			if(tableEditor.editor === 'timer-item-editor') editor.submit().then();
			else if(tableEditor.editor === 'timer-report-item-editor') editor.submit().then();
			tableEditor.clear();
		}
	}, [tableEditor.confirmed]);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed) {
			if (multiCheckboxPicker.id === 'table-editor-project') editor.changeProject(multiCheckboxPicker.selected[0] ?? null);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		if (picker.confirmed) {
			if (picker.id === 'table-editor-time') editor.changeDates(picker.dates.from, picker.dates.to);
			picker.clear();
		}
	}, [picker.confirmed]);

	return <>
		<EditorInput
			iconColor={theme.colors.gold}
			value={editor.description}
			onChange={({currentTarget}) => editor.changeDescription(currentTarget.value)}
			icon={faPen}
			size={200}
		/>
		<Space36/>
		<ToggleButton
			id={'table-editor-project'}
			icon={faProjectDiagram}
			label={'Projekt'}
			selected={editor.project !== null}
			active={multiCheckboxPicker.id === 'table-editor-project'}
			onClick={async () => {
				multiCheckboxPicker.toggle('table-editor-project', true);
				multiCheckboxPicker.setSelected(editor.project !== null ? [editor.project] : []);
				await projects.loadList(true, undefined, editor.user ?? undefined);
			}}
		/>
		<Space12/>
		<ToggleButton
			id={'table-editor-time'}
			icon={faStopwatch}
			label={editor.dates.to !== null ? buildTotalTimeFromDuration(moment(editor.dates.to).diff(moment(editor.dates.from))) : '0:00:00'}
			selected={true}
			active={picker.id === 'table-editor-time'}
			onClick={async () => {
				picker.toggle('table-editor-time', { from: editor.dates.from!, to: editor.dates.to! }, {
					showPredefinedRanges: false,
					showTime: true,
					blockFutureDates: true
				});
			}}
		/>
	</>;
};

export default TimerItemEditor;
