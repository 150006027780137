import React, {useEffect, useState} from "react";
import styled from "styled-components";
import SearchInput from "../Controls/SearchInput";
import {faCheck, faSearch} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Checkbox from "../Controls/Checkbox";
import {useSink} from "react-redux-sink";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import {CheckboxListLoader, FullWidthButtonLoader, SearchInputLoader} from "../Loaders";
import ColorPickerSink from "../../store/color-picker-sink";
import Draggable from "react-draggable";
import {DragHandle} from "../Drag";

const PickerContainer = styled.div<{ top: number; left: number; active: boolean; }>`
	width: 348px;
	border-radius: 8px;
	background-color: ${({theme}) => theme.colors.white};
	box-shadow: 0 0 4px 0 ${({theme}) => theme.colors.darkGray}30;
	position: absolute;
	top: ${({top}) => top}px;
	left: ${({left}) => left}px;
	display: ${({active}) => active ? 'block' : 'none'};
`;
const FieldContainer = styled.div`
	padding: 12px;
	width: calc(100% - 24px);
`;
const ButtonContainer = styled.div`
	padding: 12px;
	width: calc(100% - 24px);
	border-top: 1px solid ${({theme}) => theme.colors.lightGray};
`;
const Button = styled.button`
	border: none;
	background-color: ${({theme}) => theme.colors.gold};
	color: ${({theme}) => theme.colors.white};
	border-radius: 4px;
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
	width: 100%;
	padding: 12px;
	transition: .25s background-color;
	cursor: pointer;

	&:hover {
		background-color: ${({theme}) => theme.colors.orange};
	}
`;
const ButtonText = styled.span`
	margin-left: 12px;
`;
const CheckboxesContainer = styled.div`
	padding: 0 12px;
	margin: 12px 0 24px 0;
	display: grid;
	grid-row-gap: 4px;
	max-height: 150px;
	overflow-y: auto;
`;
const ColorPickerContainer = styled.div<{ active: boolean; top: number; left: number; }>`
	width: 163px;
	height: 176px;
	border-radius: 4px;
	background-color: ${({theme}) => theme.colors.white};
	box-shadow: 0 0 4px 0 ${({theme}) => theme.colors.darkGray}30;
	position: absolute;
	z-index: 1;
	top: ${({top}) => top}px;
	left: ${({left}) => left}px;
	display: block;
`;
const ColorPickerItemContainer = styled.div`
	overflow-y: auto;
	padding: 12px 16px 12px 21px;
	max-height: calc(100% - 24px);
`;
const ColorPickerItem = styled.div<{ color: string; active: boolean; }>`
	width: 100%;
	height: 24px;
	position: relative;
	border-radius: 4px;
	cursor: pointer;
	border: 2px solid ${({active, color}) => active ? color : 'transparent'};
	transition: .25s border;

	&:hover {
		border: 2px solid ${({color}) => color};
	}

	&:not(:last-child) {
		margin-bottom: 4px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 2px;
		left: 2px;
		border-radius: 4px;
		background-color: ${({color}) => color};
		width: calc(100% - 4px);
		height: calc(100% - 4px);
	}
`;

export const DEFINED_COLORS = [
	"#e53935", "#d81b60", "#8e24aa", "#3949ab",
	"#1e88e5", "#00acc1", "#00897b", "#43a047",
	"#c0ca33", "#fdd835", "#ffb200", "#fb8c00",
	"#f4511e", "#6d4c41", "#757575", "#546e7a"
];

export const ColorPicker: React.FC = () => {
	const picker = useSink(ColorPickerSink);
	const element = document.querySelector('.toggle-button-' + picker.id)?.getBoundingClientRect();

	const onResize = () => picker.clear();

	useEffect(() => {
		window.addEventListener('resize', onResize);
		return () => window.removeEventListener('resize', onResize);
	}, []);
	return (
		<Draggable
			bounds={'parent'}
			handle={'strong'}
		>
			<ColorPickerContainer
				active={element !== undefined}
				top={(element?.top ?? 0) + (element?.height ?? 0)}
				left={element?.left ?? 0}
			>
				<DragHandle isTop/>
				<ColorPickerItemContainer>
					{DEFINED_COLORS.map((o, i) =>
						<ColorPickerItem
							key={i}
							color={o}
							active={picker.color === o}
							onClick={() => picker.confirm(o)}
						/>
					)}
				</ColorPickerItemContainer>
				<DragHandle/>
			</ColorPickerContainer>
		</Draggable>
	);
};

const MultiCheckPicker: React.FC = () => {
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const element = document.querySelector('.toggle-button-' + multiCheckboxPicker.id)?.getBoundingClientRect();

	const onResize = () => multiCheckboxPicker.clear();

	useEffect(() => {
		window.addEventListener('resize', onResize);
		return () => window.removeEventListener('resize', onResize);
	}, []);

	return (
		<Draggable
			bounds={'parent'}
			handle={'strong'}
		>
			<PickerContainer
				active={element !== undefined}
				top={(element?.top ?? 0) + (element?.height ?? 0)}
				left={element?.left ?? 0}
			>
				<DragHandle isTop/>
				<FieldContainer>
					{multiCheckboxPicker.items !== null && <SearchInput
						icon={faSearch}
						placeholder={'Najít...'}
					/> || <SearchInputLoader/>}
				</FieldContainer>
				<CheckboxesContainer>
					{multiCheckboxPicker.items !== null && multiCheckboxPicker.items.length > 0 && multiCheckboxPicker.items.map((o, i) =>
						<Checkbox
							key={i}
							checked={multiCheckboxPicker.selected.includes(o.value)}
							value={o.value}
							label={o.label}
							onChange={(value) => multiCheckboxPicker.toggleCheckItem(o.value)}
						/>
					) || <CheckboxListLoader/>}
				</CheckboxesContainer>
				<ButtonContainer>
					{multiCheckboxPicker.items !== null && <Button onClick={() => multiCheckboxPicker.confirm()}>
						<FontAwesomeIcon icon={faCheck}/>
						<ButtonText>Potvrdit vybrané</ButtonText>
					</Button> || <FullWidthButtonLoader/>}
				</ButtonContainer>
				<DragHandle/>
			</PickerContainer>
		</Draggable>
	);
};

export default MultiCheckPicker;
