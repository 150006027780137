import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import FilterSink from "../../../store/filter-sink";
import {toast} from "react-hot-toast";
import PaginationSink from "../../../store/pagination-sink";

export interface ProjectsSummaryProps {
	projectId: string;
	color: string;
	projectName: string;
	customerId: string;
	customerName: string;
	recordsCount: number;
	usersCount: number;
	duration: number;
}

@sink("projects-summary", SinkFactory)
export default class ProjectsSummarySink {

	constructor(factory: SinkContainer){
		this.filter = factory.getSink(FilterSink);
		this.pagination = factory.getSink(PaginationSink);
	}

	filter: FilterSink;

	pagination: PaginationSink;

	@state listLoading: boolean = true;

	@state list: ProjectsSummaryProps[] = [];

	@effect
	async loadTable() {
		this.listLoading = true;
		const result = await Api.fetchProjectsSummary(
			this.filter.from,
			this.filter.to,
			this.filter.users,
			this.filter.projects,
			this.filter.customers,
			this.filter.search
		);
		if(result !== undefined && result !== null && result.result !== null){
			this.list = result.result.list;
			this.pagination.changeTotal(result.result.totalCount);
		}
		else toast.error('Nastala chyba!');
		this.listLoading = false;
	}

	@effect
	clearTable() {
		this.list = [];
		this.listLoading = true;
	}
}
