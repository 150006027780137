import Production from "./backend";
import {StockTableItemInterface} from "../storage/stock-sink";
import {StockDetailInterface} from "../storage/detail-sink";

export interface FetchAllApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: StockTableItemInterface[];
		pageNumber: number;
		pageSize: number;
		totalCount: number;
	};
}

export interface FetchDetailApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: StockDetailInterface;
}

export interface StatusApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
}

export interface FetchListApiResult {
	responseType: number;
	isSuccess: boolean;
	errors: string[];
	result: {
		list: string[];
	};
}

export interface UploadApiResult {
	status: number;
	id: string;
}

export interface ApiCalls {
	fetchAll: (page: number, limit: number, search: string, locations: string[], types: string[]) => Promise<FetchAllApiResult>;
	fetchLocationsList: () => Promise<string[]>;
	fetchTypesList: () => Promise<string[]>;
	fetch: (id: string) => Promise<StockDetailInterface>;
	save: (id: string | null, name: string, location: string, type: string, available: number, total: number) => Promise<boolean>;
	delete: (id: string) => Promise<boolean>;
	upload: (id: string, file: File) => Promise<UploadApiResult>;
	deletePhoto: (id: string, photo: string) => Promise<boolean>;
}

export default process.env.NODE_ENV === "production" ? Production : Production;
