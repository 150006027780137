import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import DetailSink from "./detail-sink";
import moment from "moment";
import Api from "../api";
import UsersSink from "./users-sink";
import {tryParseInt} from "../../../lib/string-lib";
import {toast} from "react-hot-toast";

interface UserItemValidationProps {
	name: boolean;
	email: boolean;
	hourlyWage: boolean;
	positions: boolean;
	roles: boolean;
	employment: boolean;
}

@sink("users-creator", SinkFactory)
export default class CreatorSink {

	constructor(factory: SinkContainer){
		this.users = factory.getSink(UsersSink);
		this.detail = factory.getSink(DetailSink);
	}

	users: UsersSink;

	detail: DetailSink;

	@state opened: boolean = false;

	@state loading: boolean = true;

	@state id: string | null = null;

	@state name: string = "";

	@state phone: string = "";

	@state email: string = "";

	@state address: string = "";

	@state bankAccount: string = "";

	@state identificationNumber: string = "";

	@state birthDate: Date = moment().toDate();

	@state startDate: Date = moment().toDate();

	@state hourlyWage: string = "";

	@state availability: string = "";

	@state hourlyLoad: string = "";

	@state leaderId: string | null = null;

	@state positions: string[] = [];

	@state roles: string[] = [];

	@state employment: string | null = null;

	@state note: string = "";

	@state asanaUrl: string = "";

	@state sharePointUrl: string = "";

	@state validation: UserItemValidationProps = {
		name: true,
		email: true,
		hourlyWage: true,
		positions: true,
		roles: true,
		employment: true
	};

	@effect
	changeName(name: string) {
		this.name = name;
	}

	@effect
	changePhone(phone: string) {
		this.phone = phone;
	}

	@effect
	changeEmail(email: string) {
		this.email = email;
	}

	@effect
	changeAddress(address: string) {
		this.address = address;
	}

	@effect
	changeBankAccount(bankAccount: string) {
		this.bankAccount = bankAccount;
	}

	@effect
	changeIdentificationNumber(identificationNumber: string) {
		this.identificationNumber = identificationNumber;
	}

	@effect
	changeBirthDate(birthDate: Date) {
		this.birthDate = birthDate;
	}

	@effect
	changeStartDate(startDate: Date) {
		this.startDate = startDate;
	}

	@effect
	changeHourlyWage(hourlyWage: string) {
		this.hourlyWage = hourlyWage;
	}

	@effect
	changeHourlyLoad(hourlyLoad: string) {
		this.hourlyLoad = hourlyLoad;
	}

	@effect
	changeAvailability(availability: string) {
		this.availability = availability;
	}

	@effect
	changeLeader(leaderId: string | null) {
		this.leaderId = leaderId;
	}

	@effect
	changePositions(positions: string[]) {
		this.positions = positions;
	}

	@effect
	changeRoles(roles: string[]) {
		this.roles = roles;
	}

	@effect
	changeEmployment(employment: string | null) {
		this.employment = employment;
	}

	@effect
	changeNote(note: string) {
		this.note = note;
	}

	@effect
	changeAsanaUrl(url: string) {
		this.asanaUrl = url;
	}

	@effect
	changeSharePointUrl(url: string) {
		this.sharePointUrl = url;
	}

	@effect
	open(id: string | null) {
		this.opened = true;
		this.id = id;
	}

	@effect
	async load(id: string) {
		this.loading = true;
		const data = await Api.fetch(id);
		if(data !== undefined) {
			this.name = data.info.name;
			this.phone = data.info.phone;
			this.email = data.info.email;
			this.address = data.info.address;
			this.bankAccount = data.info.bankAccount;
			this.identificationNumber = data.info.identificationNumber;
			this.birthDate = data.info.birthDate;
			this.startDate = data.info.startDate;
			this.hourlyWage = data.info.hourlyWages[0].wage.toString();
			this.availability = data.info.availability;
			this.hourlyLoad = data.info.hourlyLoad;
			this.leaderId = data.info.senior?.id ?? null;
			this.positions = data.info.positions.map(o => o.id.toString());
			this.roles = data.info.roles.map(o => o.id);
			this.employment = data.info.employment.id.toString();
			this.note = data.info.note;
			this.asanaUrl = data.info.asanaUrl;
			this.sharePointUrl = data.info.sharePointUrl;
			this.loading = false;
		}
	}

	@trigger("users-creator/id", { formatter: (id) => id })
	async idTrigger(id: string | null) {
		if(id !== null) await this.load(id as string);
		else this.loading = false;
	}

	@effect
	close() {
		this.clear();
	}

	@effect clear() {
		this.opened = false;
		this.loading = true;
		this.id = null;
		this.name = "";
		this.phone = "";
		this.email = "";
		this.address = "";
		this.bankAccount = "";
		this.identificationNumber = "";
		this.birthDate = moment().toDate();
		this.startDate = moment().toDate();
		this.hourlyWage = "";
		this.availability = "";
		this.hourlyLoad = "";
		this.leaderId = null;
		this.positions = [];
		this.roles = [];
		this.employment = null;
		this.note = "";
		this.asanaUrl = "";
		this.sharePointUrl = "";
		this.validation = {
			name: true,
			email: true,
			hourlyWage: true,
			positions: true,
			roles: true,
			employment: true
		};
	}

	private static isValid (obj: UserItemValidationProps): boolean {
		return obj.name && obj.email && obj.hourlyWage && obj.positions && obj.roles && obj.employment;
	}

	private createValidation(): UserItemValidationProps {
		const wage = tryParseInt(this.hourlyWage, null);
		let v = {
			name: true,
			email: true,
			hourlyWage: true,
			positions: true,
			roles: true,
			employment: true
		};
		if(wage === null) v.hourlyWage = false;
		if(this.email === "") v.email = false;
		if(this.name === "") v.name = false;
		if(this.positions.length === 0) v.positions = false;
		if(this.roles.length === 0) v.roles = false;
		if(this.employment === null) v.employment = false;
		return v;
	}

	@effect
	validate(obj: UserItemValidationProps) {
		this.validation = obj;
	}

	@effect
	async submit() {
		const valid = this.createValidation();
		this.validate(valid);
		if(CreatorSink.isValid(valid)) {
			this.loading = true;
			const toastId = toast.loading('Probíhá ukládání...');
			const result = await Api.save(
				this.id,
				this.name,
				this.email,
				this.phone,
				this.address,
				this.birthDate,
				this.startDate,
				parseInt(this.hourlyWage),
				this.positions.map(o => parseInt(o)),
				this.roles,
				parseInt(this.employment as string),
				this.hourlyLoad,
				this.availability,
				this.note,
				this.identificationNumber,
				this.bankAccount,
				this.asanaUrl,
				this.sharePointUrl,
				this.leaderId ?? undefined
			);
			toast.dismiss(toastId);
			if(result) {
				toast.success('Uloženo');
				this.clear();
				this.detail.clear();
				await this.users.loadTable();
			}
			this.loading = false;
		}
		else toast.error('Formulář obsahuje nevalidní data');
	}
}
