import React, {DragEventHandler, SyntheticEvent, useEffect} from "react";
import styled from "styled-components";
import {useSink} from "react-redux-sink";
import TableEditorSink from "../../store/table-editor-sink";
import {faCheck, faPen, faSearch, faUserFriends} from "@fortawesome/pro-regular-svg-icons";
import CircleButton from "../Controls/CircleButton";
import {getEditorComponent} from "../../lib/editor-lib";
import Draggable from 'react-draggable';
import { DragHandle } from "../Drag";

//#region styled
const TableEditorContainer = styled.div<{
	top: number;
	left: number;
	width: number;
	height: number;
}>`
	position: absolute;
	width: calc(${({width}) => width}px - 24px);
	top: calc(${({top}) => top}px - ${({height}) => height}px);
	left: ${({left}) => left}px;
	background-color: ${({theme}) => theme.colors.lightWhite};
	border-radius: 8px;
	padding: 16px 12px;
	box-shadow: 0 0 4px 0 ${({theme}) => theme.colors.darkGray}30;
	display: flex;
	justify-content: space-between;
`;
const Inputs = styled.div`
	display: flex;
	align-items: center;
`;
const ConfirmButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
//#endregion

const TableEditor: React.FC = () => {
	const tableEditor = useSink(TableEditorSink);
	const element = document.querySelector('.table-' + tableEditor.id)?.getBoundingClientRect();

	const onResize = () => tableEditor.clear();

	useEffect(() => {
		window.addEventListener('resize', onResize);
		return () => window.removeEventListener('resize', onResize);
	}, []);

	return (
		<Draggable
			bounds={'parent'}
			handle={'strong'}
		>
			<TableEditorContainer
				top={(tableEditor.defaultDimensions?.top ?? (element?.top ?? 0)) + (tableEditor.defaultDimensions?.height ?? (element?.height ?? 0))}
				left={tableEditor.defaultDimensions?.left ?? (element?.left ?? 0)}
				width={tableEditor.defaultDimensions?.width ?? (element?.width ?? 0)}
				height={tableEditor.defaultDimensions?.height ?? (element?.height ?? 0)}
			>
				<DragHandle isTop/>
				<Inputs>{tableEditor.editor !== null ? getEditorComponent(tableEditor.editor) : ''}</Inputs>
				<ConfirmButton>
					<CircleButton icon={faCheck} onClick={() => tableEditor.confirm()}/>
				</ConfirmButton>
				<DragHandle/>
			</TableEditorContainer>
		</Draggable>
	);
};

export default TableEditor;
