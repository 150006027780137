import React, {useEffect} from "react";
import {
	DetailClose,
	DetailContainer,
	DetailContent,
	DetailContentLeft,
	DetailContentRight,
	DetailHeader,
	DetailHeaderButtons,
	DetailHeaderLeft,
	DetailHeaderRight,
	DetailInfo,
	DetailInfoRowGrid,
	DetailInfoSmallGray,
	DetailProjectTitle,
	DetailTitle,
	ProjectUserHeader
} from "../../../components/Detail";
import BorderButton from "../../../components/Controls/BorderButton";
import HeaderButton from "../../../components/Controls/HeaderButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faTimes} from "@fortawesome/pro-light-svg-icons";
import {useSink} from "react-redux-sink";
import DetailSink from "../storage/detail-sink";
import {BorderButtonLoader, DetailInfoLoader, DetailTitleLoader, HeaderButtonLoader} from "../../../components/Loaders";
import {
	faBoxCheck, faCalendarAlt, faCloud, faCoins,
	faEdit,
	faStickyNote, faStopwatch, faTasks,
	faTrash,
	faUserFriends,
	faUserTie
} from "@fortawesome/pro-regular-svg-icons";
import {Space12, Space36} from "../../../components/Creator";
import ThemeSink from "../../../store/theme-sink";
import Permission from "../../../components/Permissions";
import Permissions from "../../../permissions";
import {toast} from "react-hot-toast";
import CreatorSink from "../storage/creator-sink";
import DialogSink from "../../../store/dialog-sink";
import moment from "moment";
import {convertToPriceFormat} from "../../../lib/string-lib";
import {TextItem} from "../../../components/Table/Items";
import {calculateRemainingProjectTime} from "../../../lib/time-lib";

const Detail: React.FC = () => {
	const detail = useSink(DetailSink);
	const creator = useSink(CreatorSink);
	const dialog = useSink(DialogSink);
	const theme = useSink(ThemeSink);

	const resolveEndColor = (hasEnd: boolean, end?: Date, billingDate?: Date) => {
		if (!hasEnd) return theme.colors.iceBlue;
		if (hasEnd && !billingDate) {
			const diff = moment(end).diff(moment());
			if (diff < 0) return theme.colors.red;
			else if (diff > 0) return theme.colors.brightGreen;
			else return theme.colors.darkGray;
		}
		const diff = moment(end).diff(moment(billingDate as Date), 'days');
		if (diff < 0) return theme.colors.red;
		else if (diff > 0) return theme.colors.brightGreen;
		else return theme.colors.darkGray;
	};

	useEffect(() => {
		if (dialog.confirm === 'confirm' && dialog.id !== null) {
			if (dialog.id === 'project-archive') detail.toggleArchive().then();
			else if (dialog.id === 'project-delete') detail.delete().then();
		}
	}, [dialog.confirm]);

	return (
		<DetailContainer show={detail.opened}>
			<DetailHeader>
				<DetailHeaderLeft>
					<DetailClose onClick={() => detail.close()}>
						<FontAwesomeIcon icon={faChevronLeft}/>
					</DetailClose>
					{!detail.loading && detail.data && <DetailProjectTitle color={detail.data.color} name={detail.data.name}/> ||
					<DetailTitleLoader/>}
				</DetailHeaderLeft>
				<DetailHeaderRight>
					<Permission permissions={[Permissions.PROJECTS_CREATE]}>
						{!detail.loading && detail.data && <HeaderButton
							icon={faEdit}
							label={'Upravit'}
							click={() => creator.open(detail.id)}
						/> || <HeaderButtonLoader/>}
					</Permission>
					<Permission permissions={[Permissions.PROJECTS_ARCHIVE]}>
						<Space12/>
						{!detail.loading && detail.data && <BorderButton
							color={theme.colors.darkGray}
							icon={faBoxCheck}
							label={detail.data?.archived ? 'Aktivovat' : 'Archivovat'}
							click={() => dialog.init('project-archive', 'Varování', detail.data?.archived ? 'Opravdu chcete aktivovat projekt?' : 'Před uzavřením zkontrolujte veškeré náležitosti spojené s tímto projektem. Opravdu chcete archivovat projekt?')}
						/> || <BorderButtonLoader/>}
					</Permission>
					<Permission permissions={[Permissions.PROJECTS_DELETE]}>
						<Space12/>
						{!detail.loading && detail.data && <BorderButton
							color={theme.colors.darkGray}
							icon={faTrash}
							label={'Smazat'}
							click={() => dialog.init('project-delete', 'Varování', 'Opravdu chcete smazat projekt?')}
						/> || <BorderButtonLoader/>}
					</Permission>
					<Permission permissions={[Permissions.PROJECTS_CREATE, Permissions.PROJECTS_DELETE]}>
						<Space36/>
					</Permission>
					<DetailClose onClick={() => detail.close()}>
						<FontAwesomeIcon icon={faTimes}/>
					</DetailClose>
				</DetailHeaderRight>
			</DetailHeader>
			<DetailHeaderButtons>
				{!detail.loading && detail.data &&
				<BorderButton
					color={theme.colors.orange}
					label={'Zákazník'}
					icon={faUserTie}
					click={() => toast('Pracuje se na tom', {icon: '😎'})}
				/> || <BorderButtonLoader/>}
				<Space12/>
				{!detail.loading && detail.data &&
				<BorderButton
					color={theme.colors.orange}
					label={'0'}
					icon={faStopwatch}
					click={() => toast('Pracuje se na tom', {icon: '😎'})}
				/> || <BorderButtonLoader/>}
				<Space12/>
				{!detail.loading && detail.data &&
				<BorderButton
					color={theme.colors.orange}
					label={'Finance'}
					icon={faCoins}
					click={() => toast('Pracuje se na tom', {icon: '😎'})}
				/> || <BorderButtonLoader/>}
				<Space12/>
				{!detail.loading && detail.data &&
				<BorderButton
					color={theme.colors.gold}
					label={'SharePoint'}
					icon={faCloud}
					click={() => toast('Pracuje se na tom', {icon: '😎'})}
				/> || <BorderButtonLoader/>}
				<Space12/>
				{!detail.loading && detail.data &&
				<BorderButton
					color={theme.colors.gold}
					label={'Asana'}
					icon={faTasks}
					click={() => toast('Pracuje se na tom', {icon: '😎'})}
				/> || <BorderButtonLoader/>}
				<Space12/>
			</DetailHeaderButtons>
			<DetailContent>
				<DetailContentLeft>
					{!detail.loading && detail.data && <DetailInfo icon={faUserFriends} label={'Uživatelé'}>
						<ProjectUserHeader manager title={detail.data.manager.name}
															 positions={detail.data.manager.positions.map(o => o.name)}/>
						<DetailInfoRowGrid>
							<div>Hodinovka</div>
							<div>{detail.data.manager.hourlyWage.toString()} Kč/h</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Hodin</div>
							<div>{detail.data.manager.trackedHours.toString()}</div>
						</DetailInfoRowGrid>
						{detail.data.users.map((o) => <>
							<ProjectUserHeader title={o.name} positions={o.positions.map(o => o.name)}/>
							<DetailInfoRowGrid>
								<div>Hodinovka</div>
								<div>{o.hourlyWage.toString()} Kč/h</div>
							</DetailInfoRowGrid>
							<DetailInfoRowGrid>
								<div>Hodin</div>
								<div>{o.trackedHours.toString()}</div>
							</DetailInfoRowGrid>
						</>)}
					</DetailInfo> || <DetailInfoLoader/>}
				</DetailContentLeft>
				<DetailContentRight>
					{!detail.loading && detail.data && <DetailInfo icon={faCalendarAlt} label={'Termíny'}>
						<DetailInfoRowGrid>
							<div>Začátek</div>
							<div>{moment(detail.data.start).format('D.M.YYYY')}</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Konec</div>
							<div>
								<TextItem
									color={!detail.data.hasEnd ? theme.colors.iceBlue : undefined}
									content={detail.data.end ? moment(detail.data.end).format('D.M.YYYY') : 'Bez konce'}
								/>
							</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Zbývá</div>
							<div>
								<TextItem
									color={resolveEndColor(detail.data.hasEnd, detail.data.end, detail.data.billingDate)}
									content={detail.data.hasEnd && detail.data.end ? `${calculateRemainingProjectTime(detail.data.hasEnd, detail.data.end, detail.data.billingDate)} dní` : 'Projekt nemá konec'}
								/>
							</div>
						</DetailInfoRowGrid>
						<DetailInfoRowGrid>
							<div>Hodin celkem</div>
							<div>
								{detail.data.totalTrackedHours}
								<DetailInfoSmallGray>(?% z odhadu {convertToPriceFormat(detail.data.estimatedTime)} h ~ x
									MD)</DetailInfoSmallGray>
							</div>
						</DetailInfoRowGrid>
					</DetailInfo> || <DetailInfoLoader/>}
					{!detail.loading && detail.data && <DetailInfo icon={faStickyNote} label={'Poznámka'}>
						{detail.data.note}
					</DetailInfo> || <DetailInfoLoader/>}
				</DetailContentRight>
			</DetailContent>
		</DetailContainer>
	);
};

export default Detail;
