import React, {ReactElement} from "react";
import styled from "styled-components";
import {
	faSort
} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BorderButton from "../Controls/BorderButton";
import ThemeSink from "../../store/theme-sink";
import {useSink} from "react-redux-sink";
import {faTrash} from "@fortawesome/pro-regular-svg-icons";

//#region styled
const Container = styled.div`
	width: calc(100% - 72px);
	padding: 24px 0 0 0;
	margin: 0 36px;
	border-top: 2px solid ${({theme}) => theme.colors.lightGray};
`;
export const Row = styled.div<{ columns: number; sizes?: string[]; background?: string; onHover?: string; }>`
	width: 100%;
	padding: 14px 0;
	display: block;
	background-color: ${({background}) => background ? background : 'transparent'};
	@media (max-width: ${({theme}) => theme.breakpoints.desktop}) {
		-webkit-box-shadow: 0 0 4px 0 ${({theme}) => theme.colors.pebbleBlack}0D;
		-moz-box-shadow: 0 0 4px 0 ${({theme}) => theme.colors.pebbleBlack}0D;
		box-shadow: 0 0 4px 0 ${({theme}) => theme.colors.pebbleBlack}0D;
		border-radius: 4px;
		padding: 12px 24px;
		width: calc(100% - 48px);
		&:not(:last-child) {
			margin-bottom: 12px;
		}
	}
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		display: grid;
		grid-template-columns:${({columns, sizes}) => {
			let size = "";
			for (let i = 0; i < columns; i++) {
				if (sizes && sizes[i]) size += ` ${sizes[i]}`;
				else size += " 1fr";
			}
			return size;
		}};
		min-height: 0;
		grid-column-gap: 12px;
		min-width: 0;
		border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
		& > *:first-child {
			padding-left: 12px;
		}

		& > *:last-child {
			padding-right: 12px;
		}

		&:hover {
			background-color: ${({onHover}) => onHover};
		}
	}
`;
const Header = styled(Row)`
	padding: 0;
	display: none;
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		display: grid;
	}
`;
const HeaderItemContainer = styled.div`
	display: flex;
	align-items: center;
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
	color: ${({theme}) => theme.colors.darkGray};
	padding-bottom: 12px;
`;
const HeaderItemTitle = styled.span`
	margin-right: 12px;
`;
const Cell = styled.div`
	display: flex;
	align-items: center;
`;

//#endregion

interface HeaderInterface {
	label: string | ReactElement;
	sortable?: boolean;
}

interface ItemInterface {
	id?: string;
	onHover?: string;
	background?: string;
	children: ReactElement[] | string[];
}

interface TableInterface {
	additional?: { left?: ReactElement; right?: ReactElement };
	header: HeaderInterface[];
	items: ItemInterface[];
	sizes?: string[];
}

const AdditionalContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
`;
const AdditionalBlock = styled.div`
	display: flex;
	align-items: flex-end;
`;
const DeleteButtonContainer = styled.div`
`;
const DeleteButtonLabel = styled.div`
	color: ${({theme}) => theme.colors.iceBlue};
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
	margin-bottom: 12px;
`;

export const DeleteButton: React.FC<{
	onClick(): void;
}> = ({ onClick }) => {
	const theme = useSink(ThemeSink);
	return (
		<DeleteButtonContainer>
			<DeleteButtonLabel>Vybrané položky</DeleteButtonLabel>
			<BorderButton color={theme.colors.darkGray} label={'Smazat'} icon={faTrash} click={onClick}/>
		</DeleteButtonContainer>
	);
};

const Table: React.FC<TableInterface> =
	({
		 additional,
		 header,
		 items,
		 sizes
	 }) => {
		return (
			<Container>
				{additional && <AdditionalContainer>
					<AdditionalBlock>{additional.left}</AdditionalBlock>
					<AdditionalBlock>{additional.right}</AdditionalBlock>
				</AdditionalContainer>}
				<Header sizes={sizes} columns={header.length}>
					{header.map((o, i) => <HeaderItem
						key={i}
						title={o.label}
						sortable={o.sortable}
					/>)}
				</Header>
				{items.map((o, i) => <Row className={'table-row-' + (o.id ?? i)} key={i} sizes={sizes} columns={header.length}
																	onHover={o.onHover ?? (o.background ?? 'transparent')} background={o.background ?? 'transparent'}>
					{header.map((e, k) => <Cell key={k}>
						{o.children[k]}
					</Cell>)}
				</Row>)}
			</Container>
		);
	};
const HeaderItem: React.FC<{
	title: string | ReactElement;
	sortable?: boolean;
}> = ({
				title,
				sortable = false
			}) => {
	return (
		<HeaderItemContainer>
			<HeaderItemTitle>{title}</HeaderItemTitle>
			{sortable && <FontAwesomeIcon icon={faSort}/>}
		</HeaderItemContainer>
	);
};

export default Table;
