import React, {useEffect} from "react";
import {
	CreatorClose, CreatorContainer, CreatorContent, CreatorData,
	CreatorHeader,
	CreatorHeaderLeft,
	CreatorHeaderRight, CreatorTitleInputContainer, Space36
} from "../../../components/Creator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-light-svg-icons";
import HeaderButton from "../../../components/Controls/HeaderButton";
import {
	faTimes,
	faSave,
	faBalanceScale
} from "@fortawesome/pro-regular-svg-icons";
import {useSink} from "react-redux-sink";
import CreatorSink from "../storage/creator-sink";
import {DetailInfoLoader, DetailTitleLoader, HeaderButtonLoader} from "../../../components/Loaders";
import PermissionsSink from "../storage/permissions-sink";
import {DetailInfoRowGrid, Divider} from "../../../components/Detail";
import Checkbox from "../../../components/Controls/Checkbox";
import {toast} from "react-hot-toast";
import RolesSink from "../storage/roles-sink";

const Creator: React.FC = () => {
	const creator = useSink(CreatorSink);
	const permissions = useSink(PermissionsSink);

	useEffect(() => {
		permissions.loadList().then();
	}, []);

	return (
		<CreatorContainer show={creator.opened}>
			<CreatorHeader>
				<CreatorHeaderLeft>
					<CreatorClose onClick={() => creator.close()}>
						<FontAwesomeIcon icon={faChevronLeft}/>
					</CreatorClose>
					{!creator.loading && <CreatorTitleInputContainer
						value={creator.name}
						onChange={({currentTarget}) => creator.name = currentTarget.value}
					/> || <DetailTitleLoader/>}
				</CreatorHeaderLeft>
				<CreatorHeaderRight>
					{!creator.loading && <HeaderButton
						icon={faSave}
						label={'Uložit'}
						click={() => {
							creator.save().then();
						}}
					/> || <HeaderButtonLoader/>}
					<Space36/>
					<CreatorClose onClick={() => creator.close()}>
						<FontAwesomeIcon icon={faTimes}/>
					</CreatorClose>
				</CreatorHeaderRight>
			</CreatorHeader>
			<CreatorContent>
				{!creator.loading && !permissions.listLoading && <CreatorData icon={faBalanceScale} label={'Seznam práv role'}>
					{permissions.list.sort((a: any, b: any): number => {
						const textA = a.module.toUpperCase();
						const textB = b.module.toUpperCase();
						return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
					}).map((o, i, list) => {
						return <>
							{i > 0 && !(i > 0 && list[i - 1].module === o.module) && <Divider/>}
							<DetailInfoRowGrid key={i}>
								{!(i > 0 && list[i - 1].module === o.module) &&
								<div>{o.module}</div>}
								{(i > 0 && list[i - 1].module === o.module) && <div/>}
								<div>
									<Checkbox
										checked={creator.permissions.includes(o.id)}
										value={o.id}
										label={o.name}
										onChange={(value: boolean) => value ? creator.addPermission(o.id) : creator.removePermission(o.id)}
									/>
								</div>
							</DetailInfoRowGrid>
						</>;
					})}
				</CreatorData> || <DetailInfoLoader/>}
			</CreatorContent>
		</CreatorContainer>
	);
};

export default Creator;
