import React from "react";
import styled from "styled-components";
import moment from "moment";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import {useSink} from "react-redux-sink";

//#region styled
const Container = styled.div`
	padding: 12px 24px;
	height: 100%;
`;
const Button = styled.div<{ active?: boolean; }>`
	width: 116px;
	padding: 5px 8px;
	border-radius: 4px;
	background-color: ${({theme, active}) => active ? `${theme.colors.orange}0D` : 'transparent'};
	color: ${({theme, active}) => active ? `${theme.colors.orange}` : theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(14, "medium")};
	transition: .25s color, .25s background-color;
	cursor: pointer;
	
	&:not(:last-child) {
		margin-bottom: 8px;
	}

	&:hover {
		background-color: ${({theme}) => theme.colors.orange}0D;
		color: ${({theme}) => theme.colors.orange};
	}
`;
//#endregion

const PredefinedRanges: React.FC = () => {
	const picker = useSink(DateTimePickerSink);
	return (
		<Container>
			<Button
				active={
					moment(picker.dates.from).isSame(moment(), 'day') &&
					moment(picker.dates.to).isSame(moment(), 'day')
				}
				onClick={() => picker.changeDates(new Date(), new Date())}
			>Dnes</Button>
			<Button
				active={
					moment(picker.dates.from).isSame(moment().startOf('week'), 'day') &&
					moment(picker.dates.to).isSame(moment().endOf('week'), 'day')
				}
				onClick={() => {
					const currentDate = moment();
					const weekStart = currentDate.clone().startOf('week');
					const weekEnd = currentDate.clone().endOf('week');
					picker.changeDates(weekStart.toDate(), weekEnd.toDate());
				}}
			>Tento týden</Button>
			<Button
				active={
					moment(picker.dates.from).isSame(moment().subtract(1, 'week').startOf('week'), 'day') &&
					moment(picker.dates.to).isSame(moment().subtract(1, 'week').endOf('week'), 'day')
				}
				onClick={() => {
					const currentDate = moment();
					const weekStart = currentDate.clone().subtract(1, 'weeks').startOf('week');
					const weekEnd = currentDate.clone().subtract(1, 'weeks').endOf('week');
					picker.changeDates(weekStart.toDate(), weekEnd.toDate());
				}}
			>Minulý týden</Button>
			<Button
				active={
					moment(picker.dates.from).isSame(moment().startOf('month'), 'day') &&
					moment(picker.dates.to).isSame(moment().endOf('month'), 'day')
				}
				onClick={() => {
					const currentDate = moment();
					const weekStart = currentDate.clone().startOf('month');
					const weekEnd = currentDate.clone().endOf('month');
					picker.changeDates(weekStart.toDate(), weekEnd.toDate());
				}}
			>Tento měsíc</Button>
			<Button
				active={
					moment(picker.dates.from).isSame(moment().subtract(1, 'month').startOf('month'), 'day') &&
					moment(picker.dates.to).isSame(moment().subtract(1, 'month').endOf('month'), 'day')
				}
				onClick={() => {
					const currentDate = moment();
					const weekStart = currentDate.clone().subtract(1, 'month').startOf('month');
					const weekEnd = currentDate.clone().subtract(1, 'month').endOf('month');
					picker.changeDates(weekStart.toDate(), weekEnd.toDate());
				}}
			>Minulý měsíc</Button>
			<Button
				active={
					moment(picker.dates.from).isSame(moment().startOf('year'), 'day') &&
					moment(picker.dates.to).isSame(moment().endOf('year'), 'day')
				}
				onClick={() => {
					const currentDate = moment();
					const weekStart = currentDate.clone().startOf('year');
					const weekEnd = currentDate.clone().endOf('year');
					picker.changeDates(weekStart.toDate(), weekEnd.toDate());
				}}
			>Tento rok</Button>
			<Button
				active={
					moment(picker.dates.from).isSame(moment().subtract(1, 'year').startOf('year'), 'day') &&
					moment(picker.dates.to).isSame(moment().subtract(1, 'year').endOf('year'), 'day')
				}
				onClick={() => {
					const currentDate = moment();
					const weekStart = currentDate.clone().subtract(1, 'year').startOf('year');
					const weekEnd = currentDate.clone().subtract(1, 'year').endOf('year');
					picker.changeDates(weekStart.toDate(), weekEnd.toDate());
				}}
			>Minulý rok</Button>
			<Button
				active={
					moment(picker.dates.from).isSame(moment("01/01/2020", "DD/MM/YYYY"), 'day') &&
					moment(picker.dates.to).isSame(moment(), 'day')
				}
				onClick={() => {
					const past = moment("01/01/2020", "DD/MM/YYYY");
					const currentDate = moment();
					picker.changeDates(past.toDate(), currentDate.toDate());
				}}
			>Celé období</Button>
		</Container>
	);
};

export default PredefinedRanges;
