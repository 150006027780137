import {
	ApiCalls,
	FetchAllApiResult,
	FetchDetailApiResult,
	FetchEmploymentsApiResult,
	FetchListApiResult, FetchPositionsApiResult,
	FetchStructureApiResult,
	StatusApiResult
} from ".";
import Config from "../../../../client-config.json";
import {toast} from "react-hot-toast";

const ProdApi: ApiCalls = {
	fetchAll: (active, page, limit, search, roles, positions, employments) =>
		fetch(`${Config.api}/Users?pageNumber=${page}&pageSize=${limit}&state=${active ? 2 : 1}${search !== "" ? `&name=${encodeURIComponent(search)}` : ""}${roles.map(o => `&roles=${o}`)}${positions.map(o => `&positions=${o}`)}${employments.map(o => `&employments=${o}`)}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchAllApiResult) => r),
	fetchStructure: () =>
		fetch(`${Config.api}/Users/Structure`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchStructureApiResult) => r.result.structure)
			.catch(() => {
				toast.error('Nastala chyba!')
			}),
	fetchList: (withWage, name, onlyActive) =>
		fetch(`${Config.api}/Users/List?withWage=${withWage ?? false}&onlyActive=${onlyActive ?? false}${name ? `&name=${name}` : ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchListApiResult) => r.result.list),
	fetch: (id) =>
		fetch(`${Config.api}/Users/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchDetailApiResult) => r.result),
	save: (id, name, email, phone, address, birthDate, startDate, hourlyWage, positions, roles, employment, hourlyLoad, availability, note, identificationNumber, bankAccount, asanaUrl, sharePointUrl, seniorUserId) =>
		fetch(`${Config.api}/Users/${id !== null ? id : ''}`, {
			method: id !== null ? 'PUT' : 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify({
				name,
				email,
				phone,
				address,
				birthDate,
				startDate,
				hourlyWage,
				seniorUserId,
				positions,
				roles,
				employment,
				hourlyLoad,
				availability,
				note,
				identificationNumber,
				bankAccount,
				asanaUrl,
				sharePointUrl
			})
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	delete: (id: string) =>
		fetch(`${Config.api}/Users/${id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	fetchEmployments: () =>
		fetch(`${Config.api}/Employments`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: FetchEmploymentsApiResult) => r),
	fetchPositions: () =>
		fetch(`${Config.api}/Positions`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: FetchPositionsApiResult) => r),
	archive: (id) =>
		fetch(`${Config.api}/Users/Archive/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	resetPassword: (id) =>
		fetch(`${Config.api}/Users/ResetPassword/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
};
export default ProdApi;
