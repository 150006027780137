import React, {useEffect} from "react";
import {
	CreatorClose,
	CreatorContainer,
	CreatorContent,
	CreatorData, CreatorGrayText,
	CreatorHeader,
	CreatorHeaderLeft,
	CreatorHeaderRight,
	CreatorInput,
	CreatorTextArea, CreatorTitleInputContainer,
	CreatorTitleInputWithColorSelector,
	InfoRow,
	InfoRowIcon,
	Pad24,
	SingleColumnRow,
	Space36,
	TwoShortColumnsRow
} from "../../../components/Creator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-light-svg-icons";
import HeaderButton from "../../../components/Controls/HeaderButton";
import {
	faTimes,
	faSave,
	faExternalLink,
	faLink,
	faCalendarAlt,
	faUserFriends,
	faStickyNote,
	faUserTie,
	faInfoCircle,
	faStopwatch,
	faPortalEnter,
	faPortalExit,
	faCalendarDay, faCoins, faUser, faCrown, faTrash
} from "@fortawesome/pro-regular-svg-icons";
import {useSink} from "react-redux-sink";
import CreatorSink from "../storage/creator-sink";
import ToggleButton from "../../../components/Controls/ToggleButton";
import Checkbox from "../../../components/Controls/Checkbox";
import {
	ProjectUserControlGrid,
	ProjectUserManagerIcon, ProjectUserPositions,
	ProjectUserTitle
} from "../../../components/Controls/ProjectUserControl";
import moment from "moment";
import DateTimePickerSink from "../../../store/date-time-picker-sink";
import MultiCheckboxPickerSink from "../../../store/multi-checkbox-picker-sink";
import CustomersSink from "../../Customers/storage/customers-sink";
import UsersSink from "../../Users/storage/users-sink";
import {DetailInfoLoader, DetailTitleLoader, HeaderButtonLoader, ToggleLoader} from "../../../components/Loaders";
import IconButton from "../../../components/Controls/IconButton";
import PositionSink from "../../Users/storage/position-sink";
import DialogSink from "../../../store/dialog-sink";

const Creator: React.FC = () => {
	const creator = useSink(CreatorSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const customers = useSink(CustomersSink);
	const users = useSink(UsersSink);
	const positions = useSink(PositionSink);
	const picker = useSink(DateTimePickerSink);
	const dialog = useSink(DialogSink);

	useEffect(() => {
		if(dialog.confirm === 'confirm' && dialog.id !== null) {
			if(dialog.id.startsWith('project_user_') && dialog.id.endsWith('_remove')) {
				const id = dialog.id.split('_')[2];
				creator.removeUser(id);
			}
			dialog.clear();
		}
	}, [dialog.confirm]);

	useEffect(() => {
		if (picker.confirmed) {
			if (picker.id === 'project-creator-billing-date') creator.changeBillingDate(picker.dates.from);
			else if (picker.id === 'project-creator-start') creator.changeStart(picker.dates.from);
			else if (picker.id === 'project-creator-end') creator.changeEnd(picker.dates.from);
			picker.clear();
		}
	}, [picker.confirmed]);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed && multiCheckboxPicker.id !== null) {
			if (multiCheckboxPicker.id === 'project-creator-customer') creator.changeCustomer(multiCheckboxPicker.selected[0] ?? null);
			else if (multiCheckboxPicker.id === 'project-user-manager-id') creator.setManager(multiCheckboxPicker.selected[0] ?? null);
			else if (multiCheckboxPicker.id === 'project-user-add') creator.addUser(multiCheckboxPicker.selected[0] ?? null);
			else if(multiCheckboxPicker.id.startsWith('project_user_') && multiCheckboxPicker.id.endsWith('_positions')) {
				const id = multiCheckboxPicker.id.split('_')[2];
				creator.changeUserPositions(id, multiCheckboxPicker.selected.map(o => parseInt(o)));
			}
			else if(multiCheckboxPicker.id === 'project-user-manager-positions') creator.changeManagerPositions(multiCheckboxPicker.selected.map(o => parseInt(o)));
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		if(creator.opened && multiCheckboxPicker.id !== null) {
			let list = creator.users.map(o => o.userId);
			if(multiCheckboxPicker.id === 'project-user-manager-id') {
				multiCheckboxPicker.items = users.list.filter(o => !list.includes(o.id)).map((o) => {
					return {
						label: o.name,
						value: o.id
					};
				});
			}
			else if(multiCheckboxPicker.id === 'project-user-add') {
				let filtered = users.list.filter(o => !list.includes(o.id));
				if(creator.manager !== null) filtered = filtered.filter(o => o.id !== creator.manager!.userId);
				multiCheckboxPicker.items = filtered.map((o) => {
					return {
						label: o.name,
						value: o.id
					};
				});
			}
			else {
				const id = multiCheckboxPicker.id.split('_')[2];
				list = list.filter(o => o !== id);
				let filtered = users.list.filter(o => !list.includes(o.id));
				if(creator.manager !== null) filtered = filtered.filter(o => o.id !== creator.manager!.userId);
				multiCheckboxPicker.items = filtered.map((o) => {
					return {
						label: o.name,
						value: o.id
					};
				});
			}
		}
	}, [users.list]);

	const resolveUserName = (id: string): string => {
		const user = users.list.filter(o => o.id === id);
		if(user[0]) return user[0].name;
		else return 'Uživatel';
	};

	return (
		<CreatorContainer show={creator.opened}>
			<CreatorHeader>
				<CreatorHeaderLeft>
					<CreatorClose onClick={() => creator.close()}>
						<FontAwesomeIcon icon={faChevronLeft}/>
					</CreatorClose>
					{!creator.loading && <CreatorTitleInputWithColorSelector/> || <DetailTitleLoader/>}
					<Space36/>
					{!creator.loading && <ToggleButton
						id={'project-creator-customer'}
						selected={creator.customerId !== null}
						active={multiCheckboxPicker.id === 'project-creator-customer'}
						onClick={async () => {
							multiCheckboxPicker.toggle('project-creator-customer');
							multiCheckboxPicker.setSelected(creator.customerId !== null ? [creator.customerId] : []);
							await customers.loadList();
						}}
						icon={faUserTie}
						label={'Zákazník'}
					/> || <ToggleLoader/>}
				</CreatorHeaderLeft>
				<CreatorHeaderRight>
					{!creator.loading && <HeaderButton
						icon={faSave}
						label={'Uložit'}
						click={() => creator.submit()}
					/> || <HeaderButtonLoader/>}
					<Space36/>
					<CreatorClose onClick={() => creator.close()}>
						<FontAwesomeIcon icon={faTimes}/>
					</CreatorClose>
				</CreatorHeaderRight>
			</CreatorHeader>
			<CreatorContent>
				{!creator.loading && <CreatorData icon={faUserFriends} label={'Uživatelé'}>
					<ProjectUserControlGrid>
						<div>
							<ProjectUserTitle>
								<ProjectUserManagerIcon>
									<FontAwesomeIcon icon={faCrown}/>
								</ProjectUserManagerIcon>
								Manažer
							</ProjectUserTitle>
							<ToggleButton
								id={'project-user-manager-id'}
								active={multiCheckboxPicker.id === 'project-user-manager-id'}
								selected={creator.manager !== null}
								onClick={async () => {
									multiCheckboxPicker.toggle('project-user-manager-id', true);
									multiCheckboxPicker.setSelected(creator.manager !== null ? [creator.manager.userId] : [])
									await users.loadList(true, undefined, true);
								}}
								icon={faUser}
								label={creator.manager !== null ? resolveUserName(creator.manager.userId) : 'Uživatel'}
							/>
						</div>
						<div>
							<CreatorInput
								trailingIcon={faStopwatch}
								label={'Hodinovka'}
								endIcon={<>Kč/h</>}
								disabled={creator.manager === null}
								value={creator.manager?.hourlyWage}
								onChange={({currentTarget}) => creator.changeManagerWage(currentTarget.value)}
							/>
						</div>
						<ProjectUserPositions>
							<ToggleButton
								id={'project-user-manager-positions'}
								active={multiCheckboxPicker.id === 'project-user-manager-positions'}
								selected={creator.manager !== null ? creator.manager.positions.length > 0 : false}
								onClick={async () => {
									if(creator.manager !== null) {
										multiCheckboxPicker.toggle('project-user-manager-positions', true);
										await positions.load();
									}
								}}
								icon={faUser}
								label={'Pozice'}
							/>
						</ProjectUserPositions>
						{creator.users.map((o, i) => <>
							<div>
								<ProjectUserTitle>Člen</ProjectUserTitle>
								<ToggleButton
									selected={creator.users[i] !== undefined && creator.users[i] !== null}
									icon={faUser}
									label={resolveUserName(o.userId)}
								/>
							</div>
							<div>
								<CreatorInput
									trailingIcon={faStopwatch}
									label={'Hodinovka'}
									endIcon={<>Kč/h</>}
									value={o.hourlyWage}
									onChange={({currentTarget}) => creator.changeUserWage(o.userId, currentTarget.value)}
								/>
							</div>
							<ProjectUserPositions>
								<ToggleButton
									id={`project_user_${o.userId}_positions`}
									active={multiCheckboxPicker.id === `project_user_${o.userId}_positions`}
									selected={creator.users[i]?.positions.length > 0}
									onClick={async () => {
										multiCheckboxPicker.toggle(`project_user_${o.userId}_positions`, true);
										await positions.load();
									}}
									icon={faUser}
									label={'Pozice'}
								/>
								<Space36/>
								<IconButton icon={faTrash} onClick={() => dialog.init(`project_user_${o.userId}_remove`, 'Varování', 'Opravdu chcete odstranit uživatele z projektu?')}/>
							</ProjectUserPositions>
						</>)}
						<div>
							<ProjectUserTitle>Člen</ProjectUserTitle>
							<ToggleButton
								id={'project-user-add'}
								active={multiCheckboxPicker.id === 'project-user-add'}
								selected={false}
								onClick={async () => {
									multiCheckboxPicker.toggle('project-user-add', true);
									await users.loadList(true, undefined, true);
								}}
								icon={faUser}
								label={'Uživatel'}
							/>
						</div>
					</ProjectUserControlGrid>
				</CreatorData> || <DetailInfoLoader/>}
				{!creator.loading && <CreatorData icon={faCalendarAlt} label={'Termíny'}>
					<TwoShortColumnsRow>
						<CreatorInput
							value={creator.estimatedTime}
							onChange={({currentTarget}) => creator.changeEstimatedTime(currentTarget.value)}
							trailingIcon={faStopwatch}
							label={'Hodinový odhad'}
						/>
						<CreatorGrayText>Přibližně ? MD</CreatorGrayText>
					</TwoShortColumnsRow>
					<Pad24/>
					<TwoShortColumnsRow>
						<CreatorInput disabled={picker.id === 'project-creator-start'}
													className={'toggle-button-project-creator-start'}
													value={moment(creator.start).format('D.M.YYYY')}
													onClick={() => picker.toggle('project-creator-start', {
														from: creator.start,
														to: creator.start
													}, {
														showPredefinedRanges: false,
														selectRange: false
													})}
													trailingIcon={faPortalEnter} endIcon={<FontAwesomeIcon icon={faCalendarDay}/>}
													label={'Začátek'}/>
						{creator.hasEnd &&
						<CreatorInput disabled={picker.id === 'project-creator-end'} className={'toggle-button-project-creator-end'}
													value={moment(creator.end ?? new Date()).format('D.M.YYYY')}
													onClick={() => picker.toggle('project-creator-end', creator.end ? {
														from: creator.end,
														to: creator.end
													} : undefined, {
														showPredefinedRanges: false,
														selectRange: false
													})}
													trailingIcon={faPortalExit} endIcon={<FontAwesomeIcon icon={faCalendarDay}/>}
													label={'Konec'}/>}
					</TwoShortColumnsRow>
					{creator.isInvoiced && <>
						<Pad24/>
						<TwoShortColumnsRow>
							<CreatorInput disabled={picker.id === 'project-creator-billing-date'}
														className={'toggle-button-project-creator-billing-date'}
														value={moment(creator.billingDate ?? new Date()).format('D.M.YYYY')}
														onClick={() => picker.toggle('project-creator-billing-date', creator.billingDate ? {
															from: creator.billingDate,
															to: creator.billingDate
														} : undefined, {
															showPredefinedRanges: false,
															selectRange: false
														})}
														trailingIcon={faCoins} endIcon={<FontAwesomeIcon icon={faCalendarDay}/>}
														label={'Datum fakturace'}/>
						</TwoShortColumnsRow>
					</>}
					<InfoRow>
						<InfoRowIcon>
							<FontAwesomeIcon icon={faInfoCircle}/>
						</InfoRowIcon>
						V ideálním případě je konec shodný s fakturací. Datum fakturace určí, kdy projekt přejde do uzavřeného
						stavu.
					</InfoRow>
					<Checkbox checked={creator.isInvoiced} value={'isInvoiced'} label={'Projekt je vyfakturován'}
										onChange={() => creator.toggleIsInvoiced()}/>
					<Checkbox checked={!creator.hasEnd} value={'hasEnd'} label={'Projekt nemá konec'}
										onChange={() => creator.toggleHasEnd()}/>
				</CreatorData> || <DetailInfoLoader/>}
				{!creator.loading && <CreatorData icon={faStickyNote} label={'Poznámka'}>
					<CreatorTextArea value={creator.note}
													 onChange={({currentTarget}) => creator.changeNote(currentTarget.value)}/>
				</CreatorData> || <DetailInfoLoader/>}
				{!creator.loading && <CreatorData icon={faExternalLink} label={'Odkazy'}>
					<SingleColumnRow>
						<CreatorInput value={creator.asanaUrl}
													onChange={({currentTarget}) => creator.changeAsanaUrl(currentTarget.value)}
													trailingIcon={faLink} label={'Asana'}/>
					</SingleColumnRow>
					<SingleColumnRow>
						<CreatorInput value={creator.sharePointUrl}
													onChange={({currentTarget}) => creator.changeSharePointUrl(currentTarget.value)}
													trailingIcon={faLink} label={'SharePoint'}/>
					</SingleColumnRow>
				</CreatorData> || <DetailInfoLoader/>}
			</CreatorContent>
		</CreatorContainer>
	);
};

export default Creator;
