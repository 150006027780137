import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

//#region styled
const Container = styled.button`
	position: relative;
	font-size: 18px;
	color: ${({theme}) => theme.colors.iceBlue};
	background-color: transparent;
	border: none;
	cursor: pointer;
	transition: .25s color;
	&:hover {
		color: ${({theme}) => theme.colors.blueGray};
	}
`;
//#endregion

interface IconButtonProps {
	icon: IconDefinition;
	onClick(): void;
}

const IconButton: React.FC<IconButtonProps> =
	({
		icon,
		onClick
	 }) => {
	return (
		<Container onClick={onClick}>
			<FontAwesomeIcon icon={icon}/>
		</Container>
	);
};

export default IconButton;
