import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import PaginationSink from "../../../store/pagination-sink";
import FilterSink from "../../../store/filter-sink";
import {toast} from "react-hot-toast";
import IdentitySink from "../../../store/identity-sink";
import TimerStatisticsSink from "./timer-statistics-sink";

export interface TimerTableItemInterface {
	id: string;
	userId: string;
	userName: string;
	description: string;
	projectId?: string;
	color?: string;
	projectName?: string;
	customerName?: string;
	start: Date;
	end: Date;
}

@sink("timer-overview", SinkFactory)
export default class TimerSink {

	constructor(factory: SinkContainer){
		this.pagination = factory.getSink(PaginationSink);
		this.filter = factory.getSink(FilterSink);
		this.identity = factory.getSink(IdentitySink);
		this.statistics = factory.getSink(TimerStatisticsSink);
	}

	pagination: PaginationSink;

	filter: FilterSink;

	identity: IdentitySink;

	statistics: TimerStatisticsSink;

	@state tableLoading: boolean = true;

	@state table: TimerTableItemInterface[] = [];

	@state toDelete: string[] = [];

	@effect
	checkAll() {
		this.toDelete = this.table.map(o => o.id);
	}

	@effect
	uncheckAll() {
		this.toDelete = [];
	}

	@effect
	toggleToDelete(id: string) {
		if(this.toDelete.includes(id)) this.toDelete = this.toDelete.filter(o => o !== id);
		else this.toDelete = [...this.toDelete, id];
	}

	@effect
	async loadTable(isOverview?: boolean, userFilter?: string) {
		this.tableLoading = true;
		const result = await Api.fetchAll(
			this.pagination.page,
			this.pagination.limit,
			userFilter ? [userFilter] : this.filter.users,
			this.filter.projects,
			this.filter.customers,
			this.filter.search,
			isOverview ? undefined : this.filter.from,
			isOverview ? undefined : this.filter.to
		);
		if(result !== undefined && result !== null && result.result !== null){
			this.table = result.result.list;
			this.pagination.changeTotal(result.result.totalCount);
		}
		else toast.error('Nastala chyba!');
		this.tableLoading = false;
	}

	@effect
	async deleteSelected() {
		this.tableLoading = true;
		const result = await Api.deleteItems(this.toDelete);
		if(result !== undefined && result !== null){
			this.toDelete = [];
			await this.loadTable(true, this.identity.data?.id);
			await this.statistics.refreshStatistics();
			toast.success('Záznamy odstraněny');
		}
		else toast.error('Nastala chyba!');
		this.tableLoading = false;
	}

	@effect
	clearTable() {
		this.table = [];
		this.tableLoading = true;
		this.toDelete = [];
	}
}
