import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import {faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons";
import DialogSink from "../../store/dialog-sink";
import {useSink} from "react-redux-sink";
import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {Pad12} from "../Creator";

const Container = styled.div`
	background-color: ${({theme}) => theme.colors.white};
	box-shadow: 0 0 4px 0 ${({theme}) => theme.colors.darkGray}30;
	width: 350px;
	height: auto;
	border-radius: 4px;
	padding: 24px 36px;
	position: relative;
`;
const Title = styled.div`
	color: ${({theme}) => theme.colors.blueGray};
	font: ${({theme}) => theme.fonts.Montserrat(18, "bold")};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Icon = styled.div`
	color: ${({theme}) => theme.colors.red};
	margin-right: 18px;
`;
const Description = styled.div`
	padding: 24px 0;
	color: ${({theme}) => theme.colors.darkGray};
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
`;
const Button = styled.button<{ transparent?: boolean; }>`
	border: none;
	background-color: ${({theme, transparent}) => transparent ? 'transparent' : theme.colors.gold};
	color: ${({theme, transparent}) => transparent ? theme.colors.iceBlue : theme.colors.white};
	border-radius: 4px;
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
	width: 100%;
	padding: 12px;
	transition: .25s background-color, .25s color;
	cursor: pointer;
	&:hover {
		background-color: ${({theme, transparent}) => transparent ? 'transparent' : theme.colors.orange};
		color: ${({theme, transparent}) => transparent ? theme.colors.blueGray : theme.colors.white};
	}
`;
const ButtonText = styled.span`
	margin-left: 12px;
`;
const Left = styled.div`
	display: flex;
	align-items: center;
`;
const Right = styled.div`
	display: flex;
	align-items: center;
	color: ${({theme}) => theme.colors.iceBlue};
	transition: .25s color;
	cursor: pointer;
	&:hover {
		color: ${({theme}) => theme.colors.blueGray};
	}
`;

const Dialog: React.FC = () => {
	const dialog = useSink(DialogSink);

	return (
		<Container>
			<Title>
				<Left>
					<Icon>
						<FontAwesomeIcon icon={faExclamationTriangle}/>
					</Icon>
					{dialog.title}
				</Left>
				<Right onClick={() => dialog.clear()}>
					<FontAwesomeIcon icon={faTimes}/>
				</Right>
			</Title>
			<Description>{dialog.description}</Description>
			<Button onClick={() => dialog.action("confirm")}>
				<FontAwesomeIcon icon={faCheck}/>
				<ButtonText>Potvrdit</ButtonText>
			</Button>
			<Pad12/>
			<Button transparent onClick={() => dialog.clear()}>
				<FontAwesomeIcon icon={faTimes}/>
				<ButtonText>Zrušit</ButtonText>
			</Button>
		</Container>
	);
};

export default Dialog;
