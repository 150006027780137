import React from "react";
import {
	CreatorClose, CreatorContainer, CreatorContent, CreatorData,
	CreatorHeader,
	CreatorHeaderLeft,
	CreatorHeaderRight, CreatorInput, CreatorTitleInputContainer, Space36, TwoColumnsRow
} from "../../../components/Creator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-light-svg-icons";
import HeaderButton from "../../../components/Controls/HeaderButton";
import {
	faTimes,
	faSave,
	faMapMarkerAlt, faFolderOpen, faBox
} from "@fortawesome/pro-regular-svg-icons";
import {useSink} from "react-redux-sink";
import CreatorSink from "../storage/creator-sink";
import {DetailInfoLoader, DetailTitleLoader, HeaderButtonLoader} from "../../../components/Loaders";

const Creator: React.FC = () => {
	const creator = useSink(CreatorSink);

	return (
		<CreatorContainer show={creator.opened}>
			<CreatorHeader>
				<CreatorHeaderLeft>
					<CreatorClose onClick={() => creator.close()}>
						<FontAwesomeIcon icon={faChevronLeft}/>
					</CreatorClose>
					{!creator.loading && <CreatorTitleInputContainer
						value={creator.title}
						onChange={({currentTarget}) => creator.changeTitle(currentTarget.value)}
					/> || <DetailTitleLoader/>}
				</CreatorHeaderLeft>
				<CreatorHeaderRight>
					{!creator.loading && <HeaderButton
						icon={faSave}
						label={'Uložit'}
						click={() => creator.submit()}
					/> || <HeaderButtonLoader/>}
					<Space36/>
					<CreatorClose onClick={() => creator.close()}>
						<FontAwesomeIcon icon={faTimes}/>
					</CreatorClose>
				</CreatorHeaderRight>
			</CreatorHeader>
			<CreatorContent>
				{!creator.loading && <CreatorData icon={faBox} label={'Položka'}>
					<TwoColumnsRow>
						<CreatorInput
							trailingIcon={faMapMarkerAlt}
							label={'Umístění'}
							value={creator.location}
							whisperer
							onChange={({currentTarget}) => creator.changeLocation(currentTarget.value)}
						/>
						<CreatorInput
							trailingIcon={faFolderOpen}
							label={'Typ'}
							value={creator.type}
							whisperer
							onChange={({currentTarget}) => creator.changeType(currentTarget.value)}
						/>
						<CreatorInput
							label={'Dostupné'}
							value={creator.available}
							onChange={({currentTarget}) => creator.changeAvailable(currentTarget.value)}
						/>
						<CreatorInput
							label={'Celkem'}
							value={creator.total}
							onChange={({currentTarget}) => creator.changeTotal(currentTarget.value)}
						/>
					</TwoColumnsRow>
				</CreatorData> || <DetailInfoLoader/>}
			</CreatorContent>
		</CreatorContainer>
	);
};

export default Creator;
