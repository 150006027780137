import { sink, state, effect } from "react-redux-sink";

const INIT_PAGE = 1;
const INIT_LIMIT = 10;
const INIT_TOTAL = 0;

@sink("pagination")
export default class PaginationSink {

	constructor() {
		const limitObject = localStorage.getItem("limit");
		this.limit = parseInt(limitObject ?? INIT_LIMIT.toString()) ?? INIT_LIMIT
	}

	@state page: number = INIT_PAGE;
	@state limit: number = INIT_LIMIT;
	@state total: number = INIT_TOTAL;

	@effect clear() {
		const limitObject = localStorage.getItem("limit");
		this.page = INIT_PAGE;
		this.limit = parseInt(limitObject ?? INIT_LIMIT.toString()) ?? INIT_LIMIT
		this.total = INIT_TOTAL;
	}

	@effect init(total: number) {
		this.total = total;
		this.page = 1;
	}

	@effect changePage(page: number) {

	}

	@effect first() {
		this.page = 1;
	}

	@effect previous() {
		this.page--;
	}

	@effect next() {
		this.page++;
	}

	@effect last() {
		this.page = Math.ceil(this.total / this.limit);
	}

	@effect
	changeTotal(total: number) {
		this.total = total;
	}

	@effect changeLimit(limit: number) {
		this.limit = limit;
		localStorage.setItem("limit", limit.toString());
	}
}
