import React from "react";
import {ResponsivePie} from "@nivo/pie";
import styled from "styled-components";
import RatioChartsSink from "../storage/ratio-charts-sink";
import {useSink} from "react-redux-sink";
import moment from "moment";
import ThemeSink from "../../../store/theme-sink";
import {PieGraphLoader} from "../../../components/Loaders";

const PieChartContainer = styled.div`
	width: 100%;
	height: 550px;
`;

const Tooltip = styled.div`
	border-radius: 4px;
	background-color: ${({theme}) => theme.colors.white};
	padding: 6px 12px;
	font-weight: 400;
	color: ${({theme}) => theme.colors.pebbleBlack};
	box-shadow: 0 0 4px 0 ${({theme}) => theme.colors.darkGray}30;
`;

const PieChart: React.FC = () => {
	const ratio = useSink(RatioChartsSink);
	const theme = useSink(ThemeSink);

	const isEmpty = (): boolean => {
		if (ratio.show === 'users') return ratio.byUsers.length === 0;
		else if (ratio.show === 'projects') return ratio.byProjects.length === 0;
		else if (ratio.show === 'customers') return ratio.byCustomers.length === 0;
		return true;
	}

	const resolveRatioPie = () => {
		if(ratio.show === 'users') return ratio.byUsers.map((o) => {
			return {
				"id": o.name,
				"label": o.name,
				"value": moment.duration(o.duration).asHours()
			};
		});
		else if(ratio.show === 'projects') return ratio.byProjects.map((o) => {
			return {
				"id": o.name ?? 'Bez projektu',
				"label": o.name ?? 'Bez projektu',
				"value": moment.duration(o.duration).asHours()
			};
		});
		else if(ratio.show === 'customers') return ratio.byCustomers.map((o) => {
			return {
				"id": o.name ?? 'Bez zákazníka',
				"label": o.name ?? 'Bez zákazníka',
				"value": moment.duration(o.duration).asHours()
			};
		});
		return [];
	};

	return (
		<PieChartContainer>
			{!isEmpty() && <ResponsivePie
				data={resolveRatioPie()}
				margin={{ top: 60, right: 60, bottom: 60, left: 60 }}
				padAngle={1}
				activeOuterRadiusOffset={8}
				valueFormat={(value: number) => value.toFixed(1) + " h"}
				colors={[theme.colors.gold, theme.colors.orange]}
				borderWidth={1}
				tooltip={(b) =>
					<Tooltip>
						{b.datum.label}: <b>{b.datum.formattedValue}</b>
					</Tooltip>
				}
				borderColor={{ from: 'color', modifiers: [ [ 'opacity', 0 ] ] }}
				arcLinkLabelsSkipAngle={10}
				arcLinkLabelsTextColor={theme.colors.darkGray}
				arcLinkLabelsThickness={2}
				arcLinkLabelsColor={{ from: 'color' }}
				arcLabelsSkipAngle={10}
				arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
			/> || <PieGraphLoader noData/>}
		</PieChartContainer>
	);
};

export default PieChart;
