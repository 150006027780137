const TIMER_LIST = "TIMER.LIST";
const TIMER_LIST_ALL = "TIMER.LIST.ALL";
const TIMER_CREATE = "TIMER.CREATE";
const TIMER_CREATE_ALL = "TIMER.CREATE.ALL";
const TIMER_DELETE = "TIMER.DELETE";
const TIMER_DELETE_ALL = "TIMER.DELETE.ALL";
const TIMER_ACTIVITY = "TIMER.LIST.ACTIVITY";

const FINANCE_PROJECT_LIST = "FINANCE.PROJECT.LIST";
const FINANCE_PROJECT_CREATE = "FINANCE.PROJECT.CREATE";
const FINANCE_PROJECT_DELETE = "FINANCE.PROJECT.DELETE";
const FINANCE_PROJECT_PAY = "FINANCE.PROJECT.PAY";

const FINANCE_USER_LIST = "FINANCE.USER.LIST";
const FINANCE_USER_CREATE = "FINANCE.USER.CREATE";
const FINANCE_USER_DELETE = "FINANCE.USER.DELETE";
const FINANCE_USER_PAY = "FINANCE.USER.PAY";

const PROJECTS_LIST = "PROJECTS.LIST";
const PROJECTS_LIST_ALL = "PROJECTS.LIST.ALL";
const PROJECTS_VIEW = "PROJECTS.VIEW";
const PROJECTS_CREATE = "PROJECTS.CREATE";
const PROJECTS_DELETE = "PROJECTS.DELETE";
const PROJECTS_ARCHIVE = "PROJECTS.ARCHIVE";

const CUSTOMERS_LIST = "CUSTOMERS.LIST";
const CUSTOMERS_LIST_ALL = "CUSTOMERS.LIST.ALL";
const CUSTOMERS_VIEW = "CUSTOMERS.VIEW";
const CUSTOMERS_CREATE = "CUSTOMERS.CREATE";
const CUSTOMERS_DELETE = "CUSTOMERS.DELETE";

const PROPERTY_LIST = "PROPERTY.LIST";
const PROPERTY_LIST_ALL = "PROPERTY.LIST.ALL";
const PROPERTY_VIEW = "PROPERTY.VIEW";
const PROPERTY_VIEW_ALL = "PROPERTY.VIEW.ALL";
const PROPERTY_CREATE = "PROPERTY.CREATE";
const PROPERTY_DELETE = "PROPERTY.DELETE";

const STOCK_LIST = "STOCK.LIST";
const STOCK_VIEW = "STOCK.VIEW";
const STOCK_CREATE = "STOCK.CREATE";
const STOCK_DELETE = "STOCK.DELETE";

const USERS_LIST = "USERS.LIST";
const USERS_LIST_ALL = "USERS.LIST.ALL";
const USERS_VIEW = "USERS.VIEW";
const USERS_CREATE = "USERS.CREATE";
const USERS_DELETE = "USERS.DELETE";
const USERS_ARCHIVE = "USERS.ARCHIVE";
const USERS_RESET = "USERS.RESET";
const USERS_STRUCTURE_LIST = "USERS.STRUCTURE.LIST";
const USERS_STRUCTURE_UPDATE = "USERS.STRUCTURE.UPDATE";

const ROLES_LIST = "ROLES.LIST";
const ROLES_CREATE = "ROLES.CREATE";
const ROLES_DELETE = "ROLES.DELETE";
const ROLES_VIEW = "ROLES.VIEW";

const PERMISSIONS_LIST = "PERMISSIONS.LIST";

export default {
	TIMER_LIST,
	TIMER_LIST_ALL,
	TIMER_CREATE,
	TIMER_CREATE_ALL,
	TIMER_DELETE,
	TIMER_DELETE_ALL,
	TIMER_ACTIVITY,

	FINANCE_PROJECT_LIST,
	FINANCE_PROJECT_CREATE,
	FINANCE_PROJECT_DELETE,
	FINANCE_PROJECT_PAY,

	FINANCE_USER_LIST,
	FINANCE_USER_CREATE,
	FINANCE_USER_DELETE,
	FINANCE_USER_PAY,

	PROJECTS_LIST,
	PROJECTS_LIST_ALL,
	PROJECTS_VIEW,
	PROJECTS_CREATE,
	PROJECTS_DELETE,
	PROJECTS_ARCHIVE,

	CUSTOMERS_LIST,
	CUSTOMERS_LIST_ALL,
	CUSTOMERS_VIEW,
	CUSTOMERS_CREATE,
	CUSTOMERS_DELETE,

	PROPERTY_LIST,
	PROPERTY_LIST_ALL,
	PROPERTY_VIEW,
	PROPERTY_VIEW_ALL,
	PROPERTY_CREATE,
	PROPERTY_DELETE,

	STOCK_LIST,
	STOCK_VIEW,
	STOCK_CREATE,
	STOCK_DELETE,

	USERS_LIST,
	USERS_LIST_ALL,
	USERS_VIEW,
	USERS_CREATE,
	USERS_DELETE,
	USERS_ARCHIVE,
	USERS_RESET,
	USERS_STRUCTURE_LIST,
	USERS_STRUCTURE_UPDATE,

	ROLES_LIST,
	ROLES_CREATE,
	ROLES_DELETE,
	ROLES_VIEW,

	PERMISSIONS_LIST
};
