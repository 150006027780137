import React, {useEffect} from "react";
import {
	faAlien, faIdCardAlt, faMapMarkerAlt,
	faPlus, faSearch, faStopwatch, faUndo, faUser, faUserFriends
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import {LinkItem, ProjectItem, TextItem, UserItem} from "../../components/Table/Items";
import Creator from "./components/Creator";
import Detail from "./components/Detail";
import {useSink} from "react-redux-sink";
import CreatorSink from "./storage/creator-sink";
import DetailSink from "./storage/detail-sink";
import ThemeSink from "../../store/theme-sink";
import Filters from "../../components/Filters";
import SearchInput from "../../components/Controls/SearchInput";
import {Pad24, Space12, Space36} from "../../components/Creator";
import ToggleButton from "../../components/Controls/ToggleButton";
import BorderButton from "../../components/Controls/BorderButton";
import {
	BorderButtonLoader,
	PaginationLoader,
	SearchInputLoader,
	TableLoader,
	ToggleLoader
} from "../../components/Loaders";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import UsersSink, {UsersTableItemInterface} from "./storage/users-sink";
import NavigationSink from "../../store/navigation-sink";
import Routes from "../../routes";
import PaginationSink from "../../store/pagination-sink";
import FilterSink from "../../store/filter-sink";
import RolesSink from "../Roles/storage/roles-sink";
import Permissions from "../../permissions";
import {hasAnyPermission} from "../../lib/application-lib";
import IdentitySink from "../../store/identity-sink";
import EmploymentSink from "./storage/employment-sink";
import PositionSink from "./storage/position-sink";

const UsersOverview: React.FC = () => {
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const identity = useSink(IdentitySink);
	const creator = useSink(CreatorSink);
	const detail = useSink(DetailSink);
	const users = useSink(UsersSink);
	const theme = useSink(ThemeSink);
	const navigation = useSink(NavigationSink);
	const pagination = useSink(PaginationSink);
	const filter = useSink(FilterSink);
	const roles = useSink(RolesSink);
	const positions = useSink(PositionSink);
	const employments = useSink(EmploymentSink);

	const loadTable = () => {
		if (navigation.location.pathname.includes('neaktivni')) users.loadTable(false).then();
		else users.loadTable(true).then();
	}

	useEffect(() => {
		return () => {
			users.clear();
			pagination.clear();
			filter.clear();
			detail.clear();
			creator.clear();
			positions.clear();
			employments.clear();
			roles.clear();
			multiCheckboxPicker.clear();
		};
	}, []);

	useEffect(() => {
		multiCheckboxPicker.items = roles.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [roles.list]);

	useEffect(() => {
		multiCheckboxPicker.items = users.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [users.list]);

	useEffect(() => {
		multiCheckboxPicker.items = positions.list.map((o) => {
			return {
				label: o.name,
				value: o.id.toString()
			};
		})
	}, [positions.list]);

	useEffect(() => {
		multiCheckboxPicker.items = employments.list.map((o) => {
			return {
				label: o.name,
				value: o.id.toString()
			};
		})
	}, [employments.list]);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed) {
			if (multiCheckboxPicker.id === 'users-filter-positions') filter.changePositions(multiCheckboxPicker.selected);
			else if (multiCheckboxPicker.id === 'users-filter-roles') filter.changeRoles(multiCheckboxPicker.selected);
			else if (multiCheckboxPicker.id === 'users-filter-employments') filter.changeEmployments(multiCheckboxPicker.selected);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		loadTable();
	}, [pagination.page, pagination.limit, filter.search, filter.positions, filter.roles, filter.employments]);

	useEffect(() => loadTable(), [navigation.location]);

	const getTableHeaders = () => {
		const headers = [];
		const hasListAllPermission = hasAnyPermission([Permissions.USERS_LIST_ALL], identity.data?.permissions ?? []);

		headers.push({label: "Jméno", sortable: true});
		headers.push({label: "Výchozí pozice"});
		if (hasListAllPermission) headers.push({label: "Pracovní poměr"});
		headers.push({label: "Email"});
		headers.push({label: "Telefon"});

		return headers;
	};

	const getTableItem = (o: UsersTableItemInterface) => {
		const children = [];
		const hasListAllPermission = hasAnyPermission([Permissions.USERS_LIST_ALL], identity.data?.permissions ?? []);
		children.push(<UserItem
			onClick={() => hasAnyPermission([Permissions.USERS_VIEW], identity.data?.permissions ?? []) ? detail.open(o.id) : null}
			content={o.name} image={o.imageId}/>);
		children.push(<TextItem content={o.positions.join(", ")}/>);
		if (hasListAllPermission) children.push(<TextItem content={o.employment as string}/>);
		children.push(<LinkItem content={o.email} link={`mailto:${o.email}`}/>);
		children.push(<LinkItem content={o.phone} link={`tel:${o.phone}`}/>);
		return {children};
	};

	return (
		<>
			<Detail/>
			<Creator/>
			<ListHeader
				title={'Přehled'}
				icon={faUserFriends}
				headerButton={{
					label: "Přidat položku",
					icon: faPlus,
					click: () => creator.open(null),
					permissions: [Permissions.USERS_CREATE]
				}}
				menu={[
					{
						label: "Aktivní",
						active: !navigation.location.pathname.includes('neaktivni'),
						link: Routes.paths.USERS_ACTIVE_OVERVIEW
					},
					{
						label: "Neaktivní",
						active: navigation.location.pathname.includes('neaktivni'),
						link: Routes.paths.USERS_NOT_ACTIVE_OVERVIEW
					}
				]}
			/>
			<Pad24/>
			<Filters
				left={[
					<>{!users.tableLoading && <SearchInput icon={faSearch}/> || <SearchInputLoader/>}</>,
					<Space36/>,
					<>{!users.tableLoading && <ToggleButton
						id={'users-filter-positions'}
						selected={filter.positions.length > 0}
						active={multiCheckboxPicker.id === 'users-filter-positions'}
						onClick={() => {
							multiCheckboxPicker.toggle('users-filter-positions');
						}}
						icon={faIdCardAlt}
						label={'Pozice'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!users.tableLoading && <ToggleButton
						id={'users-filter-roles'}
						selected={filter.roles.length > 0}
						active={multiCheckboxPicker.id === 'users-filter-roles'}
						onClick={async () => {
							multiCheckboxPicker.toggle('users-filter-roles');
							multiCheckboxPicker.setSelected(filter.roles);
							await roles.loadList();
						}}
						icon={faAlien}
						label={'Role'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!users.tableLoading && <ToggleButton
						id={'users-filter-employment'}
						selected={filter.employments.length > 0}
						active={multiCheckboxPicker.id === 'users-filter-employment'}
						onClick={() => {
							multiCheckboxPicker.toggle('users-filter-employment');
						}}
						icon={faIdCardAlt}
						label={'Pracovní poměr'}
					/> || <ToggleLoader/>}</>,
				]}
				right={[
					<>{!users.tableLoading &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'}
												color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			{!users.tableLoading && users.table.length > 0 && <Table
				header={getTableHeaders()}
				items={users.table.map((o) => getTableItem(o))}
			/> ||
			<TableLoader columns={hasAnyPermission([Permissions.USERS_LIST_ALL], identity.data?.permissions ?? []) ? 5 : 4}
									 rows={5} noData={!users.tableLoading}/>}
			{!users.tableLoading && users.table.length > 0 && <Pagination/> || <PaginationLoader/>}
			<Pad24/>
		</>
	);
};

export default UsersOverview;
