import {
	ApiCalls,
	FetchAllRolesApiResult,
	FetchAllPermissionsApiResult,
	FetchPermissionsListApiResult,
	FetchModulesListApiResult, FetchRolesListApiResult, StatusApiResult
} from ".";
import Config from "../../../../client-config.json";

const ProdApi: ApiCalls = {
	fetchAllPermissions: (page, limit, search, users, modules, roles) =>
		fetch(`${Config.api}/Permissions?pageNumber=${page}&pageSize=${limit}${search !== "" ? `&name=${encodeURIComponent(search)}` : ""}${users.map(o => `&users=${o}`)}${modules.map(o => `&modules=${encodeURIComponent(o)}`)}${roles.map(o => `&roles=${o}`)}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchAllPermissionsApiResult) => r),
	fetchPermissionsList: () =>
		fetch(`${Config.api}/Permissions/List`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchPermissionsListApiResult) => r.result.list),
	fetchModulesList: () =>
		fetch(`${Config.api}/Permissions/Modules/List`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchModulesListApiResult) => r.result.list),
	fetchAllRoles: (page, limit, search, users, permissions) =>
		fetch(`${Config.api}/Roles?pageNumber=${page}&pageSize=${limit}${search !== "" ? `&name=${encodeURIComponent(search)}` : ""}${users.map(o => `&users=${o}`)}${permissions.map(o => `&permissions=${o}`)}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchAllRolesApiResult) => r),
	fetchRolesList: () =>
		fetch(`${Config.api}/Roles`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: FetchRolesListApiResult) => r.result.list),
	fetch: (id) =>
		fetch(`${Config.api}/Roles/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
		})
			.then((r) => r.json())
			.then((r: any) => r.result),
	save: (id: string | null, name: string, permissions: string[]) =>
		fetch(`${Config.api}/Roles/${id !== null ? id : ''}`, {
			method: id !== null ? 'PUT' : 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			},
			body: JSON.stringify({
				name,
				permissions
			})
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
	delete: (id: string) =>
		fetch(`${Config.api}/Roles/${id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`
			}
		})
			.then((r) => r.json())
			.then((r: StatusApiResult) => r.isSuccess),
};
export default ProdApi;
