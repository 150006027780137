import React, {useEffect} from "react";
import {
	faArrowToBottom, faCheck, faCheckDouble,
	faPen, faPlus,
	faProjectDiagram, faSearch, faStopwatch, faTrash, faUndo, faUserFriends, faUserTie
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table, {DeleteButton} from "../../components/Table";
import Pagination from "../../components/Pagination";
import ToggleButton from "../../components/Controls/ToggleButton";
import {
	Pad24,
	Space12, Space36
} from "../../components/Creator";
import Filters, {Divider} from "../../components/Filters";
import {useSink} from "react-redux-sink";
import ThemeSink from "../../store/theme-sink";
import {ProjectItem, TextItem, TimeDurationItem} from "../../components/Table/Items";
import BorderButton from "../../components/Controls/BorderButton";
import SearchInput from "../../components/Controls/SearchInput";
import CircleButton from "../../components/Controls/CircleButton";
import IconButton from "../../components/Controls/IconButton";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import FilterSink from "../../store/filter-sink";
import NavigationSink from "../../store/navigation-sink";
import PaginationSink from "../../store/pagination-sink";
import UsersSink from "../Users/storage/users-sink";
import ProjectsSink from "../Projects/storage/projects-sink";
import CustomersSink from "../Customers/storage/customers-sink";
import DateTimePickerSink from "../../store/date-time-picker-sink";
import TimerSink, {TimerTableItemInterface} from "./storage/timer-sink";
import {toast} from "react-hot-toast";
import Routes from "../../routes";
import DateRangeControl from "../../components/Controls/DateRangeControl";
import moment from "moment";
import {
	BorderButtonLoader,
	PaginationLoader, RangeControlLoader,
	SearchInputLoader,
	TableLoader,
	ToggleLoader
} from "../../components/Loaders";
import ReportAdderSink from "./storage/report-adder-sink";
import Permissions from "../../permissions";
import IdentitySink from "../../store/identity-sink";
import Permission from "../../components/Permissions";
import {buildTotalTimeFromDuration} from "../../lib/time-lib";
import TimerStatisticsSink from "./storage/timer-statistics-sink";
import {TimerStatistics} from "../../components/Statistics";
import DialogSink from "../../store/dialog-sink";
import Checkbox from "../../components/Controls/Checkbox";
import {hasAnyPermission} from "../../lib/application-lib";
import TableEditorSink from "../../store/table-editor-sink";
import TimerItemEditorSink from "./storage/timer-item-editor-sink";

const TimerReportDetail: React.FC = () => {
	const theme = useSink(ThemeSink);
	const timer = useSink(TimerSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const filter = useSink(FilterSink);
	const navigation = useSink(NavigationSink);
	const pagination = useSink(PaginationSink);
	const users = useSink(UsersSink);
	const projects = useSink(ProjectsSink);
	const customers = useSink(CustomersSink);
	const picker = useSink(DateTimePickerSink);
	const adder = useSink(ReportAdderSink);
	const identity = useSink(IdentitySink);
	const dialog = useSink(DialogSink);
	const statistics = useSink(TimerStatisticsSink);
	const tableEditor = useSink(TableEditorSink);
	const editor = useSink(TimerItemEditorSink);

	useEffect(() => {
		return () => {
			timer.clearTable();
			pagination.clear();
			statistics.clear();
			filter.clear();
			multiCheckboxPicker.clear();
			users.clear();
			projects.clear();
			customers.clear();
			picker.clear();
			adder.clear();
			dialog.clear();
		};
	}, []);

	useEffect(() => {
		multiCheckboxPicker.items = users.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [users.list]);

	useEffect(() => {
		multiCheckboxPicker.items = projects.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [projects.list]);

	useEffect(() => {
		multiCheckboxPicker.items = customers.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [customers.list]);

	useEffect(() => {
		timer.loadTable().then();
	}, [pagination.page, pagination.limit]);

	useEffect(() => {
		timer.loadTable().then();
		statistics.loadTotal().then();
	}, [filter.from, filter.to, filter.users, filter.customers, filter.projects, filter.search]);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed) {
			if (multiCheckboxPicker.id === 'timer-brief-filter-users') filter.changeUsers(multiCheckboxPicker.selected);
			else if (multiCheckboxPicker.id === 'timer-brief-filter-customers') filter.changeCustomers(multiCheckboxPicker.selected);
			else if (multiCheckboxPicker.id === 'timer-brief-filter-projects') filter.changeProjects(multiCheckboxPicker.selected);
			else if (multiCheckboxPicker.id === 'timer-report-adder-users') adder.changeUser(multiCheckboxPicker.selected[0] ?? null);
			else if (multiCheckboxPicker.id === 'timer-report-adder-projects') adder.changeProject(multiCheckboxPicker.selected[0] ?? null);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		if (picker.confirmed) {
			if (picker.id === 'timer-brief-filter-dates') filter.changeDates(picker.dates.from, picker.dates.to);
			else if (picker.id === 'timer-report-adder-time') adder.changeDates(picker.dates.from, picker.dates.to);
			picker.clear();
		}
	}, [picker.confirmed]);

	useEffect(() => {
		if (dialog.confirm === 'confirm') {
			if (dialog.id === 'timer-report-delete') timer.deleteSelected().then();
			dialog.clear();
		}
	}, [dialog.confirm]);

	const getTableSizes = () => {
		const hasListAllPermissions = hasAnyPermission([Permissions.TIMER_LIST_ALL], identity.data?.permissions ?? []);
		const sizes = ["55px", "1fr", "1fr", "1fr"];
		if (hasListAllPermissions) sizes.push("1fr");
		return sizes;
	}

	const getTableHeaders = () => {
		const headers = [];
		const hasListAllPermissions = hasAnyPermission([Permissions.TIMER_LIST_ALL], identity.data?.permissions ?? []);

		headers.push({
			label: <Checkbox checked={timer.toDelete.length === timer.table.length} value={'all'} onChange={(e) => {
				if (timer.toDelete.length === timer.table.length) timer.uncheckAll();
				else timer.checkAll();
			}}/>
		});
		headers.push({label: "Popis"});
		headers.push({label: "Projekt"});
		if (hasListAllPermissions) headers.push({label: "Uživatel"});
		headers.push({label: "Čas"});

		return headers;
	};

	const getTableItem = (o: TimerTableItemInterface) => {
		const children = [];
		const hasListAllPermissions = hasAnyPermission([Permissions.TIMER_LIST_ALL], identity.data?.permissions ?? []);

		children.push(<Checkbox checked={timer.toDelete.includes(o.id)} value={o.id}
														onChange={(e) => timer.toggleToDelete(o.id)}/>);
		children.push(<TextItem onClick={() => showEditor(o)}
														color={o.description !== "" ? undefined : theme.colors.iceBlue}
														content={o.description !== "" ? o.description : "Bez popisu"}/>);
		children.push(<>
			{o.projectId ? <ProjectItem onClick={() => showEditor(o)}
																	project={o.projectName as string} color={o.color as string}
																	customer={o.customerName as string}/> :
				<TextItem onClick={() => showEditor(o)} color={o.projectId ? undefined : theme.colors.iceBlue} content={'Bez projektu'}/>}
		</>);
		if (hasListAllPermissions) children.push(<TextItem content={o.userName}/>);
		children.push(<TimeDurationItem	onClick={() => showEditor(o)} from={o.start} to={o.end}/>);

		return {
			id: o.id,
			children
		};
	};

	const showEditor = (o: TimerTableItemInterface) => {
		tableEditor.show('row-' + o.id, 'timer-report-item-editor');
		editor.initialize(o.id, o.description, o.projectId ?? null, o.userId, moment.utc(o.start).toDate(), moment.utc(o.end).toDate());
	};

	return (
		<>
			<ListHeader
				title={'Report'}
				icon={faStopwatch}
				headerButton={{
					label: "Stáhnout report",
					icon: faArrowToBottom,
					click: () => toast('Pracuje se na tom', {icon: '😎'}),
					permissions: [Permissions.TIMER_LIST_ALL, Permissions.TIMER_LIST]
				}}
				menu={[
					{
						label: "Souhrně",
						active: navigation.location.pathname.includes('souhrne'),
						link: Routes.paths.TIMER_REPORT_BRIEF
					},
					{
						label: "Podrobně",
						active: navigation.location.pathname.includes('podrobne'),
						link: Routes.paths.TIMER_REPORT_DETAIL
					}
				]}
			/>
			<Pad24/>
			<Permission permissions={[Permissions.TIMER_CREATE_ALL]}>
				<Filters
					left={[
						<>{!adder.saving && <SearchInput
							icon={faPen}
							iconColor={theme.colors.gold}
							value={adder.description}
							onChange={({currentTarget}) => adder.changeDescription(currentTarget.value)}
						/> || <SearchInputLoader/>}</>,
						<Space36/>,
						<>{!adder.saving && <ToggleButton
							id={'timer-report-adder-users'}
							selected={adder.user !== null}
							active={multiCheckboxPicker.id === 'timer-report-adder-users'}
							onClick={async () => {
								multiCheckboxPicker.toggle('timer-report-adder-users', true);
								multiCheckboxPicker.setSelected(adder.user ? [adder.user] : []);
								await users.loadList();
							}}
							icon={faUserFriends}
							label={'Uživatelé'}
						/> || <ToggleLoader/>}</>,
						<Space12/>,
						<>{!adder.saving && adder.user !== null && <ToggleButton
							id={'timer-report-adder-projects'}
							selected={adder.project !== null}
							active={multiCheckboxPicker.id === 'timer-report-adder-projects'}
							onClick={async () => {
								multiCheckboxPicker.toggle('timer-report-adder-projects', true);
								multiCheckboxPicker.setSelected(adder.project ? [adder.project] : []);
								await projects.loadList(true, undefined, adder.user as string);
							}}
							icon={faProjectDiagram}
							label={'Projekt'}
						/> || <ToggleLoader/>}</>
					]}
					right={[
						<>{!adder.saving && <ToggleButton
							id={'timer-report-adder-time'}
							selected={adder.from !== null && adder.to !== null}
							active={picker.id === 'timer-report-adder-time'}
							onClick={() => {
								picker.toggle('timer-report-adder-time', undefined,{
									rightPosition: true,
									showPredefinedRanges: false,
									showTime: true
								});
							}}
							icon={faStopwatch}
							label={adder.from !== null && adder.to !== null ? buildTotalTimeFromDuration(moment(adder.to).diff(adder.from)) : '0:00:00'}
						/> || <ToggleLoader/>}</>,
						<Space36/>,
						<CircleButton
							icon={faCheck}
							onClick={() => adder.submit()}
						/>,
						<Space36/>,
						<IconButton
							icon={faTrash}
							onClick={() => adder.clear()}
						/>
					]}
				/>
				<Divider/>
			</Permission>
			<Filters
				left={[
					<>{!timer.tableLoading && <DateRangeControl
						id={'timer-brief-filter-dates'}
						from={filter.from}
						to={filter.to}
						rangeStart={moment().toDate()}
						rangeEnd={moment().toDate()}
						goPrev={(from, to) => {
							toast('Téměř hotovo', {icon: '😎'})
						}}
						goNext={(from, to) => {
							toast('Téměř hotovo', {icon: '😎'})
						}}
						onClick={() => {
							picker.toggle('timer-brief-filter-dates', {from: filter.from, to: filter.to});
						}}
					/> || <RangeControlLoader/>}</>,
					<Space36/>,
					<Permission permissions={[Permissions.TIMER_LIST_ALL]}>
						{!timer.tableLoading && <ToggleButton
							id={'timer-brief-filter-users'}
							selected={filter.users.length > 0}
							active={multiCheckboxPicker.id === 'timer-brief-filter-users'}
							onClick={async () => {
								multiCheckboxPicker.toggle('timer-brief-filter-users');
								multiCheckboxPicker.setSelected(filter.users);
								await users.loadList();
							}}
							icon={faUserFriends}
							label={'Uživatelé'}
						/> || <ToggleLoader/>}
						<Space12/>
					</Permission>,
					<>{!timer.tableLoading && <ToggleButton
						id={'timer-brief-filter-customers'}
						selected={filter.customers.length > 0}
						active={multiCheckboxPicker.id === 'timer-brief-filter-customers'}
						onClick={async () => {
							multiCheckboxPicker.toggle('timer-brief-filter-customers');
							multiCheckboxPicker.setSelected(filter.customers);
							await customers.loadList();
						}}
						icon={faUserTie}
						label={'Zákazníci'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!timer.tableLoading && <ToggleButton
						id={'timer-brief-filter-projects'}
						selected={filter.projects.length > 0}
						active={multiCheckboxPicker.id === 'timer-brief-filter-projects'}
						onClick={async () => {
							multiCheckboxPicker.toggle('timer-brief-filter-projects');
							multiCheckboxPicker.setSelected(filter.projects);
							await projects.loadList();
						}}
						icon={faProjectDiagram}
						label={'Projekty'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!timer.tableLoading && <ToggleButton
						id={'timer-brief-filter-description'}
						selected={filter.search.length > 0}
						active={multiCheckboxPicker.id === 'timer-brief-filter-description'}
						onClick={async () => {
							multiCheckboxPicker.toggle('timer-brief-filter-description');
						}}
						icon={faPen}
						label={'Popis'}
					/> || <ToggleLoader/>}</>
				]}
				right={[
					<>{!timer.tableLoading &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'}
												color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			{!timer.tableLoading && timer.table.length > 0 && <Table
				sizes={getTableSizes()}
				additional={{
					left: <>
						<DeleteButton
							onClick={() => timer.toDelete.length > 0 ? dialog.init('timer-report-delete', 'Varování', 'Opravdu chcete odstranit vybrané záznamy?') : null}/>
					</>,
					right: <>
						<TimerStatistics/>
					</>
				}}
				header={getTableHeaders()}
				items={timer.table.map(o => getTableItem(o))}
			/> ||
			<TableLoader columns={hasAnyPermission([Permissions.TIMER_LIST_ALL], identity.data?.permissions ?? []) ? 5 : 4}
									 rows={5} noData={!timer.tableLoading}/>}
			{!timer.tableLoading && timer.table.length > 0 && <Pagination/> || <PaginationLoader/>}
			<Pad24/>
		</>
	);
}

export default TimerReportDetail;
