import React, {useEffect} from "react";
import {
	faAlien,
	faBalanceScale, faPuzzlePiece, faSearch,
	faStopwatch, faUndo, faUserFriends
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import {TextItem} from "../../components/Table/Items";
import {useSink} from "react-redux-sink";
import Filters from "../../components/Filters";
import SearchInput from "../../components/Controls/SearchInput";
import {Pad24, Space12, Space36} from "../../components/Creator";
import ToggleButton from "../../components/Controls/ToggleButton";
import BorderButton from "../../components/Controls/BorderButton";
import ThemeSink from "../../store/theme-sink";
import PermissionsSink from "./storage/permissions-sink";
import DetailSink from "./storage/detail-sink";
import {
	BorderButtonLoader,
	PaginationLoader,
	SearchInputLoader,
	TableLoader,
	ToggleLoader
} from "../../components/Loaders";
import PaginationSink from "../../store/pagination-sink";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import UsersSink from "../Users/storage/users-sink";
import RolesSink from "./storage/roles-sink";
import FilterSink from "../../store/filter-sink";

const PermissionsOverview: React.FC = () => {
	const theme = useSink(ThemeSink);
	const permissions = useSink(PermissionsSink);
	const pagination = useSink(PaginationSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const users = useSink(UsersSink);
	const roles = useSink(RolesSink);
	const filter = useSink(FilterSink);

	useEffect(() => {
		return () => {
			pagination.clear();
			filter.clear();
			permissions.clear();
			permissions.clear();
			multiCheckboxPicker.clear();
			users.clear();
			roles.clear();
		}
	}, []);

	useEffect(() => {
		if(multiCheckboxPicker.id === 'permissions-filter-users'){
			multiCheckboxPicker.items = users.list.map((o) => {
				return {
					label: o.name,
					value: o.id
				};
			});
		}
	}, [users.list]);

	useEffect(() => {
		if(multiCheckboxPicker.id === 'permissions-filter-roles'){
			multiCheckboxPicker.items = roles.list.map((o) => {
				return {
					label: o.name,
					value: o.id
				};
			});
		}
	}, [roles.list]);

	useEffect(() => {
		if(multiCheckboxPicker.id === 'permissions-filter-modules'){
			multiCheckboxPicker.items = permissions.modules.map((o) => {
				return {
					label: o,
					value: o
				};
			});
		}
	}, [permissions.modules]);

	useEffect(() => {
		if(multiCheckboxPicker.confirmed) {
			if(multiCheckboxPicker.id === 'permissions-filter-users') filter.changeUsers(multiCheckboxPicker.selected);
			else if(multiCheckboxPicker.id === 'permissions-filter-modules') filter.changeModules(multiCheckboxPicker.selected);
			else if(multiCheckboxPicker.id === 'permissions-filter-roles') filter.changeRoles(multiCheckboxPicker.selected);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		permissions.loadTable().then();
	}, [pagination.page, pagination.limit, filter.search, filter.users, filter.modules, filter.roles]);

	return (
		<>
			<ListHeader
				title={'Práva'}
				icon={faBalanceScale}
			/>
			<Filters
				left={[
					<>{!permissions.tableLoading && <SearchInput icon={faSearch}/> || <SearchInputLoader/>}</>,
					<Space36/>,
					<>{!permissions.tableLoading && <ToggleButton
						id={'permissions-filter-users'}
						selected={filter.users.length > 0}
						active={multiCheckboxPicker.id === 'permissions-filter-users'}
						onClick={async () => {
							multiCheckboxPicker.toggle('permissions-filter-users');
							multiCheckboxPicker.setSelected(filter.users);
							await users.loadList();
						}}
						icon={faUserFriends}
						label={'Uživatelé'}
					/> ||
					<ToggleLoader/>}</>,
					<Space12/>,
					<>{!permissions.tableLoading && <ToggleButton
						id={'permissions-filter-modules'}
						selected={filter.modules.length > 0}
						active={multiCheckboxPicker.id === 'permissions-filter-modules'}
						onClick={async () => {
							multiCheckboxPicker.toggle('permissions-filter-modules');
							multiCheckboxPicker.setSelected(filter.modules);
							await permissions.loadModules();
						}}
						icon={faPuzzlePiece}
						label={'Moduly'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!permissions.tableLoading && <ToggleButton
						id={'permissions-filter-roles'}
						selected={filter.roles.length > 0}
						active={multiCheckboxPicker.id === 'permissions-filter-roles'}
						onClick={async () => {
							multiCheckboxPicker.toggle('permissions-filter-roles');
							multiCheckboxPicker.setSelected(filter.roles);
							await roles.loadList();
						}}
						icon={faAlien}
						label={'Role'}
					/> || <ToggleLoader/>}</>
				]}
				right={[
					<>{!permissions.tableLoading &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'} color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			{!permissions.tableLoading && <Table
				header={[
					{label: "Název", sortable: true},
					{label: "Modul", sortable: true},
					{label: "Aktivních rolí", sortable: true},
					{label: "Aktivních uživatelů", sortable: true}
				]}
				items={permissions.table.map((o) => {
					return {
						children: [
							<TextItem content={o.name}/>,
							<TextItem content={o.module}/>,
							<TextItem content={o.activeRoles.toString()}/>,
							<TextItem content={o.activeUsers.toString()}/>
						]
					}
				})}
			/> || <TableLoader columns={4} rows={5}/>}
			{!permissions.tableLoading && <Pagination/> || <PaginationLoader/>}
			<Pad24/>
		</>
	);
};

export default PermissionsOverview;
