export const capitalizeFirstLetter = (string: string) => {
	return string[0].toUpperCase() + string.slice(1);
};

export const tryParseInt = (str: string, defaultValue: number | null): number | null => {
	let retValue = defaultValue;
	if(str !== null) {
		if(str.length > 0) {
			const val = parseInt(str);
			if (!isNaN(val)) {
				retValue = val;
			}
		}
	}
	return retValue;
}

export const tryParseFloat = (str: string, defaultValue: number | null): number | null => {
	let retValue = defaultValue;
	if(str !== null) {
		if(str.length > 0) {
			const val = parseFloat(str);
			if (!isNaN(val)) {
				retValue = val;
			}
		}
	}
	return retValue;
}

export const convertToPriceFormat = (price: number): string => {
	if(price === Infinity) return '∞';
	return price.toFixed(0).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ');
}

export const resolveRepeatString = (exp: string): string => {
	const split = exp.split(" ");
	if(split[1].includes("/")) return " " + split[1].split("/")[1] + " dny";
	if(split[2].includes("/")) return " " + split[2].split("/")[1] + " měsíce";
	if(split[2].includes("*")) return " " + split[2].split("*")[1] + " týdny";
	if(split[3].includes("/")) return " " + split[3].split("/")[1] + " rok";

	return "";
}
