import styled from "styled-components";
import React from "react";
import {randomNumber} from "../../lib/math-lib";
import {faSadTear} from "@fortawesome/pro-light-svg-icons/faSadTear";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HeaderButton from "../Controls/HeaderButton";
import {faUndo} from "@fortawesome/pro-regular-svg-icons";
import {toast} from "react-hot-toast";

//#region styled
export const DetailTitleLoader = styled.div`
	width: 266px;
	height: 24px;
	margin-left: 24px;
	background-color: ${({theme}) => theme.colors.lightGray};
	border-radius: 4px;
`;
export const HeaderButtonLoader = styled.div`
	width: 100px;
	height: 36px;
	position: relative;
	background-color: ${({theme}) => theme.colors.lightGray};
	border-radius: 4px;
`;
export const BorderButtonLoader = styled(HeaderButtonLoader)`
`;
const ToggleButtonLoaderContainer = styled(HeaderButtonLoader)`
`;
const ToggleButtonLoaderTitleContainer = styled(HeaderButtonLoader)`
	height: 14px;
	width: 50px;
	background-color: ${({theme}) => theme.colors.lightWhite};
	position: absolute;
	top: 11px;
	left: 16px;
`;
const DetailInfoLoaderContainer = styled.div`
	position: relative;
	width: 100%;
	height: 350px;
	border-radius: 4px;
	background-color: ${({theme}) => theme.colors.lightGray};

	&:not(:last-child) {
		margin-bottom: 24px;
	}
`;
const DetailInfoLoaderWhiteBlock = styled.div`
	background-color: ${({theme}) => theme.colors.lightWhite};
	width: calc(100% - 2px);
	height: calc(100% - 50px);
	position: absolute;
	bottom: 1px;
	left: 1px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
`;
const DetailInfoLoaderTitleBlock = styled.div`
	background-color: ${({theme}) => theme.colors.lightWhite};
	width: 166px;
	height: 18px;
	position: absolute;
	top: 14px;
	left: 36px;
	border-radius: 4px;
`;
const TableLoaderContainer = styled.div`
	width: calc(100% - 72px);
	margin: 0 36px;
	position: relative;
`;
const TableLoaderColumns = styled.div<{ columns: number; }>`
	display: grid;
	grid-template-columns: repeat(${({columns}) => columns}, 1fr);
	background-color: ${({theme}) => theme.colors.lightGray};
	height: 50px;

	&:not(:last-child) {
		margin-bottom: 2px;
	}
`;
const TableLoaderItem = styled.div`
	background-color: ${({theme}) => theme.colors.lightWhite};
	width: 60%;
	height: 18px;
	position: relative;
	top: 16px;
	left: 10%;
	border-radius: 4px;
`;
const SearchInputLoaderContainer = styled.div`
	width: 324px;
	height: 48px;
	background-color: ${({theme}) => theme.colors.lightGray};
	border-radius: 4px;
	position: relative;
`;
const SearchInputLoaderIconContainer = styled(HeaderButtonLoader)`
	height: 18px;
	width: 18px;
	background-color: ${({theme}) => theme.colors.lightWhite};
	position: absolute;
	top: 16px;
	left: 24px;
`;
const PaginationLoaderContainer = styled.div`
	width: 360px;
	margin: 52px auto 0 auto;
	position: relative;
	display: block;
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}) {
		display: flex;
		width: calc(100% - 72px);
		align-items: center;
		justify-content: space-between;
	}
`;
const PaginationLoaderLeft = styled.div`
	display: flex;
	align-items: center;
	justify-content: left;
`;
const PaginationLoaderRight = styled.div`
	display: flex;
	align-items: center;
	justify-content: right;
`;
const PaginationLoaderText = styled.div`
	background-color: ${({theme}) => theme.colors.lightGray};
	width: 50px;
	height: 18px;
	border-radius: 4px;
`;
const PaginationLoaderInput = styled.div`
	background-color: ${({theme}) => theme.colors.lightGray};
	width: 70px;
	height: 36px;
	border-radius: 4px;
	margin-left: 12px;
`;
const PaginationLoaderButton = styled.div`
	background-color: ${({theme}) => theme.colors.lightGray};
	width: 36px;
	height: 36px;
	border-radius: 4px;
	margin-left: 12px;
`;
const PaginationLoaderCircle = styled.div`
	background-color: ${({theme}) => theme.colors.lightGray};
	width: 28px;
	height: 28px;
	border-radius: 50%;
	margin-left: 12px;
`;
const ColumnGraphLoaderContainer = styled.div<{ count: number; }>`
	background-color: ${({theme}) => theme.colors.lightGray};
	width: calc(100% - 72px);
	height: 350px;
	position: relative;
	margin: 0 36px;
	display: grid;
	grid-template-columns: repeat(${({count}) => count}, 1fr);
	grid-column-gap: 1%;
	align-items: flex-end;
	justify-content: center;
`;
const ColumnGraphLoaderItem = styled.div`
	background-color: ${({theme}) => theme.colors.lightWhite};
	width: 50%;
	height: ${() => randomNumber(15, 95)}%;
	margin: 0 auto;
	transition: 1s height;
`;
const NoDataContainer = styled.div`
	width: 302px;
	height: 136px;
	position: absolute;
	padding: 24px;
	z-index: 1;
	top: calc(50% - 92px);
	left: calc(50% - 175px);
	text-align: center;
	font-size: 36px;
	color: ${({theme}) => theme.colors.darkGray};
	background-color: ${({theme}) => theme.colors.white}A0;
	border-radius: 4px;
`;
const NoDataText = styled.div`
	font: ${({theme}) => theme.fonts.Montserrat(16, "medium")};
	color: ${({theme}) => theme.colors.blueGray};
	margin: 12px 0;
`;
const CircleButtonLoaderContainer = styled(HeaderButtonLoader)`
	width: 36px;
	height: 36px;
	border-radius: 50%;
`;
const CircleButtonLoaderIconContainer = styled(HeaderButtonLoader)`
	height: 14px;
	width: 14px;
	background-color: ${({theme}) => theme.colors.lightWhite};
	position: absolute;
	top: 11px;
	left: 11px;
`;
const IconButtonLoaderContainer = styled(HeaderButtonLoader)`
	width: 24px;
	height: 24px;
	border-radius: 50%;
`;
const IconButtonLoaderIconContainer = styled(HeaderButtonLoader)`
	height: 8px;
	width: 8px;
	background-color: ${({theme}) => theme.colors.lightWhite};
	position: absolute;
	top: 8px;
	left: 8px;
`;

const CheckboxListLoaderContainer = styled.div`
	width: 100%;
	height: auto;
`;
const CheckboxListLoaderItem = styled.div`
	width: 100%;
	height: 28px;
	background-color: ${({theme}) => theme.colors.lightGray};
	border-radius: 4px;

	&:not(:last-child) {
		margin-bottom: 4px;
	}
`;
const RangeControlLoaderContainer = styled(HeaderButtonLoader)`
	width: 226px;
	height: 39px;
	border-radius: 4px;
	display: flex;
	padding: 0 12px;
	align-items: center;
	justify-content: space-between;
`;
const RangeControlLoaderSmall = styled(HeaderButtonLoader)`
	height: 14px;
	width: 14px;
	background-color: ${({theme}) => theme.colors.lightWhite};
`;
const RangeControlLoaderText = styled(RangeControlLoaderSmall)`
	width: ${randomNumber(48, 100)}px;
`;
const FullWidthButtonLoaderContainer = styled.div`
	height: 42px;
	width: 100%;
	background-color: ${({theme}) => theme.colors.lightGray};
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const FullWidthButtonLoaderText = styled.div`
	width: 50%;
	height: 14px;
	background-color: ${({theme}) => theme.colors.lightWhite};
	border-radius: 4px;
`;
const PieGraphLoaderContainer = styled.div`
	width: 550px;
	height: 550px;
	margin: 0 auto;
	position: relative;
`;
const PieGraphLoaderCircle = styled.div`
	background-color: ${({theme}) => theme.colors.lightGray};
	width: 100%;
	height: 100%;
	border-radius: 50%;
`;
const PieGraphLoaderPiece = styled.div`
`;
const TimerStatisticsLoaderContainer = styled(HeaderButtonLoader)`
	width: 227px;
	height: 47px;
	border-radius: 4px;
	padding: 12px 24px;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(2, 1fr);
	align-items: center;
`;
const TimerStatisticsLoaderLine = styled(HeaderButtonLoader)`
	width: ${() => randomNumber(20, 75)}%;
	height: 18px;
	transition: 1s width;
	background-color: ${({theme}) => theme.colors.lightWhite};
	border-radius: 4px;
`;
//#endregion

export const DetailInfoLoader: React.FC = () => {
	return (
		<DetailInfoLoaderContainer>
			<DetailInfoLoaderTitleBlock/>
			<DetailInfoLoaderWhiteBlock/>
		</DetailInfoLoaderContainer>
	);
};

export const ToggleLoader: React.FC = () => {
	return (
		<ToggleButtonLoaderContainer>
			<ToggleButtonLoaderTitleContainer/>
		</ToggleButtonLoaderContainer>
	);
};

export const CircleButtonLoader: React.FC = () => {
	return (
		<CircleButtonLoaderContainer>
			<CircleButtonLoaderIconContainer/>
		</CircleButtonLoaderContainer>
	);
};
export const IconButtonLoader: React.FC = () => {
	return (
		<IconButtonLoaderContainer>
			<IconButtonLoaderIconContainer/>
		</IconButtonLoaderContainer>
	);
};

interface TableLoaderInterface {
	columns: number;
	rows: number;
	noData?: boolean;
}

const NoData: React.FC = () => {
	return (
		<NoDataContainer>
			<FontAwesomeIcon icon={faSadTear}/>
			<NoDataText>Žádná data</NoDataText>
			<HeaderButton icon={faUndo} label={'Obnovit'} click={() => toast('Pracuje se na tom', {icon: '😎'})}/>
		</NoDataContainer>
	);
};

export const TableLoader: React.FC<TableLoaderInterface> =
	({
		 columns,
		 rows,
		 noData
	 }) => {
		return (
			<TableLoaderContainer>
				{noData && <NoData/>}
				{[...Array(rows + 1)].map((a, j) =>
					<TableLoaderColumns key={j} columns={columns}>
						{[...Array(columns)].map((o, i) =>
							<TableLoaderItem key={i}/>
						)}
					</TableLoaderColumns>
				)}
			</TableLoaderContainer>
		);
	};

export const SearchInputLoader: React.FC = () => {
	return (
		<SearchInputLoaderContainer>
			<SearchInputLoaderIconContainer/>
		</SearchInputLoaderContainer>
	);
};

export const PaginationLoader: React.FC = () => {
	return (
		<PaginationLoaderContainer>
			<PaginationLoaderLeft>
				<PaginationLoaderText/>
				<PaginationLoaderInput/>
				<PaginationLoaderButton/>
				<PaginationLoaderButton/>
				<PaginationLoaderCircle/>
				<PaginationLoaderCircle/>
				<PaginationLoaderCircle/>
				<PaginationLoaderButton/>
				<PaginationLoaderButton/>
			</PaginationLoaderLeft>
			<PaginationLoaderRight>
				<PaginationLoaderText/>
				<PaginationLoaderInput/>
			</PaginationLoaderRight>
		</PaginationLoaderContainer>
	);
};

interface ColumnGraphLoaderInterface {
	count: number;
	noData?: boolean;
}

export const ColumnGraphLoader: React.FC<ColumnGraphLoaderInterface> =
	({
		 count,
		 noData
	 }) => {
		return (
			<ColumnGraphLoaderContainer count={count}>
				{noData && <NoData/>}
				{[...Array(count)].map((o, i) =>
					<ColumnGraphLoaderItem key={i}/>
				)}
			</ColumnGraphLoaderContainer>
		);
	};

interface PieGraphLoaderProps {
	noData?: boolean;
}

export const PieGraphLoader: React.FC<PieGraphLoaderProps> =
	({
		noData
	 }) => {
	return (
		<PieGraphLoaderContainer>
			{noData && <NoData/>}
			<PieGraphLoaderCircle>
				<PieGraphLoaderPiece/>
			</PieGraphLoaderCircle>
		</PieGraphLoaderContainer>
	);
};

export const FullWidthButtonLoader: React.FC = () => {
	return (
		<FullWidthButtonLoaderContainer>
			<FullWidthButtonLoaderText/>
		</FullWidthButtonLoaderContainer>
	);
};

export const CheckboxListLoader: React.FC = () => {
	return (
		<CheckboxListLoaderContainer>
			<CheckboxListLoaderItem/>
			<CheckboxListLoaderItem/>
			<CheckboxListLoaderItem/>
			<CheckboxListLoaderItem/>
		</CheckboxListLoaderContainer>
	);
};

export const RangeControlLoader: React.FC = () => {
	return (
		<RangeControlLoaderContainer>
			<RangeControlLoaderSmall/>
			<RangeControlLoaderText/>
			<RangeControlLoaderSmall/>
		</RangeControlLoaderContainer>
	);
};

export const TimerStatisticsLoader: React.FC = () => {
	return (
		<TimerStatisticsLoaderContainer>
			<TimerStatisticsLoaderLine/>
			<TimerStatisticsLoaderLine/>
		</TimerStatisticsLoaderContainer>
	);
};
