import moment from "moment";
import styled from "styled-components";
import {useEffect} from "react";
import {useSink} from "react-redux-sink";
import DateTimePickerSink from "../../store/date-time-picker-sink";

export const CronGenWrapper = styled.div`
    padding: 0 24px 12px 24px;
`;

export const RepeatSelectWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const RepeatSelectText = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: ${props => props.theme.colors.darkGray};
`;

export const EveryInput = styled.input`
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 700;
    border: 1px solid ${props => props.theme.colors.lightWhite};
    border-radius: 4px;
    margin: 0 12px;
    padding: 6px 12px;
    width: 25px;
    background-color: transparent;
    color: ${props => props.theme.colors.blueGray};
`;

export const DEFAULT_REPEATS = [
	{
		id: "never",
		label: "Nikdy"
	},
	{
		id: "daily",
		label: "Periodicky",
		text: "dny"
	},
	{
		id: "weekly",
		label: "Týdně",
		text: "týdny"
	},
	{
		id: "monthly",
		label: "Měsíčně",
		text: "měsíce"
	},
	{
		id: "yearly",
		label: "Ročně",
		text: "roky"
	}
];

const CronGenerator = () => {
	const picker = useSink(DateTimePickerSink);

	useEffect(() => {
		let result = "* ";

		if(picker.cron.selected === "never") {
			picker.changeExpression("");
			return;
		}
		const now = moment(picker.dates.from);
		const day = now.date();
		const month = now.month() + 1;
		const year = now.year();
		if(picker.cron.selected === "daily"){
			result += day + "/" + picker.cron.every+ " " + month + " " + year;
		}
		else if(picker.cron.selected === "weekly"){
			result += day + " " + month + "*" + picker.cron.every + " " + year;
		}
		else if(picker.cron.selected === "monthly"){
			result += day + " " + month + "/" + picker.cron.every + " " + year;
		}
		else if(picker.cron.selected === "yearly"){
			result += day + " " + month + " " + year + "/" + picker.cron.every;
		}
		picker.changeExpression(result);
	}, [picker.dates.from, picker.cron.selected, picker.cron.every]);

	return <CronGenWrapper>
		<RepeatSelectWrapper>
			<RepeatSelectText>Opakovat</RepeatSelectText>
			<select onChange={({currentTarget}) => picker.changeSelected(currentTarget.value)}>
				{DEFAULT_REPEATS.map((o: { id: string, label: string }) => <option value={o.id} selected={o.id === picker.cron.selected}>{o.label}</option>)}
			</select>
		</RepeatSelectWrapper>
		{picker.cron.selected !== DEFAULT_REPEATS[0].id && <>
			<RepeatSelectWrapper>
				<RepeatSelectText>Každé</RepeatSelectText>
				<EveryInput onChange={({currentTarget}) => picker.changeEvery(currentTarget.value)}
										value={picker.cron.every.toString()}/>
				<RepeatSelectText>{DEFAULT_REPEATS.filter(o => o.id === picker.cron.selected)[0].text}</RepeatSelectText>
			</RepeatSelectWrapper>
		</>}
	</CronGenWrapper>;
};

export default CronGenerator;
