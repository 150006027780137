import React, {useEffect} from "react";
import {
	CreatorClose,
	CreatorContainer,
	CreatorContent,
	CreatorData,
	CreatorHeader,
	CreatorHeaderLeft,
	CreatorHeaderRight,
	CreatorInput,
	CreatorTextArea,
	CreatorTitleInputContainer,
	Pad24,
	SingleColumnRow, Space12,
	Space36,
	ThreeColumnsRow, ToggleRow,
	TwoColumnsFixedAndFullRow,
	TwoShortColumnsRow
} from "../../../components/Creator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-light-svg-icons";
import HeaderButton from "../../../components/Controls/HeaderButton";
import {
	faTimes,
	faSave,
	faPhone,
	faEnvelope,
	faExternalLink,
	faLink,
	faUser,
	faMapMarkerAlt,
	faUserCowboy,
	faStickyNote,
	faBirthdayCake,
	faPortalEnter,
	faCalendarDay,
	faFingerprint, faPiggyBank, faStopwatch, faFire, faClock, faAlien, faIdCardAlt
} from "@fortawesome/pro-regular-svg-icons";
import {useSink} from "react-redux-sink";
import CreatorSink from "../storage/creator-sink";
import ToggleButton from "../../../components/Controls/ToggleButton";
import MultiCheckboxPickerSink from "../../../store/multi-checkbox-picker-sink";
import UsersSink from "../storage/users-sink";
import DateTimePickerSink from "../../../store/date-time-picker-sink";
import moment from "moment";
import RolesSink from "../../Roles/storage/roles-sink";
import PositionSink from "../storage/position-sink";
import EmploymentSink from "../storage/employment-sink";
import {DetailInfoLoader, DetailTitleLoader, HeaderButtonLoader} from "../../../components/Loaders";

const Creator: React.FC = () => {
	const creator = useSink(CreatorSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const users = useSink(UsersSink);
	const roles = useSink(RolesSink);
	const positions = useSink(PositionSink);
	const employments = useSink(EmploymentSink);
	const picker = useSink(DateTimePickerSink);

	useEffect(() => {
		if (multiCheckboxPicker.confirmed) {
			if (multiCheckboxPicker.id === 'user-creator-leader') creator.changeLeader(multiCheckboxPicker.selected[0] ?? null);
			else if (multiCheckboxPicker.id === 'user-creator-positions') creator.changePositions(multiCheckboxPicker.selected);
			else if (multiCheckboxPicker.id === 'user-creator-roles') creator.changeRoles(multiCheckboxPicker.selected);
			else if (multiCheckboxPicker.id === 'user-creator-employment') creator.changeEmployment(multiCheckboxPicker.selected[0] ?? null);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		if (picker.confirmed) {
			if (picker.id === 'user-creator-start') creator.changeStartDate(picker.dates.from);
			else if (picker.id === 'user-creator-birthday') creator.changeBirthDate(picker.dates.from);
			picker.clear();
		}
	}, [picker.confirmed]);

	return (
		<CreatorContainer show={creator.opened}>
			<CreatorHeader>
				<CreatorHeaderLeft>
					<CreatorClose onClick={() => creator.close()}>
						<FontAwesomeIcon icon={faChevronLeft}/>
					</CreatorClose>
					{!creator.loading && <CreatorTitleInputContainer
						value={creator.name}
						onChange={({currentTarget}) => creator.changeName(currentTarget.value)}
					/> || <DetailTitleLoader/>}
				</CreatorHeaderLeft>
				<CreatorHeaderRight>
					{!creator.loading && <HeaderButton
						icon={faSave}
						label={'Uložit'}
						click={() => creator.submit()}
					/> || <HeaderButtonLoader/>}
					<Space36/>
					<CreatorClose onClick={() => creator.close()}>
						<FontAwesomeIcon icon={faTimes}/>
					</CreatorClose>
				</CreatorHeaderRight>
			</CreatorHeader>
			<CreatorContent>
				{!creator.loading && <CreatorData icon={faUser} label={'Osobní informace'}>
					<ThreeColumnsRow>
						<CreatorInput value={creator.phone} onChange={({currentTarget}) => creator.changePhone(currentTarget.value)}
													trailingIcon={faPhone} label={'Telefon'}/>
						<CreatorInput value={creator.email} onChange={({currentTarget}) => creator.changeEmail(currentTarget.value)}
													trailingIcon={faEnvelope} label={'Email'}/>
						<CreatorInput value={creator.address}
													onChange={({currentTarget}) => creator.changeAddress(currentTarget.value)}
													trailingIcon={faMapMarkerAlt} label={'Adresa'}/>
						<CreatorInput value={creator.bankAccount}
													onChange={({currentTarget}) => creator.changeBankAccount(currentTarget.value)}
													trailingIcon={faPiggyBank} label={'Bankovní účet'}/>
						<CreatorInput value={creator.identificationNumber}
													onChange={({currentTarget}) => creator.changeIdentificationNumber(currentTarget.value)}
													trailingIcon={faFingerprint} label={'Rodné číslo'}/>
					</ThreeColumnsRow>
					<Pad24/>
					<TwoShortColumnsRow>
						<CreatorInput disabled={picker.id === 'user-creator-birthday'}
													className={'toggle-button-user-creator-birthday'}
													value={moment(creator.birthDate).format('D.M.YYYY')}
													onClick={() => picker.toggle('user-creator-birthday', {
														from: creator.birthDate,
														to: creator.birthDate
													}, {
														showPredefinedRanges: false,
														selectRange: false
													})}
													trailingIcon={faBirthdayCake} endIcon={<FontAwesomeIcon icon={faCalendarDay}/>}
													label={'Narozeniny'}/>
						<CreatorInput disabled={picker.id === 'user-creator-start'} className={'toggle-button-user-creator-start'}
													value={moment(creator.startDate).format('D.M.YYYY')}
													onClick={() => picker.toggle('user-creator-start', {
														from: creator.startDate,
														to: creator.startDate
													}, {
														showPredefinedRanges: false,
														selectRange: false
													})}
													trailingIcon={faPortalEnter} endIcon={<FontAwesomeIcon icon={faCalendarDay}/>}
													label={'Nástup'}/>
					</TwoShortColumnsRow>
				</CreatorData> || <DetailInfoLoader/>}
				{!creator.loading && <CreatorData icon={faUserCowboy} label={'Firemní informace'}>
					<TwoColumnsFixedAndFullRow>
						<CreatorInput value={creator.hourlyWage}
													onChange={({currentTarget}) => creator.changeHourlyWage(currentTarget.value)}
													trailingIcon={faStopwatch} endIcon={<>Kč/h</>} label={'Hodinovka'}/>
						<ToggleRow>
							<ToggleButton
								id={'user-creator-leader'}
								active={multiCheckboxPicker.id === 'user-creator-leader'}
								selected={creator.leaderId !== null}
								onClick={async () => {
									multiCheckboxPicker.toggle('user-creator-leader', true);
									multiCheckboxPicker.setSelected(creator.leaderId !== null ? [creator.leaderId] : []);
									await users.loadList();
								}}
								icon={faUser}
								label={'Lídr'}
							/>
							<Space12/>
							<ToggleButton
								id={'user-creator-positions'}
								active={multiCheckboxPicker.id === 'user-creator-positions'}
								selected={creator.positions.length > 0}
								onClick={async () => {
									multiCheckboxPicker.toggle('user-creator-positions', true);
									multiCheckboxPicker.setSelected(creator.positions);
									await positions.load();
								}}
								icon={faIdCardAlt}
								label={'Pozice'}
							/>
							<Space12/>
							<ToggleButton
								id={'user-creator-roles'}
								active={multiCheckboxPicker.id === 'user-creator-roles'}
								selected={creator.roles.length > 0}
								onClick={async () => {
									multiCheckboxPicker.toggle('user-creator-roles', true);
									multiCheckboxPicker.setSelected(creator.roles);
									await roles.loadList();
								}}
								icon={faAlien}
								label={'Role'}
							/>
							<Space12/>
							<ToggleButton
								id={'user-creator-employment'}
								active={multiCheckboxPicker.id === 'user-creator-employment'}
								selected={creator.employment !== null}
								onClick={async () => {
									multiCheckboxPicker.toggle('user-creator-employment', true);
									multiCheckboxPicker.setSelected(creator.employment !== null ? [creator.employment] : []);
									await employments.load();
								}}
								icon={faIdCardAlt}
								label={'Pracovní poměr'}
							/>
						</ToggleRow>
					</TwoColumnsFixedAndFullRow>
					<Pad24/>
					<TwoShortColumnsRow>
						<CreatorInput value={creator.hourlyLoad}
													onChange={({currentTarget}) => creator.changeHourlyLoad(currentTarget.value)}
													trailingIcon={faFire} endIcon={<>h/den</>} label={'Hodinové vytížení'}/>
						<CreatorInput value={creator.availability}
													onChange={({currentTarget}) => creator.changeAvailability(currentTarget.value)}
													trailingIcon={faClock} label={'Dostupnost'}/>
					</TwoShortColumnsRow>
				</CreatorData> || <DetailInfoLoader/>}
				{!creator.loading && <CreatorData icon={faStickyNote} label={'Poznámka'}>
					<CreatorTextArea value={creator.note}
													 onChange={({currentTarget}) => creator.changeNote(currentTarget.value)}/>
				</CreatorData> || <DetailInfoLoader/>}
				{!creator.loading && <CreatorData icon={faExternalLink} label={'Odkazy'}>
					<SingleColumnRow>
						<CreatorInput value={creator.asanaUrl}
													onChange={({currentTarget}) => creator.changeAsanaUrl(currentTarget.value)}
													trailingIcon={faLink} label={'Asana'}/>
					</SingleColumnRow>
					<SingleColumnRow>
						<CreatorInput value={creator.sharePointUrl}
													onChange={({currentTarget}) => creator.changeSharePointUrl(currentTarget.value)}
													trailingIcon={faLink} label={'SharePoint'}/>
					</SingleColumnRow>
				</CreatorData> || <DetailInfoLoader/>}
			</CreatorContent>
		</CreatorContainer>
	);
};

export default Creator;
