import styled from "styled-components";
import React from "react";

const SwitcherContainer = styled.div`
	border-radius: 4px;
	width: calc(100% - 72px);
	margin: 0 36px;
	text-align: center;
`;
const SwitcherItem = styled.button<{ active: boolean; }>`
	border: 1px solid ${({theme}) => theme.colors.lightGray};
	background-color: ${({theme, active}) => active ? theme.colors.gold : 'transparent'};
	transition: .25s background-color, .25s color;
	padding: 9px 30px;
	font: ${({theme}) => theme.fonts.Montserrat(14, "semibold")};
	color: ${({theme, active}) => active ? theme.colors.white : theme.colors.darkGray};
	cursor: pointer;
	&:hover {
		background-color: ${({theme}) => theme.colors.gold};
		color: ${({theme}) => theme.colors.white};
	}
	&:first-child {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
	&:last-child {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
`;

interface SwitcherProps {
	items: {
		title: string;
		active: boolean;
		onClick(): void;
	}[];
}

const Switcher: React.FC<SwitcherProps> =
	({
		items
	 }) => {
	return (
		<SwitcherContainer>
			{items.map((o, i) =>
				<SwitcherItem key={i} active={o.active} onClick={o.onClick}>{o.title}</SwitcherItem>
			)}
		</SwitcherContainer>
	);
};

export default Switcher;
