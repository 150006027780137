import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import DetailSink from "../storage/detail-sink";
import StockSink from "./stock-sink";
import Api from "../api";
import {tryParseFloat, tryParseInt} from "../../../lib/string-lib";
import {toast} from "react-hot-toast";

interface StockItemValidationProps {
	name: boolean;
	location: boolean;
	type: boolean;
	available: boolean;
	total: boolean;
}

@sink("stock-creator", SinkFactory)
export default class CreatorSink {

	constructor(factory: SinkContainer){
		this.stock = factory.getSink(StockSink);
		this.detail = factory.getSink(DetailSink);
	}

	stock: StockSink;

	detail: DetailSink;

	@state opened: boolean = false;

	@state loading: boolean = true;

	@state id: string | null = null;

	@state title: string = "";

	@state location: string = "";

	@state type: string = "";

	@state available: string = "";

	@state total: string = "";

	@state validation: StockItemValidationProps = {
		name: true,
		location: true,
		type: true,
		available: true,
		total: true
	};

	@effect
	changeTitle(title: string) {
		this.title = title;
	}

	@effect
	changeLocation(location: string) {
		this.location = location;
	}

	@effect
	changeType(type: string) {
		this.type = type;
	}

	@effect
	changeAvailable(available: string) {
		this.available = available;
	}

	@effect
	changeTotal(total: string) {
		this.total = total;
	}

	@effect
	open(id: string | null) {
		this.opened = true;
		this.id = id;
	}

	@effect
	async load(id: string) {
		this.loading = true;
		const data = await Api.fetch(id);
		this.title = data.name;
		this.location = data.location;
		this.type = data.type;
		this.available = data.available.toString();
		this.total = data.total.toString();
		this.loading = false;
	}

	@trigger("stock-creator/id", { formatter: (id) => id })
	async idTrigger(id: string | null) {
		if(id !== null) await this.load(id as string);
		else this.loading = false;
	}

	@effect
	close() {
		this.opened = false;
		this.clear();
	}

	@effect
	clear() {
		this.opened = false;
		this.id = null;
		this.title = "";
		this.location = "";
		this.type = "";
		this.available = "";
		this.total = "";
		this.validation = {
			name: true,
			location: true,
			type: true,
			available: true,
			total: true
		};
		this.loading = true;
	}

	private static isValid (obj: StockItemValidationProps): boolean {
		return obj.name && obj.location && obj.type && obj.available && obj.total;
	}

	private createValidation(): StockItemValidationProps {
		const available = tryParseInt(this.available, null);
		const total = tryParseInt(this.total, null);
		let v = {
			name: true,
			location: true,
			type: true,
			available: true,
			total: true
		};
		if(available === null) v.available = false;
		if(total === null || (available !== null && total < available)) v.total = false;
		if(this.location === "") v.location = false;
		if(this.type === "") v.type = false;
		if(this.title === "") v.name = false;
		return v;
	}

	@effect
	validate(obj: StockItemValidationProps) {
		this.validation = obj;
	}

	@effect
	async submit() {
		const valid = this.createValidation();
		this.validate(valid);
		if(CreatorSink.isValid(valid)) {
			this.loading = true;
			const toastId = toast.loading('Probíhá ukládání...');
			const result = await Api.save(this.id, this.title, this.location, this.type, parseInt(this.available), parseInt(this.total));
			toast.dismiss(toastId);
			if(result) {
				toast.success('Uloženo');
				this.clear();
				this.detail.clear();
				await this.stock.loadTable();
			}
			this.loading = false;
		}
		else toast.error('Formulář obsahuje nevalidní data');
	}

}
