import React, {useEffect} from "react";
import {
	faBalanceScale,
	faPlus, faSearch,
	faUndo,
	faUserFriends
} from "@fortawesome/pro-regular-svg-icons";
import ListHeader from "../../components/Headers/ListHeader";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import {TextItem} from "../../components/Table/Items";
import Creator from "./components/Creator";
import Detail from "./components/Detail";
import {useSink} from "react-redux-sink";
import CreatorSink from "./storage/creator-sink";
import DetailSink from "./storage/detail-sink";
import Filters from "../../components/Filters";
import SearchInput from "../../components/Controls/SearchInput";
import ToggleButton from "../../components/Controls/ToggleButton";
import {Pad24, Space12, Space36} from "../../components/Creator";
import BorderButton from "../../components/Controls/BorderButton";
import ThemeSink from "../../store/theme-sink";
import RolesSink from "./storage/roles-sink";
import {
	BorderButtonLoader,
	PaginationLoader,
	SearchInputLoader,
	TableLoader,
	ToggleLoader
} from "../../components/Loaders";
import PermissionsSink from "./storage/permissions-sink";
import MultiCheckboxPickerSink from "../../store/multi-checkbox-picker-sink";
import UsersSink from "../Users/storage/users-sink";
import PaginationSink from "../../store/pagination-sink";
import FilterSink from "../../store/filter-sink";
import Permissions from "../../permissions";

const RolesOverview: React.FC = () => {
	const roles = useSink(RolesSink);
	const permissions = useSink(PermissionsSink);
	const creator = useSink(CreatorSink);
	const detail = useSink(DetailSink);
	const theme = useSink(ThemeSink);
	const multiCheckboxPicker = useSink(MultiCheckboxPickerSink);
	const users = useSink(UsersSink);
	const pagination = useSink(PaginationSink);
	const filter = useSink(FilterSink);

	useEffect(() => {
		return () => {
			roles.clearTable();
			pagination.clear();
			filter.clear();
			permissions.clear();
			creator.clear();
			detail.clear();
			multiCheckboxPicker.clear();
			users.clear();
		};
	}, []);

	useEffect(() => {
		multiCheckboxPicker.items = users.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [users.list]);

	useEffect(() => {
		multiCheckboxPicker.items = permissions.list.map((o) => {
			return {
				label: o.name,
				value: o.id
			};
		})
	}, [permissions.list]);

	useEffect(() => {
		if(multiCheckboxPicker.confirmed) {
			if(multiCheckboxPicker.id === 'roles-filter-users') filter.changeUsers(multiCheckboxPicker.selected);
			else if(multiCheckboxPicker.id === 'roles-filter-permissions') filter.changePermissions(multiCheckboxPicker.selected);
			multiCheckboxPicker.clear();
		}
	}, [multiCheckboxPicker.confirmed]);

	useEffect(() => {
		roles.loadTable().then();
	}, [pagination.page, pagination.limit, filter.search, filter.users, filter.permissions]);

	return (
		<>
			<Detail/>
			<Creator/>
			<ListHeader
				title={'Role'}
				icon={faBalanceScale}
				headerButton={{
					label: "Přidat roli",
					icon: faPlus,
					click: () => creator.open(null),
					permissions: [Permissions.ROLES_CREATE]
				}}
			/>
			<Filters
				left={[
					<>{!roles.tableLoading && <SearchInput icon={faSearch}/> || <SearchInputLoader/>}</>,
					<Space36/>,
					<>{!roles.tableLoading && <ToggleButton
						id={'roles-filter-users'}
						selected={filter.users.length > 0}
						active={multiCheckboxPicker.id === 'roles-filter-users'}
						onClick={async () => {
							multiCheckboxPicker.toggle('roles-filter-users');
							multiCheckboxPicker.setSelected(filter.users);
							await users.loadList();
						}}
						icon={faUserFriends}
						label={'Uživatelé'}
					/> || <ToggleLoader/>}</>,
					<Space12/>,
					<>{!roles.tableLoading && <ToggleButton
						id={'roles-filter-permissions'}
						selected={filter.permissions.length > 0}
						active={multiCheckboxPicker.id === 'roles-filter-permissions'}
						onClick={async () => {
							multiCheckboxPicker.toggle('roles-filter-permissions');
							multiCheckboxPicker.setSelected(filter.permissions);
							await permissions.loadList();
						}}
						icon={faBalanceScale}
						label={'Práva'}
					/> || <ToggleLoader/>}</>
				]}
				right={[
					<>{!roles.tableLoading &&
					<BorderButton click={() => filter.clear()} icon={faUndo} label={'Zrušit filtry'} color={theme.colors.darkGray}/> ||
					<BorderButtonLoader/>}</>
				]}
			/>
			{!roles.tableLoading && <Table
				header={[
					{label: "Název", sortable: true},
					{label: "Aktivních uživatelů", sortable: true},
					{label: "Práv", sortable: true}
				]}
				items={roles.table.map((o) => {
					return {
						children: [
							<TextItem onClick={() => detail.open(o.id)} content={o.name}/>,
							<TextItem content={o.usersCount.toString()}/>,
							<TextItem content={o.permissionsCount.toString()}/>,
						]
					};
				})}
			/> || <TableLoader columns={4} rows={5}/>}
			{!roles.tableLoading && <Pagination/> || <PaginationLoader/>}
			<Pad24/>
		</>
	);
};

export default RolesOverview;
