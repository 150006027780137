import {effect, sink, SinkContainer, SinkFactory, state, trigger} from "react-redux-sink";
import Api from "../api";
import {toast} from "react-hot-toast";
import RolesSink from "./roles-sink";
import DetailSink from "./detail-sink";

interface RoleItemValidationProps {
	name: boolean;
}

@sink("roles-creator", SinkFactory)
export default class CreatorSink {

	constructor(factory: SinkContainer){
		this.roles = factory.getSink(RolesSink);
		this.detail = factory.getSink(DetailSink);
	}

	roles: RolesSink;

	detail: DetailSink;

	@state opened: boolean = false;

	@state loading: boolean = true;

	@state id: string | null = null;

	@state name: string = "";

	@state permissions: string[] = [];

	@state validation: RoleItemValidationProps = {
		name: true
	};

	@effect
	open(id: string | null) {
		this.opened = true;
		this.id = id;
	}

	@effect
	async load(id: string) {
		this.loading = true;
		const data = await Api.fetch(id);
		this.name = data.name;
		this.permissions = data.permissions.map((o) => o.id);
		this.loading = false;
	}

	@trigger("roles-creator/id", { formatter: (id) => id })
	async idTrigger(id: string | null) {
		if(id !== null) await this.load(id as string);
		else this.loading = false;
	}

	@effect
	close() {
		this.clear();
	}

	@effect
	clear() {
		this.opened = false;
		this.id = null;
		this.name = "";
		this.permissions = [];
		this.loading = true;
		this.validation = { name: true };
	}

	@effect
	addPermission(id: string) {
		if (!this.permissions.includes(id)) this.permissions = [...this.permissions, id];
	}

	@effect
	removePermission(id: string) {
		this.permissions = this.permissions.filter((o) => o !== id);
	}

	private static isValid (obj: RoleItemValidationProps): boolean {
		return obj.name;
	}

	private createValidation(): RoleItemValidationProps {
		let v = {
			name: true
		};
		if(this.name === "") v.name = false;
		return v;
	}

	@effect
	validate(obj: RoleItemValidationProps) {
		this.validation = obj;
	}

	@effect
	async save() {
		const valid = this.createValidation();
		this.validate(valid);
		if(CreatorSink.isValid(valid)) {
			this.loading = true;
			const toastId = toast.loading('Probíhá ukládání...');
			const result = await Api.save(this.id, this.name, this.permissions);
			toast.dismiss(toastId);
			if(result) {
				toast.success('Uloženo');
				this.clear();
				this.detail.clear();
				await this.roles.loadTable();
			}
			this.loading = false;
		}
		else toast.error('Formulář obsahuje nevalidní data');
	}

}
